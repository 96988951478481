import * as React from 'react'
import { useToggle } from '@hooks/use-toggle'
import { useSelector } from 'react-redux'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { SummaryBoxPromocodesEdit } from '@modules/aside/summary-box/summary-box-codes/summary-box-promocode/summary-box-promocodes-edit'
import { SummaryBoxAddCodeRow } from '@modules/aside/summary-box/summary-box-codes/summary-box-add-code-row'
import { SummaryBoxAddedPromocodeRow } from '@modules/aside/summary-box/summary-box-codes/summary-box-promocode/smmary-box-added-promocode-row'

export const SummaryBoxPromocode = (): JSX.Element => {
  const [isEditModeEnabled, toggleIsEditModeEnabled] = useToggle()

  const reservation = useSelector(selectReservation)

  return (
    <div>
      {isEditModeEnabled && <strong className="font-size-sm summary-box__codes__text mb-2">Dodaj zniżki:</strong>}
      {isEditModeEnabled ? (
        <SummaryBoxPromocodesEdit onHide={toggleIsEditModeEnabled} />
      ) : (
        <div>
          {reservation.promocode ? (
            <SummaryBoxAddedPromocodeRow
              code={reservation.promocode.code}
              codeDescription={reservation.promocode.text}
            />
          ) : (
            <SummaryBoxAddCodeRow text="Mam kod rabatowy / pakietowy" onClick={toggleIsEditModeEnabled} />
          )}
        </div>
      )}
    </div>
  )
}
