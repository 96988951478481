import i18next from 'i18next'
import * as React from 'react'

import pl from './languages/pl'
import de from './languages/de'
import en from './languages/en'

export enum Languages {
  Pl = 'pl',
  De = 'de',
  En = 'en',
}

export const i18nInit = (lang?: Languages): void => {
  i18next.init({
    lng: lang,
    fallbackLng: Languages.Pl,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      de: {
        translation: de,
      },
      pl: {
        translation: pl,
      },
      en: {
        translation: en,
      },
    },
  })
}

const i18n = (key: string, props: {} = {}): string => i18next.t(key, props) as unknown as string

const i18nHTML = (key: string, props: {} = {}): JSX.Element => {
  const message = i18next.t(key, props) as unknown as string
  return <span dangerouslySetInnerHTML={{ __html: message }} />
}

const getCurrentLanguage = (): string => i18next.language

export { i18n, i18nHTML, getCurrentLanguage }
