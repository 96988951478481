import { CardActions } from '@components/card/card-actions'
import { IconWithText } from '@components/icon-with-text'
import * as React from 'react'
import { useModal } from '@modals/use-modal'
import { getAccommodationTypeName } from '@helpers/utils'
import { AccommodationType, Resort } from '@models/app-data'
import { PriceInformation } from '@hooks/use-price-information'

interface Props {
  onApartmentSelect: () => void
  isApartmentSelected: boolean
  accommodationType: AccommodationType
  resort: Resort
  priceInformation: PriceInformation
}

export const ApartmentCardActions = ({
  onApartmentSelect,
  isApartmentSelected,
  accommodationType,
  priceInformation,
  resort,
}: Props): JSX.Element => {
  const [toggleIsOpen] = useModal('ApartmentDetailsModal', {
    accommodationType,
    priceInformation,
    resort,
    title: getAccommodationTypeName(accommodationType),
    isApartmentSelected: isApartmentSelected,
    onApartmentSelect: onApartmentSelect,
  })

  return (
    <CardActions
      onButtonClick={onApartmentSelect}
      isActive={isApartmentSelected}
      wrapperClassName="apartment-card__actions"
    >
      <IconWithText
        text="rozwiń opis"
        iconClassName="uil-plus me-1 font-size-xxl"
        onClick={toggleIsOpen}
        wrapperClassName="text-muted"
      />
    </CardActions>
  )
}
