import * as React from 'react'
import { formatPriceShort } from '@helpers/utils'

interface Props {
  variant: 'optimum' | 'lux' | 'comfort'
  dates: string
  prices: { regularClient: number; newClient: number }
  bonuses: { firstYear: number; secondYear: number; thirdYear: number; fourthYear: number }
}

export const LocalForSubscriptionWithBenefitProductVariant = ({
  variant,
  dates,
  prices,
  bonuses,
}: Props): JSX.Element => (
  <div className={`col-sm-4 col-12 mb-5 mb-sm-0 is-${variant}`}>
    <div className="subscription-with-benefits-owner-modal__label">
      <strong className="lh-initial d-block">{variant.toUpperCase()}</strong>
      <span className="font-size-xxs text-nowrap">{dates}</span>
    </div>
    <div className="subscription-with-benefits-owner-modal__table">
      <div className="d-flex justify-content-center">
        <div className="col-5 subscription-with-benefits-owner-modal__table__price me-1">
          <strong className="d-block lh-initial font-size-sm">{formatPriceShort(prices.newClient)}</strong>
          <span className="font-size-xxs">Nowi Klienci</span>
        </div>
        <div className="col-5 subscription-with-benefits-owner-modal__table__price is-regular-client ms-1">
          <strong className="d-block lh-initial font-size-sm">{formatPriceShort(prices.regularClient)}</strong>
          <span className="font-size-xxs">Stali Klienci</span>
        </div>
      </div>
      <ul className="mt-3 px-4 font-size-sm text-dark">
        <Point number={1} title={<strong className="text-danger">Elastyczny</strong>} />
        <Point number={2} title={<GiftAmount amount={bonuses.firstYear} />} />
        <Point number={3} title={<GiftAmount amount={bonuses.secondYear} />} />
        <Point number={4} title={<GiftAmount amount={bonuses.thirdYear} />} />
        <Point
          number={5}
          title={
            <strong>
              <GiftAmount amount={bonuses.fourthYear} /> <span className="text-danger">+ Pakiet VIP</span>
            </strong>
          }
        />
      </ul>
    </div>
  </div>
)

const Point = ({ number, title }) => (
  <li className="d-flex align-items-center font-size-xxs mb-1">
    <span className="subscription-with-benefits-owner-modal__table__point">{number}</span>{' '}
    <div>
      <span className="fw-semi-bold">Pobyt</span> {title}
    </div>
  </li>
)

const GiftAmount = ({ amount }) => (
  <strong className="text-dark">
    + <i className="uil-gift" /> {formatPriceShort(amount)}
  </strong>
)
