import * as React from 'react'
import * as clsx from 'clsx'
import { setHideModal, setModalIsOpen } from '@store/actions/modal-actions'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { RootState } from '@store/index'
import { useDevicesSize } from '@hooks/use-devices-size'
import { ModalConfig, ModalParams, ModalsMap, ModalTypes } from '@modals/types'
import { ModalHeader } from '@modals/modal-header'

interface ModalComponentRowProps {
  modal: ModalTypes
  params?: ModalParams
  config?: ModalConfig
  isOpen: boolean
}

export const ModalComponentRow: React.FC<ModalComponentRowProps> = ({ modal, isOpen, params, config }) => {
  const dispatch = useDispatch()
  const { isDesktop } = useDevicesSize()

  const [ModalContent, options] = ModalsMap[modal]

  const modals = useSelector((state: RootState) => state.modalState.modals)
  const lastModal = modals[modals.length - 1]

  const onClose = React.useCallback(() => {
    config?.onClose?.()
    return dispatch(setHideModal(modal))
  }, [modal, config])

  const toggleIsOpen = React.useCallback(() => {
    config?.onHide?.()
    dispatch(setModalIsOpen(modal, !isOpen))
  }, [modal, isOpen])

  return (
    <Modal
      key={modal}
      show={isOpen}
      onHide={toggleIsOpen}
      onExited={onClose}
      centered={isDesktop}
      fullscreen="xl-down"
      backdropClassName="custom-modal__backdrop"
      data-modal-active={lastModal?.modal === modal && lastModal.isOpen && 'active'}
      {...options}
      className={clsx('custom-modal', options.className)}
    >
      <ModalHeader title={params?.title} isSendLinkVisible={!!params?.isSendLinkVisible} toggleIsOpen={toggleIsOpen} />
      <ModalContent {...params} toggleIsVisible={toggleIsOpen} />
    </Modal>
  )
}
