import * as React from 'react'
import { SendLinkButton } from '@components/controls/send-link-button'
import { CollapsableInformation } from '@components/collapsable-information'
import { ResortDetails, ResortFaq } from '@models/resort'

interface Props {
  resortDetails: ResortDetails
}

export const ApartmentDetailsGlobalInformation = ({ resortDetails }: Props): JSX.Element => (
  <div className="mt-2">
    <CollapsableInformation
      infos={resortDetails.faqs}
      collapsableItemClassName="collapsable-item__primary px-3"
      contentRenderer={collapsableInformationRenderer}
    />
    <div className="container-lg px-xl-0">
      <SendLinkButton className="my-2 d-xl-none" />
    </div>
  </div>
)

const collapsableInformationRenderer = (information: ResortFaq) => (
  <div className="mb-4">
    <span className="d-block font-size-lg mb-2">{information.subtitle}</span>
    <span>{information.description}</span>
  </div>
)
