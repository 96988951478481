import * as React from 'react'
import { ReservationImprovement, ResortImprovement } from '@models/reservation'
import { SummaryBoxReservationInformationItem } from '@modules/aside/summary-box/summary-box-reservation-information-item'
import { formatPrice } from '@helpers/utils'
import { useSelector } from 'react-redux'
import { selectReservationFeedings, selectReservationImprovements } from '@store/selectors/reservation-selectors'
import { SummaryBoxReservationSingleImprovement } from '@modules/aside/summary-box/summary-box-reservation-single-improvement'
import {
  createSingleFeedingImprovement,
  getGroupedImprovements,
  isFeeding,
  isPaidByVip,
  isStayOption,
  isVip,
  sortImprovementByPosition,
} from '@helpers/improvements'
import { useModal } from '@modals/use-modal'
import { selectResortDetails } from '@store/selectors/resort-selectors'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'
import { useVipModals } from '@modules/improvement-step/improvements-sections/kinds/vip/use-vip-modals'

export const SummaryBoxImprovements = (): JSX.Element | null => {
  const [reservationImprovement, setReservationImprovement] = React.useState<ReservationImprovement | null>(null)

  const resortDetails = useSelector(selectResortDetails)
  const reservationImprovements = useSelector(selectReservationImprovements)
  const reservationFeedings = useSelector(selectReservationFeedings)

  const getResortImprovement = React.useCallback(
    (reservationImprovement: ReservationImprovement | null) =>
      resortDetails.improvements.find(
        (resortImprovement: ResortImprovement) => resortImprovement.code === reservationImprovement?.code,
      ),
    [resortDetails.improvements],
  )

  const handleImprovementDelete = (improvement: ReservationImprovement) => () => {
    showImprovementDeleteModal(null, { improvement, onEdit: () => handleEditSelection(improvement) })
  }

  const handleEditSelection = (improvement: ReservationImprovement) => {
    hideImprovementDeleteModal()
    setReservationImprovement(improvement)
  }

  useDidUpdateEffect(() => {
    if (reservationImprovement) {
      showModal(reservationImprovement)
      setReservationImprovement(null)
    }
  }, [reservationImprovement])

  const showModal = (improvement: ReservationImprovement) => {
    if (isFeeding(improvement)) {
      showFeedingDetailsModal(null, { feedingImprovement: improvement })
      return
    }

    if (isVip(improvement)) {
      showVipDetailsModal()
      return
    }

    const resortImprovement = getResortImprovement(improvement)
    if (!resortImprovement) {
      showNotificationModal()
      return
    }

    if (isStayOption(improvement)) {
      showStayOptionModal(null, { resortImprovement })
      return
    }

    showImprovementDetailsModal(null, { resortImprovement })
  }

  const { stayOptionsImprovements, restImprovements, vipImprovements } =
    getGroupedImprovements<ReservationImprovement>(reservationImprovements)

  const improvements = sortImprovementByPosition(
    [
      ...vipImprovements,
      ...stayOptionsImprovements,
      ...restImprovements,
      ...(reservationFeedings.items.length ? [createSingleFeedingImprovement(reservationFeedings)] : []),
    ].filter((improvement: ReservationImprovement) => !isPaidByVip(improvement)),
  )

  const { showVipDetailsModal } = useVipModals(getResortImprovement(reservationImprovement))

  const [showNotificationModal] = useModal('NotificationMessageModal', {
    title: 'Błąd',
    message: {
      message: 'Przepraszamy, nie można edytować wybranego ulepszenia.',
      variant: 'error',
    },
  })

  const [showImprovementDeleteModal, hideImprovementDeleteModal] = useModal('ImprovementDeleteModal', {
    improvement: null,
    onEdit: handleEditSelection,
  })

  const [showFeedingDetailsModal] = useModal('FeedingDetailsModal', { title: 'Szczegóły wyżywienia:' })
  const [showImprovementDetailsModal] = useModal('ImprovementDetailsModal', { title: 'Szczegóły ulepszenia:' })
  const [showStayOptionModal] = useModal('StayOptionDetailsModal', { title: 'Szczegóły ulepszenia' })

  if (!reservationImprovements.length) return null

  const improvementPrice = (improvement: ReservationImprovement) =>
    isFeeding(improvement) ? reservationFeedings.total_price_brutto : improvement.price_brutto
  return (
    <div className="py-2 summary-box__improvements">
      <div className="container-lg">
        <div className="justify-content-between mb-1 d-flex">
          <strong className="summary-box__improvements__title">Wybrane ulepszenia:</strong>
        </div>
        <div className="d-flex flex-column">
          {improvements.map((improvement: ReservationImprovement) => (
            <SummaryBoxReservationInformationItem
              key={improvement.id}
              icon="uil-check"
              value={`+ ${formatPrice(improvementPrice(improvement))}`}
              title={
                <SummaryBoxReservationSingleImprovement
                  improvement={improvement}
                  onDelete={handleImprovementDelete(improvement)}
                  onEdit={() => setReservationImprovement(improvement)}
                />
              }
            />
          ))}
        </div>
      </div>
    </div>
  )
}
