import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useFormRequest } from '@hooks/use-form-request'
import { addPromocode } from '@requests/reservation/reservation-requests'
import { useDispatch } from 'react-redux'
import { SummaryBoxCodeInput } from '@modules/aside/summary-box/summary-box-codes/summary-box-code-input'

interface Props {
  onHide: () => void
}

export const SummaryBoxPromocodesEdit = ({ onHide }: Props): JSX.Element => {
  const methods = useForm({
    defaultValues: {
      code: '',
    },
  })

  const dispatch = useDispatch()

  const { handleAction, isLoading } = useFormRequest(methods.setError)

  const handleAddPromocode = async () => {
    methods.clearErrors('code')
    const { code } = methods.getValues()
    const { isResolved } = await handleAction(() => dispatch(addPromocode(code)))
    if (isResolved) onHide()
  }

  return (
    <FormProvider {...methods}>
      <SummaryBoxCodeInput inputName="code" isLoading={isLoading} onCodeAdd={handleAddPromocode} onClose={onHide} />
    </FormProvider>
  )
}
