import * as React from 'react'
import * as clsx from 'clsx'
import { formatDate } from '@helpers/date-formatter'
import { ElementRenderer } from '@components/element-renderer'

interface DateElementProps {
  date: string
  icon: string
  title: string
  onClick?: () => void
}

const DateElement = ({ icon, title, onClick, date }: DateElementProps): JSX.Element => (
  <ElementRenderer
    renderAs={onClick ? 'button' : 'div'}
    className="d-flex cursor-pointer btn-unstyled px-0"
    onClick={onClick}
  >
    <h3 className="text-darker-gray align-self-center my-0 d-xl-none me-3">{formatDate(date, 'dd')}</h3>
    <div>
      <div className="d-flex align-items-center">
        <i className={clsx('me-1 text-primary font-size-xxl d-none d-xl-inline', icon)} />
        <small className="text-muted d-block font-size-sm fw-semi-bold">{title}</small>
      </div>
      <strong className="d-none d-xl-inline text-darker-gray me-1 font-size-lg">{formatDate(date, 'dd')}</strong>
      <strong className="text-darker-gray font-size-lg">{formatDate(date, 'MMMM yyyy')}</strong>
    </div>
  </ElementRenderer>
)

export default DateElement
