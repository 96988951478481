export enum LoaderActionTypesKeys {
  SET_LOADER_VISIBLE = 'SET_LOADER_VISIBLE',
  SET_LOADER_HIDDEN = 'SET_LOADER_HIDDEN',
}

export interface SetLoaderVisibleAction {
  type: LoaderActionTypesKeys.SET_LOADER_VISIBLE
}

export interface SetLoaderHiddenAction {
  type: LoaderActionTypesKeys.SET_LOADER_HIDDEN
}

export type LoaderActionsTypes = SetLoaderVisibleAction | SetLoaderHiddenAction
