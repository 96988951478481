import { AnyAction, configureStore, ThunkDispatch } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import modalState from '@store/reducers/modal-reducer'
import appDataState from '@store/reducers/app-data-reducers'
import reservationState from '@store/reducers/reservation-reducer'
import resortState from '@store/reducers/resort-reducer'
import stepsState from '@store/reducers/steps-reducer'
import webSocketState from '@store/reducers/websocket-reducers'
import clientDetailsState from '@store/reducers/client-details-reducers'
import notificationMessagesState from '@store/slices/notification-messages-slice'
import loaderState from '@store/reducers/loader-reducer'
import formErrorsState from '@store/reducers/form-errors-reducer'
import pageLeaveState from '@store/reducers/page-leave-reducer'
import { throwMiddleware } from '@store/throw-middleware'
import type {} from 'redux-thunk/extend-redux'
import dateRangeSlice from '@store/slices/date-range-slice'

export const store = configureStore({
  reducer: {
    appDataState,
    modalState,
    reservationState,
    dateRangeSlice,
    resortState,
    stepsState,
    webSocketState,
    clientDetailsState,
    notificationMessagesState,
    loaderState,
    formErrorsState,
    pageLeaveState,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(throwMiddleware),
})

export type RootState = ReturnType<typeof store.getState>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>

export const getState = (): RootState => store.getState()
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
