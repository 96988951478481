import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setWebSocketData } from '@store/actions/websocket-actions'
import { RootState } from '@store/index'

const protocol = document.location.protocol === 'http:' ? 'ws' : 'wss'
const port = document.location.port ? `:${document.location.port}` : ''

export const socketUrl = `${protocol}://${document.location.hostname}${port}/ws/website/`

export const WebSocketHandler = (): null => {
  const dispatch = useDispatch()
  const token = useSelector((state: RootState) => state.reservationState.reservation.token)

  React.useEffect(() => {
    let webSocket

    if (token) {
      webSocket = connect()
    }

    return () => webSocket?.close()
  }, [token])

  const connect = () => {
    const webSocket = new WebSocket(socketUrl)

    webSocket.onmessage = (e: MessageEvent) => {
      const data = JSON.parse(e.data)
      if (data.event) {
        dispatch(setWebSocketData(data.event))
      }
    }

    webSocket.addEventListener('open', () => {
      if (token) {
        webSocket.send(JSON.stringify({ payload: { token: token }, event: 'LOGIN_TOKEN' }))
      }
    })

    webSocket.onclose = () => {
      setTimeout(connect, 3000)
    }

    return webSocket
  }

  return null
}
