import * as React from 'react'
import { Gallery } from '@components/gallery'
import { useSelector } from 'react-redux'
import { useResponsivenessImages } from '@hooks/use-responsiveness-images'
import { SummaryBoxReservationInformation } from '@modules/aside/summary-box/summary-box-reservation-information'
import { SummaryBoxImprovements } from '@modules/aside/summary-box/summary-box-improvements'
import { SummaryBoxCodes } from '@modules/aside/summary-box/summary-box-codes/summary-box-codes'
import { SummaryBoxPrices } from '@modules/aside/summary-box/summary-box-prices'
import { SummaryBoxReservationExpiration } from '@modules/aside/summary-box/summary-box-reservation-expiration'
import { selectStep } from '@store/selectors/steps-selectors'
import { SummaryBoxWarranty } from '@modules/aside/summary-box/summary-box-warranty'
import { selectResortDetails } from '@store/selectors/resort-selectors'
import { selectResorts } from '@store/selectors/app-data-selectors'
import { Image, Resort } from '@models/app-data'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { SubscriptionContractActiveLabel } from '@modules/aside/subscription-contract-info/subscription-contract-active-label'

interface Props {
  hasActiveSubscriptionContract: boolean
}

export const SummaryBox = ({ hasActiveSubscriptionContract }: Props): JSX.Element => {
  const { expire_after } = useSelector(selectReservation)
  const resortDetails = useSelector(selectResortDetails)
  const resorts = useSelector(selectResorts)
  const step = useSelector(selectStep)

  const resortsImages = React.useMemo(
    () =>
      resorts.reduce((resortImages: Image[], resort: Resort) => [...resortImages, ...resort.reservation_images], []),
    [resorts],
  )

  const images = useResponsivenessImages(
    resortDetails?.reservation_images?.length ? resortDetails?.reservation_images : resortsImages,
    'xs',
  )

  return (
    <div className="summary-box">
      <div className="summary-box__floating">
        <div>
          <Gallery showIndex={false} additionalClass="summary-box-gallery" items={images} />
          <div className="summary-box__main text-white">
            <div className="d-flex justify-content-between pt-3 px-3 mb-1">
              <strong className="font-size-xl">Twój pobyt:</strong>
              {/*<SummaryBoxReservationActions />*/}
            </div>
            <SummaryBoxReservationInformation wrapperClassName="px-3 pb-3" />
            <SummaryBoxImprovements />
            <SummaryBoxWarranty />
            {step.stepNumber > 2 && <SummaryBoxCodes wrapperClassName="px-3" />}
          </div>
          <SummaryBoxPrices wrapperClassName="summary-box__prices text-white" />
        </div>
        {hasActiveSubscriptionContract && <SubscriptionContractActiveLabel />}
        {step.stepNumber > 1 && expire_after && <SummaryBoxReservationExpiration expirationTime={expire_after} />}
      </div>
    </div>
  )
}
