import 'core-js/stable'
import 'regenerator-runtime/runtime'

import 'react-status-alert/dist/status-alert.css'

import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import 'dates-selection-range/build/styles.css'

import '@assets/styles/app.scss'
import '@iconscout/unicons/css/line.css'
import { NavigationPath } from '@models/routes'
import store from '@store/index'
import * as React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import StatusAlert from 'react-status-alert'
import StartupView from './startup'
import { MainLayout } from '@modules/main-layout'
import * as smoothscroll from 'smoothscroll-polyfill'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { CrashView } from '@components/crash-view'
import { WebSocketHandler } from '@components/web-socket-handler'
import { NotFound } from '@modules/404'
import { AccommodationSelectionStep } from '@modules/accommodation-selection-step/accommodation-selection-step'
import { ModalComponent } from '@modals/modal-component'
import { useNotificationModal } from '@hooks/use-notification-modal'
import { ImprovementsStep } from '@modules/improvement-step/improvements-step'
import { SummaryStep } from '@modules/summary-step/summary-step'
import TagManager from 'react-gtm-module'
import { useGtm } from '@hooks/use-gtm'
import { usePageLeaveConfirmation } from '@hooks/use-page-leave-confirmation'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'

smoothscroll.polyfill()

if (process.env.SENTRY_RESERVATION_APP_NEW_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_RESERVATION_APP_NEW_DSN,
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.3,
  })
}

if (process.env.GOOGLE_TAG_MANAGER) {
  TagManager.initialize({ gtmId: process.env.GOOGLE_TAG_MANAGER })
}

const AppContainer = (props): JSX.Element => {
  const location = useLocation()
  const { trackPage } = useGtm()
  useNotificationModal()
  usePageLeaveConfirmation()

  useDidUpdateEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    trackPage(location.pathname)
  }, [location.pathname])

  return props.children
}

interface State {
  loadFailed: boolean
}

export default class AppView extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props)

    this.state = {
      loadFailed: false,
    }
  }

  public showCrash = (): void => {
    this.setState({ loadFailed: true })
  }

  public render(): React.ReactNode {
    const { loadFailed } = this.state

    return (
      <Provider store={store}>
        <StartupView onLoadFail={this.showCrash}>
          <BrowserRouter basename="/">
            <AppContainer>
              <StatusAlert />
              <WebSocketHandler />
              <ModalComponent />
              <MainLayout>
                <Routes>
                  <Route path={NavigationPath.AccommodationSelectionStep} element={<AccommodationSelectionStep />} />
                  <Route path={NavigationPath.ImprovementStep} element={<ImprovementsStep />} />
                  <Route path={NavigationPath.SummaryStep} element={<SummaryStep />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </MainLayout>
            </AppContainer>
          </BrowserRouter>
        </StartupView>
        {loadFailed && <CrashView />}
      </Provider>
    )
  }
}
