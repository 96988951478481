import * as React from 'react'
import { SpinInput } from '@components/controls/spin-input'
import { useFormContext, useWatch } from 'react-hook-form'
import { FormError } from '@components/controls/form-error'
import { ImprovementDetailsFormInputs } from '@modules/improvement-step/improvements-sections/kinds/improvements/improvement-details-modal'

export const ImprovementAmountChange = (): JSX.Element => {
  const { control, setValue } = useFormContext<ImprovementDetailsFormInputs>()

  const amount = useWatch({ control, name: 'amount' })
  const maxAmount = useWatch({ control, name: 'maxAmount' })

  const handleAmountChange = value => {
    setValue('amount', value)
  }

  return (
    <>
      <div className="mb-2 mb-sm-0 improvement-modal__amount-change">
        <strong className="text-darker-gray text-sm-end d-block fw-semi-bold mb-1">Ilość ulepszeń:</strong>
        <SpinInput
          value={amount}
          min={1}
          max={maxAmount}
          className="mx-5 btn-tall"
          readOnly={true}
          onIncrement={handleAmountChange}
          onDecrement={handleAmountChange}
        />
      </div>
      <FormError inputName="amount" className="mt-n3" />
    </>
  )
}
