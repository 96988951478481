import * as React from 'react'
import { ModalParams, ModalTypes, ModalConfig } from './types'
import { useDispatch, useSelector } from 'react-redux'
import { setModalIsOpen, setShowModal } from '@store/actions/modal-actions'
import { SetModalIsOpenAction, SetShowModalAction } from '@store/action-types/modal-action-types'
import { selectModals } from '@store/selectors/modal-selectors'

type UseModal = [
  (event?, optionalParams?: ModalParams, showModal?: boolean) => SetModalIsOpenAction | SetShowModalAction,
  () => SetModalIsOpenAction,
]

export const useModal = (modalName: ModalTypes, modalParams?: ModalParams, config?: ModalConfig): UseModal => {
  const dispatch = useDispatch()
  const isOpened = useSelector(selectModals).some(row => row.modal === modalName)

  React.useEffect(() => {
    return () => {
      if (config?.persist) {
        dispatch(setModalIsOpen(modalName, false))
      }
    }
  }, [])

  const toggleIsOpen = React.useCallback(
    (event?, optionalParams?: ModalParams, showModal?: boolean) => {
      if (isOpened && !showModal) {
        return hideModal()
      } else {
        return dispatch(setShowModal(modalName, { ...modalParams, ...optionalParams }, config))
      }
    },
    [modalName, isOpened, modalParams],
  )

  const hideModal = React.useCallback(() => dispatch(setModalIsOpen(modalName, false)), [modalName])

  return [toggleIsOpen, hideModal]
}
