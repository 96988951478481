import * as React from 'react'
import { useSelector } from 'react-redux'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { SubscriptionWithBenefitCodeLabel } from '@components/labels/subscription-with-benefit/subscription-with-benefit-code-label'

export const LocalSelectionMapApartmentsListLabels = (): JSX.Element => {
  const { promocode } = useSelector(selectReservation)

  return <div>{promocode && <SubscriptionWithBenefitCodeLabel className="mt-sm-n5 pb-sm-4" />}</div>
}
