import * as React from 'react'
import * as clsx from 'clsx'
import { CustomFormGroup } from '@components/controls/form-controls/custom-form-group'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useToggle } from '@hooks/use-toggle'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'
import { BookingGuest } from '@models/reservation'
import { formatPriceShort } from '@helpers/utils'
import { IconWithText } from '@components/icon-with-text'
import { deleteGuests, updateGuest } from '@requests/reservation/guest-requests'
import { useFormRequest } from '@hooks/use-form-request'
import { useDispatch } from 'react-redux'
import { FeedingDetailsModalGuestBirthdaySelection } from '@modules/improvement-step/improvements-sections/kinds/feedings/feeding-details-modal-guest-birthday-selection'
import { TextWithSpinner } from '@components/loaders/text-with-spinner'

export interface FeedingDetailsModalFormInputs {
  type: string
}

interface Props {
  childGuest: BookingGuest
  position: number
  onClearError: () => void
}

export const FeedingDetailsModalGuestRow = ({ childGuest, position, onClearError }: Props): JSX.Element => {
  const [isBirthDateCalendarVisible, toggleBirthDateCalendarVisibility] = useToggle()

  const methods = useForm<FeedingDetailsModalFormInputs>({
    defaultValues: {
      type: childGuest.birthday,
    },
  })

  const { handleAction } = useFormRequest(methods.setError)
  const { handleAction: handleRemove, isLoading: isRemoving } = useFormRequest(methods.setError)

  const dispatch = useDispatch()

  const birthday = useWatch({ control: methods.control, name: 'type' })

  useDidUpdateEffect(() => {
    onClearError()
    handleChildBirthdayChange()
  }, [birthday])

  React.useEffect(() => {
    methods.clearErrors()
    methods.setValue('type', childGuest.birthday)
  }, [childGuest])

  const handleChildBirthdayChange = async () => {
    await handleAction(() => dispatch(updateGuest({ birthday, wallet_limits: [] }, childGuest.urls.details)))
  }

  const handleChildRemove = async () => {
    await handleRemove(() => dispatch(deleteGuests([childGuest.id])))
  }

  return (
    <FormProvider {...methods}>
      <div>
        <div className="d-flex flex-sm-row flex-column align-items-start">
          <div className="d-flex align-items-center col-12 col-sm-6 mt-4">
            <button
              className="uil-trash-alt me-3 text-darker-gray font-size-xxl btn-unstyled"
              onClick={handleChildRemove}
            />
            <TextWithSpinner
              isLoading={isRemoving}
              text={`Data urodzenia dziecka #${position}:`}
              loadingText="Usuwanie"
              spinnerColor="primary"
              wrapperClassName="text-darker-gray fw-semi-bold"
            />
          </div>

          <CustomFormGroup
            inputName="type"
            formGroupProps={{ className: 'col-12 col-sm-6' }}
            formControlProps={{
              readOnly: true,
              className: 'cursor-pointer bg-white',
              onClick: toggleBirthDateCalendarVisibility,
            }}
          />
        </div>
        {childGuest.type === 'baby' && (
          <IconWithText
            wrapperClassName="mt-2 col-12 col-sm-6 ms-auto"
            text={`Dzieci poniżej 2 lat wyżywienie ${formatPriceShort(0)}`}
            textClassName="fw-semi-bold text-darker-gray font-size-sm"
            iconClassName="uil-exclamation-circle font-size-xl text-primary me-2"
          />
        )}
      </div>

      <div className={clsx('feeding-modal__guest-birthday-selection', { 'is-visible': isBirthDateCalendarVisible })}>
        <FeedingDetailsModalGuestBirthdaySelection
          onBirthdayDateSelect={toggleBirthDateCalendarVisibility}
          birthday={birthday}
        />
      </div>
    </FormProvider>
  )
}
