import * as React from 'react'
import * as clsx from 'clsx'
import { Controller, useFormContext } from 'react-hook-form'
import InputMask, { Props } from 'react-input-mask'

export interface MaskedFormControlProps extends Props {
  name: string
  className?: string
  formatValue?: (value) => string
  hasError?: boolean
}

export const MaskedFormControl = ({
  name,
  className,
  formatValue,
  hasError,
  ...props
}: MaskedFormControlProps): JSX.Element => {
  const { control } = useFormContext()

  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <InputMask
          id={name}
          className={clsx(
            'shadow-none py-3 font-size-sm form-control form-control-with-status',
            { 'is-empty': !value },
            { 'is-failed': hasError },
            className,
          )}
          value={formatValue ? formatValue(value) : value}
          onChange={onChange}
          {...props}
        />
      )}
      control={control}
      name={name}
    />
  )
}
