import { WebSocketState } from '@store/reducers/websocket-reducers'

export enum WebSocketActionTypesKeys {
  SET_WEBSOCKET_DATA = 'SET_WEBSOCKET_DATA',
}

export interface SetWebSocketDataAction {
  type: WebSocketActionTypesKeys.SET_WEBSOCKET_DATA
  payload: WebSocketState
}

export type WebSocketActionsTypes = SetWebSocketDataAction
