import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  className?: string
  kind?: 'bordered' | 'grow'
  role?: string
  color?: string
}

export const Spinner = ({ className, kind = 'bordered', color = 'primary', role = 'spinner' }: Props): JSX.Element => (
  <div
    role={role}
    className={clsx(
      { 'spinner-border': kind === 'bordered', 'spinner-grow': kind === 'grow' },
      `text-${color}`,
      className,
    )}
  />
)
