import * as React from 'react'
import { Checkbox } from '@components/controls/checkbox'
import { useFormContext, useWatch } from 'react-hook-form'
import { SummaryStepFormInputs } from '@modules/summary-step/summary-step'
import { ExpandableText } from '@components/expandable-text'
import { Rules } from '@helpers/rules'

export const MARKETING_RULES_KEYWORDS = ['email_rules', 'sms_rules', 'phone_call_rules'] as const

export const SummaryStepMarketingConsents = (): JSX.Element => {
  const { control, setValue } = useFormContext<SummaryStepFormInputs>()

  const marketingRules = useWatch({ control, name: MARKETING_RULES_KEYWORDS })
  const selectedMarketingRules = marketingRules.filter(Boolean)

  const isAnyMarketingRuleSelected =
    selectedMarketingRules.length > 0 && selectedMarketingRules.length < MARKETING_RULES_KEYWORDS.length

  const handleAllSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked
    MARKETING_RULES_KEYWORDS.forEach(key => setValue(key, newValue))
  }

  React.useEffect(() => {
    const isEachSelected = marketingRules.every(Boolean)

    setValue('allMarketingRulesSelected', isEachSelected)
  }, [marketingRules])

  return (
    <>
      <Checkbox
        className="checkbox--small font-size-sm is-muted-label align-items-start"
        name="allMarketingRulesSelected"
        id="allMarketingRulesSelected"
        onChange={handleAllSelection}
        label={
          <div className="mt-1">
            Zgody marketingowe <span className="font-size-xs">(zaznacz wszystkie)</span>
            <small className="d-block mt-1">{Rules.marketing_rules}</small>
          </div>
        }
        indeterminate={isAnyMarketingRuleSelected}
      />

      <div className="ms-3 mt-1">
        <Checkbox
          name="email_rules"
          id="email_rules"
          label={
            <div className="mt-1">
              Zgoda na dostarczanie powiadomień i ofert poprzez pocztę elektroniczną.
              <ExpandableText text={Rules.email_rules} initialHeight={0} />
            </div>
          }
          className="checkbox--small font-size-sm fw-normal font-size-sm is-muted-label align-items-start"
        />
        <Checkbox
          name="sms_rules"
          id="sms_rules"
          label={
            <div className="mt-1">
              Zgoda na dostarczanie powiadomień i ofert poprzez SMS.
              <ExpandableText text={Rules.sms_rules} initialHeight={0} />
            </div>
          }
          className="checkbox--small font-size-sm fw-normal font-size-sm is-muted-label align-items-start"
        />
        <Checkbox
          name="phone_call_rules"
          id="phone_call_rules"
          label={
            <div className="mt-1">
              <span className="mb-1 d-block">Zgoda na przedstawianie ofert poprzez kontakt telefoniczny.</span>
              <ExpandableText text={Rules.phone_call_rules} initialHeight={0} />
            </div>
          }
          className="checkbox--small font-size-sm fw-normal font-size-sm is-muted-label align-items-start"
        />
      </div>
    </>
  )
}
