import * as React from 'react'
import {
  selectGuests,
  selectReservation,
  selectReservationFeedings,
  selectReservationUrls,
} from '@store/selectors/reservation-selectors'
import { formatPriceShort } from '@helpers/utils'
import { FeedingCalculations } from '@requests/reservation/reservation-request-models'
import { commonObjectGet } from '@requests/basic-requests'
import { useDispatch, useSelector } from 'react-redux'
import { useApiRequest } from '@hooks/use-form-request'
import { addFeeding } from '@requests/reservation/feeding-requests'
import { ModalActions } from '@modals/modal-actions'
import { ModalSteps } from '@modals/modal-steps'
import { ImprovementModalWrapper } from '@modules/improvement-step/improvement-modal-wrapper'
import { DateFormats, formatDate, parseISODate } from '@helpers/date-formatter'
import { compareAsc, differenceInDays } from 'date-fns'
import { FeedingDetailsModalCheck } from '@modules/improvement-step/improvements-sections/kinds/feedings/feeding-details-modal-check'
import { FeedingDetailsModalSummaryPriceRow } from '@modules/improvement-step/improvements-sections/kinds/feedings/feeding-details-modal-summary-price-row'
import { FeedingTypes } from '@modules/improvement-step/improvements-sections/kinds/feedings/models'
import { createCartItemFromFeedingCalculation, useGtmEvents } from '@hooks/use-gtm-events'
import { SubscriptionContractFeedingDiscountLabel } from '@components/labels/subscription-contract/subscription-contract-feeding-discount-label'
import { ContentLoader } from '@components/loaders/content-loader'
import { CSSTransition } from 'react-transition-group'
import { getFeedingImprovementData } from '@helpers/improvements'

export interface FeedingSelection {
  title: string
  kind: FeedingTypes
}

const feedings = [
  { title: 'Śniadanie', kind: 'breakfast' },
  { title: 'Obiadokolacja', kind: 'dinner' },
  { title: 'Pełne wyżywienie', kind: 'dinner_with_breakfast' },
]

interface Props {
  onModalClose: () => void
  onStepChange: () => void
  step: number
}

export const FeedingDetailsModalSelection = ({ onModalClose, onStepChange, step }: Props): JSX.Element => {
  const [feedingCalculations, setFeedingCalculations] = React.useState<FeedingCalculations[]>([])
  const [selectedFeedingType, setSelectedFeedingType] = React.useState<FeedingTypes>('breakfast')

  const guests = useSelector(selectGuests)
  const reservationUrls = useSelector(selectReservationUrls)
  const reservationFeedings = useSelector(selectReservationFeedings)
  const { date_from, date_to } = useSelector(selectReservation)

  const dispatch = useDispatch()

  const { handleAction, isLoading } = useApiRequest()
  const { handleAction: handleCalculation, isLoading: isCalculating } = useApiRequest()
  const { addToCart } = useGtmEvents()

  const handleAddFeeding = async () => {
    if (!selectedFeedingType) return

    await handleAction(() => dispatch(addFeeding(selectedFeedingType)))

    const feeding = feedings.find(feeding => feeding.kind === selectedFeedingType)
    const feedingCalculation = getFeedingCalculation(selectedFeedingType)

    addToCart(
      feedingCalculation?.total_price_brutto ?? '0',
      createCartItemFromFeedingCalculation(feedingCalculation, selectedFeedingType, feeding),
    )

    onModalClose()
  }

  const handleFeedingChange = (feedingType: FeedingTypes) => () => {
    setSelectedFeedingType(feedingType)
  }

  const getFeedingCalculation = (feedingType: FeedingTypes | null): FeedingCalculations | undefined =>
    feedingCalculations.find(calculations => calculations.feeding_type === feedingType)

  const updateFeedingCalculations = async () => {
    if (!reservationUrls.feeding_calculator) return

    const { data } = await handleCalculation<FeedingCalculations[]>(() =>
      commonObjectGet(reservationUrls.feeding_calculator),
    )

    if (data) setFeedingCalculations(data)
  }

  React.useEffect(() => {
    updateFeedingCalculations()
  }, [reservationUrls.feeding_calculator])

  React.useEffect(() => {
    if (!reservationFeedings.items.length) return
    setSelectedFeedingType(getFeedingImprovementData(reservationFeedings.items).code)
  }, [reservationFeedings])

  const getFeedingButtonDescription = (feeding: FeedingSelection) => {
    const calculationForType = feedingCalculations.find(
      (calculation: FeedingCalculations) => calculation.feeding_type === feeding.kind,
    )

    const price = formatPriceShort(getFeedingCalculation(feeding.kind)?.total_price_brutto ?? 0)

    if (!calculationForType) return `${formatPriceShort(getFeedingCalculation(feeding.kind)?.total_price_brutto ?? 0)}`

    if (calculationForType.dates.length === differenceInDays(parseISODate(date_to), parseISODate(date_from)) + 1) {
      return `na wszystkie dni pobytu w cenie ${price}`
    }

    if (calculationForType.dates.length === 1) {
      return `${formatDate(calculationForType.dates[0], DateFormats.MONTH_WITH_FULL_WORD_MONTH)} w cenie ${price}`
    }

    const sortedFeedingDates = calculationForType.dates.sort((a, b) => compareAsc(parseISODate(a), parseISODate(b)))

    return `od ${formatDate(sortedFeedingDates[0], DateFormats.MONTH_WITH_FULL_WORD_MONTH)} do ${formatDate(
      sortedFeedingDates[sortedFeedingDates.length - 1],
      DateFormats.MONTH_WITH_FULL_WORD_MONTH,
    )} w cenie ${price}`
  }

  return (
    <>
      <ContentLoader isLoading={isCalculating}>
        <ImprovementModalWrapper
          contentTitle={
            <div>
              <strong className="d-block mb-2 mb-sm-0">Wybierz wyżywienie</strong>
              <SubscriptionContractFeedingDiscountLabel />
            </div>
          }
          image={require('@assets/images/feeding.webp')}
          imageContent={<ModalSteps step={step} stepCount={2} />}
        >
          <div className="px-3 px-xl-5 py-4 d-flex flex-column container-lg">
            {feedings.map((feeding: FeedingSelection) => (
              <CSSTransition
                timeout={{ enter: 0, exit: 200 }}
                classNames="fade"
                in={!isCalculating}
                unmountOnExit
                key={feeding.kind}
              >
                <FeedingDetailsModalCheck
                  key={feeding.title}
                  title={`${feeding.title} dla ${guests.length} osób`}
                  description={getFeedingButtonDescription(feeding)}
                  isActive={feeding.kind === selectedFeedingType}
                  onFeedingSelect={handleFeedingChange(feeding.kind)}
                  feedingCalculations={getFeedingCalculation(feeding.kind)}
                />
              </CSSTransition>
            ))}
          </div>
          <FeedingDetailsModalSummaryPriceRow
            isCalculating={isCalculating}
            feedingCalculations={getFeedingCalculation(selectedFeedingType)}
          />
        </ImprovementModalWrapper>
      </ContentLoader>

      <ModalActions
        isSubmitDisabled={!selectedFeedingType}
        onSubmit={handleAddFeeding}
        onDecline={onStepChange}
        isLoading={isLoading || isCalculating}
        loadingText={isCalculating ? 'Pobieranie cen' : 'Zapisywanie'}
        submitColor="danger"
        declineText="Wróć"
        declineIcon="uil-angle-left-b"
        submitIcon="uil-check"
      />
    </>
  )
}
