import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { Icon } from '@components/icon'
import { SubscriptionWithBenefitCodeInput } from '@modules/aside/subscription-with-benefits/subscription-with-benefit-code-input'
import { CustomTooltip } from '@components/custom-tooltip'
import { GLOBAL_URLS } from '@helpers/consts'
import { useModal } from '@modals/use-modal'

export const SubscriptionWithBenefitsCodeBanner = (): JSX.Element => {
  const [showSubscriptionWithBenefitsModal] = useModal('LocalForClientWithBenefitModal', {
    title: 'Oferty specjalne',
    isPreview: true,
  })

  return (
    <div className="subscription-with-benefits__banner card mt-3 px-4 pe-4 text-darker-gray">
      <strong className="subscription-with-benefits__banner__title">Oferty specjalne</strong>

      <SubscriptionWithBenefitCodeInput inputClass="w-100" />
      <CustomTooltip delay={500} id="subscription-with-benefits-code" content={<TooltipContent />}>
        <IconWithText
          onClick={showSubscriptionWithBenefitsModal}
          text="Jak skorzystać ze specjalnych lokali?"
          iconClassName="uil-exclamation-circle text-primary me-1 lh-0"
          textClassName="fw-semi-bold font-size-md text-start lh-0"
          wrapperClassName="mt-3"
        />
      </CustomTooltip>
      <Icon icon="tag" className="subscription-with-benefits__banner__watermark" />
    </div>
  )
}

const TooltipContent = () => (
  <p className="mt-3">
    Jesteś zainteresowany ofertami?{' '}
    <a
      href={GLOBAL_URLS.ECOMMERCE}
      target="_blank"
      className="text-decoration-none text-nowrap fw-semi-bold text-primary me-2"
    >
      Kup teraz w E-Sklepie
    </a>
    lub zadzwoń: 696 11 67 67
  </p>
)
