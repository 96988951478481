import * as React from 'react'
import Select, { Props } from 'react-select'
import { Controller, useFormContext } from 'react-hook-form'

export interface ReactSelectOption {
  value: string
  label: string
}

export type SelectProps = Props

interface CustomReactSelectProps extends Props {
  inputName: string
  borderless?: boolean
}

export const CustomReactSelect = ({ borderless, inputName, ...props }: CustomReactSelectProps): JSX.Element => {
  const { control } = useFormContext()

  return (
    <Controller
      name={inputName}
      control={control}
      render={({ field }) => (
        <Select
          styles={selectStyles(borderless)}
          isSearchable={false}
          noOptionsMessage={noOptionMessage}
          {...props}
          {...field}
        />
      )}
    />
  )
}

const noOptionMessage = () => 'Brak danych'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectStyles = borderless => ({
  menu: provided => ({ ...provided, zIndex: 7, left: 0 }),
  menuList: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: '3px',
  }),
  control: provided => ({
    ...provided,
    border: (borderless && 'none') || provided.border,
    borderColor: '#9099a1',
    boxShadow: 'none',
    height: '48px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '&:focus': { border: 'none' },
    '&:hover': { borderColor: '#b99153' },
  }),
  indicatorSeparator: provided => ({ ...provided, display: 'none' }),
  singleValue: provided => ({
    ...provided,
    color: '#6c757d',
    display: 'contents',
    whiteSpace: 'normal',
    fontWeight: '500',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#e9ecef' : 'white',
    color: '#6c757d',
    fontWeight: '500',
    minHeight: '48px',
    display: 'flex',
    alignItems: 'center',
    '& + &': {
      borderTop: '1px solid #6c757d45',
    },
    cursor: 'pointer',
    '&:active': { backgroundColor: '#e9ecef' },
    '&:hover': { backgroundColor: '#e9ecef' },
  }),
  valueContainer: provided => ({
    ...provided,
    lineHeight: 'normal',
    display: 'flex',
    flexWrap: 'no-wrap',
    paddingLeft: (borderless && '0') || provided.paddingLeft,
  }),
})
