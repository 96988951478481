import * as React from 'react'
import { ModalWithImageWrapper } from '@modals/modal-with-image-wrapper'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { BaseModalProps } from '@modals/types'
import { useReservationDataCleanup } from '@hooks/use-reservation-data-cleanup-on-render'

export const ReservationExpiredModal = ({ toggleIsVisible }: BaseModalProps): JSX.Element => {
  const navigate = useNavigate()
  const { cleanData } = useReservationDataCleanup()

  const navigateToAccommodationSelectionStep = () => {
    navigate(NavigationPath.AccommodationSelectionStep)
    toggleIsVisible()
    cleanData()
  }

  return (
    <ModalWithImageWrapper>
      <div className="ps-xl-5 py-5 container-lg text-darker-gray">
        <p>Czas na dokonanie rezerwacji minął. Aby rozpocząć ponownie proces rezerwacji przejdź do strony głównej.</p>

        <button
          className="btn btn-secondary fw-semi-bold btn-thin px-4 mt-3"
          onClick={navigateToAccommodationSelectionStep}
        >
          Przejdź do strony głównej
        </button>
      </div>
    </ModalWithImageWrapper>
  )
}
