import * as React from 'react'
import { CustomTooltip } from '@components/custom-tooltip'
import { Apartment } from '@models/reservation'

interface Props {
  apartment: Apartment
}

export const LocalForClientWithBenefitMark = ({ apartment }: Props): JSX.Element => (
  <CustomTooltip
    id={apartment.display_name}
    content={
      <div className="font-size-sm">
        <span className="text-nowrap fw-semi-bold">Lokal w ramach oferty specjalnej</span>
      </div>
    }
  >
    <i className="uil-star text-purple font-size-lg me-1" data-testid="subscription-with-benefit-local-mark" />
  </CustomTooltip>
)
