import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAllApartments, selectReservation } from '@store/selectors/reservation-selectors'
import { IconWithText } from '@components/icon-with-text'
import { selectResortDetails } from '@store/selectors/resort-selectors'
import { useSteps } from '@hooks/use-steps'
import { LocalSelectionMapApartmentsList } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-selection-map-apartments-list'
import { ContentLoader } from '@components/loaders/content-loader'
import { selectAccommodationTypeById } from '@store/selectors/app-data-selectors'
import { RootState } from '@store/index'
import { Apartment } from '@models/reservation'
import { updateReservation } from '@store/actions/reservation-actions'
import {
  isForClientWithBenefit,
  useClickableResortMap,
} from '@modules/accommodation-selection-step/local-selection/local-selection-map/use-clickable-resort-map'
import { SaveButton } from '@components/controls/save-button'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'
import { TextWithSpinner } from '@components/loaders/text-with-spinner'
import { formatPrice, getAccommodationTypeName } from '@helpers/utils'
import { ACCOMMODATION_TYPES, HOUSE_ACCOMMODATION_TYPE, HOUSE_WITH_GARDEN_ACCOMMODATION_TYPE } from '@helpers/consts'
import { Icon } from '@components/icon'
import svgPanZoom from 'svg-pan-zoom'
import { commonObjectGet } from '@requests/basic-requests'
import styleHelper from '@helpers/style-helper'
import { createReservationCartItem, useGtmEvents } from '@hooks/use-gtm-events'
import { LocalSelectionMapPlaceholder } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-selection-map-placeholder'
import { useApiRequest } from '@hooks/use-form-request'
import { useApartments } from '@modules/accommodation-selection-step/local-selection/local-selection-map/use-apartments'
import { useMapApartmentSelection } from '@modules/accommodation-selection-step/local-selection/local-selection-map/use-map-apartment-selection'
import {
  LocalSelectionMapModalLegend,
  MapLocalShapeKind,
} from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-selection-map-modal-legend'
import { LocalSelectionMapApartmentsListLabels } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-selection-map-apartments-list-labels'
import { useModal } from '@modals/use-modal'

export const PANNED_MAP_IDENTIFIER = 'panned-map'
const MODAL_HEADER_HEIGHT = 55
const DEFAULT_APARTMENT_LIST_HEIGHT = 300
const CIEPLICE_RESORT_ID = 9

interface Props {
  onMoveToNextStep: () => void
}

export const LocalSelectionMapModal = ({ onMoveToNextStep }: Props): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [isReservationForSelectedLocalCreating, setReservationIsCreatingForSelectedLocal] = React.useState(false)
  const [isReservationForRandomLocalCreating, setReservationIsCreatingForRandomLocal] = React.useState(false)
  const [map, setMap] = React.useState('')
  const [mapSvg, setMapSvg] = React.useState('')
  const [maximumListHeight, setMaximumListHeight] = React.useState(0)
  const [mapDocument, setMapDocument] = React.useState<Document | null>(null)
  const [selectedApartmentId, setSelectedApartmentId] = React.useState<number | null>(null)
  const [mapApartments, setMapApartments] = React.useState<Apartment[]>([])
  const [isMapPlaceholderVisible, setIsMapPlaceholderVisible] = React.useState(false)

  const [mapHeight, setMapHeight] = React.useState('auto')
  const [mapWrapperHeight, setMapWrapperHeight] = React.useState('auto')
  const [panZoom, setPanZoom] = React.useState<SvgPanZoom.Instance | null>(null)

  const [showSelectedInvalidAccommodationTypeModal] = useModal('InvalidSelectedAccommodationTypeModal')
  const [showLocalForClientWithBenefit] = useModal('LocalForClientWithBenefitModal', {
    title: 'Lokal dostępny w ramach oferty specjalnej',
  })

  const mapRef = React.useRef<HTMLObjectElement | null>(null)
  const { accommodation_type_id } = useSelector(selectReservation)

  const { goNext } = useSteps()
  const { addToCart } = useGtmEvents()
  const dispatch = useDispatch()

  const { handleAction, isLoading: isFetchingMap } = useApiRequest({
    shouldDispatchModalError: false,
    errorCatcher: () => {
      setIsLoading(false)
      setIsMapPlaceholderVisible(true)
    },
  })

  const handleApartmentSelection = (apartment: Apartment) => {
    const isForSubscriptionWithBenefitClient = isForClientWithBenefit(apartment)

    if (!apartment.is_available && isForSubscriptionWithBenefitClient) {
      showLocalForClientWithBenefit(null, {}, true)
      return
    }

    if (isForSubscriptionWithBenefitClient && accommodation_type_id !== apartment.accommodation_type_id) {
      showSelectedInvalidAccommodationTypeModal(null, { apartment }, true)
      return
    }

    setSelectedApartmentId(apartment.id)
  }

  const reservation = useSelector(selectReservation)
  const resortDetails = useSelector(selectResortDetails)
  const { isLoading: isFetchingApartments } = useApartments()
  const { selectApartment } = useMapApartmentSelection(panZoom, handleApartmentSelection)

  const allApartments = useSelector(selectAllApartments)

  const apartments = React.useMemo(
    () =>
      allApartments.filter(apartment => {
        if (isForClientWithBenefit(apartment)) return true

        if (
          reservation.accommodation_type_id &&
          ACCOMMODATION_TYPES.Houses.includes(apartment.accommodation_type_id) &&
          ACCOMMODATION_TYPES.Houses.includes(reservation.accommodation_type_id)
        ) {
          if (reservation.accommodation_type_id === HOUSE_WITH_GARDEN_ACCOMMODATION_TYPE) {
            return apartment.has_garden_fixed
          }

          if (reservation.accommodation_type_id === HOUSE_ACCOMMODATION_TYPE) {
            return !apartment.has_garden_fixed
          }
        }

        return apartment.accommodation_type_id === reservation.accommodation_type_id
      }),
    [allApartments, reservation.accommodation_type_id],
  )

  const accommodationType = useSelector((state: RootState) =>
    selectAccommodationTypeById(state, reservation.accommodation_type_id),
  )

  useClickableResortMap({
    mapDocument,
    apartments,
    onMapChange: setMap,
    resortDetails,
    onLoadingVisibilityChange: setIsLoading,
    selectedApartmentId,
    onApartmentSelect: selectApartment,
    onMapApartmentsChange: setMapApartments,
  })

  React.useEffect(() => {
    setMap(resortDetails.map_svg)
  }, [resortDetails.map_svg])

  const sendAddToCartEvent = (apartmentId: number | null) => {
    addToCart(
      reservation.prices.stay_charge_without_climatic,
      createReservationCartItem(
        apartmentId,
        resortDetails,
        accommodationType,
        mapAvailableApartments.find(apartment => apartment.id === apartmentId)?.display_name ?? null,
        reservation,
      ),
    )
  }

  const rejectLocalSelection = async () => {
    setReservationIsCreatingForRandomLocal(true)
    await handleMoveToNextStep()
    sendAddToCartEvent(null)
  }

  const handleLocalSelectionSave = async () => {
    setReservationIsCreatingForSelectedLocal(true)
    dispatch(updateReservation({ selected_apartment_id: selectedApartmentId }))
    sendAddToCartEvent(selectedApartmentId)
  }

  useDidUpdateEffect(() => {
    if (reservation.selected_apartment_id === selectedApartmentId) {
      handleMoveToNextStep()
    }
  }, [reservation.selected_apartment_id])

  const handleMoveToNextStep = async () => {
    await goNext()
    onMoveToNextStep?.()
  }

  const handleImageLoad = React.useCallback((event: React.ChangeEvent<HTMLObjectElement>) => {
    setMapWrapperHeight(mapHeight)
    const restItemHeight = 370
    setIsLoading(false)
    setMaximumListHeight(event.target.clientHeight - restItemHeight)
    setMapDocument(event.target.contentDocument)

    setMapHeight(styleHelper.valueToPX(event.target.contentDocument?.documentElement.scrollHeight || 0))

    function beforePan(oldPan, newPan): SvgPanZoom.PointModifier {
      const clientWidth = event.target.contentDocument?.documentElement.scrollWidth ?? 0,
        clientHeight = event.target.contentDocument?.documentElement.scrollHeight ?? 0,
        sizes = this.getSizes(),
        leftLimit = -((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) + clientWidth,
        rightLimit = sizes.width - clientWidth - sizes.viewBox.x * sizes.realZoom,
        topLimit = -((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) + clientHeight,
        bottomLimit = sizes.height - clientHeight - sizes.viewBox.y * sizes.realZoom

      return {
        x: Math.max(leftLimit, Math.min(rightLimit, newPan.x)),
        y: Math.max(topLimit, Math.min(bottomLimit, Math.min(newPan.y, 0))),
      }
    }

    const initializedPanZoom = svgPanZoom(`#${PANNED_MAP_IDENTIFIER}`, {
      beforePan,
      zoomScaleSensitivity: 0.4,
      dblClickZoomEnabled: false,
      preventMouseEventsDefault: false,
      mouseWheelZoomEnabled: false,
      minZoom: 1,
    })

    setPanZoom(initializedPanZoom)
  }, [])

  const mapAvailableApartments = mapApartments.filter((apartment: Apartment) => apartment.is_available)
  const allAvailableApartments = apartments.filter((apartment: Apartment) => apartment.is_available)

  const hasGarden = mapAvailableApartments.some(apartment => apartment.has_garden_fixed)

  const handleZoomIn = () => {
    panZoom?.zoomIn()
  }

  const handleZoomOut = () => {
    panZoom?.zoomOut()
  }

  const fetchMapSvg = () =>
    handleAction(async () => {
      setMapSvg(await commonObjectGet(map))
      if (isMapPlaceholderVisible) setIsMapPlaceholderVisible(false)
    })

  React.useEffect(() => {
    if (map) {
      setMapHeight('auto')
      fetchMapSvg()
    }
  }, [map])

  React.useEffect(() => {
    if (mapHeight !== 'auto') {
      setMapWrapperHeight(styleHelper.valueToPX(parseInt(mapHeight.replace('px', ''), 10) - MODAL_HEADER_HEIGHT))
    }
  }, [mapHeight])

  const selectionPrices = resortDetails.improvements_extra.reduce(
    (previousValue, currentValue) => {
      if (currentValue.code === 'apartment_select')
        return { ...previousValue, apartment_select: currentValue.price_brutto }
      if (currentValue.code === 'house_with_garden')
        return { ...previousValue, house_with_garden: currentValue.price_brutto }
      if (currentValue.code === 'apartment_with_garden')
        return { ...previousValue, apartment_with_garden: currentValue.price_brutto }

      return previousValue
    },
    { apartment_select: '', apartment_with_garden: '', house_with_garden: '' },
  )

  const localData = React.useMemo(() => {
    const isHouse = ACCOMMODATION_TYPES.Houses.includes(apartments[0]?.accommodation_type_id)

    return {
      accommodationTypeName: isHouse ? 'domkach' : 'apartamentach',
      price: isHouse ? selectionPrices.house_with_garden : selectionPrices.apartment_with_garden,
    }
  }, [apartments[0]?.accommodation_type_id, resortDetails.improvements_extra])

  const mapLocalVariants = React.useMemo(() => {
    const hasLocalForSubscriptionWithBenefitsOwner = mapApartments.some(apartment => isForClientWithBenefit(apartment))
    const isCiepliceResort = resortDetails.id === CIEPLICE_RESORT_ID
    return [
      'available',
      'occupied',
      ...(hasLocalForSubscriptionWithBenefitsOwner ? ['subscription_with_benefit'] : []),
      ...(isCiepliceResort ? ['construction'] : []),
    ] as MapLocalShapeKind[]
  }, [mapApartments])

  return (
    <ContentLoader isLoading={isLoading || isFetchingApartments} className="overflow-visible">
      <div className="d-flex flex-md-row flex-column">
        <div className="col-md-8 col-12 position-relative">
          {isMapPlaceholderVisible ? (
            <LocalSelectionMapPlaceholder onReload={fetchMapSvg} isLoading={isFetchingMap} />
          ) : (
            <>
              <div style={{ height: mapWrapperHeight }} className="local-selection-modal__map__wrapper">
                {mapSvg && (
                  <>
                    <object
                      style={{ height: mapHeight }}
                      id={PANNED_MAP_IDENTIFIER}
                      ref={mapRef}
                      type="image/svg+xml"
                      className="local-selection-modal__map"
                      data={map}
                      onLoad={handleImageLoad}
                      key={mapSvg}
                    >
                      Mapa ośrodka
                    </object>
                    <LocalSelectionMapModalLegend variants={mapLocalVariants} />
                  </>
                )}
              </div>
              <div className="local-selection-modal__zoom-buttons__wrapper bg-light">
                <button className="btn btn-light shadow-none" onClick={handleZoomIn}>
                  <i className="uil-plus" />
                </button>
                <hr className="local-selection-modal__zoom-buttons__spacer" />
                <button className="btn btn-light shadow-none" onClick={handleZoomOut}>
                  <i className="uil-minus" />
                </button>
              </div>
            </>
          )}
        </div>

        <div className="col-md-4 col-12 px-4 pb-4 d-flex flex-column">
          <LocalSelectionMapApartmentsListLabels />
          <h4 className="text-primary mt-2 text-center text-md-start">
            Wybrany typ lokalu to{' '}
            <span className="text-lowercase text-nowrap">{getAccommodationTypeName(accommodationType)}</span>
          </h4>
          <p className="font-size-sm text-darker-gray text-center text-md-start">
            System sam wybierze lokal. Jeśli chcesz wybrać miejsce swoich wakacji
            <strong> to koszt {formatPrice(selectionPrices.apartment_select)} za dobę</strong>.
          </p>
          {hasGarden && (
            <div className="bg-light-azure rounded border d-flex p-2 mb-md-4">
              <Icon icon="grass" />
              <span className="font-size-sm text-darker-gray ms-2">
                W {localData.accommodationTypeName} z ogrodem obowiązuje dopłata w wysokości{' '}
                <strong>{formatPrice(localData.price)} za dobę</strong>.
              </span>
            </div>
          )}
          <LocalSelectionMapApartmentsList
            apartments={isMapPlaceholderVisible ? allAvailableApartments : mapAvailableApartments}
            maximumListHeight={isMapPlaceholderVisible ? DEFAULT_APARTMENT_LIST_HEIGHT : maximumListHeight}
            selectedApartmentId={selectedApartmentId}
            onApartmentSelect={handleApartmentSelection}
          />
          {!!allAvailableApartments.length && (
            <TextWithSpinner
              isLoading={isReservationForRandomLocalCreating}
              wrapperClassName="py-3 text-darker-gray"
              spinnerColor="muted"
              text={
                <IconWithText
                  text="Rezygnuję z wyboru lokalu i idę dalej"
                  iconClassName="uil-times font-size-xxl me-2"
                  textClassName="fw-semi-bold"
                  wrapperClassName="text-darker-gray justify-content-md-center justify-content-start"
                  onClick={rejectLocalSelection}
                />
              }
            />
          )}
          <SaveButton
            isLoading={isReservationForSelectedLocalCreating}
            className="btn btn-danger w-100 fw-bold btn-tall"
            disabled={!selectedApartmentId}
            onClick={handleLocalSelectionSave}
            text="Wybieram i idę dalej"
          />
        </div>
      </div>
    </ContentLoader>
  )
}
