import { IconWithText } from '@components/icon-with-text'
import * as React from 'react'

export const MostPopularImprovementBanner = (): JSX.Element => (
  <div className="border border-success rounded-3 d-inline-block ps-2 pe-3 py-1 bg-success bg-opacity-25 mb-2">
    <IconWithText
      text="najczęściej wybierane"
      iconClassName="uil-check me-2"
      wrapperClassName="text-success d-inline-block lh-initial"
      textClassName="font-size-sm"
    />
  </div>
)
