import * as React from 'react'
import { useSelector } from 'react-redux'
import { selectClientActiveSubscriptionContract } from '@store/selectors/client-details-selectors'
import { IconWithText } from '@components/icon-with-text'
import { Button } from 'react-bootstrap'
import { useModal } from '@modals/use-modal'

export const SubscriptionContractFeedingDiscountLabel = (): JSX.Element | null => {
  const [showSubscriptionDescriptionModal] = useModal('SubscriptionContractDescriptionModal', { title: 'Subskrypcja' })

  const activeSubscriptionContract = useSelector(selectClientActiveSubscriptionContract)
  if (activeSubscriptionContract) return null

  return (
    <div className="bg-light-blue">
      <IconWithText
        wrapperClassName="col-12 mt-3 border rounded px-3 py-2"
        iconClassName="uil-exclamation-circle text-primary font-size-xxl me-3 align-self-center"
        textClassName="fw-normal font-size-md w-100"
        text={
          <div className="text-darker-gray d-flex justify-content-between">
            <div>
              <strong className="d-block text-muted">Czy wiesz, że...</strong>
              <span>Z subskrypcją otrzymujesz dodatkowe rabaty na wyżywienie?</span>
            </div>
            <Button
              className="btn bg-transparent border-0 text-primary fw-bold"
              onClick={showSubscriptionDescriptionModal}
            >
              Szczegóły
            </Button>
          </div>
        }
      />
    </div>
  )
}
