import { ClientDetails } from '@models/app-data'

export enum ClientDetailsActionTypesKeys {
  SET_CLIENT_DETAILS = 'SET_CLIENT_DETAILS',
}

export interface SetClientDetailsAction {
  type: ClientDetailsActionTypesKeys.SET_CLIENT_DETAILS
  payload: ClientDetails
}

export type ClientDetailsActionsTypes = SetClientDetailsAction
