import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Reservation } from '@models/reservation'
import { NotificationEvents } from '@models/websocket'
import { RootState } from '@store/index'
import { useWebSocket } from '@hooks/use-web-socket'
import { updateReservation } from '@store/actions/reservation-actions'

export const ReservationWebSocketHandler: React.FC = () => {
  const dispatch = useDispatch()
  const token = useSelector((state: RootState) => state.reservationState.reservation.token)

  useWebSocket<Reservation, []>((message, payload) => {
    switch (message) {
      case NotificationEvents.CHANGE_BOOKING:
        if (token === (payload as Reservation).token) {
          if (payload.status !== 'unfinished_canceled') {
            dispatch(updateReservation(payload as Reservation))
          }
        }
        break
    }
  }, [])

  return null
}
