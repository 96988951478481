import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Resort } from '@models/app-data'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { setNextStepActive, setNextStepInactive } from '@store/actions/steps-actions'
import { CollapsableResort } from '@modules/accommodation-selection-step/resort-selection/collapsable-resorts/collapsable-resort'
import { Accordion } from 'react-bootstrap'
import { ContentLoader } from '@components/loaders/content-loader'
import { selectIsLoaderVisible } from '@store/selectors/loader-selectors'
import { useSortedResorts } from '@modules/accommodation-selection-step/resort-selection/use-sorted-resorts'
import { createResortViewListItem, useGtmEvents } from '@hooks/use-gtm-events'

export const ResortsList = (): JSX.Element => {
  const reservation = useSelector(selectReservation)
  const sortedResorts = useSortedResorts()

  const dispatch = useDispatch()
  const { viewItemList } = useGtmEvents()

  React.useEffect(() => {
    dispatch(reservation.accommodation_type_id ? setNextStepActive() : setNextStepInactive())
  }, [reservation.accommodation_type_id])

  const isLoading = useSelector(selectIsLoaderVisible)

  React.useEffect(() => {
    if (!reservation.date_from || !reservation.date_to) return

    viewItemList(
      sortedResorts.map((resort, index) => createResortViewListItem(resort, index)),
      'rezerwuj',
    )
  }, [reservation.date_to, reservation.date_from])

  return (
    <ContentLoader isLoading={isLoading} className="is-positioned-to-top">
      <Accordion className="collapsable-resort px-xl-4">
        {sortedResorts.map((resort: Resort) => (
          <CollapsableResort key={resort.id} resort={resort} />
        ))}
      </Accordion>
    </ContentLoader>
  )
}
