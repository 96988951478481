import { AppDataActionsTypes, AppDataActionTypesKeys } from '@store/action-types/app-data-action-types'
import { AppData, TextBlock } from '@models/app-data'

export interface AppDataState {
  appData: AppData
}

const emptyTextBlock: TextBlock = {
  id: 0,
  name: '',
  content: '',
  subtitle: '',
}

const initialState: AppDataState = {
  appData: {
    text_blocks: {
      awards: emptyTextBlock,
      login: emptyTextBlock,
      payment: emptyTextBlock,
      privacy: emptyTextBlock,
      questions: emptyTextBlock,
      security: emptyTextBlock,
      safety: emptyTextBlock,
    },
    rules: [],
    accommodation_types: [],
    improvement_groups: [],
    default_reservation_params: {
      accommodation_type_id: null,
      date_from: '',
      date_to: '',
      resort_id: null,
    },
    available_seasons: [],
    resorts: [],
    urls: {
      api: {
        available_apartments: '',
        calendar: '',
        client_details: '',
        google_review_list: '',
        nip_search: '',
        reservation_calculate_price: '',
        reservation_check_accommodation_type: '',
        reservation_check_dates: '',
        reservation_check_code: '',
        reservation_create_unfinished_booking: '',
      },
      cms: {
        rules: '',
        rodo: '',
      },
      account: {
        reset_password_link: '',
        reset_password: '',
        login: '',
        logout: '',
        register: '',
        user_details: '',
      },
      other: {
        main_site: '',
      },
    },
    company_info: {
      call_center_email: '',
      call_center_phone_number: {
        pl: '',
        en: '',
        de: '',
      },
      call_center_working_hours: {},
    },
    payment_methods: [],
    countries: [],
    max_reservation_days: 21,
    min_reservation_days: 1,
    package_vip_improvements_in_price: { package_vip: [], package_vip_winter: [] },
  },
}

const appDataState = (state: AppDataState = initialState, action: AppDataActionsTypes): AppDataState => {
  switch (action.type) {
    case AppDataActionTypesKeys.SET_APP_DATA:
      return { ...state, appData: action.payload }
    default:
      return state
  }
}

export default appDataState
