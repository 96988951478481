import * as React from 'react'
import { SummaryBox } from '@modules/aside/summary-box/summary-box'
import { useSelector } from 'react-redux'
import { selectClientActiveSubscriptionContract } from '@store/selectors/client-details-selectors'
import { SubscriptionContractBanner } from '@modules/aside/subscription-contract-info/subscription-contract-banner'
import { SubscriptionWithBenefitsCodeBanner } from '@modules/aside/subscription-with-benefits/subscription-with-benefits-code-banner'
import { selectStep } from '@store/selectors/steps-selectors'
import { EcommerceBanner } from '@modules/aside/ecommerce/ecommerce-banner'

export const Aside = (): JSX.Element => {
  const step = useSelector(selectStep)
  const activeSubscriptionContract = useSelector(selectClientActiveSubscriptionContract)

  return (
    <aside className="flex-column d-none d-xl-flex aside">
      <div className="aside__floating">
        <SummaryBox hasActiveSubscriptionContract={!!activeSubscriptionContract} />
        <EcommerceBanner />
        {step.stepNumber === 1 && <SubscriptionWithBenefitsCodeBanner />}
        {!activeSubscriptionContract && <SubscriptionContractBanner />}
      </div>
    </aside>
  )
}
