import { ThunkResult } from '@models/models'
import { UpdateReservationAction } from '@store/action-types/reservation-action-types'
import { commonObjectDelete, commonObjectPost } from '@requests/basic-requests'
import { updateReservation } from '@store/actions/reservation-actions'
import { FeedingTypes } from '@modules/improvement-step/improvements-sections/kinds/feedings/models'

export function addFeeding(feedingType: FeedingTypes): ThunkResult<Promise<void>, UpdateReservationAction> {
  return async (dispatch, getState) => {
    const url = getState().reservationState.reservation.urls.feeding
    dispatch(updateReservation({ ...(await commonObjectPost(url, { feeding_type: feedingType })) }))
  }
}

export function removeFeeding(): ThunkResult<Promise<void>, UpdateReservationAction> {
  return async (dispatch, getState) => {
    const url = getState().reservationState.reservation.urls.feeding
    dispatch(updateReservation({ ...(await commonObjectDelete(url)) }))
  }
}
