import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { formatPriceShort } from '@helpers/utils'
import { CheckButton } from '@components/controls/check-button'
import { useApiRequest } from '@hooks/use-form-request'
import { useDispatch, useSelector } from 'react-redux'
import { ResortImprovement } from '@models/reservation'
import { addReservationImprovement } from '@requests/reservation/improvement-requests'
import { selectReservationImprovements } from '@store/selectors/reservation-selectors'
import { isVip, isWinterVip } from '@helpers/improvements'
import { BaseModalProps } from '@modals/types'
import { ModalActions } from '@modals/modal-actions'
import { ImprovementModalWrapper } from '@modules/improvement-step/improvement-modal-wrapper'
import { VipPricesBlock } from '@modules/improvement-step/improvements-sections/kinds/vip/vip-prices-block'
import { createCartItem, useGtmEvents } from '@hooks/use-gtm-events'

interface Props extends BaseModalProps {
  onShowDetailList: () => void
  resortImprovement: ResortImprovement
}

export const VipDetailsModal = ({ toggleIsVisible, onShowDetailList, resortImprovement }: Props): JSX.Element => {
  const dispatch = useDispatch()
  const { handleAction, isLoading } = useApiRequest()

  const improvements = useSelector(selectReservationImprovements)
  const { addToCart } = useGtmEvents()

  const handleAddImprovement = async () => {
    if (!improvements.find(isVip)) {
      await handleAction(() => dispatch(addReservationImprovement([{ code: resortImprovement.code }])))

      addToCart(resortImprovement.price_brutto, createCartItem(resortImprovement))
    }

    toggleIsVisible()
  }

  const checkButtonTitle = (
    <span className="font-size-lg">{`Dolicz do rachunku ${formatPriceShort(resortImprovement.price_brutto)}`}</span>
  )

  return (
    <>
      <ImprovementModalWrapper
        contentTitle={isWinterVip(resortImprovement) ? 'Zimowy pakiet ulepszeń VIP' : 'Pakiet ulepszeń VIP'}
        image={resortImprovement.image.sm}
      >
        <div className="px-3 pb-3 px-xl-5 container-lg">
          <p className="text-muted mt-2">{resortImprovement.description}</p>
          <IconWithText
            text="Co dostaję w pakiecie?"
            iconClassName="uil-plus me-2 font-size-lg"
            wrapperClassName="text-darker-gray d-inline-flex"
            onClick={onShowDetailList}
          />
          <strong className="d-block text-primary mt-3 mb-2">
            {isWinterVip(resortImprovement) ? 'Zimowy pakiet VIP' : 'Pakiet VIP'}
          </strong>
          <CheckButton
            className="w-100"
            title={checkButtonTitle}
            description="Dolicz do kwoty rezerwacji"
            isActive={true}
          />
        </div>
        <div className="p-3 bg-light-blue px-xl-5 border-top">
          <div className="container-lg ps-xl-0">
            <VipPricesBlock improvement={resortImprovement} />
          </div>
        </div>
      </ImprovementModalWrapper>
      <ModalActions
        onDecline={toggleIsVisible}
        onSubmit={handleAddImprovement}
        isLoading={isLoading}
        submitIcon="uil-check"
      />
    </>
  )
}
