import * as React from 'react'
import { StarShape } from '@components/star-shape'
import { OpinionResult } from '@models/models'
import { formatDate } from '@helpers/date-formatter'

interface Props {
  opinion: OpinionResult
}

export const OpinionRow = ({ opinion }: Props): JSX.Element => {
  const ratingStars = Array.from(Array(Math.ceil(opinion.rating)).keys()).map(el => {
    const decimal = Math.ceil((opinion.rating % 1) * 100)
    const filled = Math.floor(opinion.rating)
    return <StarShape key={el} size={17} lastFill={el + 1 > filled ? decimal : null} />
  })

  return (
    <tr>
      <td>
        <div className="py-2 container-lg">
          <div className="d-flex justify-content-between mb-2">
            <div className="d-flex align-items-center">
              <strong className="me-1 lh-normal text-darker-gray">{opinion.rating}</strong>
              {ratingStars}
            </div>
            <div>
              <strong className="text-muted font-size-md">{formatDate(opinion.created_at, 'dd.MM.yyyy')}</strong>
            </div>
          </div>
          <p className="text-muted">{opinion.text}</p>
          <strong className="text-muted">{opinion.author_name}</strong>
        </div>
      </td>
    </tr>
  )
}
