import * as React from 'react'
import { addSeconds, intervalToDuration, isAfter } from 'date-fns'
import { parseISODate } from '@helpers/date-formatter'
import { Spinner } from '@components/loaders/spinner'
import { useModal } from '@modals/use-modal'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'

interface Props {
  expirationTime: string
}

export const SummaryBoxReservationExpiration = ({ expirationTime }: Props): JSX.Element => {
  const [remainingTime, setRemainingTime] = React.useState<null | string>(null)
  const [isExpired, setIsExpired] = React.useState(false)

  let timeout: NodeJS.Timer

  const [showExpirationModal] = useModal('ReservationExpiredModal', { title: 'Rezerwacja wygasła' })

  const recalculateTime = () => {
    const remaining = intervalToDuration({ start: parseISODate(expirationTime), end: new Date() })
    if (isExpired) {
      setRemainingTime('0m 0s')
      return
    }

    setRemainingTime(`${remaining.minutes}m ${remaining.seconds}s`)
  }

  React.useEffect(() => {
    timeout = setInterval(recalculateTime, 1000)

    return () => {
      clearInterval(timeout)
    }
  }, [expirationTime, isExpired])

  useDidUpdateEffect(() => {
    if (isExpired) showExpirationModal()
  }, [isExpired])

  React.useEffect(() => {
    if (!expirationTime || isExpired) return
    if (!isAfter(parseISODate(expirationTime), addSeconds(new Date(), 1))) setIsExpired(true)
  }, [remainingTime, expirationTime, isExpired])

  return (
    <div className="summary-box__expiration-time px-3 pb-2">
      <i className="uil-clock-five text-danger fw-bold d-block font-size-xxl lh-initial" />
      <span className="text-darker-gray">
        Rezerwacja aktywna jeszcze przez <br />
        {remainingTime ? (
          <strong className="text-danger"> {remainingTime}</strong>
        ) : (
          <Spinner className="text-danger spinner--small" color="danger" />
        )}
      </span>
    </div>
  )
}
