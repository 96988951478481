import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { TrapezoidButton, TrapezoidButtonVariant } from '@components/controls/trapezoid-button'
import { ModalMobileActions } from '@modals/modal-mobile-actions'
import { TextWithSpinner } from '@components/loaders/text-with-spinner'
import { useDevicesSize } from '@hooks/use-devices-size'

interface Props {
  onDecline?: () => void
  onSubmit: () => void
  isLoading?: boolean
  loadingText?: string
  submitText?: string
  submitColor?: TrapezoidButtonVariant
  declineText?: string
  declineIcon?: string
  submitIcon?: string
  isSubmitDisabled?: boolean
}

export const ModalActions = ({
  onDecline,
  onSubmit,
  isLoading,
  loadingText,
  submitText = 'Wybieram',
  declineText = 'Anuluj',
  submitColor,
  declineIcon = 'uil-times',
  submitIcon,
  isSubmitDisabled,
}: Props): JSX.Element => {
  const { isDesktop } = useDevicesSize()

  if (isDesktop)
    return (
      <div className={clsx('d-flex ps-5', onDecline ? 'justify-content-between' : 'justify-content-end')}>
        {onDecline && (
          <IconWithText
            text={declineText}
            iconClassName={clsx('font-size-xxl me-2', declineIcon)}
            onClick={onDecline}
            wrapperClassName="text-darker-gray"
          />
        )}

        <TrapezoidButton onClick={onSubmit} variant={submitColor} isDisabled={isLoading || isSubmitDisabled}>
          <TextWithSpinner
            isLoading={!!isLoading}
            loadingText={loadingText}
            text={submitText}
            iconClassName={clsx('me-2 font-size-xxl lh-1', submitIcon)}
          />
        </TrapezoidButton>
      </div>
    )

  return (
    <ModalMobileActions
      isLoading={!!isLoading}
      isSubmitDisabled={isSubmitDisabled}
      loadingText={loadingText}
      onSubmit={onSubmit}
      onDecline={onDecline}
      submitText={submitText}
      declineText={declineText}
      submitColor={submitColor}
    />
  )
}
