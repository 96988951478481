import { i18n } from '@i18n/i18n'

export const stayDeclination = (days: number): string => {
  if (days === 1) {
    return i18n('stayDeclination.single')
  } else if (days > 1 && days < 5) {
    return i18n('stayDeclination.few')
  } else {
    return i18n('stayDeclination.multiple')
  }
}
