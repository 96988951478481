import { ModalConfig, ModalParams, ModalTypes } from '@modals/types'
import { ModalActionsTypes, ModalActionTypesKeys } from '@store/action-types/modal-action-types'

export interface ModalItem {
  modal: ModalTypes
  params?: ModalParams
  isOpen: boolean
  config?: ModalConfig
}

export interface ModalState {
  modals: ModalItem[]
}

const initialState: ModalState = {
  modals: [],
}

const modalState = (state: ModalState = initialState, action: ModalActionsTypes): ModalState => {
  switch (action.type) {
    case ModalActionTypesKeys.SHOW_MODAL:
      return { ...state, modals: [...state.modals, action.payload] }
    case ModalActionTypesKeys.HIDE_MODAL:
      return { ...state, modals: [...state.modals.filter(row => row.modal !== action.payload)] }
    case ModalActionTypesKeys.SET_IS_OPEN_MODAL:
      return {
        ...state,
        modals: [
          ...state.modals.map(row =>
            row.modal === action.payload.modal ? { ...row, isOpen: action.payload.isOpen } : { ...row },
          ),
        ],
      }
    default:
      return state
  }
}

export default modalState
