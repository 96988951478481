import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  children: JSX.Element | JSX.Element[]
  className?: string
}

export const MainInfoBox = ({ children, className }: Props): JSX.Element => (
  <div className={clsx('border-bottom py-2 bg-light-blue d-block d-xl-none cursor-pointer', className)}>{children}</div>
)
