import * as React from 'react'
import { i18nInit } from '@i18n/i18n'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { fetchAppData } from '@requests/app-data-requests'

interface ReduxProps {
  fetchAppData: typeof fetchAppData
}

interface Props extends ReduxProps {
  onLoadFail: () => void
  children: React.ReactNode
}

interface State {
  loading: boolean
}

export class StartupViewComponent extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props)

    this.state = {
      loading: true,
    }
  }

  public componentDidMount(): void {
    this.initializeData()
  }

  public render(): React.ReactNode {
    return this.state.loading ? null : this.props.children
  }

  public componentDidCatch(error: Error): void {
    Sentry.captureException(error)
  }

  public initializeData = async (): Promise<void> => {
    try {
      await this.props.fetchAppData()
      i18nInit()
      this.setState({ loading: false })
    } catch (e) {
      this.props.onLoadFail()
    }
  }
}

const mapDispatchToProps = dispatch => ({
  fetchAppData: () => dispatch(fetchAppData()),
})

const StartupView = connect(null, mapDispatchToProps)(StartupViewComponent)

export default StartupView
