import * as React from 'react'
import { emptyReservationPrices } from '@store/reducers/reservation-reducer'
import { updateReservation } from '@store/actions/reservation-actions'
import { useDispatch, useSelector } from 'react-redux'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { AccommodationType, Resort } from '@models/app-data'
import { useModal } from '@modals/use-modal'
import { useReservationDataCleanup } from '@hooks/use-reservation-data-cleanup-on-render'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'
import { useIsMounted } from '@hooks/use-is-mounted'

export interface ApartmentSelection {
  handleApartmentSelect: () => void
  isApartmentSelected: boolean
}

export const useApartmentSelection = (accommodationType: AccommodationType, resort: Resort): ApartmentSelection => {
  const dispatch = useDispatch()
  const reservation = useSelector(selectReservation)
  const isMounted = useIsMounted()

  const [isHide, setIsHide] = React.useState(false)
  const [isClosed, setIsClosed] = React.useState(false)

  const { cleanData } = useReservationDataCleanup()

  useDidUpdateEffect(() => {
    if (isHide && isClosed) {
      cleanData()
      setIsHide(false)
      setIsClosed(false)
    }
  }, [isHide, isClosed])

  const handleMoveToTheNextStep = () => hideModal()

  const [showLocalSelectionMap, hideModal] = useModal(
    'LocalSelectionMapModal',
    { onMoveToNextStep: handleMoveToTheNextStep },
    {
      onClose: () => {
        if (isMounted()) setIsClosed(true)
      },
      onHide: () => setIsHide(true),
    },
  )

  const isApartmentSelected = React.useMemo(
    () => reservation.accommodation_type_id === accommodationType.id && reservation.resort_id === resort.id,
    [accommodationType, reservation.accommodation_type_id, reservation.resort_id, resort.id],
  )

  const handleApartmentSelect = React.useCallback(() => {
    const accommodationTypeId = isApartmentSelected ? null : accommodationType.id

    const partialUpdatedReservation = {
      accommodation_type_id: accommodationTypeId,
      resort_id: resort.id,
      ...(!accommodationTypeId && { prices: emptyReservationPrices }),
      ...(!!reservation.apartment_id && { apartment_id: null }),
      ...(!!reservation.selected_apartment_id && { selected_apartment_id: null }),
    }

    dispatch(updateReservation({ ...partialUpdatedReservation }))
    if (accommodationTypeId) showLocalSelectionMap(null, {}, true)
  }, [reservation.apartment_id, reservation.selected_apartment_id, accommodationType, isApartmentSelected, resort.id])

  return {
    handleApartmentSelect,
    isApartmentSelected,
  }
}
