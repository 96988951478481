import * as React from 'react'
import { SummaryCard } from '@modules/summary-step/summary-card'
import { formatPrice } from '@helpers/utils'
import { SummaryPromoImprovementCard } from '@modules/summary-step/promocodes/summary-promo-improvement-card'
import { useDispatch, useSelector } from 'react-redux'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { useApiRequest } from '@hooks/use-form-request'
import { updateMainReservationData } from '@requests/reservation/reservation-requests'
import { useFormContext } from 'react-hook-form'
import { SummaryStepFormInputs } from '@modules/summary-step/summary-step'

export const SummaryCancellationWarranty = (): JSX.Element => {
  const { handleAction, isLoading } = useApiRequest()

  const { setValue } = useFormContext<SummaryStepFormInputs>()

  const { warranty, warranty_costs } = useSelector(selectReservation)

  const dispatch = useDispatch()

  const handleAddWarrantyInsurance = async () => {
    await handleAction(() => dispatch(updateMainReservationData({ warranty: !warranty })))
  }

  React.useEffect(() => {
    setValue('warranty', warranty)
  }, [warranty])

  return (
    <SummaryCard
      title={
        <span>
          Dodaj <span className="underline-shadow">opłatę za opcję rezygnacji</span>
        </span>
      }
      subtitle="Skorzystaj z opcji możliwości rezygnacji z pobytu na wypadek nieprzewidzianych zdarzeń i ciesz się komfortem możliwości rezygnacji do 3 dni przed planowanym pobytem."
    >
      <SummaryPromoImprovementCard
        isLoading={isLoading}
        isActive={warranty}
        title="Opcja rezygnacji"
        description="Dodanie opcji rezygnacji zwiększy koszt rezerwacji o 10% wartości rezerwacji"
        image={require('@assets/images/warranty-image.webp')}
        cardActionChildren={
          <strong className="text-darker-gray d-flex align-items-center">+{formatPrice(warranty_costs)}</strong>
        }
        onActionClick={handleAddWarrantyInsurance}
      />
      <p className="text-darker-gray mt-4 mb-0 font-size-sm">
        Korzystając z opcji rezygnacji z pobytu <strong>otrzymasz zwrot 100% wartości wpłaconych kwot</strong> za
        rezerwację pobytu
      </p>
    </SummaryCard>
  )
}
