export const HOUSE_WITH_GARDEN_ACCOMMODATION_TYPE = 4
export const HOUSE_ACCOMMODATION_TYPE = 1

export const ACCOMMODATION_TYPES = {
  Houses: [HOUSE_ACCOMMODATION_TYPE, HOUSE_WITH_GARDEN_ACCOMMODATION_TYPE],
  Apartments: [2, 3],
}

export const CLIENT_APP_URLS = {
  RESET_PASSWORD: `${process.env.CLIENT_URL ?? ''}/reset-password/`,
  ACCOUNT_CREATE: `${process.env.CLIENT_URL ?? ''}/account-create/`,
  MAIN_PAGE: `${process.env.CLIENT_URL ?? ''}/`,
}

export const GLOBAL_URLS = {
  ECOMMERCE: 'https://sklep.holidaypark.pl',
  ECOMMERCE_SUBSCRIPTION_CONTRACT: 'https://sklep.holidaypark.pl/subskrypcja/',
  ECOMMERCE_SUBSCRIPTION_WITH_BENEFIT: 'https://sklep.holidaypark.pl/pakiet-korzysci/',
  ECOMMERCE_GO_HOLIDAY: 'https://sklep.holidaypark.pl/go-holiday/',
}

export const API_ERROR_CODE = 'api-error-code'

export const API_ERROR_HEADERS = {
  INVALID_CODE: 'invalid_code',
}
