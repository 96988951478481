import * as React from 'react'
import { LocalForSubscriptionWithBenefitProductVariant } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-for-client-with-benefit/subscription-with-benefits/local-for-subscription-with-benefit-product-variant'
import { LocalForClientWithBenefitExpandable } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-for-client-with-benefit/local-for-client-with-benefit-expandable'
import { LocalForClientWithBenefitCode } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-for-client-with-benefit/local-for-client-with-benefit-code'
import { LocalForClientOption } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-for-client-with-benefit/local-for-client-with-benefit-modal'
import { GLOBAL_URLS } from '@helpers/consts'

interface Props {
  isCollapsed: boolean
  onCollapse: (option: LocalForClientOption | null) => void
}

export const LocalForClientWithSubscriptionBenefitOption = ({ isCollapsed, onCollapse }: Props): JSX.Element => {
  return (
    <LocalForClientWithBenefitExpandable
      ecommerceLink={GLOBAL_URLS.ECOMMERCE_SUBSCRIPTION_WITH_BENEFIT}
      isCollapsed={isCollapsed}
      onCollapse={() => onCollapse(isCollapsed ? null : 'subscription_with_benefit')}
      imageAlt="Wakacyjne Pakiety Korzyści"
      image={require('@assets/images/subscription-with-benefits.svg')}
      description={
        <div className="text-md-start text-center">
          <h4 className="text-primary mb-md-0">Wakacyjne Pakiety Korzyści</h4>
          <p>Pakiety 5 pobytów 5-dniowych w domku lub apartamencie.</p>
        </div>
      }
    >
      <div className="my-3 subscription-with-benefits-owner-modal__collapsed-content is-only-check-offset">
        <div className="row">
          <LocalForSubscriptionWithBenefitProductVariant
            variant="optimum"
            dates="01.01 - 31.05 oraz 01.10 - 31.12*"
            prices={{ newClient: 7499, regularClient: 5999 }}
            bonuses={{ firstYear: 500, secondYear: 1000, thirdYear: 1500, fourthYear: 2000 }}
          />

          <LocalForSubscriptionWithBenefitProductVariant
            variant="comfort"
            dates="01.01 - 30.06 oraz 01.09 - 31.12*"
            prices={{ newClient: 9999, regularClient: 7999 }}
            bonuses={{ firstYear: 500, secondYear: 1000, thirdYear: 1500, fourthYear: 3000 }}
          />

          <LocalForSubscriptionWithBenefitProductVariant
            variant="lux"
            dates="01.01 - 07.07 oraz 26.08 - 31.12"
            prices={{ newClient: 16999, regularClient: 14999 }}
            bonuses={{ firstYear: 1000, secondYear: 2000, thirdYear: 3000, fourthYear: 4000 }}
          />
        </div>
        <small className="d-block pt-1">*z wyłączeniem terminów specjalnych</small>
        <LocalForClientWithBenefitCode productName="Pakiet Korzyści" />
      </div>
    </LocalForClientWithBenefitExpandable>
  )
}
