import * as React from 'react'
import * as clsx from 'clsx'
import { ElementRenderer } from '@components/element-renderer'

interface Props {
  text: React.ReactNode
  wrapperClassName?: string
  iconClassName?: string
  textClassName?: string
  onClick?: (event) => void
}

export const IconWithText = ({ text, iconClassName, wrapperClassName, textClassName, onClick }: Props): JSX.Element => (
  <ElementRenderer
    renderAs={onClick ? 'button' : 'div'}
    className={clsx('d-flex align-items-center', { 'cursor-pointer': !!onClick }, wrapperClassName)}
    onClick={onClick}
  >
    <i className={clsx(iconClassName)} />
    <strong className={textClassName}>{text}</strong>
  </ElementRenderer>
)
