import * as React from 'react'
import { MainInfoBox } from '@components/main-info-box'
import { useModal } from '@modals/use-modal'

export const PromotionBoxMobile = (): JSX.Element => {
  const [showSubscriptionWithBenefitsModal] = useModal('LocalForClientWithBenefitModal', {
    title: 'Oferty specjalne',
    isMobileCodeAdding: true,
    isPreview: true,
  })

  return (
    <MainInfoBox className="bg-purple">
      <button
        className="d-flex justify-content-center w-100 text-white py-2 btn-unstyled fw-bold"
        onClick={showSubscriptionWithBenefitsModal}
      >
        Oferty specjalne
      </button>
    </MainInfoBox>
  )
}
