import * as React from 'react'
import { ApartmentLocalizationFacilities } from '@modules/accommodation-selection-step/local-selection/apartment-details/apartment-localization-facilities'
import { ResortDetails } from '@models/resort'

interface Props {
  resort: ResortDetails
}

export const LocalizationFacilities = ({ resort }: Props): JSX.Element => (
  <div className="container-lg">
    <div className="d-flex flex-column p-3 bg-light-blue rounded">
      <strong className="text-muted font-size-md text-center text-xl-start">
        Udogodnienia w ośrodku: {resort.name}
      </strong>
      <ApartmentLocalizationFacilities resort={resort} />
    </div>
  </div>
)
