import { Step } from '@store/reducers/steps-reducer'

export enum StepsActionTypesKeys {
  CHANGE_STEP = 'CHANGE_STEP',
  SET_NEXT_STEP_ACTIVE = 'SET_NEXT_STEP_ACTIVE',
  SET_NEXT_STEP_INACTIVE = 'SET_NEXT_STEP_INACTIVE',
}

export interface ChangeStep {
  type: StepsActionTypesKeys.CHANGE_STEP
  payload: {
    step: Step
  }
}

export interface SetNextStepActive {
  type: StepsActionTypesKeys.SET_NEXT_STEP_ACTIVE
}

export interface SetNextStepInactive {
  type: StepsActionTypesKeys.SET_NEXT_STEP_INACTIVE
}

export type StepsActionsTypes = ChangeStep | SetNextStepActive | SetNextStepInactive
