import * as React from 'react'

interface UseNumberInputWithPlaceholderResponse {
  type: React.HTMLInputTypeAttribute
  onFocus: () => void
  onBlur: () => void
}

export const useNumberInputWithPlaceholder = (): UseNumberInputWithPlaceholderResponse => {
  const [type, setType] = React.useState<React.HTMLInputTypeAttribute>('text')

  const handleInputTypeChange = (type: React.HTMLInputTypeAttribute) => () => {
    setType(type)
  }

  return {
    type,
    onFocus: handleInputTypeChange('number'),
    onBlur: handleInputTypeChange('text'),
  }
}
