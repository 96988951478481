import * as React from 'react'
import { useSelector } from 'react-redux'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { SummaryBoxReservationInformationItem } from '@modules/aside/summary-box/summary-box-reservation-information-item'
import { formatPrice } from '@helpers/utils'
import { useModal } from '@modals/use-modal'

export const SummaryBoxWarranty = (): JSX.Element | null => {
  const reservation = useSelector(selectReservation)

  const [showWarrantyDeleteModal] = useModal('WarrantyDeleteModal')

  if (!reservation.warranty) return null

  return (
    <div className="summary-box__warranty py-2 container-lg">
      <SummaryBoxReservationInformationItem
        title={
          <span>
            Opcja rezygnacji{' '}
            <button
              className="uil-trash-alt summary-box__improvements__remove-icon"
              onClick={showWarrantyDeleteModal}
              data-testid="warranty-delete"
            />
          </span>
        }
        value={`+ ${formatPrice(reservation.prices.warranty_price_brutto)}`}
        icon="uil-check"
      />
    </div>
  )
}
