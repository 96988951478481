import TagManager from 'react-gtm-module'
import { EventData, GTMEvents } from '@models/gtm'

interface Response {
  trackPage: (pathname: string) => void
  sendEvent: <T>(data: Partial<EventData<T>>) => void
}

export const useGtm = (): Response => {
  const sendEvent = <T extends {}>(dataLayer: EventData<T>) => {
    TagManager.dataLayer({ dataLayer })
  }

  const trackPage = (pathname: string) => {
    const path = pathname.split('/')[1]
    const pagePath = path === '' ? '/' : path

    sendEvent({ event: GTMEvents.PageView, pagePath })
  }

  return {
    sendEvent,
    trackPage,
  }
}
