import * as React from 'react'
import * as clsx from 'clsx'
import { ModalWithImageWrapper } from '@modals/modal-with-image-wrapper'
import { NotificationMessage, NotificationMessageVariant } from '@models/notification-messages'

const MessageColor = {
  [NotificationMessageVariant.ERROR]: 'text-danger',
  [NotificationMessageVariant.MESSAGE]: 'text-darker-gray',
}

interface Props {
  message: NotificationMessage
}

export const NotificationMessageModal = ({ message }: Props): JSX.Element => (
  <ModalWithImageWrapper>
    <div className="ps-xl-5 py-4 container-lg">
      {message?.subtitle !== null && (
        <h3
          className={clsx(
            'fw-bold font-size-xl mb-3',
            MessageColor[message?.variant || NotificationMessageVariant.MESSAGE],
          )}
        >
          {message?.subtitle || ''}
        </h3>
      )}
      {message?.message && (
        <span className="fw-semi-bold d-block text-darker-gray font-size-lg">{message.message}</span>
      )}
    </div>
  </ModalWithImageWrapper>
)
