export default {
  HomeView: {
    test: 'Deutsche',
  },
  stayDeclination: {
    single: '',
    few: '',
    multiple: '',
  },
  firstStep: {
    resort: {
      chosen: '',
      others: '',
    },
  },
  general: {
    test: 'test',
  },
}
