import * as React from 'react'
import * as clsx from 'clsx'
import { AccommodationTypeAvailable, AccommodationTypeAvailableDateRange } from '@models/reservation'
import { parseISODate } from '@helpers/date-formatter'
import { compareAsc, isBefore } from 'date-fns'
import { CardBannerWrapper } from '@modules/accommodation-selection-step/local-selection/card-banner-wrapper'
import { OccupiedBannerDateRecommendations } from '@modules/accommodation-selection-step/local-selection/occupied-banner/occupied-bannner-date-reccomendatiotns'
import { Resort } from '@models/app-data'
import { useSelector } from 'react-redux'
import { selectReservation } from '@store/selectors/reservation-selectors'

interface Props {
  accommodationTypeAvailable: AccommodationTypeAvailable[]
  title: React.ReactNode
  resort: Resort
  accommodation?: React.ReactNode
  children?: React.ReactNode
  wrapperClassName?: string
}

export const OccupiedBanner = ({
  accommodationTypeAvailable,
  title,
  resort,
  accommodation,
  children,
  wrapperClassName,
}: Props): JSX.Element => {
  const reservation = useSelector(selectReservation)

  const { availableDates, availableDatesFullMatch } = React.useMemo(
    () =>
      accommodationTypeAvailable.reduce(
        (dates, acc) => ({
          availableDates: [...dates.availableDates, ...acc.available_dates],
          availableDatesFullMatch: [...dates.availableDatesFullMatch, ...acc.available_dates_full_match],
        }),
        { availableDates: [], availableDatesFullMatch: [] },
      ),
    [accommodationTypeAvailable],
  )

  const { previousAvailableDates, upcomingAvailableDates } = availableDates.reduce(
    (previous, date: AccommodationTypeAvailableDateRange) =>
      isBefore(parseISODate(date.date_from), parseISODate(reservation.date_to))
        ? { ...previous, previousAvailableDates: [...previous.previousAvailableDates, date] }
        : { ...previous, upcomingAvailableDates: [...previous.upcomingAvailableDates, date] },
    { previousAvailableDates: [], upcomingAvailableDates: [] },
  )

  const { previousAvailableFullMatchDates, upcomingAvailableFullMatchDates } = availableDatesFullMatch.reduce(
    (previous, date: AccommodationTypeAvailableDateRange) =>
      isBefore(parseISODate(date.date_from), parseISODate(reservation.date_to))
        ? { ...previous, previousAvailableFullMatchDates: [...previous.previousAvailableFullMatchDates, date] }
        : { ...previous, upcomingAvailableFullMatchDates: [...previous.upcomingAvailableFullMatchDates, date] },
    { previousAvailableFullMatchDates: [], upcomingAvailableFullMatchDates: [] },
  )

  const sortedPreviousAvailableDates = previousAvailableDates.sort(sortByEndDate)
  const sortedUpcomingAvailableDates = upcomingAvailableDates.sort(sortByStartDate)

  const sortedPreviousAvailableFullMatchDates = previousAvailableFullMatchDates.sort(sortByEndDate)
  const sortedUpcomingAvailableFullMatchDates = upcomingAvailableFullMatchDates.sort(sortByStartDate)

  const isRecommendedDateAvailable =
    !!sortedPreviousAvailableDates.length ||
    !!sortedUpcomingAvailableDates.length ||
    !!sortedPreviousAvailableFullMatchDates.length ||
    !!sortedUpcomingAvailableFullMatchDates.length

  return (
    <CardBannerWrapper className={wrapperClassName}>
      <span
        className={clsx('font-size-sm text-white d-block fw-semi-bold', {
          'mb-2': isRecommendedDateAvailable,
        })}
      >
        <p className="text-center d-sm-none font-size-xxl mb-1">{title}</p>

        {isRecommendedDateAvailable ? (
          <Content accommodation={accommodation} resortName={resort.name} description="Sprawdź inne dostępne daty:" />
        ) : (
          <Content accommodation={accommodation} resortName={resort.name} description="Sprawdź nasze inne Ośrodki." />
        )}
      </span>

      <OccupiedBannerDateRecommendations
        resort={resort}
        fullMatchDates={[
          ...(sortedPreviousAvailableFullMatchDates[0] ? [sortedPreviousAvailableFullMatchDates[0]] : []),
          ...(sortedUpcomingAvailableFullMatchDates[0] ? [sortedUpcomingAvailableFullMatchDates[0]] : []),
        ]}
        shorterDates={[
          ...(sortedPreviousAvailableDates[0] ? [sortedPreviousAvailableDates[0]] : []),
          ...(sortedUpcomingAvailableDates[0] ? [sortedUpcomingAvailableDates[0]] : []),
        ]}
      />
      {children}
    </CardBannerWrapper>
  )
}

const sortByStartDate = (a: AccommodationTypeAvailableDateRange, b: AccommodationTypeAvailableDateRange) =>
  sortByDate(a, b, 'date_from')

const sortByEndDate = (a: AccommodationTypeAvailableDateRange, b: AccommodationTypeAvailableDateRange) =>
  sortByDate(a, b, 'date_to')

const sortByDate = (
  a: AccommodationTypeAvailableDateRange,
  b: AccommodationTypeAvailableDateRange,
  field: 'date_from' | 'date_to',
) => compareAsc(parseISODate(a[field]), parseISODate(b[field]))

const Content = ({ resortName, description, accommodation }) => (
  <span className="d-block text-center">
    <span className="fw-semi-bold d-block">
      Przepraszamy, w tych dniach wszystkie lokale{' '}
      {accommodation && (
        <span className="d-none d-sm-inline">
          {' '}
          typu <strong className="text-nowrap">{accommodation}</strong>
        </span>
      )}{' '}
      w<strong className="text-nowrap"> Holiday Park {resortName}</strong> są niedostępne.
    </span>
    <span className="font-size-sm">{description}</span>
  </span>
)
