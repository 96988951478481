import { FetchResortOpinionsPayload, ThunkResult } from '@models/models'
import { SetGoogleOpinions, SetResortDetails } from '@store/action-types/resort-action-types'
import { setGoogleOpinions, setResortDetails } from '@store/actions/resort-actions'
import { commonObjectGet } from '@requests/basic-requests'

export function fetchResortOpinions(
  payload: FetchResortOpinionsPayload,
): ThunkResult<Promise<void>, SetGoogleOpinions> {
  return async (dispatch, getState) => {
    const state = getState()
    const resort_id = payload?.resort_id || state.reservationState.reservation.resort_id
    dispatch(setGoogleOpinions(await commonObjectGet(payload.url, { params: { resort_id, ...payload.config } })))
  }
}

export function fetchResortDetails(
  resortId: number | null,
  date_from: string,
  date_to: string,
): ThunkResult<Promise<void>, SetResortDetails> {
  return async (dispatch, getState) => {
    const resort = getState().appDataState.appData.resorts.find(resort => resort.id === resortId)
    if (!resort) return
    dispatch(setResortDetails(await commonObjectGet(resort.urls.details, { params: { date_from, date_to } })))
  }
}
