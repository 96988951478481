import { addYears, format, formatDistanceStrict, isAfter, parseISO } from 'date-fns'
import { pl } from 'date-fns/locale'
import { Reservation } from '@models/reservation'

export enum DateFormats {
  DAY_WITH_DATE = 'EE dd.MM.yyyy',
  MONTH_WITH_FULL_WORD_MONTH = 'dd MMMM',
  MONTH_WITH_FULL_WORD_MONTH_WITH_YEAR = 'dd MMMM yyyy',
  DAY_MONTH_YEAR_SEPARATED_BY_DOT = 'dd.MM.yyyy',
  DATE_WITH_FULL_DAY = 'dd.MM.yyy, EEEE',
}

export const formatDate = (date: Date | undefined | string | null, dateFormat = 'yyyy-MM-dd'): string => {
  if (!date) {
    return ''
  }
  if (typeof date === 'string') {
    return format(parseISO(date), dateFormat, { locale: pl })
  }
  if (typeof date !== 'undefined') {
    return format(date, dateFormat, { locale: pl })
  }
  return date ? date : ''
}

export const parseISODate = (value: string | Date): Date => {
  if (typeof value === 'string') {
    return parseISO(value)
  }
  return value
}

export const getRangeInDays = (startData: Date | string, endDate: Date | string): string => {
  let parsedStart = startData
  let parsedEnd = endDate
  if (!parsedStart || !parsedEnd) {
    return ''
  }

  if (typeof parsedStart === 'string') {
    parsedStart = parseISO(parsedStart)
  }

  if (typeof parsedEnd === 'string') {
    parsedEnd = parseISO(parsedEnd)
  }

  return formatDistanceStrict(parsedStart, parsedEnd, {
    locale: pl,
    unit: 'day',
    roundingMethod: 'ceil',
  })
}

export const getHoursFromDate = (date: Date | string): string => formatDate(date, 'HH:mm')

export const isAdult = (age: string): boolean => isAfter(new Date(), addYears(parseISODate(age), 18))

export const formatToGtmEventDate = (reservation: Reservation): string =>
  `${formatDate(reservation.date_from, DateFormats.DAY_MONTH_YEAR_SEPARATED_BY_DOT)}-${formatDate(
    reservation.date_to,
    DateFormats.DAY_MONTH_YEAR_SEPARATED_BY_DOT,
  )}`
