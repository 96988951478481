import * as React from 'react'
import { useApiRequest } from '@hooks/use-form-request'
import { useDispatch } from 'react-redux'
import { TextWithSpinner } from '@components/loaders/text-with-spinner'
import { RemoveItemModal } from '@modals/remove-item-modal'
import { updateMainReservationData } from '@requests/reservation/reservation-requests'
import { BaseModalProps } from '@modals/types'

export const WarrantyDeleteModal = ({ toggleIsVisible }: BaseModalProps): JSX.Element => {
  const { handleAction, isLoading } = useApiRequest()
  const dispatch = useDispatch()

  const handleDelete = async () => {
    await handleAction(() => dispatch(updateMainReservationData({ warranty: false })))
    toggleIsVisible()
  }

  return (
    <RemoveItemModal
      image={require('@assets/images/remove-improvement-modal-bg.webp')}
      title={
        <span className="text-dark font-size-xxl d-flex flex-column justify-content-center">
          Na pewno usunąć
          <br />
          <strong className="position-relative mx-auto">
            <span className="underline-shadow"> opcję rezygnacji?</span>
          </strong>
        </span>
      }
      bottomAction={
        <TextWithSpinner
          isLoading={isLoading}
          text="Usuń opcję rezygnacji"
          loadingText="Usuwanie"
          spinnerColor="danger"
          onClick={handleDelete}
        />
      }
    />
  )
}
