import { ClientDetails } from '@models/app-data'
import {
  ClientDetailsActionsTypes,
  ClientDetailsActionTypesKeys,
} from '@store/action-types/client-details-action-types'

export interface ClientDetailsState {
  clientDetails: ClientDetails | null
}

const initialState: ClientDetailsState = {
  clientDetails: null,
}

const clientDetailsState = (
  state: ClientDetailsState = initialState,
  action: ClientDetailsActionsTypes,
): ClientDetailsState => {
  switch (action.type) {
    case ClientDetailsActionTypesKeys.SET_CLIENT_DETAILS:
      return { ...state, clientDetails: action.payload }
    default:
      return state
  }
}

export default clientDetailsState
