import { AxiosErrorHandlerConfig, CustomError, useAxiosErrorHandler } from '@hooks/use-axios-error-handler'
import * as React from 'react'
import { UseFormSetError } from 'react-hook-form/dist/types/form'
import { useDispatch } from 'react-redux'
import { AxiosError } from 'axios'
import { useCancellablePromise } from '@hooks/use-cancellable-promise'
import useDebounce from '@rooks/use-debounce'
import { FieldValues } from 'react-hook-form'
import { NotificationMessageVariant } from '@models/notification-messages'
import { setNotificationMessage } from '@store/slices/notification-messages-slice'
import { AppDispatch } from '@store/index'

type State = 'pending' | 'resolved' | 'rejected' | 'idle'

interface UseFormRequestConfig<T> {
  axiosFormErrorConfig?: AxiosErrorHandlerConfig<T>
}

export interface UseRequestActionHandlerResponse<T> {
  data: T | undefined
  isResolved: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any
}

interface UseRequestResponse {
  handleAction: <T>(...params) => Promise<UseRequestActionHandlerResponse<T>>
  state: State
  isLoading: boolean
}

interface UseApiRequestParams {
  shouldDispatchModalError: boolean
  errorCatcher?: (error) => void
}

export function useApiRequest({
  shouldDispatchModalError = true,
  errorCatcher,
}: Partial<UseApiRequestParams> = {}): UseRequestResponse {
  const [state, setState] = React.useState<State>('idle')

  const { cancellablePromise } = useCancellablePromise()

  const dispatch = useDispatch()

  const handleAction = async function <T>(action) {
    setState('pending')
    try {
      const result = await cancellablePromise<T>(action())
      setState('resolved')
      return { data: result, isResolved: true, error: undefined }
    } catch (error) {
      if (!error.isCanceled) {
        setState('rejected')
        errorCatcher?.(error)
        if (shouldDispatchModalError || error.response?.status === 500) {
          handleApiRequestError(error, dispatch)
        }
      }

      return { data: undefined, isResolved: false, error }
    }
  }

  return {
    state,
    handleAction,
    isLoading: state === 'pending',
  }
}

export function useFormRequest<FormInputs extends FieldValues = FieldValues>(
  setError?: UseFormSetError<FormInputs>,
  config?: UseFormRequestConfig<FormInputs>,
): UseRequestResponse {
  const apiRequest = useApiRequest({ shouldDispatchModalError: false })
  const handleAxiosFormErrors = useAxiosErrorHandler<UseFormSetError<FormInputs>>()

  return {
    ...apiRequest,
    handleAction: async function <T>(action) {
      const result = await apiRequest.handleAction<T>(action)
      if (result.error) {
        handleAxiosFormErrors(result.error, setError, {
          showGlobalError: false,
          ...(config && { ...config.axiosFormErrorConfig }),
        })
      }

      return { ...result }
    },
  }
}

export function useScrollToError<T extends FieldValues = FieldValues>(errors: T, disabled?: boolean): void {
  const debouncedScroll = useDebounce(() => {
    if (disabled) return

    const failedInputRef: HTMLElement = (Object.values(errors).length && Object.values(errors)[0].ref) || null
    if (!failedInputRef) return

    try {
      failedInputRef.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    } catch (error) {
      failedInputRef.scrollIntoView(false)
    }
  }, 200)

  React.useEffect(() => {
    debouncedScroll()
  }, [errors])
}

export const handleApiRequestError = (error: AxiosError<CustomError>, dispatch: AppDispatch): void => {
  let errorMessage = ''
  let errorTitle = ''
  let errorSubtitle: null | string = 'Wystąpił błąd'

  if (error.response?.status === 400) {
    errorTitle = 'Nieprawidłowe dane'
    errorSubtitle = null
    errorMessage = createErrorMessage(error)
  }

  dispatch(
    setNotificationMessage({
      title: errorTitle,
      subtitle: errorSubtitle,
      message: errorMessage,
      variant: NotificationMessageVariant.ERROR,
    }),
  )
}

export const createErrorMessage = (error: AxiosError<CustomError>): string => {
  let errorMessage = ''

  if (!error.response) return ''

  if (Array.isArray(error.response.data)) {
    errorMessage = error.response.data.map(err => Object.values(err)).join('. ')
  } else if (error.response.data.non_field_errors) {
    errorMessage = error.response.data.non_field_errors.join('. ')
  } else {
    errorMessage = Object.values(error.response.data).join('. ')
  }

  return errorMessage
}
