import { AccommodationTypeAvailable, Apartment, Reservation } from '@models/reservation'

export enum ReservationActionTypesKeys {
  UPDATE_RESERVATION = 'UPDATE_RESERVATION',
  SET_AVAILABLE_APARTMENTS = 'SET_AVAILABLE_APARTMENTS',
  UPDATE_ACCOMMODATION_TYPE_AVAILABLE = 'UPDATE_ACCOMMODATION_TYPE_AVAILABLE',
}

export interface UpdateReservationAction {
  type: ReservationActionTypesKeys.UPDATE_RESERVATION
  payload: { reservation: Partial<Reservation> }
}

export interface UpdateAccommodationTypeAvailableAction {
  type: ReservationActionTypesKeys.UPDATE_ACCOMMODATION_TYPE_AVAILABLE
  payload: AccommodationTypeAvailable[]
}

export interface SetAvailableApartments {
  type: ReservationActionTypesKeys.SET_AVAILABLE_APARTMENTS
  payload: Apartment[]
}

export type ReservationActionsTypes =
  | UpdateReservationAction
  | SetAvailableApartments
  | UpdateAccommodationTypeAvailableAction
