import * as React from 'react'
import { ModalBody } from 'react-bootstrap'
import * as clsx from 'clsx'
import { BaseModalProps } from '@modals/types'
import { LocalForClientWithSubscriptionBenefitOption } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-for-client-with-benefit/subscription-with-benefits/local-for-client-with-subscription-benefit-option'
import { IconWithText } from '@components/icon-with-text'
import { LocalForClientWithGoHolidayOption } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-for-client-with-benefit/go-holiday/local-for-client-with-go-holiday-option'
import { LocalForClientWithSubscriptionContractOption } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-for-client-with-benefit/subscription-contract/local-for-client-with-subscription-contract-option'

export type LocalForClientOption = 'subscription_with_benefit' | 'go_holiday' | 'subscription_contract'

interface Props extends BaseModalProps {
  isMobileCodeAdding?: boolean
  isPreview?: boolean
}

export const LocalForClientWithBenefitModal = ({
  isMobileCodeAdding = false,
  isPreview = false,
  toggleIsVisible,
}: Props): JSX.Element => {
  const [collapsedOption, setCollapsedOption] = React.useState<LocalForClientOption | null>(
    isMobileCodeAdding ? null : 'subscription_with_benefit',
  )

  return (
    <ModalBody className={clsx('text-muted px-3 pt-4 pb-xl-4 overflow-hidden', { 'pb-5': !isMobileCodeAdding })}>
      <IconWithText
        iconClassName="uil-exclamation-circle text-primary me-2 lh-0 font-size-xl align-self-start pt-1"
        text={
          <span>
            {!isPreview && (
              <span>
                Wybrany lokal jest <strong className="text-primary">dostępny w ramach specjalnych ofert!</strong>
              </span>
            )}
            <p className="mb-0 d-inline d-md-block"> Sprawdź poniższe promocje:</p>
          </span>
        }
      />

      <LocalForClientWithSubscriptionBenefitOption
        onCollapse={setCollapsedOption}
        isCollapsed={collapsedOption === 'subscription_with_benefit'}
      />
      <LocalForClientWithGoHolidayOption
        onCollapse={setCollapsedOption}
        isCollapsed={collapsedOption === 'go_holiday'}
      />
      <LocalForClientWithSubscriptionContractOption
        onCollapse={setCollapsedOption}
        isCollapsed={collapsedOption === 'subscription_contract'}
      />

      {isMobileCodeAdding && (
        <div className="bg-very-light-gray mx-n5 ">
          <button
            className="bg-dark-gradient w-100 text-white shadow-none border-0 py-3 fw-bold"
            onClick={toggleIsVisible}
          >
            Zamknij
          </button>
        </div>
      )}
    </ModalBody>
  )
}
