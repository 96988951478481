import * as React from 'react'
import { FormControlProps, FormGroup, FormLabel } from 'react-bootstrap'
import { FormLabelProps } from 'react-bootstrap/FormLabel'
import * as clsx from 'clsx'
import { FormGroupProps } from 'react-bootstrap/FormGroup'
import { FormControlWithStatus } from '@components/controls/form-controls/form-control-with-status'
import { MaskedFormControlProps } from '@components/controls/form-controls/masked-form-control'

export interface CustomFormGroupProps {
  label?: string
  inputName: string
  formControlWrapperClassName?: string
  formControlProps?: FormControlProps
  formLabelProps?: FormLabelProps
  formGroupProps?: FormGroupProps
  maskedFormControlProps?: Omit<MaskedFormControlProps, 'name'>
  isLoading?: boolean
  isSucceed?: boolean
  isFailed?: boolean
  hideError?: boolean
}

export const CustomFormGroup = ({
  label,
  inputName,
  formControlWrapperClassName,
  formControlProps,
  formLabelProps,
  formGroupProps,
  maskedFormControlProps,
  isLoading,
  isSucceed,
  isFailed,
  hideError,
}: CustomFormGroupProps): JSX.Element => (
  <FormGroup {...formGroupProps} className={clsx('mt-3 custom-form-group', formGroupProps?.className)}>
    {label && (
      <FormLabel
        {...formLabelProps}
        className={clsx('text-darker-gray fw-semi-bold mb-1 font-size-lg', formLabelProps?.className)}
        htmlFor={inputName}
      >
        {label}
      </FormLabel>
    )}
    <FormControlWithStatus
      hideError={hideError}
      inputName={inputName}
      isLoadingVisible={!!isLoading}
      wrapperClassName={formControlWrapperClassName}
      formControlProps={formControlProps}
      isFailed={!isLoading && isFailed}
      isSucceed={!isLoading && isSucceed}
      maskedFormControlProps={maskedFormControlProps}
    />
  </FormGroup>
)
