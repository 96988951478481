import * as React from 'react'
import * as clsx from 'clsx'
import { TextWithSpinner } from '@components/loaders/text-with-spinner'
import { FloatingItem } from '@components/floating-item'
import { TrapezoidButtonVariant } from '@components/controls/trapezoid-button'

interface Props {
  isLoading: boolean
  isSubmitDisabled?: boolean
  loadingText?: string
  onDecline?: () => void
  onSubmit: () => void
  submitColor?: TrapezoidButtonVariant
  submitText: string
  declineText: string
}

export const ModalMobileActions = ({
  isLoading,
  isSubmitDisabled,
  loadingText,
  onSubmit,
  onDecline,
  submitColor,
  submitText,
  declineText,
}: Props): JSX.Element => (
  <FloatingItem className="floating-item--light py-0 d-flex">
    {onDecline && (
      <button
        className="btn bg-opacity-25 bg-black rounded-0 border-end btn-tall w-25 fw-bold text-white p-0 border-0 d-block"
        onClick={onDecline}
      >
        {declineText}
      </button>
    )}
    <button
      disabled={isLoading || isSubmitDisabled}
      onClick={onSubmit}
      className={clsx(
        'btn rounded-0 btn-tall fw-bold text-white border-0 d-block',
        submitColor ? `bg-${submitColor}` : 'bg-dark-gradient',
        onDecline ? 'w-75' : 'w-100',
      )}
    >
      <TextWithSpinner
        iconClassName=""
        isLoading={isLoading}
        text={submitText}
        loadingText={loadingText || 'Zapisywanie...'}
      />
    </button>
  </FloatingItem>
)
