import * as React from 'react'
import { ACCOMMODATION_TYPES } from '@helpers/consts'
import { useSelector } from 'react-redux'
import { selectAccommodationTypesAvailable } from '@store/selectors/reservation-selectors'
import { Resort } from '@models/app-data'
import { sortByPosition } from '@helpers/utils'
import { selectResorts } from '@store/selectors/app-data-selectors'
import { selectSelectedRecommendedResortId } from '@store/selectors/resort-selectors'

export const useSortedResorts = (): Resort[] => {
  const resorts = useSelector(selectResorts)
  const accommodationTypeAvailable = useSelector(selectAccommodationTypesAvailable)
  const lastRecommendedResortId = useSelector(selectSelectedRecommendedResortId)

  const lastSelectedRecommendedResort = resorts.find(resort => resort.id === lastRecommendedResortId)

  const resortsToCompare = React.useMemo(
    () => (lastSelectedRecommendedResort ? resorts.filter(resort => resort.id !== lastRecommendedResortId) : resorts),
    [lastSelectedRecommendedResort],
  )

  const resortOccupancy = React.useMemo(
    () =>
      resortsToCompare.reduce(
        (result, current) => {
          const resortAvailabilities = accommodationTypeAvailable.filter(el => el.resort_id === current.id)

          const isTypeAvailable = accommodationType =>
            resortAvailabilities
              .filter(el => accommodationType.includes(el.accommodation_type_id))
              .some(el => el.available)

          const isEverythingAvailable = resortAvailabilities.every(el => el.available)

          if (isEverythingAvailable) {
            return { ...result, everything: [...result.everything, current] }
          }

          if (isTypeAvailable(ACCOMMODATION_TYPES.Houses)) {
            return { ...result, houses: [...result.houses, current] }
          }

          if (isTypeAvailable(ACCOMMODATION_TYPES.Apartments)) {
            return { ...result, apartments: [...result.apartments, current] }
          }

          return { ...result, unavailable: [...result.unavailable, current] }
        },
        { everything: [], houses: [], apartments: [], unavailable: [] },
      ),
    [resortsToCompare, accommodationTypeAvailable],
  )

  return React.useMemo(
    () => [
      ...(lastSelectedRecommendedResort ? [lastSelectedRecommendedResort] : []),
      ...sortByPosition(resortOccupancy.everything),
      ...resortOccupancy.houses,
      ...resortOccupancy.apartments,
      ...resortOccupancy.unavailable,
    ],
    [resortOccupancy, lastSelectedRecommendedResort],
  )
}
