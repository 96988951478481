import * as React from 'react'
import { CardBannerWrapper } from '@modules/accommodation-selection-step/local-selection/card-banner-wrapper'
import { Resort } from '@models/app-data'

interface Props {
  resort: Resort
}

export const UnavailableResortBanner = ({ resort }: Props): JSX.Element => (
  <CardBannerWrapper>
    <div className="text-white fw-semi-bold font-size-md">
      <span>Zmieniamy się dla Ciebie.</span>
      <span className="d-block my-2">
        Do czasu ponownego udostępnienia kalendarza zachęcamy do rezerwacji za pośrednictwem naszego Biura Obsługi
        Klienta pod numerem telefonu{' '}
        <a href={'tel:+48735200800'} className="text-decoration-none text-white text-nowrap">
          +48 735 200 800
        </a>
      </span>
      {resort.apartments_map && (
        <a
          href={resort.apartments_map}
          target="_blank"
          className="text-decoration-none text-white text-nowrap d-block fw-bold mb-2"
        >
          Zobacz mapę Ośrodka
        </a>
      )}
      <span>Przepraszamy za utrudnienia.</span>
    </div>
  </CardBannerWrapper>
)
