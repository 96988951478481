import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { TextWithSpinner } from '@components/loaders/text-with-spinner'
import { RemoveItemModal } from '@modals/remove-item-modal'
import { BaseModalProps } from '@modals/types'
import { Wallet } from '@models/reservation'
import { useApiRequest } from '@hooks/use-form-request'
import { removeGastroVoucher } from '@requests/reservation/reservation-requests'
import { useAppDispatch } from '@store/index'

interface Props extends BaseModalProps {
  wallet: Wallet
}

export const SummaryGastroVoucherRemoveModal = ({ wallet, toggleIsVisible }: Props): JSX.Element => {
  const { handleAction, isLoading } = useApiRequest()

  const dispatch = useAppDispatch()

  const handleRemoveModal = async () => {
    const { isResolved } = await handleAction(() => dispatch(removeGastroVoucher(wallet.urls.details)))
    if (isResolved) toggleIsVisible()
  }

  return (
    <RemoveItemModal
      image={require('@assets/images/gastro-voucher-delete-bg.webp')}
      title={
        <span className="text-dark font-size-xxl text-center px-3">
          Na pewno odpiąć
          <br />
          <strong className="position-relative mx-auto">
            {' '}
            voucher
            <span className="underline-shadow"> Podwójna Radość</span>
          </strong>
        </span>
      }
      onUpperButtonClick={toggleIsVisible}
      upperAction={<IconWithText text="Pozostaw podpięty" iconClassName="uil-check me-2 font-size-xxl" />}
      bottomAction={
        <TextWithSpinner
          isLoading={isLoading}
          text="Odepnij voucher"
          loadingText="Odpinanie"
          spinnerColor="danger"
          wrapperClassName="cursor-pointer"
          onClick={handleRemoveModal}
        />
      }
    />
  )
}
