import * as React from 'react'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import { ResortImprovement } from '@models/reservation'
import { useModal } from '@modals/use-modal'
import { SectionCard } from '@modules/improvement-step/improvements-sections/section-card'
import Decimal from 'decimal.js'
import { createImprovementViewListItem, useGtmEvents } from '@hooks/use-gtm-events'

interface Props {
  improvement: ResortImprovement
}

export const ImprovementCard = ({ improvement }: Props): JSX.Element => {
  const [showImprovementDetailsModal] = useModal('ImprovementDetailsModal', {
    title: 'Szczegóły ulepszenia:',
    resortImprovement: improvement,
  })

  const { selectItem } = useGtmEvents()

  const handleClick = () => {
    selectItem(createImprovementViewListItem(improvement, 0), 'ulepszenia')
    showImprovementDetailsModal()
  }

  const improvementPrice = improvement.price_brutto_first_item
    ? Decimal.min(asDecimal(improvement.price_brutto), asDecimal(improvement.price_brutto_first_item)).toString()
    : improvement.price_brutto

  return (
    <div className="col-xl-4 col-sm-6">
      <SectionCard
        title={improvement.name}
        onRightActionClick={handleClick}
        image={improvement.image.sm}
        cardClassName="is-low-image"
        leftAction={
          <strong className="text-darker-gray d-flex align-items-center">
            {formatPriceShort(improvementPrice)} / {improvement.price_type_2_display}
          </strong>
        }
      >
        <p className="card-improvement__description">{improvement.description}</p>
      </SectionCard>
    </div>
  )
}
