import * as React from 'react'
import * as clsx from 'clsx'
import { Overlay } from '@components/overlay'

interface Props {
  children?: JSX.Element
  isShowed: boolean
  isShowedClassName: string
  notShowedClassName: string
  containerClassName: string
  header: JSX.Element
  toggle: () => void
}

export const OverlaySelectionContainer = ({
  children,
  isShowed,
  isShowedClassName,
  notShowedClassName,
  header,
  toggle,
  containerClassName,
}: Props): JSX.Element => (
  <>
    <div
      className={clsx(containerClassName, {
        [notShowedClassName]: !isShowed,
        [isShowedClassName]: isShowed,
      })}
    >
      {header}
      {isShowed && children}
    </div>
    <Overlay onClick={toggle} isVisible={isShowed} />
  </>
)
