import { AccommodationType, OpinionProvidersKeys } from '@models/app-data'
import * as React from 'react'
import { v4 as uuidv4 } from 'uuid'

import Decimal from 'decimal.js'

enum KeyCodes {
  Enter = 'Enter',
  NumpadEnter = 'NumpadEnter',
}

export const getAccommodationTypeName = (
  accommodationType: AccommodationType | undefined,
  reservationAccommodationTypeName?: string | null,
): JSX.Element | string => {
  if (!accommodationType) return ''

  const [accommodationTypeName, ...rest] = accommodationType.name.split(' ')

  if (reservationAccommodationTypeName) return `${reservationAccommodationTypeName} ${accommodationType.size}m²`

  return (
    <>
      {accommodationTypeName} {accommodationType.size}m² {rest.join(' ')}
    </>
  )
}

export const getOpinionProviderName = (provider: OpinionProvidersKeys | null): string => {
  switch (provider) {
    case 'google':
      return 'Google'
    case 'booking':
      return 'Booking'
    case 'tripadvisor':
      return 'Tripadvisor'
    default:
      return ''
  }
}

export const formatPrice = (value: number | string): string => {
  return Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: 'PLN',
  })
    .format(typeof value === 'string' ? parseFloat(value) : value)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const formatPriceShort = (value: number | string, removeCurrency = false): string => {
  const result = formatPrice(value).replace(',00', '')

  if (removeCurrency) {
    return result.replace(' zł', '')
  }

  return result
}

export const createHash = (): string => uuidv4()

export const createArrFromNumber = (num: number): number[] => [...Array(num).keys()]

export const isEmptyObject = <T extends {}>(element: T): boolean => !Object.keys(element).length

export const sumDecimalArray = (decimals: Decimal[]): Decimal =>
  decimals.reduce((prev: Decimal, current: Decimal) => prev.add(current), new Decimal(0))

export const handleEnter =
  (action: (event) => void) =>
  (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.code === KeyCodes.Enter || event.code === KeyCodes.NumpadEnter) {
      action(event)

      const target = event.target as HTMLInputElement
      target.blur()
    }
  }

export const asDecimal = (value: string | number | null | undefined | Decimal): Decimal => {
  try {
    return new Decimal(value === 'None' ? 0 : value || 0)
  } catch (error) {
    console.warn(error)
  }
  return new Decimal(0)
}

export const sortByPosition = <T extends { position: number }>(items: T[]): T[] =>
  [...items].sort((a, b) => a.position - b.position)
