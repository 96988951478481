import { RootState } from '@store/index'
import { ClientDetails, SubscriptionContract } from '@models/app-data'
import { createSelector } from 'reselect'

export const selectClientDetails = (state: RootState): ClientDetails | null => state.clientDetailsState.clientDetails

export const selectClientActiveSubscriptionContract = createSelector(
  selectClientDetails,
  (clientDetails: ClientDetails | null) =>
    clientDetails?.subscription_contracts.find(
      (subscription: SubscriptionContract) => subscription.status === 'confirmed',
    ),
)
