import * as React from 'react'
import * as clsx from 'clsx'
import { FormControl, FormControlProps } from 'react-bootstrap'
import { Spinner } from '@components/loaders/spinner'
import { useFormContext, useWatch } from 'react-hook-form'
import { FormError } from '@components/controls/form-error'
import { MaskedFormControl, MaskedFormControlProps } from '@components/controls/form-controls/masked-form-control'

interface Props {
  inputName: string
  isLoadingVisible: boolean
  formControlProps?: FormControlProps
  isSucceed?: boolean
  isFailed?: boolean
  wrapperClassName?: string
  maskedFormControlProps?: Omit<MaskedFormControlProps, 'name'>
  hideError?: boolean
}

export const FormControlWithStatus = ({
  inputName,
  isLoadingVisible,
  formControlProps,
  wrapperClassName,
  isFailed,
  isSucceed,
  maskedFormControlProps,
  hideError,
}: Props): JSX.Element => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext()

  const hasError = errors && !!errors[inputName]
  const value = useWatch({ control, name: inputName })

  return (
    <div
      className={clsx('form-control-with-status__container', wrapperClassName, {
        'form-control-with-status--active': isLoadingVisible && !hasError,
        'form-control-with-status--succeed': isSucceed && !hasError,
        'form-control-with-status--failed': isFailed || hasError,
      })}
      data-testid="form-control-with-status-wrapper"
    >
      {maskedFormControlProps ? (
        <MaskedFormControl name={inputName} {...maskedFormControlProps} hasError={isFailed || hasError} />
      ) : (
        <FormControl
          id={inputName}
          {...register(inputName)}
          {...formControlProps}
          className={clsx('shadow-none py-3 font-size-sm form-control-with-status', formControlProps?.className, {
            'is-empty': !value,
            'is-failed': isFailed || hasError,
          })}
        />
      )}
      <Spinner className="text-primary form-control-with-status__spinner" />
      <i className="uil-check font-size-xxl text-success form-control-with-status__succeed" />
      {!hideError && <FormError inputName={inputName} />}
    </div>
  )
}
