import * as React from 'react'
import { useSelector } from 'react-redux'
import { selectTextBlocks } from '@store/selectors/app-data-selectors'
import { SingleFooterInformation } from '@modules/footer/single-footer-information'
import { ContactInformation } from '@components/contact-information'
import { FooterAwards } from '@modules/footer/footer-elements/footer-awards'

export const FooterGeneralInformation = (): JSX.Element => {
  const { safety } = useSelector(selectTextBlocks)
  // const [showLoginModal] = useModal('LoginModal', { title: 'Logowanie / rejestracja' })

  return (
    <div className="d-xl-flex justify-content-between d-none mt-5 gap-5">
      <ContactInformation wrapperClassName="w-25" />
      <SingleFooterInformation
        wrapperClassName="w-25"
        icon="uil-shield-check"
        title={safety.name}
        subtitle={safety.subtitle}
      >
        {safety.content}
      </SingleFooterInformation>
      {/*<SingleFooterInformation*/}
      {/*  icon="uil-user"*/}
      {/*  title={login.name}*/}
      {/*  subtitle={login.subtitle}*/}
      {/*  onClick={showLoginModal}*/}
      {/*  wrapperClassName="cursor-pointer"*/}
      {/*>*/}
      {/*  {login.content}*/}
      {/*</SingleFooterInformation>*/}
      <FooterAwards singleAwardClassName="flex-column align-items-center font-size-sm text-center" />
    </div>
  )
}
