import * as React from 'react'
import { SectionCard } from '@modules/improvement-step/improvements-sections/section-card'
import { FeedingPricesBlock } from '@modules/improvement-step/improvements-sections/kinds/feedings/feeding-prices-block'
import { SubscriptionDiscountLabel } from '@components/labels/subscription-contract/subscription-discount-label'

interface Props {
  onAction: () => void
}

export const FeedingCard = ({ onAction }: Props): JSX.Element => (
  <SectionCard
    onRightActionClick={onAction}
    title="Wyżywienie"
    image={require('@assets/images/feeding.webp')}
    cardClassName="is-md-full-width"
    labels={<SubscriptionDiscountLabel />}
  >
    <p>
      Restauracja, kawiarnia i bistro w Holiday Park &amp; Resort oferuje odwiedzającym bogatą ofertę wyżywienia i pełną
      gamę napojów. Dodatkowo miejsca te cechuje wyjątkowa atmosfera z niepowtarzalnym widokiem na cały ośrodek.
    </p>
    <FeedingPricesBlock />
  </SectionCard>
)
