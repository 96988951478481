import * as React from 'react'
import * as clsx from 'clsx'
import { AccommodationTypeAvailableDateRange } from '@models/reservation'
import { formatDate } from 'dates-selection-range'
import { IconWithText } from '@components/icon-with-text'
import { useAccommodationAvailability } from '@modules/accommodation-selection-step/dates-selection/use-accommodation-availability'
import { useScrollToTop } from '@hooks/use-scroll-to-top'
import { setSelectedRecommendedResortId } from '@store/actions/resort-actions'
import { useDispatch, useSelector } from 'react-redux'
import { Resort } from '@models/app-data'
import { selectReservation } from '@store/selectors/reservation-selectors'

interface Props {
  date: AccommodationTypeAvailableDateRange
  className?: string
  resort: Resort
}

export const OccupiedBannerRecommendationButton = ({ date, resort, className }: Props): JSX.Element => {
  const { promocode } = useSelector(selectReservation)
  const { fetchAccommodationTypes } = useAccommodationAvailability()
  const { scrollToTop } = useScrollToTop()
  const dispatch = useDispatch()

  const handleDateChange = async () => {
    scrollToTop()
    await fetchAccommodationTypes({ ...date, promocode })
    dispatch(setSelectedRecommendedResortId(resort.id))
  }

  return (
    <button onClick={handleDateChange} className={clsx('date-recommendation__button', className)}>
      <div className="date-recommendation__button__date">
        <div className="font-size-xl fw-semi-bold lh-1">
          {formatDate(date.date_from, 'dd')} - {formatDate(date.date_to, 'dd')}
        </div>
        <span className="font-size-sm">
          <span className="pe-2">{formatDate(date.date_from, 'LLL')}</span> {formatDate(date.date_to, 'LLL')}
        </span>
      </div>
      <div className="date-recommendation__button__selection">
        <IconWithText text="wybierz" iconClassName="uil-check font-size-xl me-1 lh-1" />
      </div>
    </button>
  )
}
