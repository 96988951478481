import * as React from 'react'
import { OpinionRow } from '@modules/accommodation-selection-step/opinions/opinion-row'
import { OpinionResult, ResortOpinion } from '@models/models'

interface Props {
  opinions: ResortOpinion | null
}

export const OpinionsTable = ({ opinions }: Props): JSX.Element => (
  <table className="table table-striped mb-4 opinions__table">
    <colgroup>
      <col width="100%" />
    </colgroup>
    <tbody className="border-top-0">
      {opinions?.results.map((opinion: OpinionResult) => (
        <OpinionRow key={opinion.id} opinion={opinion} />
      ))}
      {!opinions?.results.length && (
        <tr>
          <td className="border-0">
            <strong className="text-darker-gray font-size-lg d-flex justify-content-center my-5">Brak opinii</strong>
          </td>
        </tr>
      )}
    </tbody>
  </table>
)
