import * as React from 'react'
import * as clsx from 'clsx'
import { useSelector } from 'react-redux'
import { selectTextBlocks } from '@store/selectors/app-data-selectors'
import { Icon } from '@components/icon'

interface Props {
  wrapperClassName?: string
  className?: string
}

export const SafetyBanner = ({ wrapperClassName, className }: Props): JSX.Element => {
  const { security: textBlock } = useSelector(selectTextBlocks)

  return (
    <div className={wrapperClassName}>
      <div className={clsx('d-flex safety-banner p-3', className)}>
        <Icon icon="shield" className="safety-banner__shield me-3 mb-xl-3" />
        <div>
          <strong>{textBlock.name}</strong>
          <span className="d-block text-muted mt-2 font-size-sm">{textBlock.content}</span>
        </div>
        <Icon icon="shield_watermark" className="safety-banner__watermark" />
      </div>
    </div>
  )
}
