import * as React from 'react'
import { ChangeEvent } from 'react'
import { BookingCar, ResortImprovement } from '@models/reservation'
import { CustomFormGroup } from '@components/controls/form-controls/custom-form-group'
import { useFormContext, useWatch } from 'react-hook-form'
import { asDecimal, createArrFromNumber, createHash, handleEnter } from '@helpers/utils'
import { useSelector } from 'react-redux'
import { selectReservationCars } from '@store/selectors/reservation-selectors'
import { CalculatedImprovement } from '@requests/reservation/reservation-request-models'
import { ImprovementDetailsFormInputs } from '@modules/improvement-step/improvements-sections/kinds/improvements/improvement-details-modal'
import { SectionSummaryRow } from '@modules/improvement-step/improvements-sections/section-summary-row'
import { IconWithText } from '@components/icon-with-text'

const createEmptyCarPlate = id => ({ id, register_number: '', isAdded: false })
const withoutId = id => el => Object.getOwnPropertyDescriptor(el, 'id') ? el.id !== id : el !== id
const withId = id => el => el.id === id
const getAddedPlates = (plates: CarPlate[]): CarPlate[] => plates.filter((plate: CarPlate) => plate.isAdded)

export interface CarPlate {
  id: string
  register_number: string
  isAdded: boolean
}

interface Props {
  resortImprovement: ResortImprovement
  calculatedImprovements: CalculatedImprovement[] | undefined
  isPriceCalculating: boolean
}

export const ImprovementCarPlatesField = ({
  resortImprovement,
  calculatedImprovements,
  isPriceCalculating,
}: Props): JSX.Element => {
  const { control, setValue } = useFormContext<ImprovementDetailsFormInputs>()

  const cars = useSelector(selectReservationCars)

  const carsForKind = React.useMemo(
    () => cars.filter((car: BookingCar) => car.kind === resortImprovement.code),
    [resortImprovement, cars],
  )

  const [plateInputs, setPlateInputs] = React.useState<string[]>([])

  const [amount, plates, maxAmount] = useWatch({ control, name: ['amount', 'plates', 'maxAmount'] })

  React.useEffect(() => {
    if (amount > plateInputs.length) addPlateInput()
    if (amount < plateInputs.length) removePlateInput()
  }, [amount])

  React.useEffect(() => {
    if (!carsForKind.length) return

    const difference = amount - carsForKind.length
    const idsFromDifference = createArrFromNumber(difference).map(createHash)

    setPlateInputs([...carsForKind.map((car: BookingCar) => car.id.toString()), ...idsFromDifference])

    const platesFromCars = carsForKind.map(car => ({
      id: car.id.toString(),
      register_number: car.register_number,
      isAdded: true,
    }))

    const platesFromRegisteredCars = [
      ...platesFromCars,
      ...idsFromDifference.map(id => ({ id, register_number: '', isAdded: true })),
    ]

    setValue('plates', platesFromRegisteredCars)
  }, [carsForKind])

  const addPlateInput = () => {
    const amountDifference = amount - plateInputs.length
    const ids = createArrFromNumber(amountDifference).map(createHash)
    setPlateInputs([...plateInputs, ...ids])
    setValue('plates', [...plates, ...ids.map(createEmptyCarPlate)])
  }

  const removePlateInput = () => {
    const idToRemove = plateInputs[plateInputs.length - 1]
    setPlateInputs(plateInputs.filter(withoutId(idToRemove)))
    setValue('plates', plates.filter(withoutId(idToRemove)))
  }

  const handleAddPlate = id => event => {
    setValue(
      'plates',
      plates.map((plate: CarPlate) =>
        plate.id === id ? { ...plate, register_number: event.target.value.toUpperCase(), isAdded: true } : plate,
      ),
    )
  }

  const handleDeletePlate = id => () => {
    const newPlateInputs = plateInputs.filter(withoutId(id))
    if (newPlateInputs.length) {
      setPlateInputs(newPlateInputs)
      setValue('plates', plates.filter(withoutId(id)))
    } else {
      setValue('plates', [{ ...plates[0], register_number: '', isAdded: false }])
    }

    setValue('amount', Math.max(amount - 1, 1))
  }

  const isAdded = id => {
    const plate = plates.find(withId(id))
    return plate?.isAdded && !!plate.register_number
  }

  const handlePlateNumberChange =
    (id: string) =>
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setValue(
        'plates',
        plates.map((plate: CarPlate) => (plate.id === id ? { ...plate, register_number: value.toUpperCase() } : plate)),
      )
    }

  const isAddingNextPlateAvailable = React.useCallback(
    (id, index) => {
      const addedPlates = getAddedPlates(plates)
      const isPlateAdded = addedPlates.find(plate => plate.id === id)?.isAdded
      return (
        asDecimal(addedPlates.length).plus(1).lte(maxAmount) &&
        isPlateAdded &&
        plateInputs.length - 1 === index &&
        plates.length < maxAmount
      )
    },
    [plates, maxAmount, plateInputs],
  )

  const handleAddPlateAmount = () => {
    const newAmount = amount + 1
    if (amount <= maxAmount) setValue('amount', newAmount)
  }

  return (
    <div>
      <div className="pb-3 container-lg">
        {plateInputs.map((id, index) => (
          <div className="row align-items-center px-3 px-xl-5" key={index}>
            <CustomFormGroup
              isSucceed={isAdded(id)}
              inputName={`plates.${index}.register_number`}
              formGroupProps={{ className: 'col-12 col-xl-6 px-0 pe-xl-3 mb-3 mb-xl-0' }}
              formControlProps={{
                onKeyDown: handleEnter(handleAddPlate(id)),
                onChange: handlePlateNumberChange(id),
                onBlur: handleAddPlate(id),
              }}
              {...(index === 0 && { label: 'Wpisz numer rejestracji (opcjonalnie)' })}
            />
            {isAddingNextPlateAvailable(id, index) && (
              <IconWithText
                text="Dodaj kolejny numer rejestracyjny"
                iconClassName="uil-plus me-2"
                onClick={handleAddPlateAmount}
                wrapperClassName="col-12 col-xl-6 text-darker-gray mt-xl-5"
              />
            )}
          </div>
        ))}
      </div>
      {plates.map((plate: CarPlate, index) => (
        <SectionSummaryRow
          key={plate.id}
          text={`${resortImprovement.name} ${plate.register_number}`}
          price={(Array.isArray(calculatedImprovements) && calculatedImprovements[index]?.price) || 0}
          onDelete={handleDeletePlate(plate.id)}
          isPriceCalculating={isPriceCalculating}
          isDeleteEnabled={(!!plates.length && !!plate.register_number) || plates.length > 1}
        />
      ))}
    </div>
  )
}
