import * as React from 'react'
import { Card } from '@components/card/card'
import { Gallery } from '@components/gallery'
import { useResponsivenessImages } from '@hooks/use-responsiveness-images'
import { ResortCardAction } from '@modules/accommodation-selection-step/resort-selection/resort-card-action'
import { Opinions } from '@modules/accommodation-selection-step/opinions/opinions'
import { ApartmentLocalizationFacilities } from '@modules/accommodation-selection-step/local-selection/apartment-details/apartment-localization-facilities'
import { Resort } from '@models/app-data'
import * as clsx from 'clsx'

interface Props {
  resort: Resort
  isCollapsed: boolean
  onResortSelection: () => void
}

export const ResortCard = ({ resort, onResortSelection, isCollapsed }: Props): JSX.Element => (
  <Card
    className="collapsable-resort__card"
    image={
      <div className="position-relative">
        <Gallery
          items={useResponsivenessImages(resort.reservation_images, 'xs')}
          additionalClass="image-gallery--rounded-lg-left"
        />
        <Opinions
          wrapperClassName="collapsable-resort__opinions-gallery"
          titleClassName="d-none"
          ratingClassName="text-primary"
          resort={resort}
        />
      </div>
    }
    cardActions={<ResortCardAction onResortSelection={onResortSelection} resortId={resort.id} />}
  >
    <div className="px-3">
      <span className={clsx('collapsable-resort__expanded-arrow', { 'is-visible': !isCollapsed })} />
      <div className="d-flex justify-content-between">
        <div>
          <h3 className="text-secondary fw-bold">{resort.name}</h3>
          {/*TODO Description for this functionality is not ready yet*/}
          {/*<IconWithText*/}
          {/*  text="Ostatnia rezerwacja 2h temu"*/}
          {/*  iconClassName="uil-clock font-size-lg me-1"*/}
          {/*  wrapperClassName="text-danger font-size-sm me-auto"*/}
          {/*  textClassName="fw-semi-bold"*/}
          {/*/>*/}
          <span className="text-muted font-size-sm mt-3">{resort.reservation_description}</span>
        </div>
        <Opinions
          wrapperClassName="d-none d-sm-block"
          ratingClassName="text-primary"
          titleClassName="d-none"
          resort={resort}
        />
      </div>
      <ApartmentLocalizationFacilities resort={resort} />
    </div>
  </Card>
)
