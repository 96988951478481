import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { useFormContext } from 'react-hook-form'

interface Props {
  inputName: string
  className?: string
}

export const FormError = ({ inputName, className }: Props): JSX.Element | null => {
  const {
    formState: { errors },
  } = useFormContext()

  const hasError = errors && !!errors[inputName]

  return hasError ? (
    <IconWithText
      wrapperClassName={clsx('text-danger align-items-center mt-2', className)}
      textClassName="font-size-xs fw-normal form-error-text"
      iconClassName="uil-info-circle me-2 align-self-start form-error-icon"
      text={<span dangerouslySetInnerHTML={{ __html: errors[inputName]?.message?.toString() ?? '' }} />}
    />
  ) : null
}
