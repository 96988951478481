import { RootState } from '@store/index'
import {
  AccommodationType,
  AppData,
  AppDataAccountUrls,
  AppDataApiUrls,
  AppDataCmsUrls,
  AppDataTextBlocks,
  CompanyInfo,
  Country,
  DefaultReservationParams,
  ImprovementGroup,
  OpinionsUrls,
  Resort,
} from '@models/app-data'
import { createSelector } from 'reselect'
import * as memoize from 'lodash.memoize'

export type SelectorWithParams<Response> = (state: RootState, ...params) => Response

export const selectAppData = (state: RootState): AppData => state.appDataState.appData

export const selectImprovementGroups = (state: RootState): ImprovementGroup[] =>
  state.appDataState.appData.improvement_groups

export const selectResorts = (state: RootState): Resort[] => state.appDataState.appData.resorts

export const selectResortById = createSelector(selectResorts, (resorts: Resort[]) =>
  memoize((id: number) => resorts.find((resort: Resort) => resort.id === id)),
)

export const selectAccommodationTypes = (state: RootState): AccommodationType[] =>
  state.appDataState.appData.accommodation_types

export const selectAccommodationTypeById = createSelector(
  selectAccommodationTypes,
  (_, id) => id,
  (accommodationTypes: AccommodationType[], id) => accommodationTypes.find(row => row.id === id),
) as SelectorWithParams<AccommodationType | undefined>

export const selectSeasons = (state: RootState): number[] => state.appDataState.appData.available_seasons

export const selectOpinionsUrls = (state: RootState): OpinionsUrls => ({
  google: state.appDataState.appData.urls.api.google_review_list,
  booking: '',
  tripadvisor: '',
})

export const selectResortAccommodationTypes = createSelector(
  selectAccommodationTypes,
  (accommodationTypes: AccommodationType[]) =>
    (resort: Resort | undefined): AccommodationType[] =>
      accommodationTypes.filter(el => resort?.accommodation_types.includes(el.id)),
)

export const selectTextBlocks = (state: RootState): AppDataTextBlocks => state.appDataState.appData.text_blocks

export const selectCompanyInfo = (state: RootState): CompanyInfo => state.appDataState.appData.company_info

export const selectPaymentMethods = createSelector(
  (state: RootState) => state.appDataState.appData,
  (appData: AppData) => appData.payment_methods,
)

export const selectCmsUrls = (state: RootState): AppDataCmsUrls => state.appDataState.appData.urls.cms

export const selectApiUrls = (state: RootState): AppDataApiUrls => state.appDataState.appData.urls.api

export const selectAccountUrls = (state: RootState): AppDataAccountUrls => state.appDataState.appData.urls.account

export const selectCountries = (state: RootState): Country[] => state.appDataState.appData.countries

export const selectDefaultReservationParams = (state: RootState): DefaultReservationParams =>
  state.appDataState.appData.default_reservation_params
