import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'

export const SubscriptionContractActiveLabel = (): JSX.Element => (
  <div className="subscription-contract__active-label px-3 pb-1 font-size-md text-white">
    <IconWithText
      text="Subskrypcja aktywna"
      iconClassName="uil-check font-size-xl lh-1 me-1"
      textClassName="fw-semi-bold"
    />
  </div>
)
