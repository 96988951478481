import * as React from 'react'
import { SubscriptionWithBenefitCodeInput } from '@modules/aside/subscription-with-benefits/subscription-with-benefit-code-input'

interface Props {
  productName: string
}

export const LocalForClientWithBenefitCode = ({ productName }: Props): JSX.Element => (
  <div className="py-md-4 p-3 px-md-5 bg-very-light-gray rounded-bottom d-flex flex-wrap align-items-center text-center text-sm-start mt-3">
    <div className="col-sm-6 col-12">
      <strong className="text-primary font-size-xl">
        Posiadasz <span className="underline-shadow gold">{productName}?</span>
      </strong>
      <p className="mb-0 pt-1 font-size-sm">
        Wpisz kod, aby odblokować dostęp <br />
        do lokali specjalnych.
      </p>
    </div>
    <div className="col-12 col-sm-6 ms-sm-auto mx-auto me-sm-0">
      <SubscriptionWithBenefitCodeInput inputClass="col-12" />
    </div>
  </div>
)
