import * as React from 'react'
import { Button, ModalBody, ModalFooter } from 'react-bootstrap'
import { IconWithText } from '@components/icon-with-text'
import { formatPrice } from '@helpers/utils'
import { BaseModalProps } from '@modals/types'
import { ContactInformationRows } from '@components/contact-information-rows'

export const SubscriptionContractDescriptionModal = ({ toggleIsVisible }: BaseModalProps): JSX.Element => (
  <>
    <ModalBody className="text-muted px-4 ps-xl-5 pb-0 pt-4 overflow-hidden">
      <p className="text-secondary fw-bold font-size-xxl">Co zyskujesz kupując Subskrypcję?</p>
      <p className="font-size-sm col-sm-10">
        Już teraz dajemy możliwość skorzystania z naszej nowej usługi w postaci <strong>subskrypcji</strong>, dzięki
        której na starcie otrzymasz wyjątkowe prezenty.
      </p>
      <div>
        {subscriptionContractBenefits.map(benefit => (
          <IconWithText
            key={benefit.title}
            text={
              <span>
                <strong>{benefit.title}</strong>
                <p>{benefit.content}</p>
              </span>
            }
            iconClassName="uil-check me-2 text-primary font-size-lg align-self-start"
            textClassName="font-size-sm"
            wrapperClassName="col-xl-9"
          />
        ))}
      </div>
      <hr className="mx-n5" />
      <ContactInformationRows />
      <hr className="mx-n5 mb-0" />
      <div className="text-primary bg-light-blue d-flex justify-content-between font-size-xxl mx-n5 px-5 py-2 flex-column flex-sm-row">
        <strong>Koszt subskrypcji:</strong>
        <strong>od {formatPrice('49,00')} / miesięcznie</strong>
      </div>
    </ModalBody>
    <ModalFooter className="px-4">
      <Button onClick={toggleIsVisible} className="btn bg-transparent border-0 me-auto">
        <IconWithText
          text="Zamknij"
          iconClassName="uil-times me-1 font-size-xxl lh-0"
          wrapperClassName="text-darker-gray"
        />
      </Button>
    </ModalFooter>
  </>
)

const subscriptionContractBenefits = [
  {
    title: 'Pobyt w Holiday Park & Resort',
    content: '5-dniowy pobyt w Holiday Park & Resort dla 4 osób w apartamencie, odnawiany co 12 miesięcy',
  },
  {
    title: 'Terminy specjalne w ramach Pakietów',
    content: 'Możliwość rezerwacji pobytu pakietowego w Terminach Specjalnych z mniejszym wykorzystaniem kodów.',
  },
  {
    title: 'Rabaty na wyżywienie',
    content: '10% rabatu na wyżywienie (przy zakupie do 30 dni przed rozpoczęciem pobytu).',
  },
  { title: 'Rabaty w wysokim sezonie', content: 'Ceny pobytów w sezonie tańsze nawet o 50%.' },
  { title: 'Powiększony rabat wyjazdowy', content: 'Do 65% rabatu na pobyty z voucherem wyjazdowym.' },
]
