import * as React from 'react'
import { ResortDetails, ResortMapSector } from '@models/resort'
import { Apartment } from '@models/reservation'

const AVAILABLE_LOCAL_SUBSCRIPTION_WITH_BENEFITS_OWNER = '#8a0970'
const AVAILABLE_LOCAL_COLOR = '#2c8f41'
const OCCUPIED_LOCAL_COLOR = '#d25e5e'
const SELECTED_LOCAL_COLOR = '#000'

const isApartment = (element: SVGGeometryElement) => element.id.startsWith('A')
const isHouse = (element: SVGGeometryElement) => element.id.startsWith('D')

export const isForClientWithBenefit = (apartment: Apartment): boolean =>
  apartment.tags.some(tag => tag.keyword === 'for_client_with_benefit')

const getApartmentIdWithoutSlashSeparation = (apartment: Apartment) => apartment.apartment_id.replace('/', '')

interface SectorVisibilityParams {
  show: boolean
  sector?: string
}

interface UseClickableResortMapResponse {
  onSectorSelection: (sector: ResortMapSector) => void
}

interface Props {
  onLoadingVisibilityChange: (isLoading: boolean) => void
  onMapChange: (mapUrl: string) => void
  mapDocument: Document | null
  resortDetails: ResortDetails
  apartments: Apartment[]
  selectedApartmentId: number | null
  onApartmentSelect: (apartment: Apartment) => void
  onMapApartmentsChange: (apartments: Apartment[]) => void
}

export const useClickableResortMap = ({
  onLoadingVisibilityChange,
  onMapChange,
  mapDocument,
  resortDetails,
  apartments,
  selectedApartmentId,
  onApartmentSelect,
  onMapApartmentsChange,
}: Props): UseClickableResortMapResponse => {
  const getMapElement = (apartment: Apartment): SVGRectElement | null =>
    mapDocument?.getElementById(getApartmentIdWithoutSlashSeparation(apartment)) as SVGRectElement | null

  const getApartmentById = (elementId: string) =>
    apartments.find(apartment => getApartmentIdWithoutSlashSeparation(apartment) === elementId)

  const isApartmentSelected = (apartment: Apartment) => apartment.id === selectedApartmentId

  const getLocalColor = (apartment: Apartment) => {
    if (isApartmentSelected(apartment)) return SELECTED_LOCAL_COLOR

    if (apartment.is_available) return AVAILABLE_LOCAL_COLOR

    return isForClientWithBenefit(apartment) ? AVAILABLE_LOCAL_SUBSCRIPTION_WITH_BENEFITS_OWNER : OCCUPIED_LOCAL_COLOR
  }

  const setSectorMap = (sectorMap: ResortMapSector) => {
    onLoadingVisibilityChange(true)
    onMapChange(sectorMap.map)
  }

  const handleLocalShapeMouseEnter = event => {
    const element = event.target as SVGGeometryElement
    element.style.cursor = 'pointer'
    element.style.fillOpacity = '0.9'
  }

  const handleLocalShapeMouseLeave = event => {
    const element = event.target as SVGGeometryElement
    element.style.cursor = 'default'
    element.style.fillOpacity = '1'
  }

  const handleLocalClick = event => {
    const element = event.target as SVGGeometryElement
    const apartment = getApartmentById(element.id)
    if (!apartment) return
    onApartmentSelect(apartment)
  }

  const localMouseEvents = (element: SVGRectElement, kind: 'addEventListener' | 'removeEventListener') => {
    element[kind]('mouseenter', handleLocalShapeMouseEnter)
    element[kind]('mouseleave', handleLocalShapeMouseLeave)
    element[kind]('mouseup', handleLocalClick)
  }

  const localShapeEvents = (action: 'register' | 'unregister') => {
    apartments.forEach((apartment: Apartment) => {
      const localElement = getMapElement(apartment)
      if (localElement && (apartment.is_available || isForClientWithBenefit(apartment))) {
        localMouseEvents(localElement, action === 'register' ? 'addEventListener' : 'removeEventListener')
      }
    })
  }

  React.useEffect(() => {
    clearLocalShapeSelection()
    setSelectedLocalShape()
  }, [selectedApartmentId])

  React.useEffect(() => {
    hideLocalShapes()
    setSectorOccupancyMessage({ show: false })
    setLocalNumberVisibility()
    provideMap()

    localShapeEvents('register')

    setAvailableMapApartments()

    return () => {
      localShapeEvents('unregister')
    }
  }, [mapDocument, apartments])

  const provideMap = () => {
    apartments.forEach((apartment: Apartment) => {
      const element = getMapElement(apartment)
      if (!element || (!isApartment(element) && !isHouse(element))) return
      setColors(element, apartment)
    })
  }

  const setLocalNumberVisibility = () => {
    const localNumbersContainer = mapDocument?.getElementById('Locals')
    Array.from(localNumbersContainer?.children || []).forEach(localNumberShape => {
      const apartment = getApartmentById(localNumberShape.id.replace('local_', ''))
      localNumberShape.setAttribute('pointer-events', 'none')
      localNumberShape.setAttribute('opacity', apartment ? '1' : '0')
    })
  }

  const getLocalShapes = () => {
    const filterByType = (el: SVGGeometryElement) => isHouse(el) || isApartment(el)
    const rects = Array.from(mapDocument?.getElementsByTagName('rect') || []).filter(filterByType)
    const polygons = Array.from(mapDocument?.getElementsByTagName('polygon') || []).filter(filterByType)
    const paths = Array.from(mapDocument?.getElementsByTagName('path') || []).filter(filterByType)
    return [...rects, ...polygons, ...paths]
  }

  const hideLocalShapes = () => {
    const elements = getLocalShapes()

    elements.forEach(element => {
      element.style.opacity = '0'
    })
  }

  const setAvailableMapApartments = () => {
    const elements = getLocalShapes()

    const mapApartments = elements.reduce((acc, curr) => {
      const apartment = apartments.find(apartment => getApartmentIdWithoutSlashSeparation(apartment) === curr.id)
      return apartment ? [...acc, apartment] : acc
    }, [])

    onMapApartmentsChange(mapApartments)
  }

  const setSectorOccupancyMessage = ({ show, sector = undefined }: SectorVisibilityParams) => {
    const messageShapeId = (sectorName: string) => `${sectorName}_full`

    const occupiedSectorMessages = sector
      ? [messageShapeId(sector)]
      : resortDetails.map_svg_sectors.map(sector => messageShapeId(sector.name))

    occupiedSectorMessages.forEach(occupiedSectorMessage => {
      const messageElement = mapDocument?.getElementById(occupiedSectorMessage)
      if (!messageElement) return

      messageElement.style.display = show ? 'visible' : 'none'
    })
  }

  const setSelectedLocalShape = () => {
    apartments.forEach((apartment: Apartment) => {
      const element = getMapElement(apartment)
      if (!element) return
      if (isApartmentSelected(apartment)) createActiveLocalShape(element)
      element.style.fill = getLocalColor(apartment)
    })
  }

  const clearLocalShapeSelection = () => {
    const rects = Array.from(mapDocument?.getElementsByTagName('rect') || [])
    const circles = Array.from(mapDocument?.getElementsByTagName('circle') || [])
    const texts = Array.from(mapDocument?.getElementsByTagName('text') || [])
    const shapes = [...rects, ...circles, ...texts]

    shapes.forEach(shape => {
      if (shape.id.startsWith('active-local')) shape.remove()
    })
  }

  const setColors = (element: SVGRectElement, apartment: Apartment) => {
    element.style.opacity = '1'
    element.style.fill = getLocalColor(apartment)
  }

  return {
    onSectorSelection: setSectorMap,
  }
}

const createActiveLocalShape = (element: SVGRectElement) => {
  if (isApartment(element) || !element.height || !element.width || !element.x || !element.y) return

  const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
  circle.setAttribute('id', 'active-local')
  circle.setAttribute('stroke', 'black')
  circle.setAttribute('stroke-width', '1')
  circle.setAttribute('fill', 'white')
  circle.setAttribute('r', '8px')
  circle.setAttribute('cx', element.x.baseVal.value.toString())
  circle.setAttribute('cy', Math.ceil(element.y.baseVal.value + element.height.baseVal.value / 2).toString())

  const checkSign = document.createElementNS('http://www.w3.org/2000/svg', 'text')
  checkSign.setAttribute('id', 'active-local')
  checkSign.setAttribute('x', (element.x.baseVal.value - 5).toString())
  checkSign.setAttribute('y', (element.y.baseVal.value + 1 + element.height.baseVal.value / 2).toString())
  checkSign.setAttribute('dominant-baseline', 'middle')
  checkSign.setAttribute('font-size', '10px')
  checkSign.setAttribute('text-anchor', 'inherit')
  checkSign.setAttribute('fill', AVAILABLE_LOCAL_COLOR)
  checkSign.innerHTML = '&#10004'

  element.parentElement?.append(circle, checkSign)
}
