import * as React from 'react'
import { ResortDetails } from '@models/resort'

interface Props {
  resortDetails: ResortDetails
}

export const ApartmentLocalizationModal = ({ resortDetails }: Props): JSX.Element => (
  <iframe
    src={resortDetails.google_maps_url}
    className="w-100 bg-light-blue apartment-localization__map"
    title="Mapa z lokalizacją ośrodka"
  />
)
