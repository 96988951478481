import { ReservationOperationsItem } from '@modules/accommodation-selection-step/reservation-operations-item'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { selectTextBlocks } from '@store/selectors/app-data-selectors'
import * as clsx from 'clsx'
import { useCallCenterData } from '@hooks/use-call-center-data'

interface Props {
  wrapperClassName?: string
}

export const ContactInformation = ({ wrapperClassName }: Props): JSX.Element => {
  const { questions } = useSelector(selectTextBlocks)
  const { isOpen, trimmedPhoneNumber } = useCallCenterData()

  return (
    <ReservationOperationsItem
      className={wrapperClassName}
      icon="uil-phone-alt"
      iconClassName="text-dark position-relative"
      iconChildren={<span className={clsx('shiny-dot', { 'shiny-dot--red': !isOpen })} />}
    >
      <a href={`tel:${trimmedPhoneNumber}`} className="text-decoration-none w-100">
        <strong className="text-muted font-size-md">{questions.name}</strong>
        <span className="text-muted font-size-sm d-block mt-1 mb-2">{questions.subtitle}</span>
        <span
          className="text-muted font-size-sm mb-1 d-block"
          dangerouslySetInnerHTML={{ __html: questions.content }}
        />
      </a>
    </ReservationOperationsItem>
  )
}
