import * as React from 'react'
import { FooterExternalMedia } from '@modules/footer/footer-external-media'
import { GeneralLinks } from '@modules/footer/general-links'
import { FooterGeneralInformation } from '@modules/footer/footer-general-information'
import { Icon } from '@components/icon'
import { LogoHolidayLink } from '@components/logo-holiday-link'
import { useScrollToTop } from '@hooks/use-scroll-to-top'

export const Footer = (): JSX.Element => {
  const { scrollToTop } = useScrollToTop()

  return (
    <>
      <div className="container-lg py-5 mt-xl-4">
        <div className="d-flex justify-content-between w-100">
          <div className="d-none d-xl-block">
            <LogoHolidayLink className="mx-auto mx-xl-0" />
          </div>
          <FooterExternalMedia />
          <button onClick={scrollToTop} className="footer__move-top-icon cursor-pointer">
            <span className="text-muted me-2 d-block align-self-center d-xl-none">Wróć na górę</span>
            <Icon icon="arrow_top" />
          </button>
        </div>
        <FooterGeneralInformation />
      </div>
      <GeneralLinks wrapperClassName="d-none d-xl-flex gap-5 bg-light-azure" linkClassName="py-3" />
    </>
  )
}
