import * as React from 'react'
import * as clsx from 'clsx'
import { ApartmentDescription } from '@modules/accommodation-selection-step/local-selection/apartment-description'
import { Gallery } from '@components/gallery'
import { Opinions } from '@modules/accommodation-selection-step/opinions/opinions'
import { FloatingItem } from '@components/floating-item'
import { LocalizationFacilities } from '@modules/accommodation-selection-step/local-selection/apartment-details/localization-facilities'
import { ApartmentLocalization } from '@modules/accommodation-selection-step/local-selection/apartment-details/apartment-localization'
import { ApartmentDetailsGlobalInformation } from '@modules/accommodation-selection-step/local-selection/apartment-details/apartment-details-global-information'
import { ApartmentDetailsImprovements } from '@modules/accommodation-selection-step/local-selection/apartment-details/apartment-details-improvements'
import { SafetyBanner } from '@components/banners/safety-banner'
import { ApartmentCardPrice } from '@modules/accommodation-selection-step/local-selection/apartment-card-price'
import { AccommodationType, ImprovementGroup, Resort } from '@models/app-data'
import { useSelector } from 'react-redux'
import { selectImprovementGroups } from '@store/selectors/app-data-selectors'
import { useResponsivenessImages } from '@hooks/use-responsiveness-images'
import { selectResortDetails } from '@store/selectors/resort-selectors'
import { PriceInformation } from '@hooks/use-price-information'
import { Modal } from 'react-bootstrap'
import { ModalMobileFloatingAction } from '@modals/modal-mobile-floating-action'
import { BaseModalProps } from '@modals/types'

interface Props extends BaseModalProps {
  accommodationType: AccommodationType
  priceInformation: PriceInformation
  resort: Resort
  onApartmentSelect: () => void
  isApartmentSelected: boolean
}

export const ApartmentDetailsModal = ({
  accommodationType,
  priceInformation,
  resort,
  toggleIsVisible,
  onApartmentSelect,
  isApartmentSelected,
}: Props): JSX.Element => {
  const resortDetails = useSelector(selectResortDetails)
  const improvementGroups = useSelector(selectImprovementGroups)

  const images = useResponsivenessImages(accommodationType.accommodation_type_images)

  const groupsIds = React.useMemo(
    () => improvementGroups.reduce((acc, curr) => ({ ...acc, [curr.id]: [] }), {}),
    [improvementGroups],
  )

  const improvements = React.useMemo(
    () =>
      resortDetails.improvements.reduce((acc, curr) => {
        if (curr.group_id && acc[curr.group_id]) {
          return { ...acc, [curr.group_id]: [...acc[curr.group_id], curr] }
        }
        return acc
      }, groupsIds),
    [resortDetails.improvements, improvementGroups],
  )

  const groupsWithImprovements = improvementGroups.filter(
    (improvementGroup: ImprovementGroup) => !!improvements[improvementGroup.id].length,
  )

  const handleApartmentSelectionClick = () => {
    onApartmentSelect()
    toggleIsVisible()
  }

  return (
    <>
      <Modal.Body data-testid="modal-body">
        <Gallery showThumbnails={true} items={images} additionalClass="apartment-details-gallery" />
        <div className="px-xl-6 pt-xl-3 pb-xl-5 d-block d-xl-flex gap-4">
          <div className="apartment-details__content pt-3 pt-xl-3 col-12 col-xl-10">
            <ApartmentDescription
              withApartmentTitle={false}
              accommodationType={accommodationType}
              priceInformation={priceInformation}
            />
            <LocalizationFacilities resort={resortDetails} />
            <Opinions wrapperClassName="border-top border-bottom my-4 py-3 container-lg" resort={resort} />
            <ApartmentLocalization resortDetails={resortDetails} />
            {groupsWithImprovements.map((improvementGroup: ImprovementGroup) => (
              <ApartmentDetailsImprovements
                resort={resort}
                accommodationType={accommodationType}
                key={improvementGroup.id}
                improvements={improvements[improvementGroup.id]}
                groupName={improvementGroup.name}
                groupDescription={improvementGroup.subtitle}
              />
            ))}
            <ApartmentDetailsGlobalInformation resortDetails={resortDetails} />
          </div>
          <div className="d-xl-block d-none col-xl-2">
            <ApartmentCardPrice wrapperClassName="ms-auto text-end p-0" priceInformation={priceInformation} />
            <button
              className={clsx(
                'btn w-100 text-end fw-bold p-3 font-size-xl my-3 lh-initial shadow-none',
                isApartmentSelected ? 'btn-success text-white' : 'btn-danger',
              )}
              onClick={handleApartmentSelectionClick}
            >
              {isApartmentSelected ? 'wybrano' : 'wybieram'}
            </button>
            <SafetyBanner className="flex-column" />
          </div>
        </div>
      </Modal.Body>
      <FloatingItem className="floating-item--light apartment-details__floating-price">
        <div className="text-center">
          <strong className="text-secondary font-size-xxl lh-1">{priceInformation.pricePerGuest} zł za osobę</strong>
          <span className="d-block text-muted lh-1">przy {priceInformation.guestAmount} osobach w lokalu</span>
        </div>
      </FloatingItem>
      <ModalMobileFloatingAction
        onFloatingItemClick={handleApartmentSelectionClick}
        floatingItemClassName={clsx({ 'is-active': isApartmentSelected })}
        floatingItemText={isApartmentSelected ? 'wybrano' : 'wybieram'}
      />
    </>
  )
}
