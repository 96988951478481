import { IconWithText } from '@components/icon-with-text'
import { useCallCenterData } from '@hooks/use-call-center-data'
import * as React from 'react'
import * as clsx from 'clsx'
import { GLOBAL_URLS } from '@helpers/consts'

export const ContactInformationRows = (): JSX.Element => {
  const { phoneNumber, todayFromHour, todayToHour, email } = useCallCenterData()

  return (
    <>
      <ContactRow
        linkHref={GLOBAL_URLS.ECOMMERCE}
        linkText="Kup teraz"
        icon="uil-store-alt"
        linkTarget="blank"
        linkClass="btn btn-outline-secondary"
      >
        <>
          <div className="fw-bold">E-Sklep Holiday</div>
          <div className="font-size-sm">
            <a href={GLOBAL_URLS.ECOMMERCE} target="_blank" className="text-decoration-none text-muted">
              sklep.holidaypark.pl
            </a>
          </div>
        </>
      </ContactRow>

      <ContactRow linkHref={`tel:${phoneNumber}`} linkText="Zadzwoń" icon="uil-phone">
        <>
          <div className="fw-bold">Kontakt telefoniczny</div>
          <div className="font-size-sm">
            {phoneNumber} ({todayFromHour} - {todayToHour})
          </div>
        </>
      </ContactRow>

      <ContactRow linkHref={`mailto:${email}`} linkText="Wyślij e-mail" icon="uil-envelope-alt">
        <>
          <div className="fw-bold">Kontakt mailowy</div>
          <div className="text-muted font-size-sm">{email}</div>
        </>
      </ContactRow>
    </>
  )
}

const ContactRow = ({ children, icon, linkHref, linkText, linkTarget = '_self', linkClass = '' }) => (
  <div className="d-flex align-items-center justify-content-between w-100 mt-4">
    <IconWithText
      text={children}
      iconClassName={clsx('me-2 font-size-icon-18', icon)}
      wrapperClassName="text-muted align-items-baseline"
    />
    <a className={clsx('text-muted fw-bold text-decoration-none', linkClass)} href={linkHref} target={linkTarget}>
      {linkText}
    </a>
  </div>
)
