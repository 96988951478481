import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ResortImprovement } from '@models/reservation'
import { VipPricesBlock } from '@modules/improvement-step/improvements-sections/kinds/vip/vip-prices-block'
import { SectionCard } from '@modules/improvement-step/improvements-sections/section-card'
import { useVipModals } from '@modules/improvement-step/improvements-sections/kinds/vip/use-vip-modals'
import { createImprovementViewListItem, useGtmEvents } from '@hooks/use-gtm-events'
import { isWinterVip } from '@helpers/improvements'

interface Props {
  vipImprovement: ResortImprovement
}

export const Vip = ({ vipImprovement }: Props): JSX.Element => {
  const { showVipDetailsModal, showVipDetailsListModal } = useVipModals(vipImprovement)
  const { selectItem } = useGtmEvents()

  const handleClick = () => {
    selectItem(createImprovementViewListItem(vipImprovement, 0), 'ulepszenia')
    showVipDetailsModal()
  }

  return (
    <SectionCard
      onRightActionClick={handleClick}
      leftAction={
        <IconWithText
          text="Co dostaję w pakiecie?"
          iconClassName="uil-plus me-1"
          onClick={showVipDetailsListModal}
          wrapperClassName="text-darker-gray"
        />
      }
      title={isWinterVip(vipImprovement) ? 'Zimowy pakiet ulepszeń VIP' : 'Pakiet ulepszeń VIP'}
      image={vipImprovement.image.sm}
      cardClassName="is-md-full-width"
    >
      <p>{vipImprovement.description}</p>
      <VipPricesBlock improvement={vipImprovement} wrapperClassName="mt-xl-4" />
    </SectionCard>
  )
}
