import * as React from 'react'
import * as clsx from 'clsx'
import { selectClientDetails } from '@store/selectors/client-details-selectors'
import { useSelector } from 'react-redux'
import { SummaryMainDataRow } from '@modules/summary-step/summary-main-data-row'
import { DateFormats, formatDate } from '@helpers/date-formatter'
import { getAccommodationTypeName } from '@helpers/utils'
import {
  selectReservation,
  selectReservationAccommodationType,
  selectReservationResort,
} from '@store/selectors/reservation-selectors'
import dateHelper from '@services/date-helper'
import { stayDeclination } from '@helpers/declination'

interface Props {
  wrapperClassName?: string
}

export const SummaryReservationData = ({ wrapperClassName }: Props): JSX.Element => {
  const clientDetails = useSelector(selectClientDetails)

  const reservation = useSelector(selectReservation)
  const resort = useSelector(selectReservationResort)
  const accommodationType = useSelector(selectReservationAccommodationType)

  const daysDiff = dateHelper.diffInDays(reservation.date_from, reservation.date_to)

  return (
    <div className={clsx('container-lg px-xl-4 pb-4', wrapperClassName)}>
      <h3 className="text-primary text-start fw-bold pt-xl-4 pt-0 mb-2">
        Podsumowanie Twojej rezerwacji {clientDetails?.first_name}
      </h3>
      <span className="text-darker-gray mb-4 d-block font-size-sm text-start">
        Już prawie koniec - poniżej znajdziesz podsumowanie Twoich wyborów, uzupełnij dane i przejdź do płatności.
      </span>
      <strong className="text-primary mb-3 font-size-xl d-block">Informacje o rezerwacji</strong>
      <div className="col-xl-10 col-10">
        <SummaryMainDataRow
          title="Przyjazd:"
          value={formatDate(reservation.date_from, DateFormats.DATE_WITH_FULL_DAY)}
        />
        <SummaryMainDataRow
          title="Wyjazd:"
          value={
            <>
              {formatDate(reservation.date_to, DateFormats.DATE_WITH_FULL_DAY)} ({daysDiff} {stayDeclination(daysDiff)})
            </>
          }
        />
        <SummaryMainDataRow title="Lokalizacja:" value={resort?.name || ''} />
        <SummaryMainDataRow
          title="Rodzaj lokalu:"
          value={getAccommodationTypeName(accommodationType, reservation.accommodation_type_name)}
        />
        {!!reservation.selected_apartment_id && (
          <SummaryMainDataRow title="Numer lokalu:" value={reservation.apartment_name} />
        )}
      </div>
    </div>
  )
}
