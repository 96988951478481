import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useApiRequest } from '@hooks/use-form-request'
import { removePromocode } from '@requests/reservation/reservation-requests'
import { useDispatch } from 'react-redux'

interface Props {
  code: string
  codeDescription: string
}

export const SummaryBoxAddedPromocodeRow = ({ code, codeDescription }: Props): JSX.Element => {
  const dispatch = useDispatch()

  const { handleAction } = useApiRequest()

  const handlePromocodeRemove = async () => {
    await handleAction(() => dispatch(removePromocode()))
  }

  return (
    <div>
      <div className="d-flex gap-2 align-items-center">
        <IconWithText
          iconClassName="uil-tag-alt summary-box__codes__added__icon me-2 font-size-lg"
          text={code}
          textClassName="font-size-sm lh-initial summary-box__codes__added__text letter-spacing-2"
        />
        <button
          className="uil-trash-alt cursor-pointer summary-box__codes__added__icon"
          onClick={handlePromocodeRemove}
        />
      </div>
      <span className="summary-box__codes__added__description">{codeDescription}</span>
    </div>
  )
}
