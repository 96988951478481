import { ResortDetails } from '@models/resort'
import { ResortOpinion } from '@models/models'

export enum ResortActionTypesKeys {
  SET_RESORT_DETAILS = 'SET_RESORT_DETAILS',
  SET_GOOGLE_OPINIONS = 'SET_GOOGLE_OPINIONS',
  SET_BOOKING_OPINIONS = 'SET_BOOKING_OPINIONS',
  SET_TRIPADVISOR_OPINIONS = 'SET_TRIPADVISOR_OPINIONS',
  SET_SELECTED_RECOMMENDED_RESORT_ID = 'SET_SELECTED_RECOMMENDED_RESORT_ID',
}

export interface SetGoogleOpinions {
  type: ResortActionTypesKeys.SET_GOOGLE_OPINIONS
  payload: { googleOpinions: ResortOpinion }
}

export interface SetBookingOpinions {
  type: ResortActionTypesKeys.SET_BOOKING_OPINIONS
  payload: { bookingOpinions: ResortOpinion }
}

export interface SetTripadvisorOpinions {
  type: ResortActionTypesKeys.SET_TRIPADVISOR_OPINIONS
  payload: { tripadvisorOpinions: ResortOpinion }
}

export interface SetResortDetails {
  type: ResortActionTypesKeys.SET_RESORT_DETAILS
  payload: { resortDetails: ResortDetails }
}

export interface SetSelectedRecommendedResortId {
  type: ResortActionTypesKeys.SET_SELECTED_RECOMMENDED_RESORT_ID
  payload: { resortId: number | null }
}

export type OpinionActionsTypes =
  | SetResortDetails
  | SetGoogleOpinions
  | SetBookingOpinions
  | SetTripadvisorOpinions
  | SetSelectedRecommendedResortId
