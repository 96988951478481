import * as React from 'react'
import * as clsx from 'clsx'
import { CustomFormGroup, CustomFormGroupProps } from '@components/controls/form-controls/custom-form-group'
import { useToggle } from '@hooks/use-toggle'
import { useFormContext } from 'react-hook-form'

export const PasswordFormGroup = (props: CustomFormGroupProps): JSX.Element => {
  const [isVisible, toggleIsVisible] = useToggle()
  const { isFailed, isLoading, isSucceed } = props

  const {
    formState: { errors },
  } = useFormContext()

  const hasError = errors && !!errors[props.inputName]

  return (
    <div className="position-relative">
      <CustomFormGroup {...props} formControlProps={{ type: isVisible ? 'text' : 'password' }} />
      <button
        className={clsx('password-form-group__visibility-icon', isVisible ? 'uil-eye-slash' : 'uil-eye', {
          'is-password-check-eye-moved': isFailed || isLoading || isSucceed || hasError,
        })}
        onClick={toggleIsVisible}
      />
    </div>
  )
}
