import { GlobalFormError } from '@store/reducers/form-errors-reducer'

export enum FormErrorsActionTypesKeys {
  REMOVE_ERRORS = 'REMOVE_ERRORS',
  SET_ERRORS = 'SET_ERRORS',
}

export interface SetFormErrorsAction {
  type: FormErrorsActionTypesKeys.SET_ERRORS
  payload: GlobalFormError
}

export interface RemoveFormErrorsAction {
  type: FormErrorsActionTypesKeys.REMOVE_ERRORS
}

export type FormErrorsActionsTypes = RemoveFormErrorsAction | SetFormErrorsAction
