import { formatToGtmEventDate } from '@helpers/date-formatter'
import DateHelper from '@services/date-helper'
import { useSelector } from 'react-redux'
import {
  selectReservation,
  selectReservationAccommodationType,
  selectReservationResort,
} from '@store/selectors/reservation-selectors'
import {
  CheckoutEventData,
  EcommerceEventData,
  EcommerceEventItem,
  GTMEvents,
  GTMItemCategory,
  ViewItemListEventData,
} from '@models/gtm'
import { useGtm } from '@hooks/use-gtm'
import { Feeding, Improvement, Reservation, ReservationImprovement, ResortImprovement } from '@models/reservation'
import { isFeeding } from '@helpers/improvements'
import { AccommodationType, Resort } from '@models/app-data'
import { FeedingCalculations } from '@requests/reservation/reservation-request-models'
import { FeedingTypes } from '@modules/improvement-step/improvements-sections/kinds/feedings/models'
import { selectClientDetails } from '@store/selectors/client-details-selectors'

const CommonFields = { item_brand: 'Holiday Park & Resort' }

type CartItem = Omit<EcommerceEventItem, 'item_brand'>
type ListItemName = 'rezerwuj' | 'ulepszenia'

interface Response {
  addToCart: (value: string | number, item: CartItem) => void
  removeFromCart: (value: string, item: CartItem) => void
  beginCheckout: (step: number) => void
  selectItem: (item: Partial<EcommerceEventItem>, itemListName: ListItemName) => void
  viewItemList: (items, itemsListItem: ListItemName) => void
}

export const useGtmEvents = (): Response => {
  const { sendEvent } = useGtm()

  const reservation = useSelector(selectReservation)
  const reservationResort = useSelector(selectReservationResort)
  const accommodationType = useSelector(selectReservationAccommodationType)
  const clientDetails = useSelector(selectClientDetails)

  const sendEventWithEcommerceCleanup = (...params: Parameters<typeof sendEvent>) => {
    sendEvent({ ecommerce: null })
    return sendEvent(...params)
  }

  const getAllCartItems = () => {
    const reservationStayItem = createReservationCartItem(
      reservation.selected_apartment_id,
      reservationResort,
      accommodationType,
      reservation.apartment_name,
      reservation,
    )

    const hasFeeding = reservation.prices.feeding.items.length

    const feeding = hasFeeding ? [createCartItem(reservation.prices.feeding.items[0], 1)] : []
    const improvements = reservation.prices.improvements.items.map((improvement, index) =>
      createCartItem(improvement, hasFeeding ? index + 2 : index + 1),
    )

    return [reservationStayItem, ...feeding, ...improvements]
  }

  const baseEventData = <T = unknown>(ecommerceData: Partial<EcommerceEventData> & T) => ({
    userID: clientDetails?.id ?? '',
    date_range: formatToGtmEventDate(reservation),
    number_of_days: DateHelper.diffInDays(reservation.date_from, reservation.date_to),
    ecommerce: {
      ...ecommerceData,
    },
  })

  const cartUpdate = (event: GTMEvents, value: string, item: CartItem) => {
    sendEventWithEcommerceCleanup({
      event,
      ...baseEventData({ currency: 'PLN', value, items: [{ ...CommonFields, ...item }] }),
    })
  }

  const addToCart = (value: string, item: CartItem) => {
    cartUpdate(GTMEvents.AddToCart, value, item)
  }

  const removeFromCart = (value: string, item: CartItem) => {
    cartUpdate(GTMEvents.RemoveFromCart, value, item)
  }

  const beginCheckout = (step: number) => {
    sendEventWithEcommerceCleanup({
      event: GTMEvents.BeginCheckout,
      ...baseEventData<CheckoutEventData>({
        value: reservation.prices.stay_charge_without_climatic,
        step,
        items: getAllCartItems(),
      }),
    })
  }

  const selectItem = (item: Partial<EcommerceEventItem>, itemListName: ListItemName) => {
    sendEventWithEcommerceCleanup({
      event: GTMEvents.SelectItem,
      ...baseEventData<ViewItemListEventData>({
        item_list_name: itemListName,
        items: [item],
      }),
    })
  }

  const viewItemList = (items: Partial<EcommerceEventItem>[], itemListName: ListItemName) => {
    sendEventWithEcommerceCleanup({
      event: GTMEvents.ViewItemList,
      ...baseEventData<ViewItemListEventData>({
        item_list_name: itemListName,
        items,
      }),
    })
  }

  return {
    addToCart,
    removeFromCart,
    beginCheckout,
    selectItem,
    viewItemList,
  }
}

export const createCartItem = (
  improvement: ResortImprovement | ReservationImprovement | Feeding,
  index?: number,
): EcommerceEventItem => {
  const isFeedingImprovement = 'code' in improvement ? isFeeding(improvement as Improvement) : true

  return {
    quantity: 'amount' in improvement ? improvement.amount : 1,
    discount: 0,
    index: index ?? 0,
    price: improvement.price_brutto,
    item_category: isFeedingImprovement ? 'wyżywienie' : 'ulepszenie',
    item_variant: improvement.code,
    item_id: improvement.code,
    item_list_name: 'ulepszenia',
    item_name: improvement.name,
    ...CommonFields,
  }
}

export const createReservationCartItem = (
  apartmentId: number | null,
  resort: Resort | undefined,
  accommodationType: AccommodationType | undefined,
  apartmentName: string | null,
  reservation: Reservation,
): EcommerceEventItem => ({
  item_id: resort?.id ?? '',
  item_name: resort?.name ?? '',
  index: 0,
  item_variant: `${accommodationType?.name} ${accommodationType?.size}m²`,
  item_category: GTMItemCategory.Resort,
  item_category2: apartmentId ? `Lokal numer ${apartmentName}` : 'rezygnacja',
  item_list_name: 'rezerwuj',
  price: reservation.prices.stay_charge_without_climatic,
  discount: 0,
  quantity: 1,
  ...CommonFields,
})

export const createCartItemFromFeedingCalculation = (
  feedingCalculation: FeedingCalculations | undefined,
  selectedFeedingType: FeedingTypes,
  feeding: { title: string } | undefined,
): EcommerceEventItem => ({
  quantity: 1,
  discount: feedingCalculation?.discount_price_brutto ?? '0',
  index: 0,
  price: feedingCalculation?.total_price_brutto ?? '0',
  item_category: GTMItemCategory.Feeding,
  item_variant: selectedFeedingType,
  item_id: selectedFeedingType,
  item_list_name: 'ulepszenia',
  item_name: feeding?.title ?? '',
  ...CommonFields,
})

export const createResortViewListItem = (resort: Resort, index: number): Partial<EcommerceEventItem> => ({
  item_id: resort.id,
  item_name: resort.name,
  index: index,
  item_category: GTMItemCategory.Resort,
  item_list_name: 'rezerwuj',
  ...CommonFields,
})

export const createImprovementViewListItem = (
  improvement: { code: string; name: string },
  index: number,
): Partial<EcommerceEventItem> => ({
  item_id: improvement.code,
  item_name: improvement.name,
  index: index,
  item_category: GTMItemCategory.Improvement,
  item_list_name: 'ulepszenia',
  ...CommonFields,
})
