import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { CustomFormGroup } from '@components/controls/form-controls/custom-form-group'
import { SaveButton } from '@components/controls/save-button'
import { SummaryCard } from '@modules/summary-step/summary-card'
import { SummaryStepFormInputs } from '@modules/summary-step/summary-step'
import { CSSTransition } from 'react-transition-group'
import { useFormRequest } from '@hooks/use-form-request'
import { useDispatch, useSelector } from 'react-redux'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { addPromocode, removePromocode } from '@requests/reservation/reservation-requests'

export const SummaryPromocode = (): JSX.Element => {
  const { control, setError, clearErrors, setValue } = useFormContext<SummaryStepFormInputs>()
  const { handleAction, isLoading } = useFormRequest(setError)

  const reservation = useSelector(selectReservation)

  const code = useWatch({ control, name: 'code' })
  const dispatch = useDispatch()

  const isAdded = !!reservation.promocode

  const handlePromocodeAction = async () => {
    clearErrors('code')
    const { isResolved } = await handleAction(() => dispatch(isAdded ? removePromocode() : addPromocode(code)))
    if (isResolved && isAdded) setValue('code', '')
  }

  return (
    <SummaryCard
      title={
        <strong className="text-primary font-size-xl">
          Dodaj kod <span className="underline-shadow">rabatowy / pakietowy</span>
        </strong>
      }
      subtitle="W tym miejscu wprowadź kod rabatowy/pakietowy, który posiadasz."
    >
      <div className="d-flex flex-sm-row flex-column gap-3">
        <CustomFormGroup
          isSucceed={isAdded}
          inputName="code"
          formGroupProps={{ className: 'col-sm-6 col-12' }}
          formControlProps={{ className: 'btn-tall letter-spacing-2' }}
        />
        <SaveButton
          onClick={handlePromocodeAction}
          disabled={!code}
          isLoading={isLoading}
          className="btn bg-dark-gradient text-white fw-bold mt-3 w-responsiveness-btn align-self-start"
          iconClassName=""
          text={isAdded ? 'Usuń kod' : 'Dodaj'}
          loadingText={isAdded ? 'Usuwanie' : 'Dodawanie'}
        />
      </div>
      <CSSTransition timeout={{ enter: 0, exit: 200 }} classNames="fade" in={isAdded} unmountOnExit>
        <span className="d-block mt-2 text-darker-gray">{reservation.promocode?.text}</span>
      </CSSTransition>
    </SummaryCard>
  )
}
