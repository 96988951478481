export enum PageLeaveActionTypesKeys {
  SET_IS_PAGE_LEAVE_ALLOWED = 'SET_IS_PAGE_LEAVE_ALLOWED',
}

export interface SetIsPageLeaveAllowed {
  type: PageLeaveActionTypesKeys.SET_IS_PAGE_LEAVE_ALLOWED
  payload: boolean
}

export type PageLeaveActionsTypes = SetIsPageLeaveAllowed
