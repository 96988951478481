import { BookingGuest, Reservation, UpdateGuestGroupPayload } from '@models/reservation'
import { ThunkResult } from '@models/models'
import { UpdateReservationAction } from '@store/action-types/reservation-action-types'
import { commonObjectDelete, commonObjectPatch, commonObjectPost } from '@requests/basic-requests'
import { updateReservation } from '@store/actions/reservation-actions'

export function createGuest(
  guestData: Partial<BookingGuest>,
): ThunkResult<Promise<Reservation>, UpdateReservationAction> {
  return async (dispatch, getState) => {
    const url = getState().reservationState.reservation.urls.guests
    const data = await commonObjectPost<Reservation, Partial<BookingGuest>>(url, { ...guestData })
    dispatch(updateReservation({ ...data }))
    return data
  }
}

export function updateGuest(
  guestData: Partial<BookingGuest>,
  url: string,
): ThunkResult<Promise<void>, UpdateReservationAction> {
  return async dispatch => {
    dispatch(updateReservation({ ...(await commonObjectPatch(url, { ...guestData })) }))
  }
}

export function deleteGuests(guestIds: number[]): ThunkResult<Promise<void>, UpdateReservationAction> {
  return async (dispatch, getState) => {
    const url = getState().reservationState.reservation.urls.guests
    dispatch(updateReservation({ ...(await commonObjectDelete(url, { ids: guestIds })) }))
  }
}

export function createGuestGroup(
  groupName: string,
  guestIds: string[],
): ThunkResult<Promise<void>, UpdateReservationAction> {
  return async (dispatch, getState) => {
    const url = getState().reservationState.reservation.urls.groups
    dispatch(updateReservation({ ...(await commonObjectPost(url, { name: groupName, guests: guestIds })) }))
  }
}

export function updateGuestGroup(
  groupData: UpdateGuestGroupPayload,
  url: string,
): ThunkResult<Promise<void>, UpdateReservationAction> {
  return async dispatch => {
    dispatch(updateReservation({ ...(await commonObjectPatch(url, groupData)) }))
  }
}

export function deleteGuestGroup(url: string): ThunkResult<Promise<void>, UpdateReservationAction> {
  return async dispatch => {
    dispatch(updateReservation({ ...(await commonObjectDelete(url)) }))
  }
}
