import { ThunkResult } from '@models/models'
import { UpdateReservationAction } from '@store/action-types/reservation-action-types'
import { commonObjectDelete, commonObjectPost, commonObjectPut } from '@requests/basic-requests'
import { updateReservation } from '@store/actions/reservation-actions'
import { AddImprovementPayload } from '@requests/reservation/reservation-request-models'

export function addReservationImprovement(
  payload: AddImprovementPayload[],
): ThunkResult<Promise<void>, UpdateReservationAction> {
  return async (dispatch, getState) => {
    const url = getState().reservationState.reservation.urls.improvements
    dispatch(updateReservation({ ...(await commonObjectPost(url, payload)) }))
  }
}

export function updateReservationImprovement(
  url: string,
  payload: AddImprovementPayload[],
): ThunkResult<Promise<void>, UpdateReservationAction> {
  return async dispatch => {
    dispatch(updateReservation({ ...(await commonObjectPut(url, payload)) }))
  }
}

export function removeImprovement(url: string): ThunkResult<Promise<void>, UpdateReservationAction> {
  return async dispatch => {
    dispatch(updateReservation({ ...(await commonObjectDelete(url)) }))
  }
}
