import * as React from 'react'
import { CustomFormGroup } from '@components/controls/form-controls/custom-form-group'
import { Checkbox } from '@components/controls/checkbox'
import { asDecimal, formatPrice } from '@helpers/utils'
import { useFormContext, useWatch } from 'react-hook-form'
import { SummaryGastroVoucherModalFormInputs } from '@modules/summary-step/promocodes/gastro-voucher/summary-gastro-voucher-modal'
import { useNumberInputWithPlaceholder } from '@hooks/use-number-input-with-placeholder'
import { useRules } from '@hooks/use-rules'

export const SummaryGastroVoucherModalPurchase = (): JSX.Element => {
  const numberInputParams = useNumberInputWithPlaceholder()
  const rules = useRules()

  const { control, setValue } = useFormContext<SummaryGastroVoucherModalFormInputs>()

  const amount = useWatch({ control: control, name: 'amount' })

  const priceToSpend = asDecimal(amount).mul(2).toString()

  const handleAmountChange = event => {
    const value = event.target.value
    setValue('amount', value.length > 6 ? value.slice(0, 6) : value)
  }

  return (
    <div className="d-flex flex-sm-row flex-column gap-2">
      <div className="rounded bg-alice-blue py-3 px-4 col-sm-8 col-12">
        <CustomFormGroup
          inputName="amount"
          formControlProps={{
            placeholder: 'min. 500 zł',
            className: 'btn-tall bg-white',
            ...numberInputParams,
            onChange: handleAmountChange,
          }}
          formGroupProps={{
            className: 'd-flex align-items-sm-center flex-md-row flex-column gap-3',
          }}
          label="Wpłacam kwotę:"
        />
        <Checkbox
          className="checkbox--small mt-4 mb-3"
          name="departure_voucher_rules"
          id="departure_voucher_rules"
          label={
            <span className="font-size-sm">
              Akceptuję treść dokumentu
              <a
                className="text-decoration-none text-darker-gray fw-bold"
                target="_blank"
                href={rules.departure_voucher_rules?.url}
              >
                {rules.departure_voucher_rules?.name}
              </a>
            </span>
          }
        />
      </div>
      {!!amount && (
        <div className="rounded bg-alice-blue py-3 px-4 col-sm-4 col-12 text-sm-end text-darker-gray">
          <span className="d-sm-block mt-3">{formatPrice(amount)} x 2 </span>
          <span className="font-size-lg text-dark fw-semi-bold">= {formatPrice(priceToSpend)}</span>
          <span className="d-block mt-sm-3 mt-2 font-size-sm">Do wykorzystania w Bistro i Restauracji</span>
        </div>
      )}
    </div>
  )
}
