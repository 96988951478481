import { Apartment } from '@models/reservation'
import * as React from 'react'
import { PANNED_MAP_IDENTIFIER } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-selection-map-modal'

const MINIMUM_PANNING_TIME_MS = 200

interface Response {
  selectApartment: (apartment: Apartment) => void
}

export const useMapApartmentSelection = (
  panZoom: SvgPanZoom.Instance | null,
  onApartmentChange: (apartment: Apartment) => void,
): Response => {
  let timer: NodeJS.Timer

  const isPanning = React.useRef(false)

  const selectApartment = (apartment: Apartment) => {
    if (isPanning.current) return
    onApartmentChange(apartment)
  }

  const handleMouseDownEvent = () => {
    clearTimeout(timer)

    timer = setTimeout(() => {
      isPanning.current = true
    }, MINIMUM_PANNING_TIME_MS)
  }

  const handleMouseUpEvent = () => {
    clearTimeout(timer)
    isPanning.current = false
  }

  React.useEffect(() => {
    clearTimeout(timer)
    const element = (document.getElementById(PANNED_MAP_IDENTIFIER) as HTMLObjectElement)?.contentDocument
      ?.documentElement

    element?.addEventListener('mousedown', handleMouseDownEvent)
    element?.addEventListener('mouseup', handleMouseUpEvent)

    return () => {
      clearTimeout(timer)
      element?.removeEventListener('mousedown', handleMouseDownEvent)
      element?.removeEventListener('mouseup', handleMouseUpEvent)
    }
  }, [panZoom])

  return {
    selectApartment,
  }
}
