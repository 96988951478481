import { differenceInDays, parseISO } from 'date-fns'

export interface CalendarPayload {
  date_from: string
  date_to?: string
  accommodation_type: number | null
  resort: number | null
  calendar_type: 'start' | 'end'
}

export interface DateRange {
  startDate: Date
  endDate: Date
  key: 'selection'
}

const DateHelper = {
  diffInDays: (startingDate: string, endingDate: string): number =>
    differenceInDays(parseISO(endingDate), parseISO(startingDate)),
  mapDatesToDateRange: (sd: string, ed: string): DateRange[] => [
    {
      startDate: parseISO(sd),
      endDate: parseISO(ed),
      key: 'selection',
    },
  ],
}

export default DateHelper
