import * as React from 'react'
import { MainInfoBox } from '@components/main-info-box'
import { DatesSelection } from '@modules/accommodation-selection-step/dates-selection/dates-selection'
import { DateSelectionParams } from '@modules/accommodation-selection-step/dates-selection/use-date-selection'

interface Props {
  dateSelection: DateSelectionParams
}

export const MainInformationMobile = ({ dateSelection }: Props): JSX.Element => (
  <MainInfoBox className="border-top">
    <DatesSelection isModalVersion={true} dateSelection={dateSelection} />
  </MainInfoBox>
)
