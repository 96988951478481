import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationMessage } from '@models/notification-messages'
import { RootState } from '@store/index'

export interface NotificationMessagesState {
  message: NotificationMessage | null
}

const initialState: NotificationMessagesState = {
  message: null,
}
export const notificationMessagesSlice = createSlice({
  name: 'notificationMessages',
  initialState,
  reducers: {
    setNotificationMessage(state, action: PayloadAction<NotificationMessage>) {
      state.message = action.payload
    },
    removeNotificationMessage(state) {
      state.message = null
    },
  },
})

export const { removeNotificationMessage, setNotificationMessage } = notificationMessagesSlice.actions
export const selectNotificationMessage = (state: RootState): NotificationMessage | null =>
  state.notificationMessagesState.message

export default notificationMessagesSlice.reducer
