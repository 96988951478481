import * as React from 'react'
import { v4 as uuidv4 } from 'uuid'

interface Props {
  size?: number
  lastFill?: number | null
  className?: string
}

export const StarShape = ({ size = 20, lastFill, className }: Props): JSX.Element => {
  const gradientId = `${uuidv4()}-gradient`
  return (
    <svg height={size} viewBox="0 0 24 24" width={size} className={className}>
      <defs>
        <linearGradient id={gradientId}>
          {lastFill ? (
            <>
              <stop offset="0%" stopColor="#b99153" />
              <stop offset={`${lastFill}%`} stopColor="#b99153" />
              <stop offset={`${lastFill}%`} stopColor="white" />
            </>
          ) : (
            <>
              <stop offset="0%" stopColor="#b99153" />
              <stop offset="100%" stopColor="#b99153" />
            </>
          )}
        </linearGradient>
      </defs>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill={`url(#${gradientId})`}
        stroke="#b99153"
        strokeWidth="1.5"
        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  )
}
