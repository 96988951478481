import * as React from 'react'
import { OpinionsModalTabs } from '@modules/accommodation-selection-step/opinions/opinions-modal-tabs'
import { OpinionsTable } from '@modules/accommodation-selection-step/opinions/opinions-table'
import { OpinionProvidersKeys, Resort } from '@models/app-data'
import { useDispatch, useSelector } from 'react-redux'
import { CustomReactSelect } from '@components/controls/custom-react-select'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { selectOpinionsUrls } from '@store/selectors/app-data-selectors'
import { getOpinionProviderName } from '@helpers/utils'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'
import { useApiRequest } from '@hooks/use-form-request'
import { ContentLoader } from '@components/loaders/content-loader'
import { fetchResortDetails, fetchResortOpinions } from '@requests/resort-requests'
import { selectAllOpinions, selectResortDetails } from '@store/selectors/resort-selectors'
import { ResortOpinion } from '@models/models'
import { selectReservation } from '@store/selectors/reservation-selectors'

const NEXT_OPINIONS_SIZE = 30
const INITIAL_PAGE_SIZE = 10

interface Props {
  defaultProvider: OpinionProvidersKeys
  resort: Resort
}

export const OpinionsModal = ({ defaultProvider, resort }: Props): JSX.Element => {
  const [provider, setProvider] = React.useState<OpinionProvidersKeys>(defaultProvider)
  const [reloadNumbers, updateReloadNumbers] = React.useState(0)
  const [pageSize, setPageSize] = React.useState(INITIAL_PAGE_SIZE)
  const [opinions, setOpinions] = React.useState<ResortOpinion | null>(null)

  const allOpinions = useSelector(selectAllOpinions)
  const resortDetails = useSelector(selectResortDetails)
  const reservation = useSelector(selectReservation)

  React.useEffect(() => {
    if (resortDetails.id !== resort.id) {
      dispatch(fetchResortDetails(resort.id, reservation.date_from, reservation.date_to))
    }
  }, [resortDetails, resort])

  const methods = useForm({
    defaultValues: {
      ordering: options[0],
    },
  })

  const { handleAction, isLoading } = useApiRequest()

  React.useEffect(() => {
    setOpinions(allOpinions[provider])
  }, [allOpinions])

  const dispatch = useDispatch()
  const ordering = useWatch({ control: methods.control, name: 'ordering' })
  const opinionUrls = useSelector(selectOpinionsUrls)

  const loadOpinions = async () => {
    await handleAction(() =>
      dispatch(
        fetchResortOpinions({
          url: opinionUrls[provider],
          resort_id: resort.id,
          config: { ordering: ordering.value, page_size: pageSize },
        }),
      ),
    )
  }

  React.useEffect(() => {
    loadOpinions()
  }, [pageSize, ordering.value, provider])

  useDidUpdateEffect(() => {
    setPageSize(Math.min(opinions?.count || 0, reloadNumbers * NEXT_OPINIONS_SIZE + INITIAL_PAGE_SIZE))
  }, [opinions?.count, reloadNumbers])

  const handleProviderChange = (newProvider: OpinionProvidersKeys) => {
    setProvider(newProvider)
  }

  const handleLoadMoreOpinions = () => {
    updateReloadNumbers(state => state + 1)
  }

  const moreOpinionButtonCount = () => {
    if (!opinions?.count) return 0
    const difference = opinions?.count - pageSize
    return Math.min(NEXT_OPINIONS_SIZE, difference)
  }

  return (
    <FormProvider {...methods}>
      <div className="px-xl-6">
        <h4 className="text-dark fw-bold my-3 text-center">Opinie ośrodka: {resortDetails.name}</h4>
        <OpinionsModalTabs resortDetails={resortDetails} onProviderChange={handleProviderChange} provider={provider} />
        <div className="d-flex justify-content-between align-items-center my-3 container-lg">
          <h4 className="text-primary fw-bold mb-0">Opinie {getOpinionProviderName(provider)}:</h4>
          <CustomReactSelect
            options={options}
            inputName="ordering"
            styles={selectStyles}
            data-testid="sort-opinions-select"
          />
        </div>

        <ContentLoader isLoading={isLoading} className="is-positioned-to-top">
          <OpinionsTable opinions={opinions} />
        </ContentLoader>
        {!!moreOpinionButtonCount() && (
          <div className="container-lg d-flex justify-content-center">
            <button
              className="btn btn-outline-primary mb-4 py-2 px-4 opinions__modal__show-more-button btn-thin"
              onClick={handleLoadMoreOpinions}
            >
              <span className="fw-bold">Wczytaj kolejne</span>
              <span> ({moreOpinionButtonCount()} opinii)</span>
            </button>
          </div>
        )}
      </div>
    </FormProvider>
  )
}

const selectStyles = {
  menuList: provided => ({ ...provided, paddingTop: 0, paddingBottom: 0 }),
  control: provided => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    '&:focus': { border: 'none' },
  }),
  indicatorSeparator: provided => ({ ...provided, display: 'none' }),
  singleValue: provided => ({ ...provided, color: '#5a5a5a', display: 'contents', fontWeight: '500' }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#6c757d' : 'white',
    color: state.isSelected ? 'white' : '#6c757d',
    cursor: 'pointer',
  }),
  valueContainer: provided => ({ ...provided, paddingLeft: 0, paddingRight: 0, display: 'block' }),
}

const options = [
  { value: '-created_at', label: 'najnowsze opinie' },
  { value: 'created_at', label: 'najstarsze opinie' },
  { value: 'rating', label: 'najniższa ocena' },
  { value: '-rating', label: 'najwyższa ocena' },
]
