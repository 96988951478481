import { asDecimal, formatPrice } from '@helpers/utils'
import Decimal from 'decimal.js'
import { AccommodationTypeAvailable } from '@models/reservation'

const MIN_DISPLAYING_DISCOUNT = 5

export interface PriceInformation {
  guestAmount: number
  pricePerGuest: string
  pricePerGuestBeforeDiscount: string
  percentageDiscount: string | null
}

export const usePriceInformation = (
  maxGuests: number,
  accommodationTypeAvailable: AccommodationTypeAvailable[],
): PriceInformation => {
  const priceGrossWithDiscount = accommodationTypeAvailable[0]?.price_brutto_avg || null
  const priceGrossBeforeDiscount = accommodationTypeAvailable[0]?.base_price_brutto_avg ?? 0

  const pricePerGuestWithDiscount = (
    priceGrossWithDiscount ? asDecimal(priceGrossWithDiscount).div(asDecimal(maxGuests)) : new Decimal(0)
  ).toString()

  const pricePerGuestBeforeDiscount = (
    priceGrossBeforeDiscount ? asDecimal(priceGrossBeforeDiscount).div(asDecimal(maxGuests)) : new Decimal(0)
  ).toString()

  const percentageDiscountValue = new Decimal(100).minus(
    new Decimal(priceGrossWithDiscount ?? 0)
      .mul(100)
      .div(priceGrossBeforeDiscount ?? '1')
      .round(),
  )

  const discountExist =
    priceGrossWithDiscount &&
    percentageDiscountValue.gte(MIN_DISPLAYING_DISCOUNT) &&
    !new Decimal(priceGrossBeforeDiscount).eq(priceGrossWithDiscount)

  const percentageDiscount = discountExist ? `${percentageDiscountValue.toString()}%` : null

  return {
    guestAmount: maxGuests,
    pricePerGuest: formatPrice(pricePerGuestWithDiscount),
    pricePerGuestBeforeDiscount: formatPrice(pricePerGuestBeforeDiscount),
    percentageDiscount,
  }
}
