import { ResortDetails } from '@models/resort'
import { OpinionActionsTypes, ResortActionTypesKeys } from '@store/action-types/resort-action-types'
import { OpinionProviders } from '@models/models'

export const emptyResortDetails: ResortDetails = {
  id: 0,
  trip_advisor_reviews_count: 0,
  booking_com_reviews_count: 0,
  google_maps_reviews_count: 0,
  google_maps_rating: '',
  booking_com_rating: '',
  reservation_images: [],
  trip_advisor_rating: '',
  google_maps_url: '',
  map_svg_sectors: [],
  accommodation_types: [],
  attractions_description: '',
  apartments_map: '',
  disable_client_reservation: false,
  faqs: [],
  features: [],
  feedings: {
    adult: {},
    child: {},
    children_5_12: {},
    children_3_4: {},
    baby: {},
  },
  improvements: [],
  improvements_extra: [],
  position: 1,
  map_description: '',
  map_svg: '',
  name: '',
  reservation_attractions: [],
  slug: '',
  reservation_description: '',
  localization_kind: 'sea',
  localization_kind_display: '',
  urls: {
    details: '',
  },
}

export interface ResortState {
  resortDetails: ResortDetails
  opinions: OpinionProviders
  selectedRecommendedResortId: number | null
}

const initialState: ResortState = {
  resortDetails: emptyResortDetails,
  selectedRecommendedResortId: null,
  opinions: {
    google: null,
    booking: null,
    tripadvisor: null,
  },
}

const resortState = (state: ResortState = initialState, action: OpinionActionsTypes): ResortState => {
  switch (action.type) {
    case ResortActionTypesKeys.SET_RESORT_DETAILS:
      return { ...state, resortDetails: { ...action.payload.resortDetails } }
    case ResortActionTypesKeys.SET_GOOGLE_OPINIONS:
      return { ...state, opinions: { ...state.opinions, google: action.payload.googleOpinions } }
    case ResortActionTypesKeys.SET_BOOKING_OPINIONS:
      return { ...state, opinions: { ...state.opinions, booking: action.payload.bookingOpinions } }
    case ResortActionTypesKeys.SET_SELECTED_RECOMMENDED_RESORT_ID:
      return { ...state, selectedRecommendedResortId: action.payload.resortId }
    default:
      return state
  }
}

export default resortState
