import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setResortDetails } from '@store/actions/resort-actions'
import { emptyResortDetails } from '@store/reducers/resort-reducer'
import { useAccommodationAvailability } from '@modules/accommodation-selection-step/dates-selection/use-accommodation-availability'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { Reservation } from '@models/reservation'

export interface DateSelectionParams {
  onDateChange: (dates: Pick<Reservation, 'date_from' | 'date_to'>) => void
  onRangeFocusChange: (range: number[]) => void
  setRange: (value: React.SetStateAction<boolean>) => void
  isPickingEndDate: boolean
  isRange: boolean
  toggleModal: () => void
}

export const useDateSelection = (): DateSelectionParams => {
  const dispatch = useDispatch()

  const [isPickingEndDate, setIsPickingEndDate] = React.useState<boolean>(false)
  const [isRange, setRange] = React.useState(false)

  const reservation = useSelector(selectReservation)

  const { fetchAccommodationTypes } = useAccommodationAvailability()

  const toggleModal = React.useCallback(() => {
    setRange(state => !state)
  }, [])

  const onDateChange = async ({ date_from, date_to }) => {
    fetchAccommodationTypes({ date_from, date_to, promocode: reservation.promocode })

    if ((date_from !== reservation.date_from || date_to !== reservation.date_to) && !reservation.resort_id) {
      dispatch(setResortDetails(emptyResortDetails))
    }
  }

  const onRangeFocusChange = ([, isPickingEndDate]: number[]) => {
    setIsPickingEndDate(!!isPickingEndDate)
  }

  return {
    onDateChange,
    onRangeFocusChange,
    setRange,
    isPickingEndDate,
    isRange,
    toggleModal,
  }
}
