import {
  ChangeStep,
  SetNextStepActive,
  SetNextStepInactive,
  StepsActionTypesKeys,
} from '@store/action-types/steps-action-types'
import { Step } from '@store/reducers/steps-reducer'

export function updateStep(step: Step): ChangeStep {
  return {
    type: StepsActionTypesKeys.CHANGE_STEP,
    payload: { step },
  }
}

export function setNextStepActive(): SetNextStepActive {
  return {
    type: StepsActionTypesKeys.SET_NEXT_STEP_ACTIVE,
  }
}

export function setNextStepInactive(): SetNextStepInactive {
  return {
    type: StepsActionTypesKeys.SET_NEXT_STEP_INACTIVE,
  }
}
