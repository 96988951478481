import * as React from 'react'
import { Checkbox } from '@components/controls/checkbox'
import { useFormContext } from 'react-hook-form'
import { SummaryStepFormInputs } from '@modules/summary-step/summary-step'
import { useRules } from '@hooks/use-rules'
import {
  MARKETING_RULES_KEYWORDS,
  SummaryStepMarketingConsents,
} from '@modules/summary-step/summary-step-marketing-consents'

export const SummaryStepConsents = (): JSX.Element => {
  const { watch, setValue } = useFormContext<SummaryStepFormInputs>()

  const requiredRules = useRules()

  const dynamicRules = Object.keys(requiredRules) as (keyof SummaryStepFormInputs)[]

  const handleAllSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked
    dynamicRules.forEach(key => setValue(key, newValue))
    MARKETING_RULES_KEYWORDS.forEach(key => setValue(key, newValue))
    setValue('allMarketingRulesSelected', newValue)
  }

  const isEachSelected = watch([...dynamicRules, ...MARKETING_RULES_KEYWORDS]).every(Boolean)

  React.useEffect(() => {
    setValue('allRulesSelected', isEachSelected)
  }, [isEachSelected])

  return (
    <div className="mt-4 col-xl-8">
      <Checkbox name="allRulesSelected" id="allSelected" label="Zaznacz wszystkie" onChange={handleAllSelection} />
      <div className="ms-4 mt-2">
        {dynamicRules.map((keyword: string) => (
          <React.Fragment key={keyword}>
            <Checkbox
              className="checkbox--small font-size-sm is-muted-label align-items-start"
              name={keyword}
              id={keyword}
              label={
                <span>
                  Oświadczam, że zapoznałem się z treścią dokumentu
                  <a
                    className="text-darker-gray text-decoration-none fw-bold mx-1"
                    href={requiredRules[keyword]?.url}
                    target="_blank"
                  >
                    {requiredRules[keyword]?.name}
                  </a>
                  i akceptuję jego postanowienia.
                </span>
              }
            />
          </React.Fragment>
        ))}
        <SummaryStepMarketingConsents />
      </div>
    </div>
  )
}
