import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectNextStep, selectPreviousStep, selectStep } from '@store/selectors/steps-selectors'
import { updateStep } from '@store/actions/steps-actions'
import { NavigationPath } from '@models/routes'
import { availableSteps, Step } from '@store/reducers/steps-reducer'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { useReservationDataCleanup } from '@hooks/use-reservation-data-cleanup-on-render'
import { useModal } from '@modals/use-modal'
import { useGtmEvents } from '@hooks/use-gtm-events'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'
import { useCreateUnfinishedBooking } from '@hooks/use-create-unfinished-booking'

type MoveDirection = 'next' | 'prev'

export interface UseSteps {
  hasNext: boolean
  hasPrev: boolean
  goNext: () => Promise<void>
  goPrev: () => Promise<void>
  nextName: string
  prevName: string
  currentStep: Step
  isLoading: boolean
}

export const useSteps = (): UseSteps => {
  const [isLoading, setIsLoading] = React.useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const reservation = useSelector(selectReservation)

  const currentStep = useSelector(selectStep)
  const previousStep = useSelector(selectPreviousStep)
  const nextStep = useSelector(selectNextStep)

  const { cleanData } = useReservationDataCleanup()

  const { beginCheckout } = useGtmEvents()

  const [showReservationDataCleanupModal] = useModal('ReservationDataCleanupModal', { title: 'Uwaga' })

  const { createUnfinishedBooking } = useCreateUnfinishedBooking()

  useDidUpdateEffect(() => {
    if (location.pathname === '/') {
      cleanData({ promocode: null })
      dispatch(updateStep(availableSteps[0]))
    }
    if (location.pathname.includes('wybor-ulepszen')) dispatch(updateStep(availableSteps[1]))
    if (location.pathname.includes('podsumowanie-rezerwacji')) dispatch(updateStep(availableSteps[2]))
  }, [location.pathname])

  React.useEffect(() => {
    if (location.pathname.includes('wybor-ulepszen')) dispatch(updateStep(availableSteps[1]))
  }, [])

  const moveToImprovementStep = async () => {
    if (!nextStep) return

    setIsLoading(true)

    await createUnfinishedBooking()

    setIsLoading(false)
  }

  const moveToReservationSummaryStep = async () => {
    if (!reservation.token || !nextStep) return

    navigate(NavigationPath.SummaryStep.replace(':token', reservation.token))
    dispatch(updateStep(nextStep))
  }

  const handleUpdateStep = (direction: MoveDirection) => async () => {
    if (direction === 'prev' && !!previousStep) {
      if (previousStep.stepNumber === 1) {
        showReservationDataCleanupModal()
        return
      }

      navigate(
        previousStep.url.includes(':token') && reservation.token
          ? previousStep.url.replace(':token', reservation.token)
          : previousStep.url,
      )

      dispatch(updateStep(previousStep))
    }

    if (direction === 'next' && !!nextStep) {
      if (nextStep.stepNumber === 2) await moveToImprovementStep()
      else if (nextStep.stepNumber === 3) await moveToReservationSummaryStep()
      beginCheckout(nextStep.stepNumber)
    }
  }

  return {
    hasNext: !!nextStep,
    hasPrev: !!previousStep,
    goNext: handleUpdateStep('next'),
    goPrev: handleUpdateStep('prev'),
    nextName: nextStep?.name || '',
    prevName: previousStep?.name || '',
    currentStep,
    isLoading,
  }
}
