import { IconWithText } from '@components/icon-with-text'
import * as React from 'react'
import { PriceWithSpinner } from '@components/loaders/price-with-spinner'

interface Props {
  text: string
  price: string | number
  onDelete: () => void
  isPriceCalculating?: boolean
  isDeleteEnabled?: boolean
}

export const SectionSummaryRow = ({
  text,
  price,
  onDelete,
  isPriceCalculating,
  isDeleteEnabled = true,
}: Props): JSX.Element => (
  <div className="border-top" data-testid="improvement-summary-row">
    <div className="p-3 px-xl-5 d-flex justify-content-between align-items-center container-lg">
      <div>
        <IconWithText
          text={text}
          iconClassName="uil-check text-primary me-2 font-size-xl"
          wrapperClassName="d-inline-flex text-muted"
        />
        {isDeleteEnabled && (
          <button
            className="uil-trash-alt text-muted ms-2 font-size-xl cursor-pointer btn-unstyled"
            onClick={onDelete}
          />
        )}
      </div>
      <PriceWithSpinner
        pricePrefix="+"
        priceItemClassName="d-block text-darker-gray"
        isLoading={!!isPriceCalculating}
        price={price}
      />
    </div>
  </div>
)
