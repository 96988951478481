import {
  Feeding,
  Improvement,
  PriceItem,
  Reservation,
  ReservationImprovement,
  ResortImprovement,
} from '@models/reservation'
import { sortByPosition } from '@helpers/utils'
import { AccommodationType } from '@models/app-data'
import { FeedingTypes } from '@modules/improvement-step/improvements-sections/kinds/feedings/models'
import { ResortDetails } from '@models/resort'

export const isFeedingAvailableInResort = (resortDetails: ResortDetails): boolean =>
  Object.values(resortDetails.feedings).some(feeding => !!Object.values(feeding).length)

export const isFeeding = <T extends { code: string }>(improvement: T): boolean =>
  !!improvement &&
  (improvement.code.startsWith('feeding__') ||
    ['dinner', 'breakfast', 'dinner_with_breakfast'].includes(improvement.code))

export const isWinterVip = (improvement: Improvement | null | undefined): boolean =>
  !!improvement && improvement.code === 'package_vip_winter'
export const isVip = (improvement: Improvement | null): boolean =>
  !!(improvement && ['package_vip_winter', 'package_vip'].includes(improvement.code))
export const isParking = (improvement: Improvement): boolean =>
  improvement && ['parking2', 'parking_vip', 'parking_large'].includes(improvement.code)
export const isBikeImprovement = (improvement: Improvement): boolean => improvement.code === 'bike'
export const isStayOption = (improvement: Improvement): boolean =>
  ['extend_stay_12', 'extend_stay', 'early_check_in_13'].includes(improvement.code)

export const isPaidByVip = (improvement: ReservationImprovement): boolean => improvement.is_paid_by_package_vip

export const getFeedingImprovementData = (feedings: Feeding[]): { name: string; code: FeedingTypes } => {
  const { hasBreakfast, hasDinner } = feedings.reduce(
    (prev, feeding) => ({
      hasBreakfast: prev.hasBreakfast || feeding.code === 'breakfast',
      hasDinner: prev.hasDinner || feeding.code === 'dinner',
    }),
    { hasBreakfast: false, hasDinner: false },
  )

  if (hasBreakfast && hasDinner) return { name: 'Śniadanie + obiadokolacja', code: 'dinner_with_breakfast' }
  if (hasBreakfast) return { name: 'Śniadanie', code: 'breakfast' }
  if (hasDinner) return { name: 'Obiadokolacja', code: 'dinner' }
  return { name: '', code: 'none' }
}

interface GroupedImprovements<T> {
  feedingImprovements: T[]
  restImprovements: T[]
  stayOptionsImprovements: T[]
  vipImprovements: T[]
}

export const getGroupedImprovements = <T extends ReservationImprovement | ResortImprovement>(
  improvements: T[],
): GroupedImprovements<T> => {
  const createGroupedImprovements = (
    groupedImprovements: GroupedImprovements<T>,
    improvement: T,
    groupName: keyof GroupedImprovements<T>,
  ) => ({ ...groupedImprovements, [groupName]: [...groupedImprovements[groupName], improvement] })

  return improvements.reduce(
    (acc, improvement) => {
      if (isVip(improvement)) return createGroupedImprovements(acc, improvement, 'vipImprovements')
      if (isFeeding(improvement)) return createGroupedImprovements(acc, improvement, 'feedingImprovements')
      if (isStayOption(improvement)) return createGroupedImprovements(acc, improvement, 'stayOptionsImprovements')
      return createGroupedImprovements(acc, improvement, 'restImprovements')
    },
    { vipImprovements: [], stayOptionsImprovements: [], feedingImprovements: [], restImprovements: [] },
  )
}

interface FeedingImprovement extends Feeding {
  position: number
  can_remove: boolean
  can_update: boolean
}

export const createSingleFeedingImprovement = (feedings: PriceItem<Feeding>): FeedingImprovement => ({
  ...feedings[0],
  can_remove: true,
  can_update: true,
  position: 1,
  price_brutto: feedings.total_price_brutto,
  ...getFeedingImprovementData(feedings.items),
})

export const sortImprovementByPosition = <T extends { position: number }>(improvements: T[]): T[] =>
  sortByPosition(improvements)

export const getImprovementLimit = (resortImprovement: ResortImprovement, reservationDetails: Reservation): number => {
  if (!reservationDetails.accommodation_type_id) return 0

  const limitForImprovement = resortImprovement.limits[reservationDetails.accommodation_type_id]

  return limitForImprovement ?? 0
}

export const getSelectableImprovements = (
  improvements: ResortImprovement[],
  accommodationType: AccommodationType | undefined,
  apartmentId: number | null,
): ResortImprovement[] =>
  improvements.filter(
    (improvement: ResortImprovement) =>
      improvement.sales_channels.includes('reservation_process') &&
      improvement.can_add &&
      (accommodationType ? improvement.available_in.includes(accommodationType.id) : true) &&
      (apartmentId ? !improvement.excluded_apartments.includes(apartmentId) : true),
  )
