import * as React from 'react'
import { Collapse } from 'react-bootstrap'
import * as clsx from 'clsx'

interface Props {
  image: string
  imageAlt: string
  description: React.ReactNode
  children: React.ReactNode
  isCollapsed: boolean
  onCollapse: () => void
  ecommerceLink: string
}

export const LocalForClientWithBenefitExpandable = ({
  children,
  image,
  description,
  isCollapsed,
  onCollapse,
  imageAlt,
  ecommerceLink,
}: Props): JSX.Element => (
  <div
    className={clsx('border rounded shadow-sm p-3 my-3', {
      'border-success': isCollapsed,
      'bg-light-blue': !isCollapsed,
    })}
  >
    <button onClick={onCollapse} className="btn-unstyled d-flex align-items-center w-100 flex-sm-nowrap">
      <div className={clsx('d-none d-md-block circle-check__wrapper me-4 ms-2', { 'is-active': isCollapsed })}>
        <div className="circle-check">
          <i className="uil-check" />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center justify-content-md-starts flex-fill flex-wrap col-11">
        <div className="col-md-2 col-12">
          <img style={{ maxHeight: 200 }} src={image} alt={imageAlt} className="w-100" />
        </div>
        <div className={clsx('mt-4 mt-md-0 col-md-9')}>
          <div className="ms-3">
            <div className="text-start">{description}</div>
            <div className="mt-3 mb-0 text-md-start text-center">
              <span className="fw-semi-bold d-block d-md-inline">Jesteś zainteresowany ofertą? </span>
              <a href={ecommerceLink} target="_blank" className="text-primary text-decoration-none fw-semi-bold">
                Kup teraz w E-Sklepie
              </a>
              {' lub '}
              <a href="tel:+48696116767" className="text-decoration-none text-darker-gray text-nowrap">
                zadzwoń: 696 11 67 67
              </a>
            </div>
          </div>
        </div>
        <i
          className={clsx(
            'font-size-xxl d-block ms-md-auto col-1 mt-4 mt-md-0',
            isCollapsed ? 'uil-angle-up' : 'uil-angle-down',
          )}
        />
      </div>
    </button>

    <Collapse in={isCollapsed}>
      <div>{children}</div>
    </Collapse>
  </div>
)
