import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { CustomFormGroup } from '@components/controls/form-controls/custom-form-group'

interface Props {
  isLoading: boolean
  inputName: string
  onCodeAdd: () => void
  onClose: () => void
}

export const SummaryBoxCodeInput = ({ isLoading, inputName, onCodeAdd, onClose }: Props): JSX.Element => (
  <div className="summary-box__codes__edit mb-2">
    <IconWithText
      iconClassName="uil-tag-alt me-2 font-size-xl lh-initial align-self-start mt-2"
      textClassName="w-100"
      text={
        <div className="position-relative">
          <CustomFormGroup
            isLoading={isLoading}
            inputName={inputName}
            formControlProps={{ className: 'summary-box__codes__edit__input' }}
          />
          <div className="summary-box__codes__edit__actions">
            <button className="uil-check text-success summary-box__codes__edit__action" onClick={onCodeAdd} />
            <button className="uil-times text-danger summary-box__codes__edit__action" onClick={onClose} />
          </div>
        </div>
      }
    />
  </div>
)
