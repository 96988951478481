import * as React from 'react'
import { ApartmentBaseImprovement } from '@modules/accommodation-selection-step/local-selection/apartment-details/apartment-base-improvement'
import { Card } from '@components/card/card'
import { useExpandableItems } from '@hooks/use-expandable-items'
import { ImprovementCode, ResortImprovement } from '@models/reservation'
import { formatPrice } from '@helpers/utils'
import { useSelector } from 'react-redux'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { isFeeding } from '@helpers/improvements'
import { AccommodationType, Resort } from '@models/app-data'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { selectResortDetails } from '@store/selectors/resort-selectors'

interface Props {
  improvements: ResortImprovement[]
  groupName: string
  groupDescription: string
  accommodationType: AccommodationType
  resort: Resort
}

export const ApartmentDetailsImprovements = ({
  improvements,
  groupDescription,
  groupName,
  resort,
  accommodationType,
}: Props): JSX.Element => {
  const reservation = useSelector(selectReservation)
  const resortDetails = useSelector(selectResortDetails)

  const filteredImprovements = React.useMemo(
    () =>
      improvements.some((improvement: ResortImprovement) => isFeeding(improvement)) ? [improvements[0]] : improvements,
    [improvements],
  )

  const { isEveryVisible, length, onShowAll, items } = useExpandableItems(filteredImprovements, 1)

  const isAdded = (improvementCode: ImprovementCode) =>
    reservation.prices.improvements.items.some(
      improvement =>
        improvement.code === improvementCode &&
        reservation.resort_id === resort.id &&
        reservation.accommodation_type_id === accommodationType.id,
    )

  return (
    <ApartmentBaseImprovement title={groupName} description={groupDescription}>
      <>
        <TransitionGroup>
          {items.map((improvement: ResortImprovement) => (
            <CSSTransition timeout={500} key={improvement.id} classNames="apartment-details__improvements__list">
              <div>
                <Card
                  image={<img className="card__image" src={improvement.image.xs} alt={improvement.name} />}
                  cardActions={
                    <strong className="d-flex align-items-center text-muted font-size-sm text-nowrap container-lg my-2">
                      {isFeeding(improvement) && resortDetails.feedings.children_3_4.breakfast_price_brutto ? (
                        <> od {formatPrice(resortDetails.feedings.children_3_4.breakfast_price_brutto)} / posiłek </>
                      ) : (
                        <>{formatPrice(improvement.price_brutto)} / doba</>
                      )}
                    </strong>
                  }
                  isActive={isAdded(improvement.code)}
                  className="mt-4 d-sm-flex flex-sm-row bg-light-blue"
                >
                  <div className="container-lg">
                    <strong className="font-size-xxl text-secondary card__title">
                      {isFeeding(improvement) ? 'Wybierz wyżywienie' : improvement.name}
                    </strong>
                    <span className="text-muted d-block my-2 w-75">{improvement.description}</span>
                  </div>
                </Card>
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>

        {!isEveryVisible && (
          <button
            className="btn bg-transparent mt-4 mt-xl-2 apartment-details__improvements__show-more-button py-2 lh-initial"
            onClick={onShowAll}
          >
            <i className="uil-plus font-size-lg me-1 lh-normal" />
            <span>Pokaż wszystkie ulepszenia</span>
            <span> (+{length})</span>
          </button>
        )}
      </>
    </ApartmentBaseImprovement>
  )
}
