import * as React from 'react'
import { AccommodationTypeAvailableDateRange } from '@models/reservation'
import { OccupiedBannerRecommendation } from '@modules/accommodation-selection-step/local-selection/occupied-banner/occupied-banner-recommendation'
import { useScrollToTop } from '@hooks/use-scroll-to-top'
import { Resort } from '@models/app-data'

interface Props {
  fullMatchDates: AccommodationTypeAvailableDateRange[]
  shorterDates: AccommodationTypeAvailableDateRange[]
  resort: Resort
}

export const OccupiedBannerDateRecommendations = ({
  fullMatchDates,
  shorterDates,
  resort,
}: Props): JSX.Element | null => {
  const { scrollToTop } = useScrollToTop()

  if (!fullMatchDates.length && !shorterDates.length) return null

  return (
    <div>
      <div className="d-flex flex-sm-row flex-column justify-content-center">
        {!!fullMatchDates.length && (
          <OccupiedBannerRecommendation
            resort={resort}
            dates={fullMatchDates}
            text="Ta sama długość pobytu"
            className="mt-3"
          />
        )}

        {!!shorterDates.length && !!fullMatchDates.length && (
          <span className="date-recommendation__separator">lub</span>
        )}

        {!!shorterDates.length && (
          <OccupiedBannerRecommendation
            resort={resort}
            className="mt-sm-3 mt-4"
            dates={shorterDates}
            text="Krótszy pobyt"
          />
        )}
      </div>
      <button className="date-recommendation__scroll-to-top__wrapper" onClick={scrollToTop}>
        <div className="date-recommendation__scroll-to-top">
          Zobacz też ofertę pozostałych resortów w wybranym terminie
          <i className="uil-angle-up date-recommendation__scroll-to-top__icon" />
        </div>
      </button>
    </div>
  )
}
