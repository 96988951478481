import * as React from 'react'
import * as clsx from 'clsx'
import { formatPrice } from '@helpers/utils'

const PADDING_OFFSET = 30

interface Props {
  className: string
  price: string | number
  barLabel?: string
  percentage?: string | number
  percentageIndicator?: React.ReactElement | string
}

export const SummaryPaymentScheduleBar = ({
  price,
  barLabel,
  className,
  percentage,
  percentageIndicator,
}: Props): JSX.Element => {
  const priceRef = React.useRef<HTMLSpanElement>(null)
  const barLabelRef = React.useRef<HTMLSpanElement>(null)
  const percentageIndicatorRef = React.useRef<HTMLSpanElement>(null)

  const [minContainerWidth, setMinContainerWidth] = React.useState('auto')

  const updateContainerWidth = () => {
    const barContainerWidth =
      (priceRef.current?.offsetWidth || 0) + (barLabelRef.current?.offsetWidth || 0) + PADDING_OFFSET

    const percentageIndicatorWidth = percentageIndicatorRef.current?.offsetWidth || 0

    if (!barContainerWidth && !percentageIndicatorWidth) return 'auto'
    setMinContainerWidth(`${Math.max(barContainerWidth, percentageIndicatorWidth)}px`)
  }

  React.useEffect(() => {
    updateContainerWidth()
  }, [priceRef.current, barLabelRef.current, percentageIndicatorRef.current])

  return (
    <div
      className={clsx('payment-schedule__bar', className, {
        'is-filled': percentage == 100,
        'is-empty': price == 0,
      })}
      style={{
        width: `${percentage}%`,
        minWidth: minContainerWidth,
      }}
    >
      {percentageIndicator && (
        <span className="payment-schedule__bar__percentage text-nowrap" ref={percentageIndicatorRef}>
          {percentageIndicator}
        </span>
      )}
      <div className="d-flex align-items-center justify-content-between w-100">
        {price && (
          <strong className="payment-schedule__price me-2" ref={priceRef}>
            {formatPrice(price)}
          </strong>
        )}
        {barLabel && (
          <span ref={barLabelRef} className="font-size-sm text-darker-gray fw-semi-bold text-nowrap">
            {barLabel}
          </span>
        )}
      </div>
    </div>
  )
}
