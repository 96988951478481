import * as React from 'react'
import { Accordion } from 'react-bootstrap'
import * as clsx from 'clsx'
import { useToggle } from '@hooks/use-toggle'

interface Props {
  title: string
  children: JSX.Element | JSX.Element[]
  id: string
  className?: string
  titleWrapperClassName?: string
}

export const CollapsableItem = ({ title, children, id, className, titleWrapperClassName }: Props): JSX.Element => {
  const [isCollapsed, toggleIsCollapsed] = useToggle()

  return (
    <Accordion className={clsx('collapsable-item container-lg px-0', className)}>
      <Accordion.Item eventKey={id}>
        <Accordion.Header onClick={toggleIsCollapsed}>
          <div className={clsx('d-flex justify-content-between align-items-center w-100', titleWrapperClassName)}>
            <span className="collapsable-item__title">{title}</span>
            <span className="pe-2 font-size-sm text-muted lh-initial d-sm-block d-none">
              {isCollapsed ? 'zwiń' : 'rozwiń'}
            </span>
          </div>
        </Accordion.Header>
        <Accordion.Body>{children}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
