import { RootState } from '@store/index'
import { availableSteps, Step } from '@store/reducers/steps-reducer'

export const selectStep = (state: RootState): Step => state.stepsState.step

export const selectNextStep = (state: RootState): Step | undefined =>
  availableSteps.find(el => el.stepNumber === state.stepsState.step.stepNumber + 1)

export const selectPreviousStep = (state: RootState): Step | undefined =>
  availableSteps.find(el => el.stepNumber === state.stepsState.step.stepNumber - 1)

export const selectIsNextStepActive = (state: RootState): boolean => state.stepsState.isNextStepActive
