import * as React from 'react'
import { StepButton } from '@components/steps/step-button'
import { useSelector } from 'react-redux'
import { useSteps } from '@hooks/use-steps'
import { selectIsNextStepActive } from '@store/selectors/steps-selectors'

export const StepButtonsCard = (): JSX.Element => {
  const isNextStepActive = useSelector(selectIsNextStepActive)
  const { hasNext, hasPrev, nextName, prevName, goNext, goPrev, isLoading } = useSteps()

  return (
    <div className="card my-4 container-lg step__bottom-card p-xl-3 bg-shadow">
      {hasPrev && (
        <StepButton
          className="align-self-start flex-row-reverse is-prev"
          onClick={goPrev}
          stepName={prevName}
          text="Poprzedni krok"
          icon="uil-angle-left-b"
        />
      )}

      {hasNext && (
        <StepButton
          className="ms-auto"
          isEnabled={isNextStepActive}
          onClick={goNext}
          stepName={nextName}
          text="Kolejny krok"
          icon="uil-angle-right-b"
          isLoading={isLoading}
        />
      )}
    </div>
  )
}
