import * as React from 'react'
import { CustomFormGroup } from '@components/controls/form-controls/custom-form-group'
import { IconWithText } from '@components/icon-with-text'
import { useSelector } from 'react-redux'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { useAppDispatch } from '@store/index'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useFormRequest } from '@hooks/use-form-request'
import { Reservation } from '@models/reservation'
import { addPromocode, removePromocode } from '@requests/reservation/reservation-requests'
import useDebounce from '@rooks/use-debounce'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'

interface FormInputs {
  code: string
}

interface Props {
  inputClass?: string
}

export const SubscriptionWithBenefitCodeInput = ({ inputClass }: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  const { promocode, accommodation_type_id } = useSelector(selectReservation)

  const methods = useForm<FormInputs>({ defaultValues: { code: promocode?.code || '' } })

  const code = useWatch({ control: methods.control, name: 'code' })
  const { handleAction, isLoading } = useFormRequest(methods.setError)

  const updateValue = (value: string) => {
    methods.setValue('code', value)
  }

  const handleAddCode = async () => {
    methods.clearErrors()

    if (promocode?.code === code) return
    await handleAction<Reservation>(() => dispatch(addPromocode(code, accommodation_type_id)))
  }

  const handleRemoveCode = async () => {
    await handleAction<Reservation>(() => dispatch(removePromocode()))
    updateValue('')
  }

  const debouncedAdd = useDebounce(handleAddCode, 500)

  useDidUpdateEffect(() => {
    if (code) {
      debouncedAdd()
      return
    }

    updateValue('')
  }, [code])

  useDidUpdateEffect(() => {
    updateValue(promocode?.code ?? '')
  }, [promocode])

  return (
    <FormProvider {...methods}>
      <div className="d-flex subscription-with-benefits__code-input__wrapper position-relative">
        <i className="uil-tag-alt font-size-xxl mt-form-input me-2 pt-1 text-muted" />
        <CustomFormGroup
          isLoading={isLoading}
          isSucceed={!!promocode}
          inputName="code"
          formGroupProps={{ className: inputClass }}
          formControlProps={{ placeholder: 'Wpisz swój kod', disabled: !!promocode }}
        />
        {promocode && (
          <button className="btn-unstyled ms-2 mt-3" onClick={handleRemoveCode}>
            <i className="uil-trash-alt text-danger font-size-xxl text-opacity-75" />
          </button>
        )}
      </div>
      {promocode && (
        <IconWithText
          wrapperClassName="mt-1"
          text="Odblokowano dostęp do lokali specjalnych"
          textClassName="font-size-sm fw-normal"
          iconClassName="uil-check text-primary font-size-xxl me-1"
        />
      )}
    </FormProvider>
  )
}
