import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  className?: string
}

export const SendLinkButton = ({ className }: Props): JSX.Element => (
  <button
    className={clsx(
      'w-100 border-0 bg-transparent d-flex align-items-center justify-content-center text-black-olive py-2 font-size-md lh-initial',
      className,
    )}
  >
    <span className="d-block me-2">Wyślij link znajomemu</span>
    <i className="uil-share-alt font-size-lg lh-1" />
  </button>
)
