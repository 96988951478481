import * as React from 'react'
import * as clsx from 'clsx'
import { CardActions } from '@components/card/card-actions'
import { IconWithText } from '@components/icon-with-text'
import { Card } from '@components/card/card'
import { TextWithSpinner } from '@components/loaders/text-with-spinner'

interface Props {
  image: string
  onActionClick: () => void
  cardActionChildren?: React.ReactElement
  title: string
  description: string
  isActive: boolean
  isLoading: boolean
}

export const SummaryPromoImprovementCard = ({
  image,
  onActionClick,
  cardActionChildren,
  title,
  description,
  isActive,
  isLoading,
}: Props): JSX.Element => (
  <Card
    className="flex-sm-row flex-column"
    image={<img className="card__image top-0" src={image} alt="Opłata za opcję rezygnacji" />}
    cardActions={
      <CardActions
        onButtonClick={onActionClick}
        isActive={isActive}
        wrapperClassName="ps-xl-0"
        btnDefaultContent={
          <IconWithText
            text={<TextWithSpinner isLoading={isLoading} text="Chcę skorzystać" wrapperClassName="text-nowrap" />}
            iconClassName={clsx(' me-2 font-size-xxl lh-1', { 'uil-plus': !isLoading })}
          />
        }
        btnClickedContent={
          <IconWithText
            text={<TextWithSpinner isLoading={isLoading} text="Rezygnuję" spinnerColor="muted" />}
            iconClassName={clsx(' me-2 font-size-xxl lh-1', { 'uil-minus': !isLoading })}
          />
        }
      >
        {cardActionChildren}
      </CardActions>
    }
  >
    <div className="ps-3 ps-xl-0">
      <strong className="text-secondary font-size-xl d-block">{title}</strong>
      <span className="text-darker-gray d-block pe-xl-5 my-2">{description}</span>
    </div>
  </Card>
)
