import * as React from 'react'
import { Card } from '@components/card/card'
import { CardActions } from '@components/card/card-actions'
import { IconWithText } from '@components/icon-with-text'
import * as clsx from 'clsx'

interface Props {
  onRightActionClick: () => void
  leftAction?: JSX.Element
  title: string
  image: string
  children?: React.ReactNode
  cardClassName?: string
  labels?: React.ReactNode
}

export const SectionCard = ({
  title,
  image,
  onRightActionClick,
  leftAction,
  children,
  cardClassName,
  labels,
}: Props): JSX.Element => (
  <Card
    image={
      <img
        className="scrollable-section__card__image"
        src={image || require('@assets/images/improvement-image-fallback.webp')}
        alt={title}
      />
    }
    className={clsx('card-improvement', cardClassName)}
    cardActions={
      <CardActions
        onButtonClick={onRightActionClick}
        btnDefaultContent={<IconWithText text="Dodaj" iconClassName="uil-plus me-2 font-size-xxl lh-1" />}
      >
        {leftAction}
      </CardActions>
    }
  >
    <div className="px-3 px-xl-4 py-4 text-muted">
      {labels && <div className="mb-3 d-inline-block">{labels}</div>}
      <h5 className="fw-bold text-start text-secondary font-size-lg">{title}</h5>
      {children}
    </div>
  </Card>
)
