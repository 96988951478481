export default {
  HomeView: {
    test: 'Polski tekst',
  },
  stayDeclination: {
    single: 'doba',
    few: 'doby',
    multiple: 'dób',
  },
  firstStep: {
    resort: {
      chosen: 'Wybrana lokalizacja',
      others: 'Inne lokalizacje',
    },
  },
  general: {
    test: 'test',
  },
}
