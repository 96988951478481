import * as React from 'react'
import { LocalForClientWithBenefitExpandable } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-for-client-with-benefit/local-for-client-with-benefit-expandable'
import { LocalForClientOption } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-for-client-with-benefit/local-for-client-with-benefit-modal'
import { useModal } from '@modals/use-modal'
import { useAppSelector } from '@store/index'
import { selectClientActiveSubscriptionContract, selectClientDetails } from '@store/selectors/client-details-selectors'
import { useSelector } from 'react-redux'
import { GLOBAL_URLS } from '@helpers/consts'

interface Props {
  isCollapsed: boolean
  onCollapse: (option: LocalForClientOption | null) => void
}

export const LocalForClientWithSubscriptionContractOption = ({ onCollapse, isCollapsed }: Props): JSX.Element => {
  const clientDetails = useAppSelector(selectClientDetails)
  const activeSubscriptionContract = useSelector(selectClientActiveSubscriptionContract)

  const [showLoginModal] = useModal('LoginModal', { title: 'Zaloguj się' })

  return (
    <LocalForClientWithBenefitExpandable
      ecommerceLink={GLOBAL_URLS.ECOMMERCE_SUBSCRIPTION_CONTRACT}
      isCollapsed={isCollapsed}
      onCollapse={() => onCollapse(isCollapsed ? null : 'subscription_contract')}
      imageAlt="Subskrypcja Holiday Park & Resort"
      image={require('@assets/images/subscription-with-contract.svg')}
      description={
        <div className="text-md-start text-center">
          <h4 className="text-primary mb-md-0">Subskrypcja</h4>
          <div className="col-md-9">
            Jeżeli jesteś Subskrybentem, udostępnimy dla Ciebie lokale specjalne w terminie{' '}
            <strong>90 dni przed planowanym pobytem!*</strong>
          </div>
        </div>
      }
    >
      <div className="my-3 text-center text-md-start subscription-with-benefits-owner-modal__collapsed-content">
        <small className="d-block mb-3">
          <div>Każda rezerwacja musi trwać 5 dób (nocy), licząc od 1 września, przykładowo:</div>
          <ul className="ps-0 mt-1">
            <li>I rezerwacja: 1.09 - 6.09.2024</li>
            <li>II rezerwacja: 6.09 - 11.09.2024</li>
            <li>itd.</li>
          </ul>
          <div>W miesiącach lipiec oraz sierpień - dowolnie!</div>
        </small>
        {clientDetails ? (
          activeSubscriptionContract ? (
            <span>
              <strong>Super! </strong>
              Twoja subskrypcja jest <strong className="text-success">aktywna</strong>. <br />
              <div className="font-size-sm mt-3 lh-initial">
                Jeśli nadal nie widzisz lokali specjalnych, upewnij się czy planowany pobyt jest w odpowiednich datach.
              </div>
            </span>
          ) : (
            <span>
              Niestety nie masz aktywnej subskrypcji. Zajrzyj do naszego{' '}
              <a
                href={GLOBAL_URLS.ECOMMERCE_SUBSCRIPTION_CONTRACT}
                target="_blank"
                className="text-primary text-decoration-none fw-semi-bold text-nowrap"
              >
                E-Sklepu
              </a>{' '}
              i dowiedz się więcej!
            </span>
          )
        ) : (
          <>
            Posiadasz aktywną Subskrypcję?{' '}
            <span className="text-nowrap">
              <button className="btn-unstyled fw-bold" onClick={showLoginModal}>
                Zaloguj się
              </button>{' '}
              i korzystaj jeszcze wygodniej.
            </span>
          </>
        )}
      </div>
    </LocalForClientWithBenefitExpandable>
  )
}
