import * as React from 'react'
import * as clsx from 'clsx'

interface Props extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'title'> {
  title: string | JSX.Element
  description: string
  isActive: boolean
  children?: React.ReactNode
  contentClassName?: string
}

export const CheckButton = ({
  title,
  description,
  isActive,
  children,
  contentClassName,
  ...props
}: Props): JSX.Element => (
  <button
    {...props}
    className={clsx('d-flex align-items-center check-button', { 'is-active': isActive }, props.className)}
  >
    <div className="check-button__icon-wrapper">
      <i className="uil-check check-button__icon" />
    </div>
    <div className={clsx('check-button__text-wrapper ms-4', contentClassName)}>
      <div>
        <strong className="d-block">{title}</strong>
        <span className="font-size-sm">{description}</span>
      </div>
      {children}
    </div>
  </button>
)
