import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  children: React.ReactNode
  className?: string
}

export const CardBannerWrapper = ({ children, className }: Props): JSX.Element => (
  <div className="card-banner">
    <div className={clsx('card-banner__content', className)}>{children}</div>
  </div>
)
