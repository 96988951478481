import * as React from 'react'
import { Step } from '@components/steps/step'
import { ResortsList } from '@modules/accommodation-selection-step/resort-selection/resorts-list'
import { MainInformation } from '@modules/accommodation-selection-step/main-information'
import { MainInformationMobile } from '@modules/accommodation-selection-step/main-information-mobile'
import { ReservationCalculatePrice } from '@modules/accommodation-selection-step/reservation-calculate-price'
import { useDateSelection } from '@modules/accommodation-selection-step/dates-selection/use-date-selection'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateReservation } from '@store/actions/reservation-actions'
import { selectAppData } from '@store/selectors/app-data-selectors'
import { PromotionBoxMobile } from '@modules/accommodation-selection-step/promotion-box-mobile'

export const AccommodationSelectionStep = (): JSX.Element => {
  const dateSelection = useDateSelection()
  const dispatch = useDispatch()
  const { default_reservation_params } = useSelector(selectAppData)

  const [searchParams] = useSearchParams()

  React.useEffect(() => {
    const reservation = {
      date_from: searchParams.get('date_from') || default_reservation_params.date_from,
      date_to: searchParams.get('date_to') || default_reservation_params.date_to,
    }
    dispatch(updateReservation(reservation))
    dateSelection.onDateChange(reservation)
  }, [default_reservation_params])

  return (
    <div className="rounded bg-shadow">
      <ReservationCalculatePrice />
      <MainInformationMobile dateSelection={dateSelection} />
      <PromotionBoxMobile />
      <div className="d-xl-flex gap-xl-3">
        <div className="w-100">
          <div className="card py-xl-3 border-xl-0 container-lg px-lg-0">
            <Step />
            <MainInformation dateSelection={dateSelection} />
            <ResortsList />
          </div>
        </div>
      </div>
    </div>
  )
}
