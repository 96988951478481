import { Reservation, ReservationImprovement, ResortImprovement } from '@models/reservation'
import { commonObjectPost } from '@requests/basic-requests'
import { useDispatch, useSelector } from 'react-redux'
import { selectAllApartments, selectReservation } from '@store/selectors/reservation-selectors'
import { selectResortDetails } from '@store/selectors/resort-selectors'
import { selectApiUrls } from '@store/selectors/app-data-selectors'
import { updateReservation } from '@store/actions/reservation-actions'
import { NavigationPath } from '@models/routes'
import { updateStep } from '@store/actions/steps-actions'
import { useApiRequest } from '@hooks/use-form-request'
import { useNavigate } from 'react-router-dom'
import { selectNextStep } from '@store/selectors/steps-selectors'

interface Response {
  createUnfinishedBooking: () => Promise<void>
}

export const useCreateUnfinishedBooking = (): Response => {
  const reservation = useSelector(selectReservation)
  const resort = useSelector(selectResortDetails)
  const { reservation_create_unfinished_booking } = useSelector(selectApiUrls)
  const apartments = useSelector(selectAllApartments)

  const { handleAction } = useApiRequest()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const nextStep = useSelector(selectNextStep)

  const createUnfinishedBooking = async () => {
    if (!nextStep) return

    const {
      accommodation_type_id: accommodation_type,
      date_from,
      date_to,
      selected_apartment_id: selected_apartment,
      prices: { improvements },
      promocode,
    } = reservation

    const addedImprovements = improvements.items
      .filter(
        (improvement: ReservationImprovement) =>
          resort.improvements.find(
            (resortImprovement: ResortImprovement) => improvement.code === resortImprovement.code,
          )?.can_add,
      )
      .map((improvement: ReservationImprovement) => ({
        code: improvement.code,
        amount: improvement.amount,
      }))

    const apartment = apartments.find(apartment => apartment.id === reservation.selected_apartment_id)

    const { data } = await handleAction<Reservation>(
      async () =>
        await commonObjectPost(reservation_create_unfinished_booking, {
          resort: resort.id,
          accommodation_type: apartment?.accommodation_type_id ?? accommodation_type,
          date_from,
          date_to,
          selected_apartment,
          improvements: addedImprovements,
          code: promocode?.code,
        }),
    )

    if (data?.token) {
      dispatch(updateReservation({ ...reservation, ...data }))
      navigate(NavigationPath.ImprovementStep.replace(':token', data.token))
      dispatch(updateStep(nextStep))
    }
  }

  return {
    createUnfinishedBooking,
  }
}
