import * as React from 'react'
import * as clsx from 'clsx'
import { SummaryCard } from '@modules/summary-step/summary-card'
import { useFormContext, useWatch } from 'react-hook-form'
import { CustomFormGroup } from '@components/controls/form-controls/custom-form-group'
import { IconWithText } from '@components/icon-with-text'
import { useDispatch, useSelector } from 'react-redux'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { useApiRequest } from '@hooks/use-form-request'
import { TextWithSpinner } from '@components/loaders/text-with-spinner'
import { updateMainReservationData } from '@requests/reservation/reservation-requests'
import { SummaryStepFormInputs } from '@modules/summary-step/summary-step'

export const SummaryRequests = (): JSX.Element => {
  const reservation = useSelector(selectReservation)

  const { handleAction, isLoading } = useApiRequest()
  const { handleAction: removeRequest, isLoading: isRequestRemoving } = useApiRequest()
  const dispatch = useDispatch()

  const { control, setValue } = useFormContext<SummaryStepFormInputs>()

  const requests = useWatch({ control, name: 'requests' })

  React.useEffect(() => {
    setValue('requests', reservation.requests)
  }, [reservation.requests])

  const handleAddRequest = async () => {
    await handleAction(() => dispatch(updateMainReservationData({ requests })))
  }

  const handleRemoveRequest = async () => {
    await removeRequest(() => dispatch(updateMainReservationData({ requests: '' })))
  }

  return (
    <SummaryCard
      title="Specjalne prośby"
      subtitle="Możesz dodać wszelkie specjalne życzenia. Nie możemy zagwarantować ich spełnienia, jednak zrobimy wszystko, co w naszej mocy, by sprostać Twoim wymaganiom."
    >
      <div>
        <CustomFormGroup inputName="requests" formControlProps={{ placeholder: 'Proszę o...', as: 'textarea' }} />
        <div className="d-inline-flex">
          <TextWithSpinner
            isLoading={isLoading}
            wrapperClassName="text-darker-gray justify-content-start mt-2 flex-row-reverse me-4"
            spinnerColor="muted"
            text={
              <IconWithText
                wrapperClassName={clsx('text-darker-gray', { 'opacity-50 pe-none': !requests || isLoading })}
                text={!reservation.requests ? 'Dodaj specjalną prośbę' : 'Aktualizuj prośbę'}
                textClassName="lh-initial"
                iconClassName="uil-plus me-2 font-size-lg"
                onClick={handleAddRequest}
              />
            }
          />
          {reservation.requests && (
            <TextWithSpinner
              isLoading={isRequestRemoving}
              wrapperClassName="text-darker-gray justify-content-start mt-2 flex-row-reverse"
              spinnerColor="muted"
              loadingText="Usuwanie"
              text={
                <IconWithText text="Usuń" iconClassName="uil-minus me-2 font-size-lg" onClick={handleRemoveRequest} />
              }
            />
          )}
        </div>
      </div>
    </SummaryCard>
  )
}
