import * as React from 'react'
import { OverlaySelectionContainer } from '@modules/accommodation-selection-step/overlay-selection-container'
import DatesSelectionBlock from '@modules/accommodation-selection-step/dates-selection/dates-selection-block'
import { DatesSelection } from '@modules/accommodation-selection-step/dates-selection/dates-selection'
import { DateSelectionParams } from '@modules/accommodation-selection-step/dates-selection/use-date-selection'
import { useSelector } from 'react-redux'
import { selectIsDateRangeVisible, toggleDateRangeSelectionVisibility } from '@store/slices/date-range-slice'
import { useAppDispatch } from '@store/index'

interface Props {
  dateSelection: DateSelectionParams
}

export const MainInformation = ({ dateSelection }: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  const isDateSelectionVisible = useSelector(selectIsDateRangeVisible)

  const toggleDateChangeVisibility = () => {
    dispatch(toggleDateRangeSelectionVisibility())
  }

  return (
    <div className="d-none d-xl-flex px-xl-4 align-items-center">
      <h3 className="text-primary text-center fw-bold text-xl-start mb-0 py-4 w-25 border-end">Wybierz ośrodek:</h3>
      <OverlaySelectionContainer
        containerClassName="w-75 gap-5 p-3 cursor-pointer position-relative align-self-end"
        notShowedClassName=""
        isShowedClassName="date-range__desktop-visible"
        isShowed={isDateSelectionVisible}
        toggle={toggleDateChangeVisibility}
        header={<DatesSelectionBlock onClick={toggleDateChangeVisibility} />}
      >
        <DatesSelection
          isModalVersion={false}
          toggleDateChangeVisibility={toggleDateChangeVisibility}
          dateSelection={dateSelection}
        />
      </OverlaySelectionContainer>
    </div>
  )
}
