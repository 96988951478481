import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useApiRequest } from '@hooks/use-form-request'
import { ResortImprovement } from '@models/reservation'
import { addReservationImprovement } from '@requests/reservation/improvement-requests'
import { BaseModalProps } from '@modals/types'
import { ModalActions } from '@modals/modal-actions'
import { ImprovementModalWrapper } from '@modules/improvement-step/improvement-modal-wrapper'
import { SectionSummaryPriceRow } from '@modules/improvement-step/improvements-sections/section-summary-price-row'

interface Props extends BaseModalProps {
  title: string
  resortImprovement: ResortImprovement
}

export const StayOptionDetailsModal = ({ toggleIsVisible, resortImprovement }: Props): JSX.Element => {
  const dispatch = useDispatch()
  const { handleAction, isLoading } = useApiRequest()

  const handleAddImprovement = async () => {
    await handleAction(() => dispatch(addReservationImprovement([{ code: resortImprovement.code }])))
    toggleIsVisible()
  }

  return (
    <>
      <ImprovementModalWrapper contentTitle={resortImprovement.name} image={resortImprovement.image.sm}>
        <SectionSummaryPriceRow text="Koszt ulepszenia łącznie:" price={resortImprovement.price_brutto} />
      </ImprovementModalWrapper>
      <ModalActions
        onDecline={toggleIsVisible}
        onSubmit={handleAddImprovement}
        isLoading={isLoading}
        submitIcon="uil-check"
      />
    </>
  )
}
