import { AvailableRule, AvailableRuleKeyword } from '@models/app-data'
import { useSelector } from 'react-redux'
import { selectAppData } from '@store/selectors/app-data-selectors'
import { selectReservation } from '@store/selectors/reservation-selectors'

const DEFAULT_RULES = ['main_rules', 'privacy_policy_rules']

export const useRules = (): Partial<Record<AvailableRuleKeyword, AvailableRule | null>> => {
  const { rules } = useSelector(selectAppData)
  const reservationDetails = useSelector(selectReservation)

  const rulesKeywords = reservationDetails.rules.length
    ? reservationDetails.rules.map(rule => rule.keyword)
    : DEFAULT_RULES

  return rules.reduce(
    (cum, curr) => (rulesKeywords.includes(curr.keyword) ? { ...cum, [curr.keyword]: curr } : cum),
    {},
  )
}
