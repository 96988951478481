import * as React from 'react'
import * as clsx from 'clsx'
import { useToggle } from '@hooks/use-toggle'

export type MapLocalShapeKind = 'available' | 'occupied' | 'subscription_with_benefit' | 'construction' | 'preparation'

interface Props {
  variants: MapLocalShapeKind[]
}

export const LocalSelectionMapModalLegend = ({ variants }: Props): JSX.Element => {
  const [isVisible, toggleIsVisible] = useToggle(true)

  return (
    <button
      className={clsx('btn-unstyled local-selection-modal__map__legend', { 'is-visible': isVisible })}
      onClick={toggleIsVisible}
    >
      <div className="local-selection-modal__map__legend__icon">
        <i className="uil-map text-white font-size-xxl lh-1" />
      </div>
      <i className="uil-times lh-1 local-selection-modal__map__legend__close" />
      <div>
        {variants.map(variant => (
          <LegendItem key={variant} variant={variant} />
        ))}
      </div>
    </button>
  )
}

const LegendItem = ({ variant }: { variant: MapLocalShapeKind }) => {
  const description = {
    available: 'Dostępny',
    occupied: 'Niedostępny',
    subscription_with_benefit: 'Oferta specjalna',
    construction: 'W budowie',
    preparation: 'W przygotowaniu',
  }[variant]

  return (
    <div className="d-flex mb-1 local-selection-modal__map__legend__variant__container">
      <div className={`me-2 local-selection-modal__map__legend__variant is-${variant}`} />
      <span className="font-size-sm fw-semi-bold text-nowrap">{description}</span>
    </div>
  )
}
