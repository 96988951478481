import * as React from 'react'
import { ModalWithImageWrapper } from '@modals/modal-with-image-wrapper'
import { BaseModalProps } from '@modals/types'
import { CustomFormGroup } from '@components/controls/form-controls/custom-form-group'
import { PasswordFormGroup } from '@components/controls/form-controls/password-form-group'
import { CLIENT_APP_URLS } from '@helpers/consts'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { IconWithText } from '@components/icon-with-text'
import { useDispatch } from 'react-redux'
import { useFormRequest } from '@hooks/use-form-request'
import { TextWithSpinner } from '@components/loaders/text-with-spinner'
import { loginToAccount } from '@requests/account-requests'
import { updateMainReservationData } from '@requests/reservation/reservation-requests'
import { Reservation } from '@models/reservation'
import { ClientDetails } from '@models/app-data'

interface FormInputs {
  email: string
  password: string
}

export const LoginModal = ({ toggleIsVisible }: BaseModalProps): JSX.Element => {
  const dispatch = useDispatch()
  const methods = useForm<FormInputs>({
    defaultValues: { email: '', password: '' },
  })

  const { password, email } = useWatch({ control: methods.control })

  const { handleAction, isLoading } = useFormRequest(methods.setError)

  const handleLogin = async () => {
    methods.clearErrors('password')
    if (!email || !password) return

    const { isResolved, error, data } = await handleAction<{ client_details: ClientDetails }>(() =>
      dispatch(loginToAccount({ password, username: email })),
    )

    if (data?.client_details) {
      dispatch(updateMainReservationData(getClientReservationData(data.client_details)))
    }

    if (isResolved) {
      toggleIsVisible()
    }

    if (error && error.response?.status === 403) {
      methods.setError('password', { message: 'Nieprawidłowe dane' })
    }
  }

  const handleRedirect = (url: string) => {
    setTimeout(() => {
      window.open(url, '_blank')
    }, 500)
  }

  return (
    <ModalWithImageWrapper>
      <div className="px-xl-5 py-4 container-lg">
        <p className="text-darker-gray">
          <strong>Zaloguj się </strong> lub <strong>utwórz konto</strong> i ciesz się nowymi możliwościami w{' '}
          <span className="text-nowrap">Holiday Park & Resort</span>.
        </p>
        <p className="fw-bold text-primary">Pamiętaj - z subskrypcją możesz więcej!</p>

        <FormProvider {...methods}>
          <CustomFormGroup
            inputName="email"
            formControlProps={{
              type: 'email',
              placeholder: 'email@holidaypark.pl',
            }}
            label="Wpisz swój adres e-mail:*"
          />

          <PasswordFormGroup inputName="password" label="Wpisz hasło do konta:*" />

          <button
            className="d-block ms-auto text-secondary cursor-pointer btn-unstyled fw-bold mt-2"
            onClick={() => handleRedirect(CLIENT_APP_URLS.RESET_PASSWORD)}
          >
            Nie pamiętam hasła
          </button>

          <button
            className="d-block cursor-pointer btn btn-primary fw-bold text-white btn-thin w-100 mt-4"
            onClick={handleLogin}
            disabled={!password || !email || isLoading}
          >
            <TextWithSpinner
              isLoading={isLoading}
              loadingText="Proszę czekać"
              wrapperClassName="align-items-center d-flex"
              text="Zaloguj się"
            />
          </button>

          <button
            className="cursor-pointer btn-unstyled fw-bold mt-4 mx-auto d-block text-darker-gray"
            onClick={() => handleRedirect(CLIENT_APP_URLS.ACCOUNT_CREATE)}
          >
            <IconWithText
              text="Nie posiadasz konta? Utwórz je teraz"
              textClassName="lh-0"
              iconClassName="uil-user me-1 font-size-xxl lh-0"
            />
          </button>
        </FormProvider>
      </div>
    </ModalWithImageWrapper>
  )
}

const getClientReservationData = (clientDetails: ClientDetails): Partial<Reservation> => {
  const {
    postcode,
    street,
    city,
    country,
    invoice,
    invoice_nip,
    invoice_company,
    invoice_city,
    invoice_postcode,
    invoice_street,
  } = clientDetails.profile

  return {
    name: `${clientDetails.first_name} ${clientDetails.last_name}`,
    email: clientDetails.email,
    ...(clientDetails.phone && { phone: clientDetails.phone }),
    ...(postcode && { postcode }),
    ...(street && { street }),
    ...(city && { city }),
    ...(country && { country }),
    ...(invoice && { invoice }),
    ...(invoice_nip && { invoice_nip }),
    ...(invoice_company && { invoice_company }),
    ...(invoice_city && { invoice_city }),
    ...(invoice_postcode && { invoice_postcode }),
    ...(invoice_street && { invoice_street }),
  }
}
