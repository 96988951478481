import * as React from 'react'
import * as clsx from 'clsx'

interface SingleItemProps {
  icon: string
  iconClassName?: string
  iconChildren?: JSX.Element
  children: JSX.Element | JSX.Element[]
  className?: string
}

export const ReservationOperationsItem = ({
  icon,
  children,
  className,
  iconClassName,
  iconChildren,
}: SingleItemProps): JSX.Element => (
  <div className={clsx('d-flex lh-1', className)}>
    <i className={clsx('me-2 text-primary font-size-xxl', icon, iconClassName)}>{iconChildren}</i>
    {children}
  </div>
)
