import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useToggle } from '@hooks/use-toggle'
import * as clsx from 'clsx'
import { CLIENT_APP_URLS } from '@helpers/consts'
import { useModal } from '@modals/use-modal'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/selectors/client-details-selectors'
import { Icon } from '@components/icon'
import { selectModals } from '@store/selectors/modal-selectors'

export const LoginBanner = (): JSX.Element | null => {
  const [showLoginModal] = useModal('LoginModal', { title: 'Rezerwujesz pobyt?' })
  const [isHidden, toggleIsHidden] = useToggle(false)

  const client = useSelector(selectClientDetails)
  const modals = useSelector(selectModals)

  const isLoginModalOpen = modals.find(modal => modal.modal === 'LoginModal' && modal.isOpen)

  React.useEffect(() => {
    if (isLoginModalOpen && !isHidden) toggleIsHidden()
  }, [isLoginModalOpen])

  if (client) return null

  return (
    <div
      className={clsx('bg-white border-top position-fixed bottom-0 w-100 z-index-5 py-4 login-banner', {
        'is-hidden': isHidden,
      })}
    >
      <div className="container d-flex align-items-center ps-xl-5 position-relative flex-wrap">
        <div className="d-flex align-items-center">
          <Icon icon="sun" style={{ height: 70, width: 70, minWidth: 70 }} />
          <div className="mx-xl-5 mx-3">
            <strong className="text-secondary font-size-xxl">Rezerwujesz pobyt?</strong>
            <p className="text-darker-gray mt-2">
              <strong>Zaloguj się</strong> lub <strong>utwórz konto</strong> i ciesz się nowymi możliwościami w{' '}
              <span className="text-nowrap">Holiday Park & Resort.</span> <br />
              <strong className="text-primary">Pamiętaj - z subskrypcją możesz więcej!</strong>
            </p>
          </div>
        </div>

        <div className="d-flex flex-wrap align-items-center ms-xl-5 col-xl-auto col-12 mt-3 mt-xl-0">
          <button
            className="btn btn-dark-grey-gradient text-white btn-thin px-5 w-responsiveness-btn mb-3 mb-xl-0"
            onClick={showLoginModal}
          >
            <IconWithText
              text="Zaloguj się"
              iconClassName="uil-user me-2 font-size-xxl"
              textClassName="lh-0 font-size-xl"
              wrapperClassName="justify-content-center"
            />
          </button>
          <span className="mx-3 text-darker-gray d-none d-xl-block">lub</span>
          <button className="btn btn-outline-light border text-white btn-thin px-5 w-responsiveness-btn">
            <a href={CLIENT_APP_URLS.ACCOUNT_CREATE} target="_blank" className="text-decoration-none">
              <strong className="text-muted font-size-xl">Utwórz konto</strong>
            </a>
          </button>
        </div>
        <button className="login-banner__close" onClick={toggleIsHidden}>
          <i className="uil-times text-white" />
        </button>
      </div>
    </div>
  )
}
