import { StepsActionsTypes, StepsActionTypesKeys } from '@store/action-types/steps-action-types'
import { NavigationPath } from '@models/routes'

export const availableSteps = [
  { stepNumber: 1, name: 'Wybór lokalu', url: NavigationPath.AccommodationSelectionStep },
  { stepNumber: 2, name: 'Wybór ulepszeń', url: NavigationPath.ImprovementStep },
  { stepNumber: 3, name: 'Podsumowanie i płatności', url: NavigationPath.SummaryStep },
]

export interface Step {
  name: string
  stepNumber: number
  url: string
}

export interface StepsState {
  step: Step
  isNextStepActive: boolean
}

const initialState: StepsState = {
  step: availableSteps[0],
  isNextStepActive: true,
}

const stepsState = (state: StepsState = initialState, action: StepsActionsTypes): StepsState => {
  switch (action.type) {
    case StepsActionTypesKeys.CHANGE_STEP:
      return { ...state, step: { ...action.payload.step } }
    case StepsActionTypesKeys.SET_NEXT_STEP_ACTIVE:
      return { ...state, isNextStepActive: true }
    case StepsActionTypesKeys.SET_NEXT_STEP_INACTIVE:
      return { ...state, isNextStepActive: false }
    default:
      return state
  }
}

export default stepsState
