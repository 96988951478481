import { ImprovementPriceCalculationResponse } from '@requests/reservation/reservation-request-models'
import * as React from 'react'
import { useApiRequest } from '@hooks/use-form-request'
import { useSelector } from 'react-redux'
import { selectReservationUrls } from '@store/selectors/reservation-selectors'
import { ResortImprovement } from '@models/reservation'
import { commonObjectPost } from '@requests/basic-requests'

export interface ImprovementPriceCalculation {
  improvementCalculation: ImprovementPriceCalculationResponse | null
  isLoading: boolean
  calculationErrorOccurred: boolean
}

interface Props {
  amount: number
  resortImprovement: ResortImprovement
  improvementId: number | undefined
}

export const useImprovementPriceCalculation = ({
  amount,
  resortImprovement,
  improvementId,
}: Props): ImprovementPriceCalculation => {
  const [improvementCalculation, setImprovementCalculation] =
    React.useState<ImprovementPriceCalculationResponse | null>(null)
  const { improvement_calculator } = useSelector(selectReservationUrls)
  const [calculationErrorOccurred, setCalculationErrorOccurred] = React.useState(false)

  const { handleAction, isLoading } = useApiRequest()

  React.useEffect(() => {
    calculatePrices()
  }, [improvement_calculator, amount, resortImprovement])

  const calculatePrices = async () => {
    if (!improvement_calculator) return
    if (calculationErrorOccurred) setCalculationErrorOccurred(false)

    const payload = { code: resortImprovement.code, amount, ...(improvementId && { id: improvementId }) }
    const { data, error } = await handleAction<ImprovementPriceCalculationResponse>(() =>
      commonObjectPost(improvement_calculator, [payload]),
    )

    if (error) {
      setCalculationErrorOccurred(true)
      return
    }

    if (data) setImprovementCalculation(data)
  }

  return { improvementCalculation, isLoading, calculationErrorOccurred }
}
