import * as React from 'react'
import { CustomFormGroup } from '@components/controls/form-controls/custom-form-group'
import { SaveButton } from '@components/controls/save-button'
import { IconWithText } from '@components/icon-with-text'
import { useFormContext, useWatch } from 'react-hook-form'
import { SummaryStepFormInputs, summaryStepInputLayout } from '@modules/summary-step/summary-step'
import { useDispatch, useSelector } from 'react-redux'
import { useFormRequest } from '@hooks/use-form-request'
import { updateMainReservationData } from '@requests/reservation/reservation-requests'
import { selectReservation } from '@store/selectors/reservation-selectors'

const invoiceFields: Partial<keyof SummaryStepFormInputs>[] = [
  'invoice_city',
  'invoice_nip',
  'invoice_company',
  'invoice_postcode',
  'invoice_street',
  'invoice_type',
]

interface Props {
  onSave: () => void
  onCancel: () => void
}

export const SummaryInvoiceEditForm = ({ onSave, onCancel }: Props): JSX.Element => {
  const { getValues, setValue, control, setError, clearErrors } = useFormContext<SummaryStepFormInputs>()

  const reservation = useSelector(selectReservation)

  const [taxNumber, invoiceType] = useWatch({ control, name: ['invoice_nip', 'invoice_type'] })

  const { handleAction, isLoading } = useFormRequest(setError)

  const dispatch = useDispatch()

  const handleSave = async () => {
    const {
      invoice_city,
      invoice_nip,
      invoice_company,
      invoice_postcode,
      invoice_street,
      invoice,
      invoice_country,
      invoice_type,
    } = getValues()
    clearErrors()

    const { isResolved } = await handleAction(() =>
      dispatch(
        updateMainReservationData({
          invoice,
          invoice_city,
          invoice_company,
          invoice_nip,
          invoice_postcode,
          invoice_street,
          invoice_type,
          invoice_country: invoice_country.value,
        }),
      ),
    )
    if (!isResolved) return
    onSave()
  }

  const isEachInvoiceDataAlreadyFilled = invoiceFields.every(field => reservation[field])
  const isCompanyInvoice = invoiceType === 'company'

  const handleCancel = () => {
    invoiceFields.forEach((field: keyof SummaryStepFormInputs) => {
      setValue(field, reservation[field])
    })

    onCancel()
  }

  return (
    <div className="col-md-10">
      <div className="d-flex text-darker-gray mt-3 mb-n1">
        <span className="fw-semi-bold col-md-6 d-none d-xl-block font-size-lg">
          {isCompanyInvoice ? 'Informacje o firmie:' : 'Dane osobowe'}
        </span>
        <span className="col-md-6">
          {isCompanyInvoice ? 'Wprowadź prawidłowe dane firmy' : 'Wprowadź swoje dane osobowe'}
        </span>
      </div>
      <CustomFormGroup
        inputName="invoice_company"
        formControlProps={{ placeholder: isCompanyInvoice ? 'Nazwa Firmy' : 'Imię nazwisko' }}
        label={isCompanyInvoice ? 'Nazwa firmy:' : 'Imię i nazwisko:'}
        {...summaryStepInputLayout}
      />
      <CustomFormGroup
        inputName="invoice_street"
        label={isCompanyInvoice ? 'Adres firmy:' : 'Adres:'}
        formControlProps={{ placeholder: 'Ulica i numer lokalu' }}
        {...summaryStepInputLayout}
      />
      <div className="col-md-6 col-12 d-flex ms-auto">
        <CustomFormGroup
          inputName="invoice_postcode"
          formGroupProps={{ className: 'col-4 ' }}
          formControlProps={{ placeholder: '__-___' }}
        />
        <CustomFormGroup
          inputName="invoice_city"
          formGroupProps={{ className: 'col-8' }}
          formControlProps={{ placeholder: 'Miejscowość' }}
        />
      </div>
      <div className="d-flex flex-md-row flex-column gap-3 col-md-6 col-12 ms-auto mt-3">
        <SaveButton
          className="bg-secondary text-white ps-3 pe-4"
          onClick={handleSave}
          disabled={!taxNumber}
          isLoading={isLoading}
        />
        {isEachInvoiceDataAlreadyFilled && (
          <IconWithText
            text="Anuluj"
            iconClassName="uil-times me-2 font-size-xxl"
            wrapperClassName="text-darker-gray cursor-pointer justify-content-center"
            onClick={handleCancel}
          />
        )}
      </div>
    </div>
  )
}
