import * as React from 'react'
import { Feeding, ReservationImprovement } from '@models/reservation'
import { BaseModalProps } from '@modals/types'
import { FeedingDetailsModalGuestsStep } from '@modules/improvement-step/improvements-sections/kinds/feedings/feeding-details-modal-guests-step'
import { FeedingDetailsModalSelection } from '@modules/improvement-step/improvements-sections/kinds/feedings/feeding-details-modal-selection'

interface Props extends BaseModalProps {
  feedingImprovement: ReservationImprovement | Feeding | null | undefined
}

export const FeedingDetailsModal = ({ toggleIsVisible }: Props): JSX.Element => {
  const [step, setStep] = React.useState(1)

  const handleStepChange = (newStep: number) => () => {
    setStep(newStep)
  }

  if (step === 1)
    return (
      <FeedingDetailsModalGuestsStep onModalClose={toggleIsVisible} onStepChange={handleStepChange(2)} step={step} />
    )

  return <FeedingDetailsModalSelection onModalClose={toggleIsVisible} onStepChange={handleStepChange(1)} step={step} />
}
