import * as React from 'react'
import { GLOBAL_URLS } from '@helpers/consts'
import * as clsx from 'clsx'

export const EcommerceBanner = (): JSX.Element => {
  const [isHovered, setIsHovered] = React.useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handelMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <a
      href={GLOBAL_URLS.ECOMMERCE}
      target="_blank"
      className="ecommerce__banner row mx-0"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handelMouseLeave}
    >
      <div className="col-8 z-index-1">
        <strong className="font-size-xl">E-Sklep Holiday</strong>
        <div className={clsx('ecommerce__banner__text-hovered', { 'is-visible': isHovered })}>
          <span className="d-block">Otwórz e-sklep</span>
          <span>w nowej karcie</span>
        </div>

        <div className={clsx('ecommerce__banner__text-initial', { 'is-visible': !isHovered })}>
          Sprawdź najlepsze okazje w jednym miejscu
        </div>
      </div>

      <div className="col-4 text-center">
        <div className={clsx('ecommerce__banner__icon-hovered', { 'is-visible': isHovered })}>
          <i className="uil-arrow-up-right ecommerce__banner__icon" />
        </div>

        <div className={clsx('ecommerce__banner__icon-initial', { 'is-visible': !isHovered })}>
          <i className="uil-shopping-bag ecommerce__banner__icon" />
          <i className="uil-shopping-bag ecommerce__banner__icon-left" />
          <i className="uil-shopping-bag ecommerce__banner__icon-right" />
        </div>
      </div>

      <img
        src={require('@assets/images/ecommerce-banner.webp')}
        alt="E-Sklep Holiday"
        className="ecommerce__banner__image"
      />
    </a>
  )
}
