import * as React from 'react'

type ToggleVisibility = [boolean, () => void]

export const useToggle = (defaultValue = false): ToggleVisibility => {
  const [isActive, setIsActive] = React.useState(defaultValue)

  const toggleState = React.useCallback(() => {
    setIsActive(state => !state)
  }, [setIsActive])

  return [isActive, toggleState]
}
