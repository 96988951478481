import * as React from 'react'

interface Props {
  title: string
  description: string
  children: JSX.Element | JSX.Element[]
}

export const ApartmentBaseImprovement = ({ title, description, children }: Props): JSX.Element => (
  <div className="border-top py-3">
    <div className="container-lg">
      <strong className="text-primary">{title}</strong>
      <span className="text-muted d-block my-2">{description}</span>
      {children}
    </div>
  </div>
)
