import * as React from 'react'
import * as clsx from 'clsx'
import { Icon, Icons } from '@components/icon'

interface PaymentProviders {
  icon: Icons
  alt: string
}

const paymentProviders: PaymentProviders[] = [
  { icon: 'przelewy24', alt: 'Przelewy 24' },
  { icon: 'mbankraty', alt: 'Mbank raty' },
  { icon: 'santanderraty', alt: 'Santander raty' },
  { icon: 'bnpraty', alt: 'BNP raty' },
  { icon: 'aliorraty', alt: 'Alior raty' },
  { icon: 'paypo', alt: 'Paypo' },
  { icon: 'blik', alt: 'Blik' },
  { icon: 'applepay', alt: 'Apple pay' },
  { icon: 'googlepay', alt: 'Google pay' },
  { icon: 'visa', alt: 'Visa' },
  { icon: 'mastercard', alt: 'Mastercard' },
]

interface Props {
  wrapperClassName?: string
  iconsWrapperClassName?: string
}

export const FooterPaymentProviders = ({ wrapperClassName, iconsWrapperClassName }: Props): JSX.Element => (
  <div className={clsx(wrapperClassName)}>
    <div className={clsx('d-flex flex-wrap py-3 container-lg', iconsWrapperClassName)}>
      {paymentProviders.map((provider: PaymentProviders) => (
        <Icon icon={provider.icon} key={provider.icon} className="mx-2" style={{ height: '35px' }} />
      ))}
    </div>
  </div>
)
