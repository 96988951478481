import { useSelector } from 'react-redux'
import { selectCompanyInfo } from '@store/selectors/app-data-selectors'
import { formatDate, getHoursFromDate, parseISODate } from '@helpers/date-formatter'
import { isAfter, isBefore, isWeekend, setHours } from 'date-fns'
import { getCurrentLanguage } from '@i18n/i18n'

const initialCallCenterData = {
  saturdayFrom: '2022-07-16T09:00:00',
  saturdayTo: '2022-07-16T18:00:00',
  sundayFrom: '2022-07-17T09:00:00',
  sundayTo: '2022-07-17T18:00:00',
  todayFromHour: '08:00',
  todayToHour: '19:00',
  isOpen:
    isAfter(new Date(), parseISODate(setHours(new Date(), 8))) &&
    isBefore(new Date(), parseISODate(setHours(new Date(), 19))),
}

interface CallCenterData {
  isOpen: boolean
  phoneNumber: string
  trimmedPhoneNumber: string
  todayFromHour: string
  todayToHour: string
  saturdayFrom: string
  saturdayTo: string
  sundayFrom: string
  sundayTo: string
  email: string
}

export const useCallCenterData = (): CallCenterData => {
  const companyInfo = useSelector(selectCompanyInfo)

  const phoneNumber = companyInfo.call_center_phone_number[getCurrentLanguage()]
  const trimmedPhoneNumber = phoneNumber.replace(/\s/g, '')

  const now = formatDate(new Date())

  if (!companyInfo.call_center_working_hours[now]) {
    return {
      phoneNumber,
      trimmedPhoneNumber,
      email: companyInfo.call_center_email,
      ...initialCallCenterData,
    }
  }

  const [todayFromDate, todayToDate] = companyInfo.call_center_working_hours[now]
  const [todayFromHour, todayToHour] = [getHoursFromDate(todayFromDate), getHoursFromDate(todayToDate)]

  const [[saturdayFrom, saturdayTo], [sundayFrom, sundayTo]] = Object.values(
    companyInfo.call_center_working_hours,
  ).filter(value => {
    return isWeekend(parseISODate(value[0]))
  })

  const email = companyInfo.call_center_email

  const isOpen = isAfter(new Date(), parseISODate(todayFromDate)) && isBefore(new Date(), parseISODate(todayToDate))

  return {
    isOpen,
    phoneNumber,
    todayFromHour,
    todayToHour,
    saturdayFrom,
    saturdayTo,
    sundayFrom,
    sundayTo,
    trimmedPhoneNumber,
    email,
  }
}
