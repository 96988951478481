import { RootState } from '@store/index'
import {
  AccommodationTypeAvailable,
  Apartment,
  BookingCar,
  BookingGuest,
  Feeding,
  GuestGroup,
  PriceItem,
  Reservation,
  ReservationImprovement,
  ReservationPrices,
  ReservationUrls,
  Wallet,
} from '@models/reservation'
import { createSelector } from 'reselect'
import { AccommodationType, Resort } from '@models/app-data'
import { SelectorWithParams } from '@store/selectors/app-data-selectors'

export const selectReservation = (state: RootState): Reservation => state.reservationState.reservation
export const selectReservationUrls = (state: RootState): ReservationUrls => state.reservationState.reservation.urls
export const selectReservationPrices = (state: RootState): ReservationPrices =>
  state.reservationState.reservation.prices

export const selectReservationApartmentId = createSelector(
  selectReservation,
  (reservation: Reservation) => reservation.selected_apartment_id,
)
export const selectAllApartments = (state: RootState): Apartment[] => state.reservationState.apartments

export const selectApartmentsByAccommodationTypeId = createSelector(
  selectAllApartments,
  (_, id) => id,
  (apartments: Apartment[], id: number) => apartments.filter(apartment => apartment.accommodation_type_id === id),
) as SelectorWithParams<Apartment[]>

export const selectReservationResort = createSelector(
  (state: RootState): Reservation => state.reservationState.reservation,
  (state: RootState) => state.appDataState.appData.resorts,
  (reservation: Reservation, resorts: Resort[]) =>
    resorts.find((resort: Resort) => resort.id === reservation.resort_id),
)

export const selectAccommodationTypesAvailable = (state: RootState): AccommodationTypeAvailable[] =>
  state.reservationState.accommodationTypesAvailable

export const selectAccommodationTypeAvailableByIdAndResort = createSelector(
  selectAccommodationTypesAvailable,
  (_, resort: Resort) => resort.id,
  (_, __, ids: number[]) => ids,
  (accommodationTypeAvailable: AccommodationTypeAvailable[], resortId: number, ids: number[]) =>
    accommodationTypeAvailable.filter(
      row => ids.find(id => id === row.accommodation_type_id) && row.resort_id === resortId,
    ),
) as SelectorWithParams<AccommodationTypeAvailable[]>

export const selectGuestGroups = (state: RootState): GuestGroup[] => state.reservationState.reservation.groups
export const selectGuests = (state: RootState): BookingGuest[] => state.reservationState.reservation.guests

export const selectReservationImprovements = (state: RootState): ReservationImprovement[] =>
  state.reservationState.reservation.prices.improvements.items

export const selectReservationFeedings = (state: RootState): PriceItem<Feeding> =>
  state.reservationState.reservation.prices.feeding

export const selectReservationCars = (state: RootState): BookingCar[] => state.reservationState.reservation.cars

export const selectReservationAccommodationType = createSelector(
  selectReservation,
  (state: RootState) => state.appDataState.appData.accommodation_types,
  (reservation: Reservation, accommodationTypes: AccommodationType[]) =>
    accommodationTypes.find(
      (accommodationType: AccommodationType) => accommodationType.id === reservation.accommodation_type_id,
    ),
) as SelectorWithParams<AccommodationType | undefined>

export const selectWallets = (state: RootState): Wallet[] => state.reservationState.reservation.wallets
