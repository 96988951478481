import { AppData } from '@models/app-data'

export enum AppDataActionTypesKeys {
  SET_APP_DATA = 'SET_APP_DATA',
}

export interface SetAppDataAction {
  type: AppDataActionTypesKeys.SET_APP_DATA
  payload: AppData
}

export type AppDataActionsTypes = SetAppDataAction
