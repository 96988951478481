import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@modals/use-modal'
import { useFormContext } from 'react-hook-form'
import { SummaryStepFormInputs } from '@modules/summary-step/summary-step'
import { selectClientDetails } from '@store/selectors/client-details-selectors'
import { selectCountries } from '@store/selectors/app-data-selectors'
import { useSelector } from 'react-redux'
import { useDidUpdateEffect } from 'dates-selection-range'

export const SummaryClientLogin = (): JSX.Element | null => {
  const client = useSelector(selectClientDetails)
  const countries = useSelector(selectCountries)

  const [showLoginModal] = useModal('LoginModal', { title: 'Rezerwujesz pobyt?' })

  const { setValue } = useFormContext<SummaryStepFormInputs>()

  const getCountry = (code: string) => countries.find(country => country.id === code)

  useDidUpdateEffect(() => {
    if (!client) return
    setValue('name', `${client.first_name} ${client.last_name}`)
    setValue('phone', client.phone)
    setValue('email', client.email)
    setValue('postcode', client.profile.postcode)
    setValue('street', client.profile.street)
    setValue('city', client.profile.city)

    const country = getCountry(client.profile.country)
    if (country) setValue('country', { value: country.id, label: country.name })

    if (!client.profile.invoice) return

    setValue('invoice', true)
    setValue('invoice_nip', client.profile.invoice_nip)
    setValue('invoice_company', client.profile.invoice_company)
    setValue('invoice_city', client.profile.invoice_city)
    setValue('invoice_postcode', client.profile.invoice_postcode)
    setValue('invoice_street', client.profile.invoice_street)
  }, [client])

  if (client) return null

  return (
    <div className="col-md-6 px-0 ms-auto mx-0 mt-2 mt-md-0">
      <button className="d-block btn btn-primary text-white text-start justify-self-end w-100" onClick={showLoginModal}>
        <IconWithText
          wrapperClassName="flex-row-reverse justify-content-between"
          iconClassName="uil-user font-size-xxl"
          text={
            <>
              <strong className="d-block font-size-xl">Masz już konto?</strong>
              <span className="fw-semi-bold">Zaloguj się, aby uzupełnić dane i korzystać z benefitów.</span>
            </>
          }
        />
      </button>
    </div>
  )
}
