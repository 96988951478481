import { AxiosError } from 'axios'
import { UseFormSetError } from 'react-hook-form/dist/types/form'
import { Path } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'

interface FieldAs {
  field: string
  newField: string
}

export interface AxiosErrorHandlerConfig<T> {
  excludeFields?: string[]
  nonFieldErrorsAs?: Path<T>
  showGlobalError?: boolean
  fieldsAs?: FieldAs[]
}

export interface CustomError {
  non_field_errors: string[]

  [key: string]: string[]
}

export const useAxiosErrorHandler = <FormInputs extends FieldValues = FieldValues>(): ((...params) => void) => {
  const initialConfiguration: AxiosErrorHandlerConfig<FormInputs> = { excludeFields: [], showGlobalError: true }

  return (
    error: AxiosError<CustomError>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setErrors: UseFormSetError<any>, //  UseFormSetError<FormInputs>, @FIXME
    configuration: AxiosErrorHandlerConfig<FormInputs> = initialConfiguration,
  ) => {
    if (!configuration.excludeFields) {
      configuration.excludeFields = []
    }
    if (configuration.nonFieldErrorsAs) {
      configuration.excludeFields.push('non_field_errors')
    }
    if (error.response?.status === 400) {
      if (error.response.data.non_field_errors && configuration.nonFieldErrorsAs) {
        setErrors(configuration.nonFieldErrorsAs, {
          type: 'server',
          message: error.response.data.non_field_errors.join('. '),
        })
      }

      if (Array.isArray(error.response.data)) {
        Object.values(error.response.data).forEach(errors => {
          Object.keys(errors).map((key: Path<FormInputs>) => {
            if (configuration.excludeFields?.includes(key)) return
            const fieldKey = configuration.fieldsAs?.find(el => el.field === key)?.newField as Path<FormInputs>
            setErrors(fieldKey ?? key, {
              type: 'server',
              message: (errors[key] || []).join('. '),
            })
          })
        })
        return
      }

      Object.keys(error.response.data).map((key: Path<FormInputs>) => {
        if (!configuration.excludeFields?.includes(key)) {
          const message = error.response?.data[key]
          if (message && typeof message[0] === 'object') {
            handleAxiosFormErrorsNestedObject(key, message, setErrors)
          } else {
            setErrors(key, {
              type: 'server',
              message: (message || []).join('. '),
            })
          }
        }
      })
    }
  }
}

const handleAxiosFormErrorsNestedObject = (fieldName, message, setErrors) => {
  message.map((validation, index) => {
    Object.keys(validation).map(key => {
      const newKey = `${fieldName}.${index}.${key}`
      setErrors(newKey, {
        type: 'server',
        message: validation[key].join('. '),
      })
    })
  })
}
