import * as React from 'react'
import * as clsx from 'clsx'
import { FloatingItem } from '@components/floating-item'
import { Spinner } from '@components/loaders/spinner'

interface Props {
  goNext: () => void
  isVisible: boolean
  isLoading: boolean
  nextName: string
}

export const StepFloatingButton = ({ goNext, nextName, isVisible, isLoading }: Props): JSX.Element => (
  <FloatingItem onClick={goNext} className={clsx('floating-item__popping-up', { 'is-visible': isVisible })}>
    <div className="text-center justify-content-center w-100">
      <span className="lh-1 font-size-sm text-white-50">Kolejny krok</span>
      <strong className="d-block text-white lh-1 font-size-xxl">{nextName}</strong>
    </div>

    <Spinner className={clsx('text-primary floating-item__spinner', { 'is-visible': isLoading })} color="white" />
    <i
      className={clsx('uil-angle-right-b text-end ms-auto text-white floating-item__next-icon', {
        'is-visible': !isLoading,
      })}
    />
  </FloatingItem>
)
