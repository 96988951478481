import * as React from 'react'
import { useSelector } from 'react-redux'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { useNavigate, useParams } from 'react-router-dom'
import { useApiRequest } from '@hooks/use-form-request'
import { NavigationPath } from '@models/routes'
import { updateStep } from '@store/actions/steps-actions'
import { availableSteps } from '@store/reducers/steps-reducer'
import { Reservation } from '@models/reservation'
import { getReservationData } from '@requests/reservation/reservation-requests'
import { fetchResortDetails } from '@requests/resort-requests'
import { setNotificationMessage } from '@store/slices/notification-messages-slice'
import { useAppDispatch } from '@store/index'

type Params = 'token'

interface ReservationDataCheckResponse {
  isChecking: boolean
}

export const useReservationDataCheck = (): ReservationDataCheckResponse => {
  const reservation = useSelector(selectReservation)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { handleAction, isLoading } = useApiRequest({ shouldDispatchModalError: false })

  const { token } = useParams<Params>()

  const fetchReservationData = async () => {
    if (!token) return
    const { data, isResolved, error } = await handleAction<Reservation>(() => dispatch(getReservationData(token)))

    if (data) {
      await handleAction(() => dispatch(fetchResortDetails(data.resort_id, data.date_from, data.date_to)))
    }

    if (!isResolved) {
      navigate(NavigationPath.AccommodationSelectionStep)
      dispatch(updateStep(availableSteps[0]))

      if (error.response?.status === 404) {
        dispatch(
          setNotificationMessage({
            title: 'Nieprawidłowa rezerwacja',
            subtitle: null,
            message: 'Rezerwacja nie istnieje.',
          }),
        )
      }
    }

    if (data?.status === 'unfinished_canceled') {
      dispatch(
        setNotificationMessage({
          title: 'Nieaktywna rezerwacja',
          subtitle: null,
          message: 'Rezerwacja została anulowana.',
        }),
      )
    }
  }

  React.useEffect(() => {
    if (reservation.token && reservation.status !== 'unfinished') {
      document.location.href = reservation.urls.sale
    }
  }, [reservation.status])

  React.useEffect(() => {
    if (reservation.token) return
    fetchReservationData()
  }, [reservation.token])

  return { isChecking: isLoading }
}
