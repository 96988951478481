import * as React from 'react'
import DatesSelectionBlock from '@modules/accommodation-selection-step/dates-selection/dates-selection-block'
import { Dispatch, SetStateAction } from 'react'
import { useModal } from '@modals/use-modal'
import { DateSelectionParams } from '@modules/accommodation-selection-step/dates-selection/use-date-selection'
import { DatesSelectionRange } from 'dates-selection-range'
import { useSelector } from 'react-redux'
import { selectAppData, selectSeasons } from '@store/selectors/app-data-selectors'
import { useReservationDataCleanup } from '@hooks/use-reservation-data-cleanup-on-render'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { stayDeclination } from '@helpers/declination'

interface Props {
  isModalVersion: boolean
  toggleDateChangeVisibility?: Dispatch<SetStateAction<boolean>>
  dateSelection: DateSelectionParams
}

export const DatesSelection = ({ isModalVersion, toggleDateChangeVisibility, dateSelection }: Props): JSX.Element => {
  const [showDateSelectionModal] = useModal('DateSelectionModal', { title: 'Data pobytu' })
  const seasons = useSelector(selectSeasons)
  const { max_reservation_days, min_reservation_days } = useSelector(selectAppData)
  const { cleanData } = useReservationDataCleanup()
  const { date_from, date_to } = useSelector(selectReservation)

  const handleModalOpen = () => {
    dateSelection.setRange(true)
    showDateSelectionModal()
  }

  return isModalVersion ? (
    <DatesSelectionBlock onClick={handleModalOpen} />
  ) : (
    <div className="date-range-wrapper" data-testid="date-range-wrapper">
      <DatesSelectionRange
        dateSelection={dateSelection}
        months={2}
        direction="horizontal"
        toggleDateChangeVisibility={toggleDateChangeVisibility}
        seasons={seasons}
        maxReservationDays={max_reservation_days}
        minReservationDays={min_reservation_days}
        onDatesUpdated={cleanData}
        dateFrom={date_from}
        dateTo={date_to}
        getStayDeclination={stayDeclination}
      />
    </div>
  )
}
