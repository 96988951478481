import { CheckButton } from '@components/controls/check-button'
import * as React from 'react'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import * as clsx from 'clsx'
import { FeedingCalculations } from '@requests/reservation/reservation-request-models'

interface Props {
  description: string
  title: string
  isActive: boolean
  onFeedingSelect: () => void
  feedingCalculations: FeedingCalculations | undefined
}

export const FeedingDetailsModalCheck = ({
  description,
  title,
  onFeedingSelect,
  isActive,
  feedingCalculations,
}: Props): JSX.Element | null => {
  if (!feedingCalculations) return null

  const hasDiscount = asDecimal(feedingCalculations?.discount).gt(0)

  return (
    <CheckButton
      title={title}
      description={description}
      isActive={isActive}
      className="w-100 position-relative mb-2"
      onClick={onFeedingSelect}
      contentClassName="flex-row justify-content-between w-100 align-items-center"
    >
      {hasDiscount && (
        <>
          <div className="check-button__discount">
            <strong className="text-white font-size-xxs">-{feedingCalculations?.discount ?? 0}%</strong>
          </div>
          <div className="d-flex flex-column align-items-end pt-3">
            <span className="font-size-sm d-block lh-1">taniej o</span>
            <strong className={clsx('font-size-lg lh-initial', isActive ? 'text-white' : 'text-danger')}>
              {formatPriceShort(feedingCalculations?.discount_price_brutto ?? 0)}
            </strong>
          </div>
        </>
      )}
    </CheckButton>
  )
}
