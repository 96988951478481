import * as React from 'react'
import { selectClientDetails } from '@store/selectors/client-details-selectors'
import { useSelector } from 'react-redux'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@modals/use-modal'
import { CLIENT_APP_URLS } from '@helpers/consts'

export const HeaderUserInfo = (): JSX.Element | null => {
  const client = useSelector(selectClientDetails)

  const text = client ? (
    <a
      href={CLIENT_APP_URLS.MAIN_PAGE}
      className="text-decoration-none d-flex cursor-pointer text-muted"
      target="_blank"
    >
      <span className="font-size-lg fw-semi-bold"> Witaj {client.first_name}!</span>
    </a>
  ) : (
    'zaloguj'
  )

  const [showLoginModal] = useModal('LoginModal', { title: 'Rezerwujesz pobyt?' })

  return (
    <IconWithText
      text={text}
      iconClassName="uil-user me-2 font-size-xl"
      wrapperClassName="text-muted py-3"
      {...(!client && { onClick: showLoginModal })}
    />
  )
}
