import * as React from 'react'
import { Step } from '@components/steps/step'
import { useReservationDataCheck } from '@hooks/use-reservation-data-check'
import { ContentLoader } from '@components/loaders/content-loader'
import { SummaryBoxMobile } from '@modules/aside/summary-box/summary-box-mobile'
import { ScrollableSections } from '@modules/improvement-step/improvements-sections/scrollable/scrollable-sections'

export const ImprovementsStep = (): JSX.Element => {
  const { isChecking } = useReservationDataCheck()

  return (
    <div className="pt-xl-3 bg-white rounded bg-shadow">
      <SummaryBoxMobile wrapperClassName="d-xl-none" />
      <Step />
      <ContentLoader isLoading={isChecking} className="mt-xl-3">
        <ScrollableSections />
      </ContentLoader>
    </div>
  )
}
