import {
  ReservationActionTypesKeys,
  SetAvailableApartments,
  UpdateAccommodationTypeAvailableAction,
  UpdateReservationAction,
} from '@store/action-types/reservation-action-types'
import { AccommodationTypeAvailable, Apartment, Reservation } from '@models/reservation'

export function updateReservation(reservation: Partial<Reservation>): UpdateReservationAction {
  return {
    type: ReservationActionTypesKeys.UPDATE_RESERVATION,
    payload: { reservation },
  }
}

export function updateAccommodationTypeAvailable(
  payload: AccommodationTypeAvailable[],
): UpdateAccommodationTypeAvailableAction {
  return {
    type: ReservationActionTypesKeys.UPDATE_ACCOMMODATION_TYPE_AVAILABLE,
    payload,
  }
}

export function setAvailableApartments(payload: Apartment[]): SetAvailableApartments {
  return {
    type: ReservationActionTypesKeys.SET_AVAILABLE_APARTMENTS,
    payload,
  }
}
