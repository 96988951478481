import DateElement from '@modules/accommodation-selection-step/dates-selection/date-element'
import { stayDeclination } from '@helpers/declination'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { selectReservation } from '@store/selectors/reservation-selectors'
import dateHelper from '@services/date-helper'

interface DatesSelectionBlockProps {
  onClick: () => void
}

const DatesSelectionBlock = ({ onClick }: DatesSelectionBlockProps): JSX.Element => {
  const { date_from, date_to } = useSelector(selectReservation)
  const daysDiff = dateHelper.diffInDays(date_from, date_to)

  return (
    <button className="container-lg d-flex justify-content-between align-items-center btn-unstyled" onClick={onClick}>
      <DateElement title="Data przyjazdu" icon="uil-arrow-down-right" date={date_from} />
      <div className="d-none d-xl-flex align-items-center font-size-xxl mx-5 text-muted">⟶</div>
      <div className="align-self-center order-xl-last bg-light-blue fw-semi-bold rounded px-2 py-1 font-size-sm lh-normal">
        <span className="text-dark">
          {daysDiff} {stayDeclination(daysDiff)}
        </span>
      </div>
      <DateElement date={date_to} title="Data wyjazdu" icon="uil-arrow-up-right" />
    </button>
  )
}

export default DatesSelectionBlock
