import * as React from 'react'
import { Form, FormCheckProps } from 'react-bootstrap'
import * as clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import { FormError } from '@components/controls/form-error'

interface Props extends FormCheckProps {
  indeterminate?: boolean
  name: string
}

export const Checkbox = ({ name, indeterminate, ...props }: Props): JSX.Element => {
  const { register } = useFormContext()
  const inputRef = React.useRef<HTMLInputElement>()

  const { ref, ...rest } = register(name)

  React.useEffect(() => {
    if (inputRef.current && indeterminate !== undefined) {
      inputRef.current.indeterminate = indeterminate
    }
  }, [indeterminate])

  return (
    <>
      <Form.Check
        {...rest}
        {...props}
        ref={(input: HTMLInputElement) => {
          ref(input)
          inputRef.current = input
        }}
        type="checkbox"
        className={clsx('checkbox', { 'is-cursor-pointer': !!props.id }, props.className)}
      />
      <FormError inputName={name} className="mb-3" />
    </>
  )
}
