import * as React from 'react'
import { Gallery } from '@components/gallery'
import { useSelector } from 'react-redux'
import { selectReservationResort } from '@store/selectors/reservation-selectors'
import { useResponsivenessImages } from '@hooks/use-responsiveness-images'
import { SummaryBoxReservationInformation } from '@modules/aside/summary-box/summary-box-reservation-information'
import { SummaryBoxImprovements } from '@modules/aside/summary-box/summary-box-improvements'
import { SummaryBoxCodes } from '@modules/aside/summary-box/summary-box-codes/summary-box-codes'
import { SummaryBoxPrices } from '@modules/aside/summary-box/summary-box-prices'
import { SummaryBoxWarranty } from '@modules/aside/summary-box/summary-box-warranty'
import { selectStep } from '@store/selectors/steps-selectors'

export const SummaryBoxMobileModal = (): JSX.Element => {
  const resortDetails = useSelector(selectReservationResort)
  const step = useSelector(selectStep)

  const images = useResponsivenessImages(resortDetails?.reservation_images)

  return (
    <div className="pt-4 overflow-hidden">
      <Gallery additionalClass="summary-box-gallery--mobile container-lg" items={images} />
      <div className="text-darker-gray pt-4 font-size-xxl">
        <SummaryBoxReservationInformation wrapperClassName="container-lg mb-3" />
        <SummaryBoxImprovements />
        <SummaryBoxWarranty />
        {step.stepNumber > 2 && <SummaryBoxCodes wrapperClassName="container-lg" />}
        <div className="bg-light-blue">
          <SummaryBoxPrices wrapperClassName="text-primary container-lg" />
        </div>
      </div>
    </div>
  )
}
