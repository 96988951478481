import * as React from 'react'
import { CustomFormGroup } from '@components/controls/form-controls/custom-form-group'
import { IconWithText } from '@components/icon-with-text'
import { SummaryStepFormInputs, summaryStepInputLayout } from '@modules/summary-step/summary-step'
import { useDispatch } from 'react-redux'
import useDebounce from '@rooks/use-debounce'
import { useFormContext } from 'react-hook-form'
import { useFormRequest } from '@hooks/use-form-request'
import { updateMainReservationData } from '@requests/reservation/reservation-requests'
import { SummaryClientLogin } from '@modules/summary-step/summary-client-login'
import { CountrySelect } from '@components/controls/country-select'

export const SummaryClientData = (): JSX.Element => {
  const { clearErrors, setValue, setError } = useFormContext<SummaryStepFormInputs>()

  const { handleAction: handleEmailUpdate, isLoading } = useFormRequest(setError)
  const { handleAction: handleNameUpdate, isLoading: isNameUpdating } = useFormRequest(setError)

  const dispatch = useDispatch()

  const handleChange =
    (field, handler) =>
    async ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      clearErrors(field)
      setValue(field, value)
      handler(async () => {
        await dispatch(updateMainReservationData({ [field]: value }))
      })
    }

  const allowOnlyLetters = (inputName: keyof SummaryStepFormInputs) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[^\p{Letter}\p{Mark}\-}\s]+/gu, '')
    setValue(inputName, value)
  }

  const debouncedEmailChange = useDebounce(handleChange('email', handleEmailUpdate), 1000)
  const debouncedNameChange = useDebounce(handleChange('name', handleNameUpdate), 1000)

  return (
    <div className="container-lg px-xl-4 py-4">
      <div className="col-md-10 col-12">
        <strong className="text-primary font-size-xl">Dane osoby rezerwującej</strong>
        <SummaryClientLogin />
        <CustomFormGroup
          isLoading={isNameUpdating}
          inputName="name"
          formControlProps={{
            placeholder: 'np. Andrzej Nowak',
            onChange: e => debouncedNameChange(e),
            onInput: allowOnlyLetters('name'),
          }}
          label="Wpisz imię i nazwisko:"
          {...summaryStepInputLayout}
        />
        <CustomFormGroup
          inputName="street"
          formControlProps={{ placeholder: 'Wpisz ulicę i numer lokalu' }}
          label="Adres zamieszkania:"
          {...summaryStepInputLayout}
        />
        <div className="col-md-6 col-12 d-flex ms-auto">
          <CustomFormGroup
            inputName="postcode"
            formControlProps={{ placeholder: '__-___' }}
            formGroupProps={{ className: 'col-4 ' }}
          />
          <CustomFormGroup
            inputName="city"
            formControlProps={{ placeholder: 'Miejscowość' }}
            formGroupProps={{ className: 'col-8' }}
          />
        </div>
        <div className="col-md-6 ms-auto">
          <CountrySelect
            name="country"
            withLabel
            wrapperProps={{ style: { minWidth: 90 }, className: 'mt-3 btn-tall col-12 col-sm-auto' }}
          />
        </div>
        <CustomFormGroup
          isLoading={isLoading}
          inputName="email"
          formControlProps={{ placeholder: 'adres@email.pl', type: 'email', onChange: e => debouncedEmailChange(e) }}
          label="Wpisz swój adres e-mail:"
          {...summaryStepInputLayout}
        />
        <CustomFormGroup
          inputName="phone"
          formControlProps={{ placeholder: '+48 ___-___-___', type: 'tel' }}
          label="Numer telefonu:"
          {...summaryStepInputLayout}
        />
        <IconWithText
          wrapperClassName="col-md-6 ms-auto col-12 mt-3"
          iconClassName="uil-exclamation-circle text-primary font-size-xl me-2 align-self-start"
          textClassName="fw-normal font-size-md"
          text={
            <span className="text-darker-gray">
              <strong>Pamiętaj!</strong> Sprawdź poprawność adresu e-mail i numeru telefonu, ponieważ wyślemy na nie
              potwierdzenie Twojej rezerwacji.
            </span>
          }
        />
      </div>
    </div>
  )
}
