import { ThunkResult } from '@models/models'
import { Promocode, Reservation } from '@models/reservation'
import {
  SetAvailableApartments,
  UpdateAccommodationTypeAvailableAction,
  UpdateReservationAction,
} from '@store/action-types/reservation-action-types'
import {
  commonObjectDelete,
  commonObjectGet,
  commonObjectPatch,
  commonObjectPost,
  commonObjectPut,
} from '@requests/basic-requests'
import {
  setAvailableApartments,
  updateAccommodationTypeAvailable,
  updateReservation,
} from '@store/actions/reservation-actions'
import {
  CalculateReservationPricePayload,
  CheckEmailResponse,
  FetchAvailableApartmentsPayload,
  FinishReservationRequestPayload,
  RecognizedGuestReservationPayload,
} from '@requests/reservation/reservation-request-models'
import { SetLoaderHiddenAction, SetLoaderVisibleAction } from '@store/action-types/loader-action-types'
import { setLoaderHidden, setLoaderVisible } from '@store/actions/loader-actions'

export function getReservationData(token: string): ThunkResult<Promise<Reservation>, UpdateReservationAction> {
  return async dispatch => {
    const data = await commonObjectGet<Reservation>(`api/reservation/reservation/${token}/`)
    dispatch(updateReservation({ ...data }))
    return data
  }
}

export function updateMainReservationData(
  payload: Partial<Reservation>,
): ThunkResult<Promise<void>, UpdateReservationAction> {
  return async (dispatch, getState) => {
    const url = getState().reservationState.reservation.urls.update
    if (!url) return
    dispatch(updateReservation({ ...(await commonObjectPatch(url, payload)) }))
  }
}

export async function checkMainReservationFields(url: string, payload: Partial<Reservation>): Promise<void> {
  await commonObjectPut(url, payload)
}

export function calculateReservationPrice(): ThunkResult<Promise<void>, UpdateReservationAction> {
  return async (dispatch, getState) => {
    const url = getState().appDataState.appData.urls.api.reservation_calculate_price
    const reservation = getState().reservationState.reservation
    const { resort_id: resort, accommodation_type_id: accommodation_type, date_from, date_to } = reservation

    if (!resort || !accommodation_type || !date_to || !date_from) return

    const data = await commonObjectPost<Reservation, CalculateReservationPricePayload>(url, {
      resort,
      accommodation_type,
      date_from,
      date_to,
    })

    dispatch(updateReservation({ ...reservation, ...data, promocode: reservation.promocode }))
  }
}

export async function checkGuestRecognition(email: string, url: string): Promise<CheckEmailResponse> {
  return await commonObjectGet(url, { params: { email: email } })
}

export function getRecognizedGuestReservationData(
  code: string,
  email: string,
): ThunkResult<Promise<Reservation>, UpdateReservationAction> {
  return async (dispatch, getState) => {
    const url = getState().reservationState.reservation.urls.authenticate_client
    const data = await commonObjectPatch<Reservation, RecognizedGuestReservationPayload>(url, { otp: code, email })
    dispatch(updateReservation({ ...data }))
    return data
  }
}

export function fetchAvailableApartments(
  params: FetchAvailableApartmentsPayload,
): ThunkResult<Promise<void>, SetAvailableApartments> {
  return async (dispatch, getState) => {
    const url = getState().appDataState.appData.urls.api.available_apartments
    dispatch(setAvailableApartments(await commonObjectGet(url, { params })))
  }
}

export function checkAccommodationTypeAvailable({
  date_from,
  date_to,
  promocode,
}: Pick<Reservation, 'date_from' | 'date_to' | 'promocode'>): ThunkResult<
  Promise<void>,
  UpdateAccommodationTypeAvailableAction | SetLoaderVisibleAction | SetLoaderHiddenAction | UpdateReservationAction
> {
  return async (dispatch, getState) => {
    dispatch(setLoaderVisible())
    const url = getState().appDataState.appData.urls.api.reservation_check_accommodation_type
    dispatch(
      updateAccommodationTypeAvailable(await commonObjectPost(url, { date_from, date_to, code: promocode?.code })),
    )
    dispatch(updateReservation({ date_from, date_to }))
    dispatch(setLoaderHidden())
  }
}

export async function getCompanyInformation(url: string, taxId: string): Promise<Reservation> {
  return commonObjectGet(url, { params: { invoice_nip: taxId } })
}

export function finishReservation(
  payload: Partial<FinishReservationRequestPayload>,
): ThunkResult<Promise<Reservation>, UpdateReservationAction> {
  return async (dispatch, getState) => {
    const finishReservationUrl = getState().reservationState.reservation.urls.finish
    const data = await commonObjectPut<Reservation>(finishReservationUrl, payload)
    dispatch(updateReservation({ ...data }))
    return data
  }
}

export function addPromocode(
  code: string,
  accommodationTypeId?: number | null,
): ThunkResult<Promise<Reservation>, UpdateReservationAction> {
  return async (dispatch, getState) => {
    const state = getState()

    const promocodeUrl = state.reservationState.reservation.urls.promocode
    let data: Reservation

    if (promocodeUrl) {
      data = await commonObjectPut<Reservation>(promocodeUrl, { code })
    } else {
      const { date_from, date_to } = state.reservationState.reservation
      const { accommodation_types } = await commonObjectPost<Pick<Promocode, 'accommodation_types'>>(
        state.appDataState.appData.urls.api.reservation_check_code,
        {
          code,
          date_from,
          date_to,
          accommodation_type: accommodationTypeId,
        },
      )
      data = { ...state.reservationState.reservation, promocode: { code, text: 'Kod dodany.', accommodation_types } }
    }

    dispatch(updateReservation({ ...data }))

    return data
  }
}

export function removePromocode(): ThunkResult<Promise<Reservation>, UpdateReservationAction> {
  return async (dispatch, getState) => {
    const state = getState()
    const promocodeUrl = state.reservationState.reservation.urls.promocode

    let data: Reservation

    if (promocodeUrl) {
      data = await commonObjectDelete<Reservation>(promocodeUrl)
    } else {
      data = { ...state.reservationState.reservation, promocode: null }
    }

    dispatch(updateReservation({ ...data }))
    return data
  }
}

export function addGastroVoucher(code: string): ThunkResult<Promise<void>, UpdateReservationAction> {
  return async (dispatch, getState) => {
    const gastroCardUrl = getState().reservationState.reservation.urls.gastro_card
    const data = await commonObjectPost<Reservation>(gastroCardUrl, { gastro_card: code.toUpperCase() })
    dispatch(updateReservation({ ...data }))
  }
}

export function purchaseGastroVoucher(
  price: number,
  departure_voucher_rules: boolean,
): ThunkResult<Promise<void>, UpdateReservationAction> {
  return async (dispatch, getState) => {
    const gastroVoucherPurchaseUrl = getState().reservationState.reservation.urls.gastro_card_buy
    const data = await commonObjectPost<Reservation>(gastroVoucherPurchaseUrl, {
      declared_sell_price: price,
      departure_voucher_rules,
    })
    dispatch(updateReservation({ ...data }))
  }
}

export function removeGastroVoucher(url: string): ThunkResult<Promise<void>, UpdateReservationAction> {
  return async dispatch => {
    const data = await commonObjectDelete<Reservation>(url)
    dispatch(updateReservation({ ...data }))
  }
}
