import * as React from 'react'
import { CustomTooltip } from '@components/custom-tooltip'
import { Apartment } from '@models/reservation'

interface Props {
  apartment: Apartment
}

export const LocalAllergyFriendlyMark = ({ apartment }: Props): JSX.Element => (
  <CustomTooltip
    id={apartment.display_name}
    content={
      <div className="font-size-sm">
        <strong className="text-nowrap">Lokal bez zwierząt</strong>
        <span className="d-block mt-1 text-nowrap">(dla alergików)</span>
      </div>
    }
  >
    <i className="uil-flower font-size-lg text-warning me-1" data-testid="allergic-local" />
  </CustomTooltip>
)
