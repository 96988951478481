import { ReactImageGalleryItem } from 'react-image-gallery'
import { Image } from '@models/app-data'
import * as React from 'react'
import { useDevicesSize } from '@hooks/use-devices-size'
import { SizedImage } from '@models/models'

export enum Queries {
  md = '(min-width: 1200px)',
  sm = '(min-width: 768px) and (max-width: 1199px)',
  xs = '(max-width: 757px)',
}

export const useResponsivenessImages = (
  imageElement: Image[] | undefined,
  size?: keyof SizedImage,
): ReactImageGalleryItem[] => {
  const { isTablet, isMobile } = useDevicesSize()

  const sizedImages = React.useMemo(
    () => imageElement?.map(accommodationTypeImage => accommodationTypeImage.image) || [],
    [imageElement],
  )

  return React.useMemo(
    () =>
      sizedImages.map(image => {
        if (size) return { original: image[size], thumbnail: image.xs }
        if (isMobile) return { original: image.xs, thumbnail: image.xs }
        if (isTablet) return { original: image.sm, thumbnail: image.xs }
        return { original: image.md, thumbnail: image.xs }
      }),
    [sizedImages, isMobile, isTablet],
  )
}
