import * as React from 'react'
import { formatPrice } from '@helpers/utils'

interface Props {
  children: JSX.Element[]
  title: string
  totalPrice: string
  pricePrefix?: string
}

export const SummaryPricesSection = ({ children, totalPrice, title, pricePrefix = '+' }: Props): JSX.Element => (
  <div className="px-xl-4 container-lg">
    <strong className="text-darker-gray d-block mb-1">{title}</strong>
    {children}
    <div className="text-end mt-2 d-flex">
      <span className="text-muted font-size-md col-8 col-md-10">razem</span>
      <strong className="text-darker-gray col-4 col-md-2 text-nowrap">
        {pricePrefix} {formatPrice(totalPrice)}
      </strong>
    </div>
  </div>
)
