import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import { CardActions } from '@components/card/card-actions'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import { useSelector } from 'react-redux'
import { selectResortAccommodationTypes, selectResortById } from '@store/selectors/app-data-selectors'
import { RootState } from '@store/index'
import { selectAccommodationTypeAvailableByIdAndResort } from '@store/selectors/reservation-selectors'

interface Props {
  onResortSelection: () => void
  resortId: number
}

export const ResortCardAction = ({ onResortSelection, resortId }: Props): JSX.Element => {
  const resort = useSelector(selectResortById)(resortId)
  const accommodationTypes = useSelector(selectResortAccommodationTypes)(resort)

  const accommodationTypeAvailable = useSelector((state: RootState) =>
    selectAccommodationTypeAvailableByIdAndResort(
      state,
      resort,
      accommodationTypes.map(accommodationType => accommodationType.id),
    ),
  )

  const price = React.useMemo(
    () =>
      Math.min(
        ...(accommodationTypes
          .map(accommodationType => {
            const available = accommodationTypeAvailable.find(
              availableType => availableType.accommodation_type_id === accommodationType.id,
            )
            return available
              ? asDecimal(available.price_brutto_avg).div(asDecimal(accommodationType.display_max_guests)).toNumber()
              : null
          })
          .filter(Number) as number[]),
      ),
    [accommodationTypes, accommodationTypeAvailable],
  )

  return (
    <CardActions
      trapezoidButtonClassName="collapsable-resort__action-button"
      onButtonClick={useAccordionButton(resortId.toString(), onResortSelection)}
      btnDefaultContent={
        <IconWithText
          text={
            <div className="d-flex flex-column align-items-end lh-initial position-relative">
              <span className="font-size-xxs d-block lh-initial">Cena od {formatPriceShort(price)} / os</span>
              <strong className="font-size-xxl d-block lh-initial">wybierz lokal </strong>
            </div>
          }
          iconClassName="uil-angle-down font-size-icon ms-3 collapsable-resort__card__action"
          wrapperClassName="flex-row-reverse lh-initial"
        />
      }
    />
  )
}
