import * as React from 'react'
import * as clsx from 'clsx'
import { ElementRenderer } from '@components/element-renderer'

interface Props {
  children: JSX.Element[] | React.ReactNode
  onClick?: () => void
  className?: string
}

export const FloatingItem = ({ children, onClick, className }: Props): JSX.Element => (
  <ElementRenderer
    renderAs={onClick ? 'button' : 'div'}
    className={clsx('floating-item d-xl-none', className)}
    onClick={onClick}
  >
    {children}
  </ElementRenderer>
)
