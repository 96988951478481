import * as React from 'react'
import { Modal } from 'react-bootstrap'
import { SendLinkButton } from '@components/controls/send-link-button'
import * as clsx from 'clsx'

interface Props {
  toggleIsOpen: () => void
  title: string
  isSendLinkVisible: boolean
}

export const ModalHeader = ({ toggleIsOpen, title, isSendLinkVisible }: Props): JSX.Element => (
  <Modal.Header className="d-flex align-item-center py-0">
    <Modal.Title
      className={clsx(
        'container-lg d-flex align-items-center ps-xl-5',
        title ? 'justify-content-between' : 'justify-content-end',
      )}
    >
      {title && <h4 className="fw-bold custom-modal__title py-3 mb-0">{title}</h4>}
      <div className="text-muted d-flex align-items-center cursor-pointer">
        {isSendLinkVisible && <SendLinkButton className="border-start px-4 py-4 d-none d-xl-flex" />}
        <button className="uil-multiply ms-2 custom-modal__close-button px-xl-2" onClick={toggleIsOpen} />
      </div>
    </Modal.Title>
  </Modal.Header>
)
