import * as React from 'react'
import { formatPriceShort } from '@helpers/utils'
import { ResortImprovement } from '@models/reservation'
import { useModal } from '@modals/use-modal'
import { SectionCard } from '@modules/improvement-step/improvements-sections/section-card'

interface Props {
  resortImprovement: ResortImprovement
}

export const StayOptionCard = ({ resortImprovement }: Props): JSX.Element => {
  const [showStayOptionModal] = useModal('StayOptionDetailsModal', {
    title: 'Szczegóły ulepszenia',
    resortImprovement: resortImprovement,
  })

  return (
    <div className="col-xl-4 col-sm-6">
      <SectionCard
        onRightActionClick={showStayOptionModal}
        leftAction={
          <strong className="text-darker-gray d-flex align-items-center">
            {formatPriceShort(resortImprovement.price_brutto)}
          </strong>
        }
        title={resortImprovement.name}
        image={resortImprovement.image.sm}
        cardClassName="stay-option-card is-low-image"
      />
    </div>
  )
}
