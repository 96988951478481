import * as React from 'react'
import { createArrFromNumber, createHash } from '@helpers/utils'
import { ResortImprovement, ReservationImprovement } from '@models/reservation'
import { useFormRequest } from '@hooks/use-form-request'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { getImprovementLimit, isParking } from '@helpers/improvements'
import { AddImprovementPayload } from '@requests/reservation/reservation-request-models'
import { addReservationImprovement, updateReservationImprovement } from '@requests/reservation/improvement-requests'
import { useImprovementPriceCalculation } from '@hooks/use-improvement-price-calculation'
import { BaseModalProps } from '@modals/types'
import { ModalActions } from '@modals/modal-actions'
import { ImprovementModalWrapper } from '@modules/improvement-step/improvement-modal-wrapper'
import { ImprovementPrice } from '@modules/improvement-step/improvements-sections/kinds/improvements/improvement-price'
import {
  CarPlate,
  ImprovementCarPlatesField,
} from '@modules/improvement-step/improvements-sections/kinds/improvements/improvement-car-plates-field'
import { SectionSummaryRow } from '@modules/improvement-step/improvements-sections/section-summary-row'
import { SectionSummaryPriceRow } from '@modules/improvement-step/improvements-sections/section-summary-price-row'
import { createCartItem, useGtmEvents } from '@hooks/use-gtm-events'

export interface ImprovementDetailsFormInputs {
  amount: number
  plates: CarPlate[]
  maxAmount: number
}

interface Props extends BaseModalProps {
  resortImprovement: ResortImprovement
  customImprovementHandler?: (improvement: ResortImprovement) => void
}

export const ImprovementDetailsModal = ({
  resortImprovement,
  customImprovementHandler,
  toggleIsVisible,
}: Props): JSX.Element => {
  const [summaryItems, setSummaryItems] = React.useState<string[]>([])
  const dispatch = useDispatch()
  const { addToCart } = useGtmEvents()

  const reservation = useSelector(selectReservation)

  const reservationImprovement = reservation.prices.improvements.items.find(
    (improvement: ReservationImprovement) => improvement.code === resortImprovement.code,
  )

  const methods = useForm<ImprovementDetailsFormInputs>({
    defaultValues: {
      amount: reservationImprovement?.amount || 1,
      plates: [],
      maxAmount: getImprovementLimit(resortImprovement, reservation),
    },
  })

  React.useEffect(() => {
    methods.setValue('amount', reservationImprovement?.amount || 1)
  }, [reservationImprovement])

  const amount = useWatch({ control: methods.control, name: 'amount' })

  const {
    improvementCalculation,
    isLoading: isCalculating,
    calculationErrorOccurred,
  } = useImprovementPriceCalculation({
    amount,
    resortImprovement,
    improvementId: reservationImprovement?.id,
  })

  const { handleAction, isLoading } = useFormRequest(methods.setError)

  const handleDelete = id => () => {
    setSummaryItems(summaryItems.filter(summaryItemId => summaryItemId !== id))
    methods.setValue('amount', amount - 1)
  }

  const createImprovementPayload = (improvement: ResortImprovement | ReservationImprovement): AddImprovementPayload => {
    const plates = methods.getValues('plates')

    return {
      code: improvement.code,
      amount,
      ...(isParking(resortImprovement) && {
        context: { register_car_numbers: plates.map(plate => plate.register_number).filter(String) },
      }),
    }
  }

  const updateImprovement = async (improvement: ReservationImprovement) => {
    const { isResolved } = await handleAction(() =>
      dispatch(updateReservationImprovement(improvement.urls.details, [createImprovementPayload(improvement)])),
    )

    if (isResolved) toggleIsVisible()
  }

  const addImprovement = async () => {
    const improvementPayload = createImprovementPayload(resortImprovement)
    await handleAction(() => dispatch(addReservationImprovement([improvementPayload])))

    addToCart(
      improvementCalculation?.total_price ?? resortImprovement.price_brutto,
      createCartItem({ ...resortImprovement, amount: improvementPayload.amount ?? 1 }),
    )

    toggleIsVisible()
  }

  const handleAddImprovement = () => {
    if (customImprovementHandler) {
      customImprovementHandler(resortImprovement)
      toggleIsVisible()
      return
    }

    reservationImprovement ? updateImprovement(reservationImprovement) : addImprovement()
  }

  React.useEffect(() => {
    if (hasOwnSummaryList(resortImprovement)) return
    const difference = amount - summaryItems.length

    if (amount > summaryItems.length) {
      setSummaryItems([...summaryItems, ...createArrFromNumber(difference).map(createHash)])
    }

    if (amount < summaryItems.length) {
      setSummaryItems(summaryItems.slice(0, difference))
    }
  }, [amount])

  React.useEffect(() => {
    if (hasOwnSummaryList(resortImprovement)) return
    if (reservationImprovement?.amount) setSummaryItems(createArrFromNumber(amount).map(createHash))
  }, [])

  const hasOwnSummaryList = improvement => isParking(improvement)

  return (
    <FormProvider {...methods}>
      <ImprovementModalWrapper
        contentTitle={resortImprovement.name}
        image={resortImprovement.image.md}
        isMostPopular
        hasAmountChange={resortImprovement.can_change_amount}
        description={
          <>
            {resortImprovement.description && <p className="text-muted mb-0 mt-1">{resortImprovement.description}</p>}
            <ImprovementPrice resortImprovement={resortImprovement} />
          </>
        }
      >
        {isParking(resortImprovement) && (
          <ImprovementCarPlatesField
            resortImprovement={resortImprovement}
            calculatedImprovements={improvementCalculation?.items}
            isPriceCalculating={isCalculating}
          />
        )}
        {!hasOwnSummaryList(reservationImprovement) &&
          summaryItems.map((id, index) => (
            <SectionSummaryRow
              key={id}
              text={resortImprovement.name}
              price={improvementCalculation?.items[index]?.price || 0}
              onDelete={handleDelete(id)}
              isPriceCalculating={isCalculating}
              isDeleteEnabled={!!index}
            />
          ))}
        <SectionSummaryPriceRow
          text="Koszt ulepszenia łącznie:"
          price={improvementCalculation?.total_price || 0}
          isCalculating={isCalculating}
        />
      </ImprovementModalWrapper>
      <ModalActions
        onDecline={toggleIsVisible}
        onSubmit={handleAddImprovement}
        isLoading={isLoading}
        submitIcon="uil-check"
        isSubmitDisabled={calculationErrorOccurred}
        submitText={reservationImprovement ? 'Zapisuję' : 'Wybieram'}
      />
    </FormProvider>
  )
}
