import * as React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

interface Props {
  children: React.ReactElement
  content: JSX.Element
  id: string
  showOnHover?: boolean
  delay?: number
}

export const CustomTooltip = ({ children, content, id, showOnHover = true, delay = 300 }: Props): JSX.Element => (
  <OverlayTrigger
    delay={delay}
    placement="top"
    overlay={<Tooltip id={id}>{content}</Tooltip>}
    {...(!showOnHover && { trigger: [] })}
  >
    <div>{children}</div>
  </OverlayTrigger>
)
