import * as React from 'react'

interface Promises {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  promise: Promise<any>
  cancel: () => void
}

interface CancellablePromise {
  cancellablePromise: <T>(promise: T) => Promise<T>
}

export const useCancellablePromise = (): CancellablePromise => {
  const promises = React.useRef<Promises[]>()

  React.useEffect(() => {
    promises.current = promises.current || []
    return () => {
      promises.current?.forEach(promise => promise.cancel())
      promises.current = []
    }
  }, [])

  const cancellablePromise = function <T>(promise: T): Promise<T> {
    const cancellable = makeCancelable(promise)
    promises.current?.push(cancellable)
    return cancellable.promise
  }
  return { cancellablePromise }
}

const makeCancelable = (promise): Promises => {
  let isCanceled = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then(val => (isCanceled ? reject({ isCanceled }) : resolve(val)))
      .catch(error => (isCanceled ? reject({ isCanceled }) : reject(error)))
  })

  const handleCancel = () => {
    isCanceled = true
  }

  return {
    promise: wrappedPromise,
    cancel: handleCancel,
  }
}
