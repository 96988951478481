import * as React from 'react'

interface Props {
  children: React.ReactNode
  image?: string
}

export const ModalWithImageWrapper = ({
  children,
  image = require('@assets/images/modal-image.webp'),
}: Props): JSX.Element => (
  <>
    <div className="custom-modal-with-image__content">{children}</div>
    <img className="custom-modal-with-image__image" src={image} alt="Holidaypark" />
  </>
)
