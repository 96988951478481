import * as React from 'react'
import { useDateSelection } from '@modules/accommodation-selection-step/dates-selection/use-date-selection'
import { BaseModalProps } from '@modals/types'
import { useSelector } from 'react-redux'
import { selectAppData, selectSeasons } from '@store/selectors/app-data-selectors'
import { useReservationDataCleanup } from '@hooks/use-reservation-data-cleanup-on-render'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { DatesSelectionRange } from 'dates-selection-range'
import { stayDeclination } from '@helpers/declination'

export const DateSelectionModal = ({ toggleIsVisible }: BaseModalProps): JSX.Element => {
  const dateSelection = useDateSelection()
  const seasons = useSelector(selectSeasons)
  const { max_reservation_days } = useSelector(selectAppData)
  const { cleanData } = useReservationDataCleanup()
  const { date_from, date_to } = useSelector(selectReservation)

  return (
    <DatesSelectionRange
      dateSelection={dateSelection}
      months={1}
      toggleDateChangeVisibility={toggleIsVisible}
      seasons={seasons}
      maxReservationDays={max_reservation_days}
      onDatesUpdated={cleanData}
      dateFrom={date_from}
      dateTo={date_to}
      getStayDeclination={stayDeclination}
    />
  )
}
