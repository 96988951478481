import * as React from 'react'
import { useModal } from '@modals/use-modal'
import { useSelector } from 'react-redux'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { useApartmentSelection } from '@modules/accommodation-selection-step/local-selection/apartment-details/use-apartment-selection'
import { AccommodationType, Resort } from '@models/app-data'
import { selectModals } from '@store/selectors/modal-selectors'
import { useDidUpdateEffect } from 'dates-selection-range'
import { useToggle } from '@hooks/use-toggle'
import { useAccommodationAvailability } from '@modules/accommodation-selection-step/dates-selection/use-accommodation-availability'

interface Props {
  accommodationType: AccommodationType
  resort: Resort
}

export const OccupiedBannerLocalForClientWithBenefit = ({ accommodationType, resort }: Props): JSX.Element => {
  const modals = useSelector(selectModals)
  const reservation = useSelector(selectReservation)
  const [modalActivity, toggleModalActivity] = useToggle(false)

  const { fetchAccommodationTypes } = useAccommodationAvailability()
  const { handleApartmentSelect } = useApartmentSelection(accommodationType, resort)

  const hasModal = modals.some(modal => modal.modal === 'LocalForClientWithBenefitModal')

  const showLocalSelectionMap = async () => {
    await fetchAccommodationTypes(reservation)
    handleApartmentSelect()
  }

  useDidUpdateEffect(() => {
    if (!hasModal && reservation.promocode) {
      showLocalSelectionMap()
    }
  }, [modalActivity])

  const [showSpecialLocalsInformationModal] = useModal(
    'LocalForClientWithBenefitModal',
    { title: 'Oferty specjalne', isPreview: true },
    { onClose: toggleModalActivity },
  )

  return (
    <button className="btn-unstyled local-for-client-with-benefit__banner" onClick={showSpecialLocalsInformationModal}>
      Posiadasz <strong className="product-name">Pakiet Korzyści</strong> lub{' '}
      <strong className="product-name">GO!Holiday?</strong>{' '}
      <span className="d-block d-sm-inline">Sprawdź dodatkowe lokale specjalne</span>
    </button>
  )
}
