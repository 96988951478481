import * as React from 'react'
import { ResortImprovement } from '@models/reservation'
import { StayOptionCard } from '@modules/improvement-step/improvements-sections/kinds/stay-options/stay-option-card'

interface Props {
  resortImprovements: ResortImprovement[]
}

export const StayOptions = ({ resortImprovements }: Props): JSX.Element => (
  <div className="d-flex flex-column flex-sm-row gap-4 gap-sm-0 gy-4 row">
    {resortImprovements.map((resortImprovement: ResortImprovement) => (
      <StayOptionCard key={resortImprovement.id} resortImprovement={resortImprovement} />
    ))}
  </div>
)
