import * as React from 'react'
import { useExpandableItems } from '@hooks/use-expandable-items'
import { CustomTooltip } from '@components/custom-tooltip'
import * as clsx from 'clsx'
import { AccommodationTypeAttribute } from '@models/app-data'

interface Props {
  attributes: AccommodationTypeAttribute[]
}

export const ApartmentSpecifications = ({ attributes }: Props): JSX.Element => {
  const { items, onShowAll, length, isEveryVisible } = useExpandableItems(attributes, 7)

  return (
    <div className="py-3 text-muted d-flex flex-wrap align-items-center">
      {items.map((attribute: AccommodationTypeAttribute) => (
        <React.Fragment key={attribute.id}>
          {attribute.description ? (
            renderWithTooltip(
              <SingleAttribute
                icon={`uil-${attribute.key}`}
                text={attribute.value}
                wrapperClassName="me-3"
                iconClassName="me-2"
              />,
              <SingleAttribute
                icon={`uil-${attribute.key}`}
                text={attribute.description}
                iconClassName="text-center d-flex apartment-attributes-tooltip__icon"
                wrapperClassName="flex-wrap py-2 px-3 apartment-attributes-tooltip"
              />,
              attribute.id,
            )
          ) : (
            <SingleAttribute
              icon={`uil-${attribute.key}`}
              text={attribute.value}
              wrapperClassName="me-3"
              iconClassName="me-2"
            />
          )}
        </React.Fragment>
      ))}
      {!isEveryVisible && (
        <button className="font-size-sm cursor-pointer text-darker-gray btn-unstyled fw-bold" onClick={onShowAll}>
          Pokaż wszystkie (+{length})
        </button>
      )}
    </div>
  )
}

interface SingleAttributeProps {
  icon: string
  text: string
  iconClassName?: string
  wrapperClassName?: string
}

const SingleAttribute = ({ icon, text, iconClassName, wrapperClassName }: SingleAttributeProps) => (
  <span
    key={icon}
    className={clsx('font-size-sm d-flex align-items-center justify-content-center cursor-default', wrapperClassName)}
  >
    <i className={clsx('font-size-md', icon, iconClassName)} />
    {text}
  </span>
)

const renderWithTooltip = (content, tooltipContent, id) => (
  <CustomTooltip id={id} content={tooltipContent}>
    {content}
  </CustomTooltip>
)
