import * as React from 'react'
import { useSelector } from 'react-redux'
import { GastroVoucherSelect } from '@modules/summary-step/promocodes/gastro-voucher/gastro-voucher-select'
import { selectClientDetails } from '@store/selectors/client-details-selectors'
import { GastroVoucherInput } from '@modules/summary-step/promocodes/gastro-voucher/gastro-voucher-input'

export const SummaryGastroVoucherModalInput = (): JSX.Element => {
  const clientDetails = useSelector(selectClientDetails)

  return (
    <div className="rounded bg-alice-blue py-3 px-4 position-relative pt-1">
      {clientDetails ? <GastroVoucherSelect /> : <GastroVoucherInput />}
    </div>
  )
}
