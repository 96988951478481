import * as React from 'react'
import { MostPopularImprovementBanner } from '@components/banners/most-popular-improvement-banner'
import { Modal } from 'react-bootstrap'
import { ImprovementAmountChange } from '@modules/improvement-step/improvements-sections/kinds/improvements/improvement-amount-change'

interface Props {
  contentTitle?: React.ReactNode
  children: React.ReactNode
  image: string
  imageContent?: React.ReactElement
  isMostPopular?: boolean
  hasAmountChange?: boolean
  description?: React.ReactNode
}

export const ImprovementModalWrapper = ({
  contentTitle,
  image,
  imageContent,
  isMostPopular,
  children,
  hasAmountChange,
  description,
}: Props): JSX.Element => (
  <Modal.Body>
    <div className="position-relative">
      <img
        className="scrollable-section__card__image border-top-0"
        src={image || require('@assets/images/improvement-image-fallback.webp')}
        alt="Holiday Park & Resort ulepszenia"
      />
      {imageContent}
    </div>
    <div className="px-3 py-3 px-xl-5 container-lg">
      {isMostPopular && <MostPopularImprovementBanner />}
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start my-1 gap-4 w-100">
        <div className="w-100">
          <strong className="text-darker-gray font-size-lg d-block">{contentTitle}</strong>
          {description}
        </div>
        {hasAmountChange && <ImprovementAmountChange />}
      </div>
    </div>
    {children}
  </Modal.Body>
)
