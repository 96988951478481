import * as React from 'react'
import { ReservationImprovement } from '@models/reservation'
import { isFeeding } from '@helpers/improvements'

interface Props {
  improvement: ReservationImprovement
  onEdit: () => void
  onDelete: () => void
}

export const SummaryBoxReservationSingleImprovement = ({ improvement, onDelete, onEdit }: Props): JSX.Element => (
  <span className="d-flex align-items-center">
    <span
      className={!improvement.can_update ? 'cursor-default' : 'cursor-pointer'}
      {...(improvement.can_update && { onClick: onEdit })}
    >
      {improvement.name}
      {improvement.amount > 1 && !isFeeding(improvement) && ` x${improvement.amount}`}
    </span>
    {improvement.can_remove && (
      <button
        className="uil-trash-alt summary-box__improvements__remove-icon"
        onClick={onDelete}
        data-testid="improvement-delete"
      />
    )}
  </span>
)
