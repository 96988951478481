import {
  ResortActionTypesKeys,
  SetGoogleOpinions,
  SetSelectedRecommendedResortId,
  SetResortDetails,
} from '@store/action-types/resort-action-types'
import { ResortDetails } from '@models/resort'
import { ResortOpinion } from '@models/models'

export function setResortDetails(resortDetails: ResortDetails): SetResortDetails {
  return {
    type: ResortActionTypesKeys.SET_RESORT_DETAILS,
    payload: { resortDetails },
  }
}

export function setGoogleOpinions(googleOpinions: ResortOpinion): SetGoogleOpinions {
  return {
    type: ResortActionTypesKeys.SET_GOOGLE_OPINIONS,
    payload: { googleOpinions },
  }
}

export function setSelectedRecommendedResortId(resortId: number | null): SetSelectedRecommendedResortId {
  return {
    type: ResortActionTypesKeys.SET_SELECTED_RECOMMENDED_RESORT_ID,
    payload: { resortId },
  }
}
