import * as React from 'react'
import { useSelector } from 'react-redux'
import { selectCountries } from '@store/selectors/app-data-selectors'
import { Country } from '@models/app-data'
import { CustomReactSelect, SelectProps, selectStyles } from '@components/controls/custom-react-select'
import { FlagIcon } from '@components/flag-icon'
import { HTMLProps } from 'react'

interface Props extends Omit<SelectProps, 'options'> {
  withLabel?: boolean
  wrapperProps?: HTMLProps<HTMLDivElement>
  name: string
}

export const CountrySelect = ({ withLabel = true, name, wrapperProps, ...selectProps }: Props): JSX.Element => {
  const countries = useSelector(selectCountries)
  const countryOptions = countries.map((country: Country) => ({ value: country.id, label: country.name }))

  return (
    <div {...wrapperProps}>
      <CustomReactSelect
        inputName={name}
        options={countryOptions}
        styles={styles}
        isSearchable
        noOptionsMessage={() => 'Brak'}
        formatOptionLabel={({ value, label }) => (
          <div className="d-flex align-items-center ps-2">
            <FlagIcon lang={value.toLowerCase()} />
            <div className="ms-2">{withLabel ? label : value}</div>
          </div>
        )}
        {...selectProps}
      />
    </div>
  )
}

const initialStyles = selectStyles(false)

const styles = {
  ...initialStyles,
  indicatorsContainer: provided => ({ ...provided, svg: { color: '#9099a1' } }),
  dropdownIndicator: provided => ({ ...provided, paddingLeft: '3px' }),
  control: provided => ({
    ...provided,
    height: 47,
    '&:hover': {
      cursor: 'pointer',
    },
    border: 'thin solid #9099a1',
    backgroundColor: 'white',
  }),
  valueContainer: provided => ({ ...initialStyles.valueContainer(provided), paddingRight: 0 }),
}
