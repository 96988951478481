import { PageLeaveActionsTypes, PageLeaveActionTypesKeys } from '@store/action-types/page-leave-action-types'
import { RootState } from '@store/index'

export interface PageLeaveState {
  isPageLeaveAllowed: boolean
}

const initialState: PageLeaveState = {
  isPageLeaveAllowed: false,
}

const pageLeaveState = (state: PageLeaveState = initialState, action: PageLeaveActionsTypes): PageLeaveState => {
  switch (action.type) {
    case PageLeaveActionTypesKeys.SET_IS_PAGE_LEAVE_ALLOWED:
      return { isPageLeaveAllowed: action.payload }
    default:
      return state
  }
}

export default pageLeaveState

export const selectIsPageLEaveAllowed = (state: RootState): boolean => state.pageLeaveState.isPageLeaveAllowed
