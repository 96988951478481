import * as React from 'react'
import * as clsx from 'clsx'

export type TrapezoidButtonVariant = 'danger' | 'light' | 'primary' | 'secondary' | 'success'

interface Props {
  onClick: () => void
  children: React.ReactNode
  isActive?: boolean
  isDisabled?: boolean
  className?: string
  variant?: TrapezoidButtonVariant
}

export const TrapezoidButton = ({
  onClick,
  children,
  variant,
  className,
  isActive,
  isDisabled,
}: Props): JSX.Element => (
  <div className={clsx('trapezoid__wrapper', className)}>
    <button
      className={clsx('font-size-lg fw-bold trapezoid__button', variant && `is-${variant}`, {
        'is-active': isActive,
        'is-disabled': isDisabled,
      })}
      onClick={onClick}
    >
      {children}
    </button>
  </div>
)
