import * as React from 'react'
import * as clsx from 'clsx'
import { Icon } from '@components/icon'

interface OpinionProps {
  logo: 'google' | 'booking' | 'tripadvisor'
  rating: string
  opinionsCount: string | number
  ratingClassName?: string
  children?: JSX.Element
  onClick?: () => void
}

export const Opinion = ({
  logo,
  rating,
  opinionsCount,
  children,
  onClick,
  ratingClassName,
}: OpinionProps): JSX.Element => (
  <button className="d-inline-flex align-items-top cursor-pointer btn-unstyled" onClick={onClick}>
    <Icon icon={logo} className="d-block me-2" />
    {children}
    <div className="d-flex flex-column align-items-end lh-initial opinions__provider-title">
      <strong className={clsx('me-2 font-size-xl', ratingClassName)}>{rating}</strong>
      <span className="d-block text-muted">({opinionsCount} opinii)</span>
    </div>
  </button>
)
