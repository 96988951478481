import * as React from 'react'
import { BaseModalProps } from '@modals/types'
import { ModalActions } from '@modals/modal-actions'
import {
  VipDetailList,
  VipImprovementBenefit,
} from '@modules/improvement-step/improvements-sections/kinds/vip/vip-detail-list'

interface Props extends BaseModalProps {
  onSelection: () => void
  code: 'package_vip' | 'package_vip_winter'
}

export const VipDetailListModal = ({ onSelection, toggleIsVisible, code }: Props): JSX.Element => (
  <>
    {code === 'package_vip' && (
      <VipDetailList
        title="Co zyskujesz wykupując pakiet VIP?"
        subtitle="Kupując pakiet VIP otrzymasz:"
        improvements={standardVipImprovements}
      />
    )}

    {code === 'package_vip_winter' && (
      <VipDetailList
        title="Co zyskujesz kupując zimowy pakiet VIP?"
        subtitle="Kupując zimowy pakiet VIP otrzymasz:"
        improvements={winterVipImprovements}
      />
    )}

    <ModalActions onSubmit={onSelection} onDecline={toggleIsVisible} submitText="Wybieram" submitIcon="uil-check" />
  </>
)

const standardVipImprovements: VipImprovementBenefit[] = [
  { title: 'Bezpłatna możliwość wybrania lokalu' },
  { title: 'Możliwość późniejszego wymeldowania - do godz. 12.00' },
  { title: 'Możliwość wcześniejszego zameldowania - od godz. 13.00' },
  { title: 'Grill elektryczny' },
  { title: 'Mikrofalówka' },
  { title: '4 ręczniki plażowe VIP' },
  { title: 'Zestaw ekskluzywnych kosmetyków' },
  { title: 'Wino czerwone premium' },
  { title: 'Owoce: banany, pomarańcze, kiwi, owoce sezonowe' },
  { title: 'Oznaczenie domku flagą VIP' },
  { title: 'Dziecko jako animator przez 3 godziny' },
  { title: 'Wynajem rowerów' },
  { title: 'Rabat w bistro HPR w godzinach 11:00 – 12:30' },
  { title: 'Warsztaty kulinarne dla dzieci 5x', discount: '(rabat 50%)' },
  { title: 'Okulary VR (5 godz.) *', discount: '(rabat 50%)' },
  { title: 'Krówki VIP' },
  { title: 'Opaski na rękę VIP' },
  { title: 'Pamiątkowy magnes' },
  { title: 'Ekspres do kawy z zestawem 20 kapsułek' },
  { title: 'Loża VIP', discount: '(rabat 50%)' },
]

const winterVipImprovements: VipImprovementBenefit[] = [
  { title: 'Bezpłatna możliwość wyboru lokalu' },
  { title: 'Możliwość wcześniejszego zameldowania - od godz. 13.00' },
  { title: 'Możliwość późniejszego wymeldowania - do godz. 12.00' },
  { title: 'Grill elektryczny' },
  { title: 'Mikrofalówka' },
  { title: 'Ekspres do kawy z zestawem 20 kapsułek' },
  { title: 'Wynajem rowerów (10 wypożyczeń, w miarę dostępności)' },
  { title: 'Wino czerwone premium' },
  { title: 'Owoce – banany, pomarańcze, owoce sezonowe' },
  { title: 'Zestaw ekskluzywnych kosmetyków' },
  { title: 'Ręczniki VIP' },
  { title: 'Oznaczenie domku flagą VIP' },
  { title: 'Krówki VIP' },
  { title: 'Opaski na rękę VIP' },
  { title: 'Pamiątkowy magnes' },
  { title: 'Dziecko jako animator przez 3 godziny' },
  { title: 'Warsztaty kulinarne dla dzieci 5x', discount: '(rabat 50%)' },
  { title: 'Strefa VIP w kawiarni (w miarę dostępności)' },
  { title: '2 miejsca parkingowe VIP (w miarę dostępności)' },
  { title: 'Loża VIP w kawiarni (rabat 50%, raz na pobyt)' },
  { title: 'Rabat -50% w bistro HPR przez pierwsze 1,5h od otwarcia (w miarę dostępności, max. 150 zł)' },
  { title: 'Rabat -20% w sklepiku HPR (max. 300 zł)' },
  { title: 'Recepcja VIP poza standardową kolejką*' },
]
