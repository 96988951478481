import * as React from 'react'
import { Nav } from 'react-bootstrap'
import { Opinion } from '@modules/accommodation-selection-step/opinions/opinion'
import { StarShape } from '@components/star-shape'
import { OpinionProvidersKeys } from '@models/app-data'
import { ResortDetails } from '@models/resort'

interface Props {
  provider: OpinionProvidersKeys | null
  onProviderChange: (provider: OpinionProvidersKeys) => void
  resortDetails: ResortDetails
}

export const OpinionsModalTabs = ({ provider, onProviderChange, resortDetails }: Props): JSX.Element => {
  const isTabActive = (tab: OpinionProvidersKeys) => tab === provider

  const handleProviderChange = (newProvider: OpinionProvidersKeys) => () => onProviderChange(newProvider)

  return (
    <Nav variant="tabs">
      <div className="container-lg px-xl-0">
        <div className="mt-1 opinions__providers-wrapper">
          <Nav.Item onClick={handleProviderChange('google')}>
            <Nav.Link className="p-0 text-center" active={isTabActive('google')}>
              <Opinion
                logo="google"
                rating={`${resortDetails.google_maps_rating} na 5`}
                opinionsCount={resortDetails.google_maps_reviews_count}
              >
                <StarShape size={17} className="me-1" />
              </Opinion>
            </Nav.Link>
          </Nav.Item>
          {/*TODO Missing backend implementation*/}
          {/*<Nav.Item onClick={handleProviderChange('booking')}>*/}
          {/*  <Nav.Link className="p-0 text-center" active={isTabActive('booking')}>*/}
          {/*    <Opinion*/}
          {/*      logo="logo_booking"*/}
          {/*      alt="logo booking"*/}
          {/*      rating={`${resortDetails.booking_com_rating} na 10`}*/}
          {/*      opinionsCount={resortDetails.booking_com_reviews_count}*/}
          {/*    />*/}
          {/*  </Nav.Link>*/}
          {/*</Nav.Item>*/}
          {/*<Nav.Item onClick={handleProviderChange('tripadvisor')}>*/}
          {/*  <Nav.Link className="p-0 text-center" active={isTabActive('tripadvisor')}>*/}
          {/*    <Opinion*/}
          {/*      logo="logo_tripadvisor"*/}
          {/*      alt="logo tripadvisor"*/}
          {/*      rating={`${resortDetails.trip_advisor_rating} na 5`}*/}
          {/*      opinionsCount={resortDetails.trip_advisor_reviews_count}*/}
          {/*    />*/}
          {/*  </Nav.Link>*/}
          {/*</Nav.Item>*/}
        </div>
      </div>
    </Nav>
  )
}
