import * as React from 'react'
import { formatPrice } from '@helpers/utils'

interface Props {
  title: string | React.ReactElement
  price: number | string
  pricePrefix?: string
}

export const SummaryPriesSectionItem = ({ title, price, pricePrefix = '+' }: Props): JSX.Element => (
  <div className="d-flex justify-content-between text-darker-gray ms-3">
    <span className="d-block fw-semi-bold font-size-md text-muted">{title}</span>
    <span className="fw-semi-bold d-block font-size-md">
      {pricePrefix} {formatPrice(price)}
    </span>
  </div>
)
