import * as React from 'react'
import { useSelector } from 'react-redux'
import { selectResortDetails } from '@store/selectors/resort-selectors'
import {
  selectReservationAccommodationType,
  selectReservationApartmentId,
  selectReservationFeedings,
  selectReservationImprovements,
} from '@store/selectors/reservation-selectors'
import { ReservationImprovement, ResortImprovement } from '@models/reservation'
import {
  getGroupedImprovements,
  getSelectableImprovements,
  isFeedingAvailableInResort,
  isVip,
  sortImprovementByPosition,
} from '@helpers/improvements'
import { ScrollableSection } from '@modules/improvement-step/improvements-sections/scrollable/scrollable-section'
import { StayOptions } from '@modules/improvement-step/improvements-sections/kinds/stay-options/stay-options'
import { Improvements } from '@modules/improvement-step/improvements-sections/kinds/improvements/improvements'
import { Feeding } from '@modules/improvement-step/improvements-sections/kinds/feedings/feeding'
import { Vip } from '@modules/improvement-step/improvements-sections/kinds/vip/vip'
import {
  ScrollableSectionTypes,
  ScrollableTab,
} from '@modules/improvement-step/improvements-sections/scrollable/scrollable-section-tabs'
import { selectAppData } from '@store/selectors/app-data-selectors'
import { createImprovementViewListItem, useGtmEvents } from '@hooks/use-gtm-events'

export const ScrollableSections = (): JSX.Element => {
  const vipSectionRef = React.useRef<HTMLDivElement>(null)
  const feedingSectionRef = React.useRef<HTMLDivElement>(null)
  const improvementSectionRef = React.useRef<HTMLDivElement>(null)
  const stayOptionsSectionRef = React.useRef<HTMLDivElement>(null)

  const resortDetails = useSelector(selectResortDetails)
  const reservationImprovements = useSelector(selectReservationImprovements)
  const reservationFeedings = useSelector(selectReservationFeedings)
  const accommodationType = useSelector(selectReservationAccommodationType)
  const apartmentId = useSelector(selectReservationApartmentId)

  const appData = useSelector(selectAppData)
  const { viewItemList } = useGtmEvents()

  const tabs: ScrollableTab[] = [
    { title: 'Dla wymagających', key: 'demanding', ref: vipSectionRef },
    { title: 'Wyżywienie', key: 'feeding', ref: feedingSectionRef },
    { title: 'Ulepszenia', key: 'improvements', ref: improvementSectionRef },
    { title: 'Opcje pobytu', key: 'stay-options', ref: stayOptionsSectionRef },
  ]

  const improvementsToAdd = React.useMemo(() => {
    const reservationVipImprovement = reservationImprovements.find(isVip)
    const improvements = reservationVipImprovement
      ? resortDetails.improvements.filter(
          (resortImprovement: ResortImprovement) =>
            !appData.package_vip_improvements_in_price[reservationVipImprovement.code]?.includes(
              resortImprovement.code,
            ),
        )
      : resortDetails.improvements

    return getSelectableImprovements(improvements, accommodationType, apartmentId).filter(
      (resortImprovement: ResortImprovement) =>
        !reservationImprovements.find((reservationImprovement: ReservationImprovement) => {
          return resortImprovement.code === reservationImprovement.code
        }),
    )
  }, [
    resortDetails.improvements,
    reservationImprovements,
    appData.package_vip_improvements_in_price,
    accommodationType,
    apartmentId,
  ])

  const reservationFeedingImprovement = reservationFeedings.items?.[0]

  const groupedImprovements = React.useMemo(() => getGroupedImprovements(improvementsToAdd), [improvementsToAdd])

  const { vipImprovements, restImprovements, stayOptionsImprovements } = {
    vipImprovements: groupedImprovements.vipImprovements,
    restImprovements: sortImprovementByPosition(groupedImprovements.restImprovements),
    stayOptionsImprovements: sortImprovementByPosition(groupedImprovements.stayOptionsImprovements),
  }

  const hasFeeding = isFeedingAvailableInResort(resortDetails)

  const spentItems = [
    !vipImprovements.length && 'demanding',
    !restImprovements.length && 'improvements',
    !stayOptionsImprovements.length && 'stay-options',
    reservationFeedingImprovement && 'feeding',
  ].filter(Boolean) as ScrollableSectionTypes[]

  React.useEffect(() => {
    const allAvailableImprovements = [...vipImprovements, ...restImprovements, ...stayOptionsImprovements]

    viewItemList(
      [
        ...allAvailableImprovements.map(createImprovementViewListItem),
        ...(reservationFeedingImprovement
          ? [createImprovementViewListItem(reservationFeedingImprovement, allAvailableImprovements.length)]
          : []),
      ],
      'ulepszenia',
    )
  }, [])

  return (
    <div className="scrollable-section container-lg">
      <p className="text-darker-gray px-xl-4 my-xl-3 text-xl-start text-center">
        Wybierz ulepszenia swoich wymarzonych wakacji
        <strong> i poczuj smak luksusu.</strong>
      </p>
      <div className="d-flex flex-column pb-xl-4 px-xl-4">
        {!!vipImprovements.length && (
          <ScrollableSection title="Dla wymagających:" ref={vipSectionRef}>
            <Vip vipImprovement={vipImprovements[0]} />
          </ScrollableSection>
        )}
        {!reservationFeedingImprovement && hasFeeding && (
          <ScrollableSection title="Wyżywienie:" ref={feedingSectionRef}>
            <Feeding />
          </ScrollableSection>
        )}
        {!!restImprovements.length && (
          <ScrollableSection title="Ulepszenia:" ref={improvementSectionRef}>
            <Improvements resortImprovements={restImprovements} />
          </ScrollableSection>
        )}
        {!!stayOptionsImprovements.length && (
          <ScrollableSection title="Opcje pobytu:" ref={stayOptionsSectionRef}>
            <StayOptions resortImprovements={stayOptionsImprovements} />
          </ScrollableSection>
        )}
      </div>
      {spentItems.length === tabs.length && (
        <div className="text-center text-primary font-size-xxl fw-semi-bold mb-5">
          <span className="position-relative underline-shadow">Wykorzystano wszystkie ulepszenia!</span>
        </div>
      )}
    </div>
  )
}
