import * as React from 'react'
import { useSelector } from 'react-redux'
import { selectReservation, selectReservationResort } from '@store/selectors/reservation-selectors'
import { asDecimal, formatPrice } from '@helpers/utils'
import { selectAccommodationTypeById } from '@store/selectors/app-data-selectors'
import { DateFormats, formatDate, parseISODate } from '@helpers/date-formatter'
import dateHelper from '@services/date-helper'
import { stayDeclination } from '@helpers/declination'
import * as clsx from 'clsx'
import { RootState } from '@store/index'
import { useModal } from '@modals/use-modal'
import { differenceInDays } from 'date-fns'

interface Props {
  wrapperClassName?: string
}

export const SummaryBoxMobile = ({ wrapperClassName }: Props): JSX.Element => {
  const reservation = useSelector(selectReservation)
  const resort = useSelector(selectReservationResort)

  const {
    prices: { stay_charge_without_climatic },
    accommodation_type_id,
    date_from,
    date_to,
  } = useSelector(selectReservation)

  const accommodationType = useSelector((state: RootState) => selectAccommodationTypeById(state, accommodation_type_id))

  const pricePerGuest = React.useMemo(
    () =>
      asDecimal(stay_charge_without_climatic)
        .div(asDecimal(accommodationType?.display_max_guests || 1))
        .div(differenceInDays(parseISODate(date_to), parseISODate(date_from))),
    [stay_charge_without_climatic, accommodationType, date_from, date_to],
  )

  const daysDiff = dateHelper.diffInDays(reservation.date_from, reservation.date_to)

  const [showReservationInformationModal] = useModal('ReservationInformationModal', { title: 'Szczegóły pobytu:' })

  return (
    <div className={clsx('bg-dark-gradient text-white', wrapperClassName)}>
      <div className="container-lg d-flex justify-content-between align-items-center">
        <div className="font-size-sm py-2">
          <span>Twój pobyt w: </span> <strong>{resort?.name}</strong>
          <div>
            <span>od </span>
            <strong>{formatDate(reservation.date_from, DateFormats.MONTH_WITH_FULL_WORD_MONTH)} - </strong>
            <strong>{formatDate(reservation.date_to, DateFormats.MONTH_WITH_FULL_WORD_MONTH_WITH_YEAR)}</strong>
            <span className="ms-2">
              ({daysDiff} {stayDeclination(daysDiff)})
            </span>
          </div>
          <div className="d-inline-block">
            <span className="me-1 font-size-xs">Cena za osobę za noc:</span>
            <strong>
              {formatPrice(pricePerGuest.isNaN() || !pricePerGuest.isFinite() ? 0 : pricePerGuest.toString())}
            </strong>
          </div>
          <span className="mx-2">/</span>
          <div className="d-inline-block">
            <span className="font-size-xs">Do zapłaty: </span>
            <strong>{formatPrice(stay_charge_without_climatic)}</strong>
          </div>
        </div>
        <button
          className="border-start ps-3 py-3 btn-unstyled"
          onClick={showReservationInformationModal}
          data-testid="show-reservation-details-button"
        >
          <i className="uil-angle-down font-size-icon" />
        </button>
      </div>
    </div>
  )
}
