import * as React from 'react'
import * as clsx from 'clsx'
import { Icon } from '@components/icon'

interface Props {
  image: JSX.Element
  children: JSX.Element | JSX.Element[]
  cardActions?: JSX.Element
  isActive?: boolean
  className?: string
  onSelectionIndicatorClick?: () => void
}

export const Card = ({
  image,
  isActive,
  children,
  cardActions,
  className,
  onSelectionIndicatorClick,
}: Props): JSX.Element => (
  <div className={clsx('card', { 'is-selected': isActive }, className)}>
    <Icon
      icon="check_selected"
      className="card__selection-indicator cursor-pointer"
      onClick={onSelectionIndicatorClick}
    />
    {image}
    <div className="card__content pt-3 ps-xl-3">
      {children}
      {cardActions}
    </div>
  </div>
)
