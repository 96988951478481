import { ThunkResult } from '@models/models'
import { AppData, ClientDetails } from '@models/app-data'
import { SetAppDataAction } from '@store/action-types/app-data-action-types'
import { SetResortDetails } from '@store/action-types/resort-action-types'
import { SetLoaderHiddenAction, SetLoaderVisibleAction } from '@store/action-types/loader-action-types'
import { setLoaderHidden, setLoaderVisible } from '@store/actions/loader-actions'
import { setAppDataStatus } from '@store/actions/app-data-actions'
import { commonObjectGet } from '@requests/basic-requests'
import { setClientDetails } from '@store/actions/client-details-actions'
import { SetClientDetailsAction } from '@store/action-types/client-details-action-types'

interface FetchAppDataResponse extends AppData {
  user: ClientDetails | null
}

export function fetchAppData(): ThunkResult<
  Promise<AppData>,
  SetAppDataAction | SetResortDetails | SetLoaderVisibleAction | SetLoaderHiddenAction | SetClientDetailsAction
> {
  return async dispatch => {
    dispatch(setLoaderVisible())
    const data = await commonObjectGet<FetchAppDataResponse>('/api/reservation/reservation-app-data/')
    dispatch(setAppDataStatus(data))
    if (data.user) {
      dispatch(setClientDetails(data.user))
    }

    dispatch(setLoaderHidden())
    return data
  }
}
