import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  title: React.ReactElement | string
  subtitle?: string
  children: React.ReactNode
  afterContent?: React.ReactElement
  childrenWrapperClassName?: string
}

export const SummaryCard = ({
  title,
  subtitle,
  children,
  afterContent,
  childrenWrapperClassName,
}: Props): JSX.Element => (
  <div className="bg-light-azure pt-1">
    <div className="container-lg bg-white px-xl-4 rounded py-4 bg-shadow">
      <div className="d-flex flex-column flex-md-row">
        <div className="col-12 col-md-5">
          <strong className="text-primary font-size-xl">{title}</strong>
          <span className="d-block mt-2 col-12 col-md-8 text-darker-gray">{subtitle}</span>
        </div>
        <div className={clsx('col-12 col-md-6 mt-2 mt-md-0', childrenWrapperClassName)}>{children}</div>
      </div>
      {afterContent}
    </div>
  </div>
)
