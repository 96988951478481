import * as React from 'react'

interface Props {
  title?: string
  value: string | JSX.Element
}

export const SummaryMainDataRow = ({ title, value }: Props): JSX.Element => (
  <div className="d-flex mb-1 text-darker-gray">
    <div className="col-6">{title}</div>
    <div className="col-6">
      <strong className="font-size-lg text-darker-gray">{value}</strong>
    </div>
  </div>
)
