import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '@store/index'

export interface DateRangeState {
  isVisible: boolean
}

const initialState: DateRangeState = {
  isVisible: false,
}
export const dateRangeSlice = createSlice({
  name: 'dateRange',
  initialState,
  reducers: {
    toggleDateRangeSelectionVisibility(state) {
      state.isVisible = !state.isVisible
    },
    closeDateRangeSelection(state) {
      state.isVisible = false
    },
  },
})

export const { toggleDateRangeSelectionVisibility, closeDateRangeSelection } = dateRangeSlice.actions

export const selectIsDateRangeVisible = (state: RootState): boolean => state.dateRangeSlice.isVisible

export default dateRangeSlice.reducer
