import * as React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@store/index'
import { ModalComponentRow } from './modal-component-row'

export const ModalComponent: React.FC = () => {
  const modals = useSelector((state: RootState) => state.modalState.modals)

  return (
    <>
      {modals.map(({ modal, params, config, isOpen }) => (
        <ModalComponentRow config={config} key={modal} isOpen={isOpen} modal={modal} params={params} />
      ))}
    </>
  )
}
