import { ResortImprovement } from '@models/reservation'
import { useModal } from '@modals/use-modal'
import { isWinterVip } from '@helpers/improvements'

interface VipModals {
  showVipDetailsModal: () => void
  showVipDetailsListModal: () => void
}

export const useVipModals = (vipImprovement: ResortImprovement | undefined): VipModals => {
  const handleDetailsShow = () => {
    toggleVipDetailsModal(null, { resortImprovement: vipImprovement })
    hideListModal()
  }

  const handleDetailsListShow = () => {
    toggleVipDetailsListModal()
    hideDetailsModal()
  }

  const [toggleVipDetailsModal, hideDetailsModal] = useModal('VipDetailsModal', {
    title: 'Szczegóły ulepszenia:',
    resortImprovement: vipImprovement,
    onShowDetailList: handleDetailsListShow,
  })

  const [toggleVipDetailsListModal, hideListModal] = useModal('VipDetailListModal', {
    title: isWinterVip(vipImprovement) ? 'Zimowy Pakiet VIP' : 'Pakiet VIP',
    onSelection: handleDetailsShow,
    code: vipImprovement?.code,
  })

  return {
    showVipDetailsModal: handleDetailsShow,
    showVipDetailsListModal: handleDetailsListShow,
  }
}
