import * as React from 'react'
import * as clsx from 'clsx'
import { SummaryBoxPromocode } from '@modules/aside/summary-box/summary-box-codes/summary-box-promocode/summary-box-promocode'

interface Props {
  wrapperClassName?: string
}

export const SummaryBoxCodes = ({ wrapperClassName }: Props): JSX.Element => (
  <div className="summary-box__codes">
    <div className={clsx('py-3', wrapperClassName)}>
      <SummaryBoxPromocode />
    </div>
  </div>
)
