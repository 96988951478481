import { Accordion, AccordionContext } from 'react-bootstrap'
import * as React from 'react'
import { useContext } from 'react'
import { useToggle } from '@hooks/use-toggle'
import { CollapsableResortContent } from '@modules/accommodation-selection-step/resort-selection/collapsable-resorts/collapsable-resort-content'
import { Resort } from '@models/app-data'
import { ResortCard } from '@modules/accommodation-selection-step/resort-selection/resort-card'
import { useDispatch, useSelector } from 'react-redux'
import { fetchResortDetails } from '@requests/resort-requests'
import { selectResortDetails } from '@store/selectors/resort-selectors'
import { selectAccommodationTypesAvailable, selectReservation } from '@store/selectors/reservation-selectors'
import { OccupiedBanner } from '@modules/accommodation-selection-step/local-selection/occupied-banner/occupied-banner'
import { AccommodationTypeAvailable } from '@models/reservation'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'
import { UnavailableResortBanner } from '@modules/accommodation-selection-step/resort-selection/unavailable-resort-banner'
import { createResortViewListItem, useGtmEvents } from '@hooks/use-gtm-events'

interface Props {
  resort: Resort
}

export const CollapsableResort = ({ resort }: Props): JSX.Element => {
  const [isCollapsed, toggleIsCollapsed] = useToggle(true)
  const resortDetails = useSelector(selectResortDetails)
  const reservation = useSelector(selectReservation)
  const accommodationTypeAvailable = useSelector(selectAccommodationTypesAvailable)

  const dispatch = useDispatch()
  const { selectItem } = useGtmEvents()
  const { onSelect } = useContext(AccordionContext)

  const resortAccommodationTypeAvailable = React.useMemo(
    () =>
      accommodationTypeAvailable.filter(
        (accommodationTypeAvailable: AccommodationTypeAvailable) => accommodationTypeAvailable.resort_id === resort.id,
      ),
    [resort, accommodationTypeAvailable],
  )

  const hasApartmentForClientWithBenefit = accommodationTypeAvailable.some(
    ({ has_apartment_for_client_with_benefit }) => has_apartment_for_client_with_benefit,
  )

  const isOccupied =
    resortAccommodationTypeAvailable.every(
      (accommodationTypeAvailable: AccommodationTypeAvailable) => !accommodationTypeAvailable.available,
    ) && !hasApartmentForClientWithBenefit

  const handleResortSelection = () => {
    if (!isCollapsed) return

    toggleIsCollapsed()

    if (resortDetails.id !== resort.id) {
      dispatch(fetchResortDetails(resort.id, reservation.date_from, reservation.date_to))
    }

    selectItem(createResortViewListItem(resort, 0), 'rezerwuj')
  }

  useDidUpdateEffect(event => onSelect?.(null, event), [reservation.date_to])

  return (
    <div className="mb-3 position-relative">
      <ResortCard resort={resort} isCollapsed={isCollapsed} onResortSelection={handleResortSelection} />
      <Accordion.Collapse
        eventKey={resort.id.toString()}
        className="bg-light-blue border border-top-0"
        onExit={toggleIsCollapsed}
      >
        <CollapsableResortContent resort={resort} />
      </Accordion.Collapse>
      {resort.disable_client_reservation && <UnavailableResortBanner resort={resort} />}
      {isOccupied && !resort.disable_client_reservation && (
        <OccupiedBanner
          resort={resort}
          title={resort.name}
          accommodationTypeAvailable={resortAccommodationTypeAvailable}
        />
      )}
    </div>
  )
}
