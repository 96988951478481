import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  text: JSX.Element | string
  price: string | number
  pricePrefix?: string
  wrapperClassName?: string
}

export const SectionCardTextWithPrice = ({ text, price, pricePrefix, wrapperClassName }: Props): JSX.Element => (
  <div className={clsx('d-flex justify-content-between align-items-center', wrapperClassName)}>
    <span className="d-block">{text}</span>
    <strong className="d-block">
      {pricePrefix} {price}
    </strong>
  </div>
)
