import {
  LoaderActionTypesKeys,
  SetLoaderHiddenAction,
  SetLoaderVisibleAction,
} from '@store/action-types/loader-action-types'

export function setLoaderVisible(): SetLoaderVisibleAction {
  return { type: LoaderActionTypesKeys.SET_LOADER_VISIBLE }
}

export function setLoaderHidden(): SetLoaderHiddenAction {
  return { type: LoaderActionTypesKeys.SET_LOADER_HIDDEN }
}
