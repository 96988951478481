import { emptyReservationPrices, emptyReservationUrls } from '@store/reducers/reservation-reducer'
import { useDispatch } from 'react-redux'
import { updateReservation } from '@store/actions/reservation-actions'
import { Reservation } from '@models/reservation'

interface ReservationDataCleanUp {
  cleanData: (additionalFields?: Partial<Reservation>) => void
}

export const useReservationDataCleanup = (): ReservationDataCleanUp => {
  const dispatch = useDispatch()

  const cleanData = (additionalFields?: Partial<Reservation>) => {
    dispatch(
      updateReservation({
        accommodation_type_id: null,
        prices: emptyReservationPrices,
        apartment_id: null,
        selected_apartment_id: null,
        resort_id: null,
        warranty: false,
        expire_after: null,
        token: null,
        urls: emptyReservationUrls,
        ...additionalFields,
      }),
    )
  }

  return { cleanData }
}
