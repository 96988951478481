import * as React from 'react'
import { asDecimal, formatPrice } from '@helpers/utils'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/selectors/client-details-selectors'
import { CustomReactSelect } from '@components/controls/custom-react-select'
import { FormError } from '@components/controls/form-error'
import { GastroCard } from '@models/app-data'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { parseISODate } from '@helpers/date-formatter'
import { isAfter, isBefore, isSameDay } from 'date-fns'

export const GastroVoucherSelect = (): JSX.Element => {
  const clientDetails = useSelector(selectClientDetails)
  const { date_from } = useSelector(selectReservation)

  const gastroCardOptions = clientDetails?.gastro_cards.reduce((acc, gastroCard: GastroCard) => {
    const isConfirmed = gastroCard.status === 'confirmed'
    const isUsed = asDecimal(gastroCard.available_amount).lte(0)
    const dateFrom = parseISODate(date_from)
    const isInRange =
      (isAfter(dateFrom, parseISODate(gastroCard.active_after)) ||
        isSameDay(dateFrom, parseISODate(gastroCard.active_after))) &&
      (isBefore(dateFrom, parseISODate(gastroCard.expire_after)) ||
        isSameDay(dateFrom, parseISODate(gastroCard.expire_after)))

    if (isConfirmed && !isUsed && isInRange) {
      return [...acc, { value: gastroCard.id, label: gastroCard.code, available_amount: gastroCard.available_amount }]
    }
    return acc
  }, [])

  const formatOptionLabel = ({ label, available_amount }) => (
    <div className="text-darker-gray">
      <span className="fw-bold font-size-lg me-2 letter-spacing-2">{label}</span>
      <div className="d-xl-block d-inline-block mt-1 mt-xl-0">
        <span className="font-size-sm fw-lighter me-2">Środki do wykorzystania:</span>
        <span className="font-size-sm fw-bold">{formatPrice(available_amount)}</span>
      </div>
    </div>
  )

  return (
    <div className="my-3">
      <label htmlFor="gastro_card" className="text-darker-gray fw-semi-bold mb-2 font-size-lg">
        Wybierz swój voucher Podwójna radość
      </label>
      <CustomReactSelect
        inputName="gastro_card"
        options={gastroCardOptions}
        formatOptionLabel={formatOptionLabel}
        isClearable={true}
        placeholder="Wybierz voucher"
        noOptionsMessage={() => 'Brak dostępnych voucherów'}
      />
      <FormError inputName="gastro_card" />
    </div>
  )
}
