import { Icon } from '@components/icon'
import * as React from 'react'
import * as clsx from 'clsx'
import { useSelector } from 'react-redux'
import { selectAppData } from '@store/selectors/app-data-selectors'

export const LogoHolidayLink = (props: React.SVGAttributes<SVGElement>): JSX.Element => {
  const appData = useSelector(selectAppData)

  return (
    <a href={appData.urls.other.main_site} target="_blank">
      <Icon icon="holiday" {...props} className={clsx('cursor-pointer', props.className)} />
    </a>
  )
}
