import * as React from 'react'
import * as clsx from 'clsx'
import { AccommodationTypeAvailableDateRange } from '@models/reservation'
import { OccupiedBannerRecommendationButton } from '@modules/accommodation-selection-step/local-selection/occupied-banner/occupied-banner-recommendation-button'
import { Resort } from '@models/app-data'

interface Props {
  dates: AccommodationTypeAvailableDateRange[]
  text: string
  className?: string
  resort: Resort
}

export const OccupiedBannerRecommendation = ({ dates, text, className, resort }: Props): JSX.Element => {
  const widthClass = { 1: 'w-100', 2: 'w-50' }[dates.length]

  return (
    <div className={clsx('date-recommendation__wrapper', className)}>
      <span className="date-recommendation__title">{text}</span>
      <div className="d-flex gap-2">
        {dates.map((date: AccommodationTypeAvailableDateRange) => (
          <OccupiedBannerRecommendationButton
            resort={resort}
            key={`${date.date_from}-${date.date_to}`}
            date={date}
            className={widthClass}
          />
        ))}
      </div>
    </div>
  )
}
