import * as React from 'react'
import * as clsx from 'clsx'
import { useSelector } from 'react-redux'
import { selectStep } from '@store/selectors/steps-selectors'
import { availableSteps } from '@store/reducers/steps-reducer'
import { CircleProgress } from '@components/circle-progress'

export const Step = (): JSX.Element => {
  const currentStep = useSelector(selectStep)

  const isActive = step => step.stepNumber === currentStep.stepNumber
  const isCompleted = step => step.stepNumber < currentStep.stepNumber

  const progress = step => {
    if (!isActive(step)) return 0
    return (step.stepNumber / availableSteps.length) * 100
  }

  return (
    <>
      <div className="d-flex align-self-center justify-content-center py-3 pb-xl-0 d-xl-none">
        <span className="text-muted">
          Krok {currentStep.stepNumber} z {availableSteps.length}
        </span>
      </div>
      <div className="d-none d-xl-block border-bottom">
        <div className="mx-auto w-75 justify-content-between pb-3 d-xl-flex d-none ps-3 px-xl-4">
          {availableSteps.map(step => (
            <div
              key={step.name}
              className={clsx('step__container', {
                'is-active': isActive(step),
                'is-completed': isCompleted(step),
              })}
            >
              <div className="step__circle-wrapper">
                {!isCompleted(step) && <CircleProgress progress={progress(step)} />}
                <i className="step__completed-icon uil-check text-success" />
                <span className="step__number">{step.stepNumber}</span>
              </div>
              <strong className="step__name">{step.name}</strong>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
