import * as React from 'react'
import { CustomTooltip } from '@components/custom-tooltip'
import { Icon } from '@components/icon'
import { Apartment } from '@models/reservation'

interface Props {
  apartment: Apartment
}

export const LocalWithGardenMark = ({ apartment }: Props): JSX.Element => (
  <CustomTooltip content={<strong>Lokal z ogrodem</strong>} id={`${apartment.display_name}-garden`}>
    <Icon icon="grass" />
  </CustomTooltip>
)
