import * as React from 'react'

interface Props {
  text: string
  onClick: () => void
}

export const SummaryBoxAddCodeRow = ({ text, onClick }: Props): JSX.Element => (
  <button className="cursor-pointer d-flex align-items-center btn-unstyled ps-0" onClick={onClick}>
    <i className="uil-plus summary-box__codes__icon" />
    <span className="font-size-sm ms-2 summary-box__codes__text">{text}</span>
  </button>
)
