export type EventData<T = unknown> = {
  event: string
  pagePath?: string
  userID?: number | string
  date_range?: string
  number_of_days?: number
} & T

export interface EcommerceEventItem {
  item_id: string | number
  item_name: string
  index: number
  discount: string | number
  item_brand: string
  item_variant: string
  item_category: string
  item_category2?: string
  item_list_name: string
  price: string
  quantity: number
}

export interface EcommerceEventData {
  currency: string
  value: string
  items: Partial<EcommerceEventItem>[]
}

export interface CheckoutEventData {
  step: number
}

export interface ViewItemListEventData {
  item_list_name: string
}

export enum GTMEvents {
  PageView = 'pageview',
  ViewItemList = 'view_item_list',
  SelectItem = 'select_item',
  AddToCart = 'add_to_cart',
  RemoveFromCart = 'remove_from_cart',
  BeginCheckout = 'begin_checkout',
}

export enum GTMItemCategory {
  Resort = 'ośrodek',
  Feeding = 'wyżywienie',
  Improvement = 'ulepszenia',
}
