import * as React from 'react'
import { ResortImprovement } from '@models/reservation'
import { formatPrice, formatPriceShort } from '@helpers/utils'
import { SectionCardTextWithPrice } from '@modules/improvement-step/improvements-sections/section-card-text-with-price'

interface Props {
  improvement: ResortImprovement
  wrapperClassName?: string
}

export const VipPricesBlock = ({ improvement, wrapperClassName }: Props): JSX.Element => (
  <div className={wrapperClassName}>
    <SectionCardTextWithPrice
      text={<strong>Koszt pakietu VIP:</strong>}
      price={formatPriceShort(improvement.price_brutto)}
      wrapperClassName="text-primary font-size-xl mb-2"
    />
    <SectionCardTextWithPrice
      text="Cena standardowa udogodnień:"
      price={formatPrice('4390.6')}
      wrapperClassName="text-muted"
    />
    <SectionCardTextWithPrice
      text={<strong>Oszczędzasz:</strong>}
      price={formatPrice('3391.6')}
      wrapperClassName="text-darker-gray"
    />
  </div>
)
