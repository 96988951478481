import * as React from 'react'
import { Icon } from '@components/icon'

interface ExternalMediaProps {
  mediaType: 'facebook' | 'instagram' | 'youtube'
  url: string
}

const ExternalMedia = ({ mediaType, url }: ExternalMediaProps) => (
  <a href={url} target="_blank" className="footer-media__link me-3">
    <Icon icon={mediaType} className="footer-media__link__icon" />
  </a>
)

export const FooterExternalMedia = (): JSX.Element => (
  <div className="d-flex align-self-center footer-media">
    <strong className="text-muted align-self-center d-none d-xl-block me-3 font-size-sm">Dołącz do nas:</strong>
    <ExternalMedia mediaType="facebook" url="https://pl-pl.facebook.com/HolidayParkAndResort/" />
    <ExternalMedia mediaType="instagram" url="https://www.instagram.com/holiday_park_resort/?hl=pl/" />
    <ExternalMedia mediaType="youtube" url="https://www.youtube.com/channel/UCNpBXyb05GhbDRM2WoiUdBg" />
  </div>
)
