import * as React from 'react'
import * as clsx from 'clsx'
import 'flag-icons/css/flag-icons.min.css'

interface Props {
  lang: string
}

export const FlagIcon = ({ lang }: Props): JSX.Element => {
  return <span className={clsx('fi', `fi-${lang}`)} />
}
