import * as React from 'react'
import { formatPrice } from '@helpers/utils'
import { useSelector } from 'react-redux'
import { selectReservationUrls } from '@store/selectors/reservation-selectors'
import { useFormContext, useWatch } from 'react-hook-form'
import { useFormRequest } from '@hooks/use-form-request'
import { commonObjectGet } from '@requests/basic-requests'
import useDebounce from '@rooks/use-debounce'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'
import { SummaryGastroVoucherModalFormInputs } from '@modules/summary-step/promocodes/gastro-voucher/summary-gastro-voucher-modal'
import { CustomFormGroup } from '@components/controls/form-controls/custom-form-group'
import { getGastroCardCode } from '@modules/summary-step/promocodes/gastro-voucher/utils'

interface GastroVoucherCheckResponse {
  available_amount: string
}

export const GastroVoucherInput = (): JSX.Element => {
  const { gastro_card } = useSelector(selectReservationUrls)
  const [availableAmount, setAvailableAmount] = React.useState<string | null>(null)
  const { control, setError, clearErrors } = useFormContext<SummaryGastroVoucherModalFormInputs>()

  const gastroCardCode = useWatch({ control: control, name: 'gastro_card' })

  const { handleAction, isLoading } = useFormRequest(setError)

  const checkVoucherCode = async () => {
    const code = getGastroCardCode(gastroCardCode)
    if (!code.length) return

    clearErrors('gastro_card')
    const { data } = await handleAction<GastroVoucherCheckResponse>(() =>
      commonObjectGet(gastro_card, { params: { gastro_card: code } }),
    )
    setAvailableAmount(data?.available_amount || null)
  }

  const debouncedCodeChange = useDebounce(checkVoucherCode, 500)

  useDidUpdateEffect(() => {
    debouncedCodeChange()
  }, [gastroCardCode])

  return (
    <>
      <CustomFormGroup
        isLoading={isLoading}
        inputName="gastro_card"
        label="Wpisz kod vouchera Podwójna radość"
        formControlWrapperClassName="mb-3"
        formControlProps={{ className: 'letter-spacing-2 text-uppercase' }}
        formLabelProps={{ className: 'mb-2' }}
        isSucceed={!!availableAmount}
      />
      {availableAmount && (
        <div className="gastro-voucher__amount-to-spent">
          <span className="text-muted font-size-sm me-2">Środki do wykorzystania:</span>
          <strong className="text-darker-gray">{formatPrice(availableAmount)}</strong>
        </div>
      )}
    </>
  )
}
