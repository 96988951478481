import * as React from 'react'
import * as clsx from 'clsx'
import { TextWithSpinner } from '@components/loaders/text-with-spinner'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean
  text?: string | React.ReactElement
  iconClassName?: string
  textWrapperClassName?: string
  loadingText?: string
  spinnerColor?: string
}

export const SaveButton = ({
  isLoading,
  text = 'Zapisz',
  loadingText = 'Zapisywanie...',
  iconClassName = 'uil-check font-size-xxl me-2',
  textWrapperClassName = '',
  spinnerColor = 'white',
  ...props
}: Props): JSX.Element => (
  <button
    {...props}
    className={clsx('btn btn-tall shadow-none', props.className)}
    disabled={props.disabled || isLoading}
  >
    <TextWithSpinner
      text={text}
      iconClassName={iconClassName}
      loadingText={loadingText}
      spinnerColor={spinnerColor}
      isLoading={!!isLoading}
      wrapperClassName={textWrapperClassName}
    />
  </button>
)
