import * as React from 'react'
import { ModalWithImageWrapper } from '@modals/modal-with-image-wrapper'
import { BaseModalProps } from '@modals/types'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { IconWithText } from '@components/icon-with-text'
import { useAccommodationAvailability } from '@modules/accommodation-selection-step/dates-selection/use-accommodation-availability'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { updateReservation } from '@store/actions/reservation-actions'
import { Reservation } from '@models/reservation'
import { useModal } from '@modals/use-modal'

interface Props extends BaseModalProps {
  lastDates: Pick<Reservation, 'date_to' | 'date_from'>
  selectedDates: Pick<Reservation, 'date_to' | 'date_from'>
  message: string
}
export const InvalidCodeModal = ({ toggleIsVisible, lastDates, selectedDates, message }: Props): JSX.Element => {
  const { promocode } = useSelector(selectReservation)
  const [, hideDateSelectionModal] = useModal('DateSelectionModal')

  const dispatch = useDispatch()

  const { fetchAccommodationTypes } = useAccommodationAvailability()

  const fetchWithNewDatesWithoutCode = async () => {
    dispatch(updateReservation({ promocode: null }))
    fetchAccommodationTypes({ ...selectedDates, promocode: null })
    hideDateSelectionModal()
    toggleIsVisible()
  }

  const fetchWithLastDatesAndCode = async () => {
    fetchAccommodationTypes({ ...lastDates, promocode })
    hideDateSelectionModal()
    toggleIsVisible()
  }

  return (
    <ModalWithImageWrapper>
      <div className="ps-xl-5 py-4 container-lg">
        <p className="fw-semi-bold text-darker-gray">{message}</p>
        <span className="fw-semi-bold text-darker-gray">Czy na pewno chcesz zmienić termin rezerwacji?</span>
        <div className="d-flex flex-wrap mt-4 me-sm-5">
          <Button
            className="btn bg-secondary text-white border-secondary fw-semi-bold shadow-none mb-3 col-12"
            onClick={fetchWithNewDatesWithoutCode}
          >
            <IconWithText
              text="Tak, zmieniam termin i usuwam kod"
              iconClassName="uil-check me-2 font-size-xxl"
              textClassName="fw-semi-bold text-start"
            />
          </Button>
          <Button
            className="bg-white border-secondary text-secondary fw-semi-bold shadow-none col-12"
            onClick={fetchWithLastDatesAndCode}
          >
            <IconWithText
              text="Nie, anuluję zmianę terminu"
              iconClassName="uil-times me-2 font-size-xxl"
              textClassName="fw-semi-bold text-start"
            />
          </Button>
        </div>
      </div>
    </ModalWithImageWrapper>
  )
}
