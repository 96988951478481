import * as React from 'react'
import * as clsx from 'clsx'
import { FloatingItem } from '@components/floating-item'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  floatingItemClassName?: string
  floatingItemText?: string
  onFloatingItemClick: () => void
}

export const ModalMobileFloatingAction = ({
  floatingItemClassName,
  floatingItemText,
  onFloatingItemClick,
}: Props): JSX.Element => (
  <FloatingItem onClick={onFloatingItemClick} className={clsx(floatingItemClassName)}>
    <div className="d-flex gap-2 align-items-center justify-content-center h-100 text-white fw-bold">
      <IconWithText
        text={floatingItemText}
        textClassName="font-size-xl lh-1"
        iconClassName="uil-check me-2 font-size-xxl"
      />
    </div>
  </FloatingItem>
)
