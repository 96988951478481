import * as React from 'react'
import { ModalWithImageWrapper } from '@modals/modal-with-image-wrapper'
import { BaseModalProps } from '@modals/types'
import { Button } from 'react-bootstrap'
import { updateStep } from '@store/actions/steps-actions'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectPreviousStep } from '@store/selectors/steps-selectors'
import { useGtm } from '@hooks/use-gtm'

export const ReservationDataCleanupModal = ({ toggleIsVisible }: BaseModalProps): JSX.Element => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const previousStep = useSelector(selectPreviousStep)
  const { sendEvent } = useGtm()

  const moveToFirstStep = () => {
    if (!previousStep) return
    navigate(previousStep.url)

    dispatch(updateStep(previousStep))
    sendEvent({ ecommerce: null })
    toggleIsVisible()
  }

  return (
    <ModalWithImageWrapper>
      <div className="ps-xl-5 py-5 container-lg">
        <span>Przechodząc do pierwszego kroku wszystkie dotychczasowe zmiany zostaną usunięte.</span>
        <div className="d-flex flex-column flex-sm-row mt-4">
          <Button
            className="btn-thin btn bg-secondary text-white border-secondary fw-semi-bold shadow-none mb-3 me-sm-3"
            onClick={moveToFirstStep}
          >
            Przechodzę
          </Button>
          <Button
            className="btn-thin bg-white border-secondary text-secondary fw-semi-bold shadow-none"
            onClick={toggleIsVisible}
          >
            Zostaję
          </Button>
        </div>
      </div>
    </ModalWithImageWrapper>
  )
}
