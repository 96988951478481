import { FormErrorsActionTypesKeys } from '@store/action-types/form-errors-action-types'
import { FormErrorsActionsTypes } from '@store/action-types/form-errors-action-types'

export interface GlobalFormError {
  [key: string]: string
}

export interface FormErrorsState {
  errors: GlobalFormError
}

const initialState: FormErrorsState = {
  errors: {},
}

const formErrorsState = (state: FormErrorsState = initialState, action: FormErrorsActionsTypes): FormErrorsState => {
  switch (action.type) {
    case FormErrorsActionTypesKeys.SET_ERRORS:
      return { ...state, errors: { ...state.errors, ...action.payload } }
    case FormErrorsActionTypesKeys.REMOVE_ERRORS:
      return { ...state, errors: {} }
    default:
      return state
  }
}

export default formErrorsState
