import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  icon?: string
  value: string | JSX.Element | null | number
  title: string | JSX.Element
  valueRenderer?: (value) => JSX.Element
}

export const SummaryBoxReservationInformationItem = ({ value, title, icon, valueRenderer }: Props): JSX.Element => (
  <div className="d-flex justify-content-between my-1 my-xl-0 mb-xl-1">
    <div className="d-flex align-items-center">
      <IconWithText
        text={title}
        iconClassName={clsx(icon, 'me-2 summary-box__reservation-item__icon')}
        textClassName="summary-box__reservation-item__title"
      />
    </div>
    <strong className="font-size-sm summary-box__reservation-item__value">
      {valueRenderer?.(value) || value || '-'}
    </strong>
  </div>
)
