import { ModalConfig, ModalParams, ModalTypes } from '@modals/types'

export enum ModalActionTypesKeys {
  HIDE_MODAL = 'HIDE_MODAL',
  SET_IS_OPEN_MODAL = 'SET_IS_OPEN_MODAL',
  SHOW_MODAL = 'SHOW_MODAL',
}

export interface SetShowModalAction {
  type: ModalActionTypesKeys.SHOW_MODAL
  payload: {
    modal: ModalTypes
    params: ModalParams | undefined
    isOpen: boolean
    config?: ModalConfig
  }
}

export interface SetHideModalAction {
  type: ModalActionTypesKeys.HIDE_MODAL
  payload: ModalTypes
}

export interface SetModalIsOpenAction {
  type: ModalActionTypesKeys.SET_IS_OPEN_MODAL
  payload: {
    modal: ModalTypes
    isOpen: boolean
  }
}

export type ModalActionsTypes = SetHideModalAction | SetShowModalAction | SetModalIsOpenAction
