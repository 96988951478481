import * as React from 'react'
import * as clsx from 'clsx'
import { Opinion } from '@modules/accommodation-selection-step/opinions/opinion'
import { useModal } from '@modals/use-modal'
import { Resort } from '@models/app-data'

interface Props {
  wrapperClassName?: string
  contentWrapperClassName?: string
  ratingClassName?: string
  titleClassName?: string
  resort: Resort
}

export const Opinions = ({
  wrapperClassName,
  contentWrapperClassName,
  ratingClassName,
  titleClassName,
  resort,
}: Props): JSX.Element | null => {
  const [toggleIsOpen] = useModal('OpinionsModal', {
    defaultProvider: 'google',
    title: 'Opinie',
    resort: resort,
  })

  if (resort.google_maps_reviews_count === 0) return null

  return (
    <div className={wrapperClassName}>
      <div className={contentWrapperClassName}>
        <strong className={clsx('text-primary d-block', titleClassName)}>Sprawdź opinie:</strong>
        <div className="d-flex mt-1 justify-content-between gap-3">
          <Opinion
            ratingClassName={ratingClassName}
            logo="google"
            rating={`${resort.google_maps_rating} na 5`}
            opinionsCount={resort.google_maps_reviews_count || 0}
            onClick={toggleIsOpen}
          />
          {/*<Opinion*/}
          {/*  logo="logo_booking"*/}
          {/*  alt="logo booking"*/}
          {/*  rating={`${resortDetails?.booking_com_rating} na 10`}*/}
          {/*  opinionsCount={resortDetails?.booking_com_reviews_count || 0}*/}
          {/*  onClick={handleProviderClick('booking')}*/}
          {/*/>*/}
          {/*<Opinion*/}
          {/*  logo="logo_tripadvisor"*/}
          {/*  alt="logo tripadvisor"*/}
          {/*  rating={`${resortDetails?.trip_advisor_rating} na 5`}*/}
          {/*  opinionsCount={resortDetails?.trip_advisor_reviews_count}*/}
          {/*  onClick={handleProviderClick('tripadvisor')}*/}
          {/*/>*/}
        </div>
      </div>
    </div>
  )
}
