import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  onClick: () => void
  isVisible: boolean
}

export const Overlay = ({ onClick, isVisible }: Props): JSX.Element => (
  <div role="none" className={clsx('overlay', { 'overlay--visible': isVisible })} onClick={onClick} />
)
