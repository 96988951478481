import * as React from 'react'
import * as clsx from 'clsx'
import { SummaryBoxReservationInformationItem } from '@modules/aside/summary-box/summary-box-reservation-information-item'
import { DateFormats, formatDate } from '@helpers/date-formatter'
import { getAccommodationTypeName } from '@helpers/utils'
import { useSelector } from 'react-redux'
import {
  selectReservation,
  selectReservationAccommodationType,
  selectReservationResort,
} from '@store/selectors/reservation-selectors'
import dateHelper from '@services/date-helper'
import { stayDeclination } from '@helpers/declination'

interface Props {
  wrapperClassName?: string
}

export const SummaryBoxReservationInformation = ({ wrapperClassName }: Props): JSX.Element => {
  const reservation = useSelector(selectReservation)
  const resort = useSelector(selectReservationResort)
  const accommodationType = useSelector(selectReservationAccommodationType)

  const daysDiff = dateHelper.diffInDays(reservation.date_from, reservation.date_to)

  return (
    <div className={clsx(wrapperClassName)}>
      <SummaryBoxReservationInformationItem
        value={formatDate(reservation.date_from, DateFormats.DAY_WITH_DATE)}
        title="Przyjazd:"
        icon="uil-arrow-down-right"
      />
      <SummaryBoxReservationInformationItem
        value={
          <span>
            <span className="font-size-xs me-1">
              ({daysDiff} {stayDeclination(daysDiff)})
            </span>
            {formatDate(reservation.date_to, DateFormats.DAY_WITH_DATE)}
          </span>
        }
        title="Wyjazd:"
        icon="uil-arrow-up-right"
      />
      <SummaryBoxReservationInformationItem value={resort?.name || ''} title="Lokalizacja:" icon="uil-location-point" />
      <SummaryBoxReservationInformationItem
        value={getAccommodationTypeName(accommodationType, reservation.accommodation_type_name)}
        title="Rodzaj lokalu:"
        icon="uil-pathfinder-unite"
      />

      {!!accommodationType && (
        <SummaryBoxReservationInformationItem
          value={
            `${accommodationType?.display_max_guests} (maks. ${accommodationType?.display_max_adult_guests} dorosłych)` ||
            '-'
          }
          title="Maks. Gości w lokalu:"
          icon="uil-user"
        />
      )}
    </div>
  )
}
