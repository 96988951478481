import { ThunkResult } from '@models/models'
import { commonObjectPost } from '@requests/basic-requests'
import { SetClientDetailsAction } from '@store/action-types/client-details-action-types'
import { setClientDetails } from '@store/actions/client-details-actions'
import { ClientDetails } from '@models/app-data'

interface LoginResponse {
  client_details: ClientDetails
}

interface LoginPayload {
  username: string
  password: string
}

export function loginToAccount(payload: LoginPayload): ThunkResult<Promise<LoginResponse>, SetClientDetailsAction> {
  return async (dispatch, getState) => {
    const url = getState().appDataState.appData.urls.account.login
    const data = await commonObjectPost<LoginResponse, LoginPayload>(url, {
      password: payload.password,
      username: payload.username,
    })
    dispatch(setClientDetails({ ...data.client_details }))
    return data
  }
}

interface CreateAccountPayload {
  email: string
  password: string
  first_name?: string
  last_name?: string
  phone?: string
  city?: string
  postcode?: string
  street?: string
}

export function createAccount(payload: CreateAccountPayload): ThunkResult<Promise<void>, SetClientDetailsAction> {
  return async (dispatch, getState) => {
    const url = getState().appDataState.appData.urls.account.register
    const data = await commonObjectPost<ClientDetails, CreateAccountPayload>(url, payload, { withCredentials: true })
    dispatch(setClientDetails({ ...data }))
  }
}
