import * as React from 'react'
import { CollapsableItem } from '@components/collapsable-item'
import * as clsx from 'clsx'

interface CollapsableInformationRequired {
  title: string
}

interface Props {
  infos: Required<CollapsableInformationRequired>[]
  collapsableItemClassName?: string
  collapsableItemTitleWrapperClassName?: string
  wrapperClassName?: string
  contentRenderer: (data: Required<CollapsableInformationRequired>) => JSX.Element
}

export const CollapsableInformation = ({
  infos,
  collapsableItemClassName,
  collapsableItemTitleWrapperClassName,
  wrapperClassName,
  contentRenderer,
}: Props): JSX.Element => (
  <div className={wrapperClassName}>
    {infos.map((information, index) => (
      <CollapsableItem
        key={information.title || index}
        id={information.title}
        title={information.title}
        titleWrapperClassName={collapsableItemTitleWrapperClassName}
        className={clsx('collapsable-item__secondary', collapsableItemClassName)}
      >
        {contentRenderer(information)}
      </CollapsableItem>
    ))}
  </div>
)
