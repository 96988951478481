import * as React from 'react'
import * as clsx from 'clsx'

export type Icons =
  | 'arrow_top'
  | 'facebook'
  | 'instagram'
  | 'youtube'
  | 'check_selected'
  | 'shield_watermark'
  | 'shield'
  | 'aliorraty'
  | 'applepay'
  | 'bnpraty'
  | 'googlepay'
  | 'lang_de'
  | 'lang_en'
  | 'lang_pl'
  | 'booking'
  | 'google'
  | 'holiday'
  | 'tripadvisor'
  | 'mastercard'
  | 'mbankraty'
  | 'paypo'
  | 'przelewy24'
  | 'santanderraty'
  | 'visa'
  | 'blik'
  | 'grass'
  | 'percent'
  | 'sun'
  | 'subscription-with-benefits-logo'
  | 'tag'

interface Props extends React.SVGAttributes<SVGElement> {
  icon: Icons
}

export const Icon = ({ icon, ...props }: Props): JSX.Element => (
  <svg {...props} className={clsx(props.className, `icon-${icon}`)} data-testid={`icon-${icon}`}>
    <use xlinkHref={`${require('@assets/images/icons/icons.svg')}#${icon}`} />
  </svg>
)
