import * as React from 'react'
import { formatDate, parseISODate } from '@helpers/date-formatter'
import { Calendar } from 'react-date-range'
import { pl } from 'date-fns/locale'
import { useFormContext } from 'react-hook-form'
import { startOfToday } from 'date-fns'
import { FeedingDetailsModalFormInputs } from '@modules/improvement-step/improvements-sections/kinds/feedings/feeding-details-modal-guest-row'

interface Props {
  onBirthdayDateSelect: () => void
  birthday: string
}

export const FeedingDetailsModalGuestBirthdaySelection = ({ onBirthdayDateSelect, birthday }: Props): JSX.Element => {
  const { setValue } = useFormContext<FeedingDetailsModalFormInputs>()

  const handleBirthDateChange = (data: Date) => {
    onBirthdayDateSelect()
    setValue('type', formatDate(data))
  }

  return (
    <Calendar
      locale={pl}
      classNames={{
        month: 'feeding-modal__guest-birthday-selection__calendar__month',
        calendarWrapper: 'feeding-modal__guest-birthday-selection__calendar__wrapper',
      }}
      maxDate={startOfToday()}
      date={parseISODate(birthday)}
      onChange={handleBirthDateChange}
    />
  )
}
