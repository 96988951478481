import * as React from 'react'
import { ResortDetails } from '@models/resort'
import { useModal } from '@modals/use-modal'

interface Props {
  resortDetails: ResortDetails
}

export const ApartmentLocalization = ({ resortDetails }: Props): JSX.Element => {
  const [toggleMapDetailsShowed] = useModal('ApartmentLocalizationModal', {
    resortDetails,
    title: resortDetails.name,
  })

  return (
    <div className="container-lg pb-3 mt-3">
      <strong className="text-primary">Lokalizacja</strong>
      <span className="text-muted d-block my-2">{resortDetails.map_description}</span>
      <iframe src={resortDetails.google_maps_url} className="rounded bg-light-blue w-100" title="Lokalizacja resortu" />
      <button
        className="d-inline-block text-center text-muted mt-3 font-size-sm text-xl-start cursor-pointer btn-unstyled"
        onClick={toggleMapDetailsShowed}
      >
        Powiększ mapę
      </button>
    </div>
  )
}
