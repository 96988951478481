import * as React from 'react'
import { isAfter } from 'date-fns'
import { parseISODate } from '@helpers/date-formatter'
import { checkAccommodationTypeAvailable } from '@requests/reservation/reservation-requests'
import { setLoaderHidden } from '@store/actions/loader-actions'
import { useDispatch } from 'react-redux'
import { createErrorMessage, handleApiRequestError, useApiRequest } from '@hooks/use-form-request'
import { Reservation } from '@models/reservation'
import { AxiosError } from 'axios'
import { API_ERROR_CODE, API_ERROR_HEADERS } from '@helpers/consts'
import { useModal } from '@modals/use-modal'
import { closeDateRangeSelection } from '@store/slices/date-range-slice'

interface Response {
  fetchAccommodationTypes: (dates: Pick<Reservation, 'date_to' | 'date_from' | 'promocode'>) => Promise<void>
}

export const useAccommodationAvailability = (): Response => {
  const lastDates = React.useRef({ date_from: '', date_to: '' })
  const selectedDates = React.useRef({ date_from: '', date_to: '' })

  const dispatch = useDispatch()
  const [showInvalidCodeModal] = useModal('InvalidCodeModal', { title: 'Brak możliwości użycia kodu' })

  const handleError = (error: AxiosError) => {
    if (error.response?.status === 400 && error.response.headers[API_ERROR_CODE] === API_ERROR_HEADERS.INVALID_CODE) {
      setTimeout(() => {
        showInvalidCodeModal(null, {
          lastDates: lastDates.current,
          selectedDates: selectedDates.current,
          message: createErrorMessage(error),
        })
      }, 400)

      return
    }

    handleApiRequestError(error, dispatch)
  }

  const { handleAction } = useApiRequest({ shouldDispatchModalError: false, errorCatcher: handleError })

  const fetchAccommodationTypes = async ({
    date_from,
    date_to,
    promocode,
  }: Pick<Reservation, 'date_to' | 'date_from' | 'promocode'>) => {
    if (isAfter(parseISODate(date_to), parseISODate(date_from))) {
      const { error } = await handleAction(async () => {
        dispatch(closeDateRangeSelection())
        selectedDates.current = { date_to, date_from }
        await dispatch(checkAccommodationTypeAvailable({ date_to, date_from, promocode }))
        lastDates.current = { date_to, date_from }
      })

      if (error) dispatch(setLoaderHidden())
    }
  }

  return { fetchAccommodationTypes }
}
