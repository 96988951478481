import * as React from 'react'
import * as clsx from 'clsx'
import { TrapezoidButton } from '@components/controls/trapezoid-button'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  onButtonClick: (event?) => void
  isActive?: boolean
  isDisabled?: boolean
  btnDefaultContent?: JSX.Element | string
  btnClickedContent?: JSX.Element | string
  children?: JSX.Element
  wrapperClassName?: string
  trapezoidButtonClassName?: string
}

export const CardActions = ({
  onButtonClick,
  btnDefaultContent = <IconWithText text="wybieram" iconClassName="uil-check me-2 font-size-md" />,
  btnClickedContent = <IconWithText text="usuń" iconClassName="uil-minus me-2 font-size-md" />,
  trapezoidButtonClassName,
  children,
  wrapperClassName,
  isActive,
  isDisabled,
}: Props): JSX.Element => (
  <div className={clsx('ps-3 d-flex', children ? 'justify-content-between' : 'justify-content-end', wrapperClassName)}>
    {children}
    <TrapezoidButton
      onClick={onButtonClick}
      className={trapezoidButtonClassName}
      isActive={isActive}
      isDisabled={isDisabled}
    >
      {isActive ? btnClickedContent : btnDefaultContent}
    </TrapezoidButton>
  </div>
)
