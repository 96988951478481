export enum NotificationMessageVariant {
  MESSAGE = 'message',
  ERROR = 'error',
}

export interface NotificationMessage {
  title?: string
  subtitle?: string | null
  message?: string
  variant?: NotificationMessageVariant
}
