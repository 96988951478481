import * as React from 'react'
import { ModalBody } from 'react-bootstrap'
import { Apartment } from '@models/reservation'
import { IconWithText } from '@components/icon-with-text'
import { BaseModalProps } from '@modals/types'
import { useSelector } from 'react-redux'
import { selectAppData } from '@store/selectors/app-data-selectors'
import { getAccommodationTypeName } from '@helpers/utils'
import { ACCOMMODATION_TYPES } from '@helpers/consts'
import { useAppDispatch } from '@store/index'
import { updateReservation } from '@store/actions/reservation-actions'

interface Props extends BaseModalProps {
  apartment: Apartment
}

export const InvalidSelectedAccommodationTypeModal = ({ apartment, toggleIsVisible }: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  const { accommodation_types } = useSelector(selectAppData)

  const selectedAccommodationType = accommodation_types.find(
    accommodationType => accommodationType.id === apartment.accommodation_type_id,
  )

  const isHouse = ACCOMMODATION_TYPES.Houses.includes(apartment.accommodation_type_id)

  const handleAccommodationTypeChange = () => {
    dispatch(updateReservation({ accommodation_type_id: apartment.accommodation_type_id }))
    toggleIsVisible()
  }

  return (
    <ModalBody className="text-muted px-5 pb-4">
      <h3 className="fw-normal text-center mt-xl-n4">
        Wybierasz <span className="fw-semi-bold underline-shadow d-inline-block text-dark">inny typ lokalu</span>
      </h3>
      <div className="mx-n4 py-3">
        <img
          src={require(`@assets/images/${isHouse ? 'house' : 'apartment'}.webp`)}
          alt="Typ zakwaterowania"
          className="w-100 rounded"
        />
      </div>

      <p className="fw-semi-bold mb-1 text-center">
        Czy na pewno chcesz zmienić typ lokalu na{' '}
        <strong className="text-nowrap">{getAccommodationTypeName(selectedAccommodationType)}</strong>
      </p>
      <button
        className="btn btn-outline-secondary mt-3 px-5 py-3 w-100 d-flex justify-content-center"
        onClick={handleAccommodationTypeChange}
      >
        <IconWithText text="Zmień typ lokalu" iconClassName="uil-exchange-alt font-size-xxl me-2 lh-1" />
      </button>
      <button className="btn-unstyled text-center w-100 fw-bold mt-4 text-darker-gray" onClick={toggleIsVisible}>
        Zamknij
      </button>
    </ModalBody>
  )
}
