import * as React from 'react'
import { useSelector } from 'react-redux'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { selectIsPageLEaveAllowed } from '@store/reducers/page-leave-reducer'
import { commonObjectPost } from '@requests/basic-requests'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'
import { selectStep } from '@store/selectors/steps-selectors'
import { useModal } from '@modals/use-modal'
import { selectModals } from '@store/selectors/modal-selectors'

export const usePageLeaveConfirmation = (): void => {
  const reservation = useSelector(selectReservation)
  const isPageLeaveAllowed = useSelector(selectIsPageLEaveAllowed)

  const step = useSelector(selectStep)

  useDidUpdateEffect(() => {
    if (location.pathname === '/') clearReservationOnFirstStep()
  }, [location.pathname])

  const clearReservationOnFirstStep = async () => {
    if (reservation.urls.cancel_unfinished) {
      await commonObjectPost(reservation.urls.cancel_unfinished)
    }
  }

  const handleBeforeUnload = event => {
    if (reservation.urls.cancel_unfinished && !isPageLeaveAllowed && step.stepNumber > 1) {
      event.returnValue = ''
    }
  }

  const handleUnload = () => {
    if (reservation.urls.cancel_unfinished) {
      navigator.sendBeacon(reservation.urls.cancel_unfinished)
    }
  }

  const [showReservationCleanupWarning] = useModal('ReservationDataCleanupModal', { title: 'Uwaga' })

  const modals = useSelector(selectModals)

  const canBackToPreviousPage = React.useMemo(
    () => step.stepNumber === 2 && !modals.some(modal => modal.modal === 'ReservationDataCleanupModal'),
    [step, modals],
  )

  useDidUpdateEffect(() => {
    if (canBackToPreviousPage) {
      history.pushState(null, '', location.pathname)
      window.scrollTo({ top: document.documentElement.scrollTop })
    }
  }, [canBackToPreviousPage])

  const handlePopState = () => {
    if (canBackToPreviousPage) {
      history.pushState(null, '', location.pathname)
      showReservationCleanupWarning()
      window.scrollTo({ top: document.documentElement.scrollTop })
    }
  }

  React.useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('unload', handleUnload)
    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      window.removeEventListener('unload', handleUnload)
      window.removeEventListener('popstate', handlePopState)
    }
  }, [reservation.urls.cancel_unfinished, isPageLeaveAllowed, step, modals])
}
