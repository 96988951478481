import * as React from 'react'
import { formatPriceShort } from '@helpers/utils'
import { useModal } from '@modals/use-modal'
import { IconWithText } from '@components/icon-with-text'
import { Wallet } from '@models/reservation'

interface Props {
  wallet: Wallet
}

export const SummaryGastroVoucherAdded = ({ wallet }: Props): JSX.Element => {
  const [showGastroVoucherRemoveModal] = useModal('SummaryGastroVoucherRemoveModal', { wallet })

  return (
    <div className="text-darker-gray d-flex flex-sm-row flex-column align-items-sm-center w-100 gap-2">
      <div className="col-12 col-sm-9 d-flex flex-column align-items-start">
        <strong className="letter-spacing-2 lh-initial text-nowrap">{wallet.code}</strong>
        <span className="font-size-sm lh-initial mt-1">
          <span>Środki do wykorzystania: </span>
          <strong>{formatPriceShort(wallet.available_amount)}</strong>
          <span> z {formatPriceShort(wallet.start_amount)}</span>
        </span>
      </div>
      <IconWithText
        text="Odepnij"
        iconClassName="uil-link-broken me-2 font-size-xxl"
        wrapperClassName="text-muted col-12 col-sm-3"
        onClick={showGastroVoucherRemoveModal}
      />
    </div>
  )
}
