import * as React from 'react'
import { SaveButton } from '@components/controls/save-button'

interface Props {
  onReload: () => void
  isLoading: boolean
}

export const LocalSelectionMapPlaceholder = ({ onReload, isLoading }: Props): JSX.Element => {
  const [isReloaded, setIsReloaded] = React.useState(false)

  const handleReload = () => {
    onReload()
    setIsReloaded(true)
  }

  return (
    <div className="local-selection-modal__map__placeholder">
      <img src={require('@assets/images/map-placeholder.svg')} alt="Holiday park" className="crash-view__image" />
      <strong className="font-size-xxl col-sm-5 col-8 text-center mt-5">
        Przepraszamy, w tej chwili nie możemy załadować mapy ośrodka
      </strong>
      <p className="font-size-xl mt-3 col-sm-4 col-8 text-center">Wykorzystaj listę, aby wybrać dostępny lokal.</p>
      <SaveButton
        onClick={handleReload}
        className="btn bg-dark-gradient fw-semi-bold btn-thin mt-2 text-white px-4"
        text="Odśwież"
        iconClassName="text-white uil-sync"
        isLoading={isLoading}
        loadingText="Proszę czekać..."
      />
      {!isLoading && isReloaded && (
        <p className="col-5 text-center mt-4">Niestety mapa ośrodka nadal nie jest dostępna.</p>
      )}
    </div>
  )
}
