import * as React from 'react'
import { useModal } from '@modals/use-modal'
import { FeedingCard } from '@modules/improvement-step/improvements-sections/kinds/feedings/feeding-card'
import { createImprovementViewListItem, useGtmEvents } from '@hooks/use-gtm-events'

export const Feeding = (): JSX.Element => {
  const { selectItem } = useGtmEvents()

  const [showFeedingModal] = useModal('FeedingDetailsModal', { title: 'Szczegóły wyżywienia:' })

  const handleClick = () => {
    selectItem(createImprovementViewListItem({ code: 'feeding', name: 'Wyżywienie' }, 0), 'ulepszenia')
    showFeedingModal()
  }

  return <FeedingCard onAction={handleClick} />
}
