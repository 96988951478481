import { formatPriceShort } from '@helpers/utils'
import * as React from 'react'
import { ResortImprovement } from '@models/reservation'
import { isParking } from '@helpers/improvements'

interface Props {
  resortImprovement: ResortImprovement
}

export const ImprovementPrice = ({ resortImprovement }: Props): JSX.Element => {
  const isParkingImprovement = isParking(resortImprovement)

  return (
    <>
      {resortImprovement.price_brutto_first_item && (
        <strong className="d-block text-darker-gray">
          {formatPriceShort(resortImprovement.price_brutto_first_item)} / {resortImprovement.price_type_2_display}
          <span> - {isParkingImprovement ? 'pierwsze miejsce parkingowe' : 'pierwszy'}</span>
        </strong>
      )}
      <strong className="text-darker-gray">
        {formatPriceShort(resortImprovement.price_brutto)} / {resortImprovement.price_type_2_display}
        {resortImprovement.price_brutto_first_item && (
          <span> - {isParkingImprovement ? 'kolejne miejsce parkingowe' : 'kolejny'}</span>
        )}
      </strong>
    </>
  )
}
