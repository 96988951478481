import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { calculateReservationPrice } from '@requests/reservation/reservation-requests'
import { isAfter } from 'date-fns'
import { parseISODate } from '@helpers/date-formatter'
import { useAccommodationAvailability } from '@modules/accommodation-selection-step/dates-selection/use-accommodation-availability'

export const ReservationCalculatePrice: React.FC = () => {
  const { date_from, date_to, resort_id, accommodation_type_id, promocode } = useSelector(selectReservation)
  const { fetchAccommodationTypes } = useAccommodationAvailability()

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (
      resort_id &&
      accommodation_type_id &&
      date_from &&
      date_to &&
      isAfter(parseISODate(date_to), parseISODate(date_from))
    ) {
      dispatch(calculateReservationPrice())
    }
  }, [resort_id, accommodation_type_id, date_from, date_to])

  React.useEffect(() => {
    fetchAccommodationTypes({ date_from, date_to, promocode })
  }, [])

  return null
}
