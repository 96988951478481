import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  isLoading: boolean
  children?: React.ReactNode
  className?: string
}

export const ContentLoader = ({ isLoading, children, className }: Props): JSX.Element => (
  <div className={clsx('content-loader', { 'is-hidden': !isLoading }, className)}>
    <img
      src={require('@assets/loader.svg')}
      alt="Holiday park loader"
      className={clsx('content-loader__loader', { 'is-hidden': !isLoading })}
      data-testid="content-loader"
    />
    <div className={clsx('content-loader__content', { 'is-visible': !isLoading })}>{children}</div>
  </div>
)
