import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useModal } from '@modals/use-modal'
import { removeNotificationMessage, selectNotificationMessage } from '@store/slices/notification-messages-slice'

export const useNotificationModal = (): void => {
  const message = useSelector(selectNotificationMessage)

  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(removeNotificationMessage())
  }

  const [showNotificationModal] = useModal(
    'NotificationMessageModal',
    { title: message?.title || '' },
    { onClose: handleClose },
  )

  React.useEffect(() => {
    if (message) showNotificationModal(null, { message })
  }, [message])
}
