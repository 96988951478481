import * as React from 'react'
import { asDecimal, formatPrice } from '@helpers/utils'
import { useSelector } from 'react-redux'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { selectAccommodationTypeById } from '@store/selectors/app-data-selectors'
import * as clsx from 'clsx'
import { RootState } from '@store/index'
import { differenceInDays } from 'date-fns'
import { parseISODate } from '@helpers/date-formatter'

interface Props {
  wrapperClassName?: string
}

export const SummaryBoxPrices = ({ wrapperClassName }: Props): JSX.Element => {
  const {
    prices: { stay_charge_without_climatic },
    accommodation_type_id,
    date_from,
    date_to,
  } = useSelector(selectReservation)

  const accommodationType = useSelector((state: RootState) => selectAccommodationTypeById(state, accommodation_type_id))

  const pricePerGuest = React.useMemo(
    () =>
      asDecimal(stay_charge_without_climatic)
        .div(asDecimal(accommodationType?.display_max_guests || 1))
        .div(differenceInDays(parseISODate(date_to), parseISODate(date_from))),
    [stay_charge_without_climatic, accommodationType, date_to, date_from],
  )

  return (
    <div className={clsx('py-3 px-3', wrapperClassName)}>
      <div className="d-flex justify-content-between align-items-center">
        <strong className="font-size-lg">Cena za osobę za noc:</strong>
        <strong className="font-size-xxl">
          {formatPrice(pricePerGuest.isNaN() || !pricePerGuest.isFinite() ? 0 : pricePerGuest.toString())}
        </strong>
      </div>
      <div className="d-flex justify-content-between align-items-center font-size-md text-light-muted">
        <strong>Łącznie do zapłaty:</strong>
        <strong>{formatPrice(stay_charge_without_climatic)}</strong>
      </div>
    </div>
  )
}
