import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { DateFormats, formatDate, parseISODate } from '@helpers/date-formatter'
import { SummaryPaymentScheduleBar } from '@modules/summary-step/payment/summary-payment-schedule-bar'
import { BasePaymentSummary, PaymentSummary } from '@models/reservation'
import { compareAsc } from 'date-fns'
import { asDecimal } from '@helpers/utils'

const getPaymentSummaryPrice = (paymentItem: BasePaymentSummary) =>
  asDecimal(paymentItem.deposit).plus(asDecimal(paymentItem.installment)).toNumber()

interface Props {
  paymentSummary: PaymentSummary
  isPaid: boolean
}

export const SummaryPaymentSchedule = ({ paymentSummary, isPaid }: Props): JSX.Element => {
  const alreadyPaid = getPaymentSummaryPrice(paymentSummary.paid)
  const restToPay = getPaymentSummaryPrice(paymentSummary.rest)
  const requiredPayment = getPaymentSummaryPrice(paymentSummary.required)

  const requiredPaymentDate = formatDate(
    paymentSummary.required.required_date,
    DateFormats.DAY_MONTH_YEAR_SEPARATED_BY_DOT,
  )

  const totalPrice = asDecimal(requiredPayment).plus(alreadyPaid).plus(restToPay).toNumber()
  const getPercent = (price: number) => asDecimal(price).mul(100).div(totalPrice).toDecimalPlaces(1).toString()

  const restToPayDate = React.useMemo(() => {
    const dates = [
      ...(paymentSummary.rest.required_date ? [parseISODate(paymentSummary.rest.required_date)] : []),
      ...(paymentSummary.rest.required_date_deposit ? [parseISODate(paymentSummary.rest.required_date_deposit)] : []),
    ].sort(compareAsc)

    return formatDate(dates[0], DateFormats.DAY_MONTH_YEAR_SEPARATED_BY_DOT)
  }, [paymentSummary.rest])

  return (
    <div>
      <strong className="text-darker-gray">Harmonogram płatności (pobyt + kaucja)</strong>
      <div className="d-flex payment-schedule__wrapper">
        {alreadyPaid > 0 && (
          <SummaryPaymentScheduleBar
            percentageIndicator={`opłacono ${getPercent(alreadyPaid)}%`}
            percentage={getPercent(alreadyPaid)}
            className="is-rest-value is-paid"
            price={alreadyPaid}
          />
        )}
        {requiredPayment > 0 && (
          <SummaryPaymentScheduleBar
            percentageIndicator={`wpłata ${getPercent(requiredPayment)}%`}
            percentage={getPercent(requiredPayment)}
            className="is-base-value"
            price={requiredPayment}
            barLabel={`do ${requiredPaymentDate}`}
          />
        )}
        {restToPay > 0 && (
          <SummaryPaymentScheduleBar
            percentageIndicator={`pozostała kwota ${getPercent(restToPay)}%`}
            percentage={getPercent(restToPay)}
            className="is-rest-value"
            price={restToPay}
            barLabel={`do ${restToPayDate}`}
          />
        )}
      </div>
      {!isPaid && (
        <IconWithText
          textClassName="fw-semi-bold font-size-sm"
          wrapperClassName="d-flex flex-sm-row-reverse text-darker-gray mt-2"
          text="Przypomnimy Ci o płatności tydzień przed terminem"
          iconClassName="uil-exclamation-circle text-primary font-xxl me-2 me-sm-0 ms-sm-2 lh-initial"
        />
      )}
    </div>
  )
}
