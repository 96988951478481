import { ModalConfig, ModalParams, ModalTypes } from '@modals/types'
import {
  ModalActionTypesKeys,
  SetHideModalAction,
  SetModalIsOpenAction,
  SetShowModalAction,
} from '@store/action-types/modal-action-types'

export function setShowModal(modal: ModalTypes, params?: ModalParams, config?: ModalConfig): SetShowModalAction {
  return {
    type: ModalActionTypesKeys.SHOW_MODAL,
    payload: { modal, params, isOpen: true, config },
  }
}

export function setHideModal(modal: ModalTypes): SetHideModalAction {
  return {
    type: ModalActionTypesKeys.HIDE_MODAL,
    payload: modal,
  }
}

export function setModalIsOpen(modal: ModalTypes, isOpen: boolean): SetModalIsOpenAction {
  return {
    type: ModalActionTypesKeys.SET_IS_OPEN_MODAL,
    payload: { modal, isOpen },
  }
}
