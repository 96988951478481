import * as React from 'react'
import { CircleProgress, CircleProgressSizes } from '@components/circle-progress'

interface Props {
  step: number
  stepCount: number
}

export const ModalSteps = ({ step, stepCount }: Props): JSX.Element => (
  <div className="modal-steps">
    <div className="modal-steps__wrapper">
      <span className="text-darker-gray d-block me-2">Krok</span>
      <div className="position-relative">
        <span className="modal-steps__number">
          <strong>{step}</strong>/{stepCount}
        </span>
        <CircleProgress progress={(step / stepCount) * 100} size={CircleProgressSizes.MEDIUM} />
      </div>
    </div>
  </div>
)
