import * as React from 'react'
import { LocalForClientWithBenefitExpandable } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-for-client-with-benefit/local-for-client-with-benefit-expandable'
import { LocalForClientWithBenefitCode } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-for-client-with-benefit/local-for-client-with-benefit-code'
import { formatPriceShort } from '@helpers/utils'
import { LocalForClientOption } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-for-client-with-benefit/local-for-client-with-benefit-modal'
import { GLOBAL_URLS } from '@helpers/consts'

interface Props {
  isCollapsed: boolean
  onCollapse: (option: LocalForClientOption | null) => void
}

export const LocalForClientWithGoHolidayOption = ({ onCollapse, isCollapsed }: Props): JSX.Element => (
  <LocalForClientWithBenefitExpandable
    ecommerceLink={GLOBAL_URLS.ECOMMERCE_GO_HOLIDAY}
    isCollapsed={isCollapsed}
    onCollapse={() => onCollapse(isCollapsed ? null : 'go_holiday')}
    imageAlt="Pakiet Go!Holiday"
    image={require('@assets/images/go-holiday.svg')}
    description={
      <div className="text-md-start text-center">
        <h4 className="text-primary mb-md-0">Pakiety GO!Holiday</h4>
        <div>
          <div>
            Specjalna promocja na <strong>Pakiet 3 pobytów w domku</strong>
          </div>
          <div>
            do wykorzystania przez 5 lat,{' '}
            <strong>
              dostępna już od <span className="text-nowrap">{formatPriceShort(3999)}</span>
            </strong>
          </div>
        </div>
      </div>
    }
  >
    <p className="my-3 text-center text-md-start subscription-with-benefits-owner-modal__collapsed-content">
      Pobyty można realizować w terminach <strong>od 01.01 do 31.05 oraz od 01.10 do 31.12</strong> z wyłączeniem
      Terminów Specjalnych, we wszystkich dostępnych{' '}
      <span className="text-nowrap">Resortach Holiday Park & Resort.</span>
    </p>
    <LocalForClientWithBenefitCode productName="Pakiet GO!Holiday" />
  </LocalForClientWithBenefitExpandable>
)
