import * as React from 'react'
import * as clsx from 'clsx'
import { Spinner } from '@components/loaders/spinner'

interface Props {
  className: string
  isEnabled?: boolean
  onClick: () => void
  stepName: string
  text: string
  icon: string
  isLoading?: boolean
}

export const StepButton = ({
  className,
  isEnabled = true,
  onClick,
  stepName,
  text,
  icon,
  isLoading,
}: Props): JSX.Element => (
  <button className={clsx('btn bg-gold-gradient gap-4 step-button', className)} disabled={!isEnabled} onClick={onClick}>
    <div>
      <span className="d-block text-start fw-semi-bold">{text}</span>
      <strong className="font-size-xl">{stepName}</strong>
    </div>
    {isLoading && <Spinner className="text-primary spinner--small" color="white" />}
    <i className={clsx('font-size-icon', icon)} />
  </button>
)
