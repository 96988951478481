import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'

export interface VipImprovementBenefit {
  title: string
  discount?: string
}

interface Props {
  title: string
  subtitle: string
  improvements: VipImprovementBenefit[]
}

export const VipDetailList = ({ title, subtitle, improvements }: Props): JSX.Element => (
  <>
    <div className="p-3 ps-xl-5 container-lg">
      <strong className="text-secondary font-size-xl mb-3 d-block">{title}</strong>
      <span className="text-darker-gray mb-3 d-block">{subtitle}</span>
      <div className="improvement-modal__vip-list">
        {improvements.map(improvement => (
          <div className="w-100 mb-xl-2" key={improvement.title}>
            <IconWithText
              textClassName="fw-normal"
              wrapperClassName="d-inline-flex gap-2"
              text={
                <>
                  <span className="text-muted">{improvement.title} </span>
                  {!!improvement.discount && <span className="text-primary">{improvement.discount}</span>}
                </>
              }
              iconClassName="d-block uil-check text-primary me-1 font-size-lg align-self-start"
            />
          </div>
        ))}
      </div>
    </div>
    <div className="p-3 ps-xl-5 bg-light-blue text-muted border-top">
      <div className="container-lg ps-xl-0 text-xl-center font-size-sm">
        * usługa dostępna wyłącznie w Mielnie i Ustroniu Morskim - w okresie otwarcia ośrodków
      </div>
    </div>
  </>
)
