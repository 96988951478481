import * as React from 'react'
import * as clsx from 'clsx'
import { SectionSummaryPriceRow } from '@modules/improvement-step/improvements-sections/section-summary-price-row'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import { FeedingCalculations } from '@requests/reservation/reservation-request-models'

interface Props {
  isCalculating: boolean
  feedingCalculations: FeedingCalculations | undefined
}

export const FeedingDetailsModalSummaryPriceRow = ({ isCalculating, feedingCalculations }: Props): JSX.Element => {
  const priceWithoutDiscount = asDecimal(feedingCalculations?.total_price_brutto ?? 0)
    .add(feedingCalculations?.discount_price_brutto ?? 0)
    .toString()

  const hasDiscount = asDecimal(feedingCalculations?.discount).gt(0)

  return (
    <SectionSummaryPriceRow
      text="Koszt wyżywienia łącznie:"
      price={feedingCalculations?.total_price_brutto ?? 0}
      isCalculating={isCalculating}
    >
      {hasDiscount && (
        <>
          <Row left="Rabat:" right={`${feedingCalculations?.discount ?? 0}%`} className="fw-semi-bold" />
          <Row
            left="Cena standardowa wyżywienia:"
            right={formatPriceShort(priceWithoutDiscount)}
            className="fw-semi-bold"
          />
          <Row
            left="Oszczędzasz:"
            right={formatPriceShort(feedingCalculations?.discount_price_brutto ?? 0)}
            className="fw-bold"
          />
        </>
      )}
    </SectionSummaryPriceRow>
  )
}

const Row = ({ left, right, className = '' }) => (
  <div className={clsx('d-flex align-items-center justify-content-between font-size-md text-darker-gray', className)}>
    <span className="d-block">{left}</span>
    <span className="d-block">{right}</span>
  </div>
)
