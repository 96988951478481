import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  renderAs: 'div' | 'button'
  children: React.ReactNode
  className?: string
  onClick?: (event) => void
}

export const ElementRenderer = ({ renderAs, children, ...params }: Props): JSX.Element => {
  if (renderAs === 'button')
    return (
      <button {...params} className={clsx(params.className, 'btn-unstyled')} type="button">
        {children}
      </button>
    )

  return <div {...params}>{children}</div>
}
