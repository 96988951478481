import * as React from 'react'

interface ExpandableItems<T> {
  onShowAll: () => void
  length: number
  items: T[]
  isEveryVisible: boolean
}

export function useExpandableItems<T>(el: T[], initialAmount: number): ExpandableItems<T> {
  const [itemsToShow, setItemsToShow] = React.useState<T[]>([])

  React.useEffect(() => {
    setItemsToShow(el.filter((item, index) => index + 1 <= initialAmount))
  }, [el])

  const onShowAll = () => {
    setItemsToShow(el)
  }

  return {
    onShowAll,
    length: el.length - itemsToShow.length,
    items: itemsToShow,
    isEveryVisible: itemsToShow.length === el.length,
  }
}
