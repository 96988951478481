import * as React from 'react'
import * as clsx from 'clsx'
import { Rules } from '@helpers/rules'
import { useRules } from '@hooks/use-rules'

interface Props {
  wrapperClassName?: string
  linkClassName?: string
}

export const GeneralLinks = ({ wrapperClassName, linkClassName }: Props): JSX.Element => {
  const rules = useRules()

  const terms = [
    { name: 'Polityka prywatności', url: rules.privacy_policy_rules?.url },
    { name: 'Regulaminy', url: Rules.list },
  ]

  return (
    <div className={clsx('justify-content-center', wrapperClassName)}>
      {terms.map(link => (
        <a
          key={link.name}
          href={link.url}
          className={clsx('text-muted text-decoration-none font-size-sm', linkClassName)}
        >
          {link.name}
        </a>
      ))}
    </div>
  )
}
