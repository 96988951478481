import * as React from 'react'
import { useSelector } from 'react-redux'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { DateFormats, formatDate } from '@helpers/date-formatter'
import { asDecimal, formatPrice, formatPriceShort, sumDecimalArray } from '@helpers/utils'
import { BookingGuest, Discount, Feeding, ReservationImprovement } from '@models/reservation'
import { isPaidByVip, sortImprovementByPosition } from '@helpers/improvements'
import { SummaryPricesSection } from '@modules/summary-step/prices/summary-prices-section'
import { SummaryPriesSectionItem } from '@modules/summary-step/prices/summary-pries-section-item'
import { SummaryPayment } from '@modules/summary-step/payment/summary-payment'
import { stayDeclination } from '@helpers/declination'
import dateHelper from '@services/date-helper'
import { CustomTooltip } from '@components/custom-tooltip'
import { selectResortDetails } from '@store/selectors/resort-selectors'
import Decimal from 'decimal.js'

export const SummaryPrices = (): JSX.Element => {
  const reservation = useSelector(selectReservation)
  const resortDetails = useSelector(selectResortDetails)

  const feedingPriceForGuest = (guest: BookingGuest) =>
    sumDecimalArray(
      reservation.prices.feeding.items
        .filter((feeding: Feeding) => feeding.guest_id === guest.id)
        .map((feeding: Feeding) => asDecimal(feeding.price_brutto)),
    ).toString()

  const daysDiff = dateHelper.diffInDays(reservation.date_from, reservation.date_to)

  const lowestClimaticPrice = React.useMemo(() => {
    const prices = reservation.prices.climatic.items.reduce((previousValue, climaticItem) => {
      const price = asDecimal(climaticItem.single_price_brutto)
      return price.gt(0) ? [...previousValue, price] : previousValue
    }, [])

    return formatPriceShort(Decimal.min(...(prices.length ? prices : [asDecimal(0)])).toString())
  }, [resortDetails])

  const shouldShowServicePrice = asDecimal(reservation.prices.single_service_price_brutto_from).gt(0)

  return (
    <div className="bg-shadow pt-1">
      <div className="bg-light-azure">
        <div className="bg-light-blue rounded pt-4">
          <p className="text-primary fw-bold font-size-xl px-xl-4 container-lg">Całkowity koszt rezerwacji:</p>
          <div className="px-xl-4 container-lg d-flex justify-content-between gap-3">
            <strong className="text-darker-gray d-block">
              Pobyt {!!reservation.guests.length && <>{reservation.guests.length} osób</>} w dniach{' '}
              {formatDate(reservation.date_from)} - {formatDate(reservation.date_to)} ({daysDiff}{' '}
              {stayDeclination(daysDiff)})
            </strong>
            {reservation.prices.residence && (
              <strong className="text-darker-gray text-nowrap">
                + {formatPrice(reservation.prices.residence.total_price_brutto_before_discount)}
              </strong>
            )}
          </div>
          <div className="border-bottom w-100 my-2" />
          <SummaryPricesSection title="Ulepszenia" totalPrice={reservation.prices.improvements.total_price_brutto}>
            {sortImprovementByPosition([...reservation.prices.improvements.items])
              .filter((improvement: ReservationImprovement) => !isPaidByVip(improvement))
              .map((improvement: ReservationImprovement) => (
                <SummaryPriesSectionItem
                  key={improvement.id}
                  title={
                    <>
                      {improvement.name} {improvement.amount > 1 && <span>x{improvement.amount}</span>}
                    </>
                  }
                  price={improvement.price_brutto}
                />
              ))}
          </SummaryPricesSection>
          {!!reservation.guests.length && !!reservation.prices.feeding.items.length && (
            <>
              <div className="border-bottom w-100 my-2" />
              <SummaryPricesSection title="Wyżywienie" totalPrice={reservation.prices.feeding.total_price_brutto}>
                {reservation.guests.map((guest: BookingGuest) => (
                  <SummaryPriesSectionItem
                    key={guest.id}
                    title={guest.type_display}
                    price={feedingPriceForGuest(guest)}
                  />
                ))}
              </SummaryPricesSection>
            </>
          )}

          {reservation.warranty && (
            <>
              <div className="border-top w-100 my-2" />
              <div className="px-xl-4 container-lg d-flex justify-content-between gap-3 ">
                <strong className="text-darker-gray d-block">Opcja rezygnacji</strong>
                <strong className="text-darker-gray text-nowrap">
                  + {formatPrice(reservation.prices.warranty_price_brutto)}
                </strong>
              </div>
            </>
          )}

          <div className="border-bottom border-dark w-100 my-2" />
          <div className="px-xl-4 container-lg font-size-xl text-darker-gray d-flex justify-content-between">
            <strong className="d-block">Należność razem:</strong>
            <strong className="d-block">
              {formatPrice(reservation.prices.stay_charge_without_discount_without_climatic)}
            </strong>
          </div>
          {reservation.prices.discounts && !!reservation.prices.discounts.items.length && (
            <>
              <div className="border-bottom border-dark w-100 my-2" />
              <SummaryPricesSection
                title="Rabaty"
                totalPrice={reservation.prices.discounts.total_price_brutto}
                pricePrefix="-"
              >
                {reservation.prices.discounts.items.map((discount: Discount) => (
                  <SummaryPriesSectionItem
                    key={discount.name}
                    title={discount.name}
                    price={discount.price_brutto}
                    pricePrefix="-"
                  />
                ))}
              </SummaryPricesSection>
            </>
          )}
          <div className="border-bottom border-dark w-100 mt-2 bg-white" />
          <div className="px-xl-4 container-lg bg-white py-2">
            <div className="font-size-xl text-darker-gray d-flex justify-content-between">
              <strong className="d-block">Do zapłaty łącznie:</strong>
              <strong className="d-block text-danger">
                {formatPrice(reservation.prices.stay_charge_without_climatic)}
              </strong>
            </div>

            <div className="font-size-sm text-muted d-flex gap-3 justify-content-between mt-2">
              <strong className="d-block opacity-50">
                Kaucja zwrotna (nie jest zawarta w cenie){' '}
                {reservation.prices.payments_summary?.rest.required_date_deposit && (
                  <span>
                    płatna do dnia{' '}
                    {formatDate(
                      reservation.prices.payments_summary.rest.required_date_deposit,
                      DateFormats.DAY_MONTH_YEAR_SEPARATED_BY_DOT,
                    )}
                  </span>
                )}
              </strong>
              <strong className="d-block opacity-50">{formatPrice(reservation.prices.deposit_amount)} / pobyt </strong>
            </div>
            <div className="font-size-sm text-muted d-flex gap-3 justify-content-between mt-2">
              <strong className="d-block opacity-50">
                Do zapłaty w obiekcie: opłata klimatyczna (nie jest zawarta w cenie)
              </strong>
              <CustomTooltip
                id="climatic"
                content={
                  <ul className="px-3 py-2 mb-0 font-500">
                    {reservation.prices.climatic.items.map(climaticItem => (
                      <li key={climaticItem.name} className="text-nowrap">
                        {climaticItem.name}: {formatPriceShort(climaticItem.single_price_brutto)}
                      </li>
                    ))}
                  </ul>
                }
              >
                <strong className="d-block opacity-50">od {lowestClimaticPrice} / os. za dzień </strong>
              </CustomTooltip>
            </div>
            {shouldShowServicePrice && !reservation.skip_service_charge && (
              <div className="font-size-sm text-muted d-flex gap-3 justify-content-between mt-2">
                <strong className="d-block opacity-50">Opłata eksploatacyjna (nie jest zawarta w cenie) </strong>
                <strong className="d-block opacity-50">
                  od {formatPrice(reservation.prices.single_service_price_brutto_from)} / os. za dzień{' '}
                </strong>
              </div>
            )}
          </div>
        </div>
      </div>
      <SummaryPayment />
    </div>
  )
}
