import * as React from 'react'
import * as clsx from 'clsx'
import { SpinInput } from '@components/controls/spin-input'
import { ModalActions } from '@modals/modal-actions'
import { ModalSteps } from '@modals/modal-steps'
import { ImprovementModalWrapper } from '@modules/improvement-step/improvement-modal-wrapper'
import { useDispatch, useSelector } from 'react-redux'
import { selectGuestGroups, selectGuests, selectReservationFeedings } from '@store/selectors/reservation-selectors'
import { FeedingDetailsModalGuestRow } from '@modules/improvement-step/improvements-sections/kinds/feedings/feeding-details-modal-guest-row'
import { formatDate } from '@helpers/date-formatter'
import { createGuest, deleteGuests } from '@requests/reservation/guest-requests'
import { useApiRequest } from '@hooks/use-form-request'
import { BookingGuest } from '@models/reservation'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useAxiosErrorHandler } from '@hooks/use-axios-error-handler'
import { getFeedingImprovementData } from '@helpers/improvements'

interface Error {
  adultAmountInput: string | null
  childAmountInput: string | null
  childrenRowError: string | null
}

interface Props {
  onStepChange: () => void
  onModalClose: () => void
  step: number
}

export const FeedingDetailsModalGuestsStep = ({ onStepChange, onModalClose, step }: Props): JSX.Element => {
  const [error, setError] = React.useState<Error>({
    adultAmountInput: null,
    childAmountInput: null,
    childrenRowError: null,
  })

  const guests = useSelector(selectGuests)
  const groups = useSelector(selectGuestGroups)
  const reservationFeedings = useSelector(selectReservationFeedings)

  const { adultGuests, childrenGuests } = React.useMemo(
    () =>
      guests.reduce(
        (acc, curr) =>
          curr.type === 'adult'
            ? { ...acc, adultGuests: [...acc.adultGuests, curr] }
            : { ...acc, childrenGuests: [...acc.childrenGuests, curr] },
        { adultGuests: [], childrenGuests: [] },
      ),
    [guests],
  )

  const clearErrors = () => {
    setError({ adultAmountInput: null, childAmountInput: null, childrenRowError: null })
  }

  const dispatch = useDispatch()

  const handleAxiosFormErrors = useAxiosErrorHandler()

  const errorCatcher = React.useCallback(
    (name: keyof Error) => error => {
      handleAxiosFormErrors(error, (res, error) => {
        setError(state => ({ ...state, [name]: error.message }))
      })
    },
    [],
  )

  const { handleAction: handleAdultAction, isLoading: isAdultActionExecuting } = useApiRequest({
    shouldDispatchModalError: false,
    errorCatcher: errorCatcher('adultAmountInput'),
  })

  const { handleAction: handleChildrenAction, isLoading: isChildrenActionExecuting } = useApiRequest({
    shouldDispatchModalError: false,
    errorCatcher: errorCatcher('childAmountInput'),
  })

  const baseGuestFields = {
    group_id: groups[0]?.id,
    name: 'Gość',
    ...(reservationFeedings.items.length && {
      feeding_type: getFeedingImprovementData(reservationFeedings.items).code,
    }),
  }

  const handleAdultCountChange = async (newCount: number) => {
    if (newCount > adultGuests.length) {
      clearErrors()
      await handleAdultAction(() => dispatch(createGuest({ type: 'adult', ...baseGuestFields })))
      return
    }

    const guestToRemove = adultGuests[adultGuests.length - 1]
    if (!guestToRemove) return
    clearErrors()
    await handleAdultAction(() => dispatch(deleteGuests([guestToRemove.id])))
  }

  const handleChildrenCountChange = async (newCount: number) => {
    clearErrors()

    if (newCount > childrenGuests.length) {
      await handleChildrenAction(() => dispatch(createGuest({ birthday: formatDate(new Date()), ...baseGuestFields })))

      return
    }

    const guestToRemove = childrenGuests[childrenGuests.length - 1]

    if (!guestToRemove) return
    await handleChildrenAction(() => dispatch(deleteGuests([guestToRemove.id])))
  }

  return (
    <>
      <ImprovementModalWrapper
        contentTitle="Potrzebujemy tylko kilku informacji o Gościach"
        image={require('@assets/images/feeding.webp')}
        imageContent={<ModalSteps step={step} stepCount={2} />}
      >
        <div className="border-bottom">
          <div className="container-lg px-xl-5 py-4">
            <div className="d-flex gap-sm-0 justify-content-between gap-3">
              <div className="d-flex align-items-sm-center gap-sm-3 flex-column flex-sm-row gap-1">
                <span className="d-block text-darker-gray fw-semi-bold">Liczba dorosłych</span>
                <SpinInput
                  isLoading={isAdultActionExecuting}
                  className="btn-tall mx-5"
                  min={1}
                  onIncrement={handleAdultCountChange}
                  onDecrement={handleAdultCountChange}
                  value={adultGuests.length}
                  readOnly
                />
              </div>
              <div className="d-flex flex-wrap flex-column flex-sm-row align-items-sm-center gap-sm-3 gap-1">
                <span className="d-block text-darker-gray fw-semi-bold">Liczba dzieci</span>
                <SpinInput
                  isLoading={isChildrenActionExecuting}
                  className="btn-tall mx-5"
                  min={0}
                  onIncrement={handleChildrenCountChange}
                  onDecrement={handleChildrenCountChange}
                  value={childrenGuests.length}
                  readOnly
                />
              </div>
            </div>
            <div className="d-flex">
              {error.adultAmountInput && (
                <p className="text-danger font-size-sm w-100 mt-3 mb-0">{error.adultAmountInput}</p>
              )}
              {error.childAmountInput && (
                <p className="text-danger font-size-sm w-100 mt-3 mb-0 text-sm-end pe-2">{error.childAmountInput}</p>
              )}
            </div>
          </div>
        </div>
        <TransitionGroup
          className={clsx('container-lg px-xl-5 feeding-modal__guests-list-container', {
            'is-visible': childrenGuests.length,
          })}
        >
          {error.childrenRowError && (
            <p className="text-danger font-size-sm w-100 mt-3 mb-0">{error.childrenRowError}</p>
          )}
          {childrenGuests.map((guest: BookingGuest, index) => (
            <CSSTransition
              key={guest.id}
              classNames="feeding-modal__guests-list-fade"
              timeout={{ enter: 0, exit: 200 }}
              unmountOnExit
            >
              <FeedingDetailsModalGuestRow position={index + 1} childGuest={guest} onClearError={clearErrors} />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </ImprovementModalWrapper>
      <ModalActions
        onSubmit={onStepChange}
        onDecline={onModalClose}
        isSubmitDisabled={!guests.length || adultGuests.length < 1}
        submitText="Szczegóły wyżywienia"
        submitColor="secondary"
      />
    </>
  )
}
