import * as React from 'react'
import * as clsx from 'clsx'

interface SingleInformationProps {
  title: string
  subtitle: string
  children: string
  iconContent?: JSX.Element
  icon: string
  wrapperClassName?: string
  contentWrapperRenderer?: (children) => JSX.Element
}

export const SingleFooterInformation = ({
  icon,
  title,
  subtitle,
  children,
  iconContent,
  wrapperClassName,
  contentWrapperRenderer,
}: SingleInformationProps): JSX.Element => {
  const createMarkup = React.useCallback(() => ({ __html: children }), [children])
  const markupElements = <div className="text-muted font-size-xs mt-2" dangerouslySetInnerHTML={createMarkup()} />

  return (
    <div className={clsx('d-flex', wrapperClassName)}>
      <i className={clsx('position-relative text-muted font-size-xxl me-2', icon)}>{iconContent}</i>
      <div className="d-flex flex-column font-size-md">
        <strong className="text-muted lh-initial">{title}</strong>
        <span className="text-muted lh-initial font-size-sm">{subtitle}</span>
        {contentWrapperRenderer?.(markupElements) || markupElements}
      </div>
    </div>
  )
}
