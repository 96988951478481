import * as React from 'react'
import { SummaryPromoImprovementCard } from '@modules/summary-step/promocodes/summary-promo-improvement-card'
import { useModal } from '@modals/use-modal'
import { SummaryGastroVoucherAdded } from '@modules/summary-step/promocodes/gastro-voucher/summary-gastro-voucher-added'
import { SummaryCard } from '@modules/summary-step/summary-card'
import { useSelector } from 'react-redux'
import { selectWallets } from '@store/selectors/reservation-selectors'
import { Wallet } from '@models/reservation'
import { IconWithText } from '@components/icon-with-text'

export const SummaryGastroVoucher = (): JSX.Element => {
  const [showGastroVoucherModal] = useModal('SummaryGastroVoucherModal', { title: 'Dodaj voucher Podwójna Radość' })

  const wallets = useSelector(selectWallets)
  return (
    <SummaryCard
      title={
        wallets.length ? (
          <span>
            Dodany voucher <span className="underline-shadow">Podwójna Radość</span>
          </span>
        ) : (
          'Oferta tylko dla Ciebie'
        )
      }
    >
      <div className="d-flex flex-column flex-md-row">
        {wallets.length ? (
          <div className="w-100">
            {wallets.map((wallet: Wallet) => (
              <SummaryGastroVoucherAdded key={wallet.id} wallet={wallet} />
            ))}
            <IconWithText
              text="Dodaj kolejny voucher"
              wrapperClassName="text-darker-gray mt-1"
              iconClassName="uil-plus me-2 font-size-xxl"
              onClick={showGastroVoucherModal}
            />
          </div>
        ) : (
          <SummaryPromoImprovementCard
            isLoading={false}
            isActive={false}
            title="Promocja podwójna radość"
            description="Podwajamy wpłaconą przez Ciebie kwotę do wykorzystania w Bistro i Restauracji"
            image={require('@assets/images/gastro-voucher-image.webp')}
            onActionClick={showGastroVoucherModal}
          />
        )}
      </div>
    </SummaryCard>
  )
}
