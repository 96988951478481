import {
  AccommodationTypeAvailable,
  Apartment,
  Reservation,
  ReservationPrices,
  ReservationUrls,
} from '@models/reservation'
import { ReservationActionsTypes, ReservationActionTypesKeys } from '@store/action-types/reservation-action-types'

export const emptyReservationPrices: ReservationPrices = {
  improvements: { items: [], total_price_brutto: '0' },
  feeding: { items: [], total_price_brutto: '0' },
  residence: {
    discount: '0',
    discount_percentage: '0',
    name: '0',
    total_price_brutto: '0',
    total_price_brutto_before_discount: '0',
  },
  climatic: { items: [], total_price_brutto: '0' },
  warranty_price_brutto: '0',
  deposit_amount: '0',
  discounts: { items: [], total_price_brutto: '0' },
  stay_charge: '0',
  stay_charge_without_discount: '0',
  stay_charge_without_climatic: '0',
  stay_charge_without_discount_without_climatic: '0',
  single_service_price_brutto_from: '0',
  rest_to_pay: '0',
  rest_to_pay_without_climatic: '0',
  rest_to_pay_deposit: '0',
  tourist_vouchers: { items: [], total_price_brutto: '0' },
  payments_summary: {
    paid: { deposit: '0', installment: '0' },
    rest: { deposit: '0', installment: '0', required_date: '', required_date_deposit: '' },
    required: { deposit: '0', installment: '0', required_date: '' },
  },
}

export const emptyReservationUrls: ReservationUrls = {
  authenticate_client: '',
  cancel_unfinished: '',
  groups: '',
  guests: '',
  update: '',
  improvements: '',
  improvement_calculator: '',
  feeding: '',
  feeding_calculator: '',
  payment: '',
  finish: '',
  promocode: '',
  gastro_card: '',
  gastro_card_buy: '',
  sale: '',
  sale_payment_select: '',
}

export interface ReservationState {
  reservation: Reservation
  apartments: Apartment[]
  accommodationTypesAvailable: AccommodationTypeAvailable[]
}

const initialState: ReservationState = {
  accommodationTypesAvailable: [],
  reservation: {
    accommodation_type_name: null,
    id: null,
    expire_after: null,
    resort_id: null,
    rules: [],
    apartment_id: null,
    apartment_name: '',
    selected_apartment_id: null,
    accommodation_type_id: null,
    date_from: '',
    date_to: '',
    token: null,
    prices: emptyReservationPrices,
    can_add_warranty: true,
    city: '',
    country: '',
    email: '',
    name: '',
    phone: '',
    postcode: '',
    street: '',
    status: 'unfinished',
    groups: [],
    guests: [],
    invoice: false,
    invoice_city: '',
    invoice_company: '',
    invoice_country: '',
    invoice_nip: '',
    invoice_postcode: '',
    invoice_type: 'company',
    invoice_street: '',
    cars: [],
    is_paid: false,
    urls: emptyReservationUrls,
    warranty: false,
    warranty_costs: '0',
    requests: '',
    promocode: null,
    wallets: [],
    skip_service_charge: false,
  },
  apartments: [],
}

const reservationState = (
  state: ReservationState = initialState,
  action: ReservationActionsTypes,
): ReservationState => {
  switch (action.type) {
    case ReservationActionTypesKeys.UPDATE_RESERVATION:
      return { ...state, reservation: { ...state.reservation, ...action.payload.reservation } }
    case ReservationActionTypesKeys.SET_AVAILABLE_APARTMENTS:
      return { ...state, apartments: action.payload }
    case ReservationActionTypesKeys.UPDATE_ACCOMMODATION_TYPE_AVAILABLE:
      return { ...state, accommodationTypesAvailable: action.payload }
    default:
      return state
  }
}

export default reservationState
