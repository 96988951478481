import * as React from 'react'
import { Icon } from '@components/icon'

//TODO it's just temporary, will change when mockups would be available !!
export const CrashView = (): JSX.Element => {
  const handleReload = () => window.location.reload()

  return (
    <div className="crash-view__wrapper">
      <div className="crash-view__content">
        <Icon icon="holiday" className="mx-auto mx-xl-0 crash-view__logo" />
        <img src={require('@assets/images/hp-temporary.jpg')} alt="Holiday park" className="crash-view__image" />
        <h3 className="text-muted text-center mt-5 crash-view__text">
          Przepraszamy wystąpił błąd połączenia z serwerem
        </h3>
        <button
          className="btn btn-primary text-white font-size-md fw-bold py-3 mt-5 w-100 crash-view__button"
          onClick={handleReload}
        >
          Przeładuj stronę
        </button>
      </div>
    </div>
  )
}
