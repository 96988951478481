import { ModalActions } from '@modals/modal-actions'
import * as React from 'react'
import { BaseModalProps } from '@modals/types'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { IconWithText } from '@components/icon-with-text'
import { useToggle } from '@hooks/use-toggle'
import { SummaryGastroVoucherModalPurchase } from '@modules/summary-step/promocodes/gastro-voucher/summary-gastro-voucher-modal-purchase'
import { SummaryGastroVoucherModalInput } from '@modules/summary-step/promocodes/gastro-voucher/summary-gastro-voucher-modal-input'
import { useApiRequest, useFormRequest } from '@hooks/use-form-request'
import { useDispatch, useSelector } from 'react-redux'
import { commonObjectGet } from '@requests/basic-requests'
import { selectReservationUrls } from '@store/selectors/reservation-selectors'
import { ContentLoader } from '@components/loaders/content-loader'
import { addGastroVoucher, purchaseGastroVoucher } from '@requests/reservation/reservation-requests'
import { ReactSelectOption } from '@components/controls/custom-react-select'
import { getGastroCardCode } from '@modules/summary-step/promocodes/gastro-voucher/utils'

export interface GastroVoucherAvailabilityCheck {
  available: boolean
}

export interface SummaryGastroVoucherModalFormInputs {
  departure_voucher_rules: boolean
  amount: number | string
  gastro_card: string | ReactSelectOption
}

export const SummaryGastroVoucherModal = ({ toggleIsVisible }: BaseModalProps): JSX.Element => {
  const [isVoucherBuyingView, toggleIsVoucherBuyingView] = useToggle(true)
  const [isAvailable, setIsAvailable] = React.useState(false)

  const methods = useForm<SummaryGastroVoucherModalFormInputs>({
    defaultValues: {
      departure_voucher_rules: false,
      amount: '',
      gastro_card: '',
    },
  })

  const amount = useWatch({ control: methods.control, name: 'amount' })
  const voucher = useWatch({ control: methods.control, name: 'gastro_card' })
  const isGastroCardRuleAccepted = useWatch({ control: methods.control, name: 'departure_voucher_rules' })

  const { gastro_card_buy } = useSelector(selectReservationUrls)

  const { handleAction, isLoading } = useApiRequest()

  const { handleAction: handleVoucherAction, isLoading: isVouchActionProceeding } = useFormRequest(methods.setError, {
    axiosFormErrorConfig: { nonFieldErrorsAs: isVoucherBuyingView ? 'gastro_card' : 'departure_voucher_rules' },
  })

  const getGastroVoucherAvailability = async () => {
    const { data } = await handleAction<GastroVoucherAvailabilityCheck>(() => commonObjectGet(gastro_card_buy))

    setIsAvailable(!!data?.available)
  }

  React.useEffect(() => {
    getGastroVoucherAvailability()
  }, [])

  const dispatch = useDispatch()

  const handleSubmit = async () => {
    if (!isVoucherBuyingView) {
      const { isResolved } = await handleVoucherAction(() =>
        dispatch(purchaseGastroVoucher(Number(amount), isGastroCardRuleAccepted)),
      )
      if (isResolved) toggleIsVisible()
      return
    }

    const { isResolved } = await handleVoucherAction(() => dispatch(addGastroVoucher(getGastroCardCode(voucher))))
    if (isResolved) toggleIsVisible()
  }

  return (
    <ContentLoader isLoading={isLoading}>
      <FormProvider {...methods}>
        <div className="px-xl-5 mt-3 border-bottom container-lg">
          <p className="text-darker-gray col-sm-10 col-12">
            W ramach <strong> Promocji Podwójna Radość</strong> zwiększamy wpłaconą przez Ciebie kwotę, którą
            wykorzystasz w Bistro i Restauracji w trakcie pobytu.
          </p>
          <div className="mb-4">
            {isVoucherBuyingView ? <SummaryGastroVoucherModalInput /> : <SummaryGastroVoucherModalPurchase />}
            {isAvailable && (
              <IconWithText
                text={isVoucherBuyingView ? 'Chcę kupić voucher Podwójna Radość' : 'Mam już voucher Podwójna Radość'}
                wrapperClassName="text-darker-gray mt-3"
                iconClassName="uil-tag-alt me-3 font-size-xxl"
                onClick={toggleIsVoucherBuyingView}
              />
            )}
          </div>
        </div>
      </FormProvider>
      <ModalActions
        onDecline={toggleIsVisible}
        onSubmit={handleSubmit}
        isLoading={isVouchActionProceeding}
        isSubmitDisabled={
          (isVoucherBuyingView && !voucher) ||
          (!isVoucherBuyingView && !amount) ||
          (!isVoucherBuyingView && !isGastroCardRuleAccepted)
        }
        submitColor={isVoucherBuyingView ? 'secondary' : 'danger'}
        submitText={isVoucherBuyingView ? 'Dodaję do rezerwacji' : 'Kupuję i płacę'}
      />
    </ContentLoader>
  )
}
