import * as React from 'react'
import { ReservationImprovement } from '@models/reservation'
import { IconWithText } from '@components/icon-with-text'
import { useApiRequest } from '@hooks/use-form-request'
import { useDispatch } from 'react-redux'
import { isFeeding } from '@helpers/improvements'
import { removeFeeding } from '@requests/reservation/feeding-requests'
import { removeImprovement } from '@requests/reservation/improvement-requests'
import { BaseModalProps } from '@modals/types'
import { TextWithSpinner } from '@components/loaders/text-with-spinner'
import { RemoveItemModal } from '@modals/remove-item-modal'
import { createCartItem, useGtmEvents } from '@hooks/use-gtm-events'

interface Props extends BaseModalProps {
  improvement: ReservationImprovement | null
  onEdit: () => void
}

export const ImprovementDeleteModal = ({ improvement, onEdit, toggleIsVisible }: Props): JSX.Element => {
  const { handleAction, isLoading } = useApiRequest()
  const dispatch = useDispatch()
  const { removeFromCart } = useGtmEvents()

  const handleDelete = async () => {
    if (!improvement) return

    isFeeding(improvement)
      ? await handleAction(() => dispatch(removeFeeding()))
      : await handleAction(() => dispatch(removeImprovement(improvement.urls.details)))

    removeFromCart(improvement.price_brutto, createCartItem(improvement))

    toggleIsVisible()
  }

  return (
    <RemoveItemModal
      image={require('@assets/images/remove-improvement-modal-bg.webp')}
      title={
        <span className="text-dark font-size-xxl text-center px-3">
          Na pewno usunąć
          <br />
          <strong className="position-relative mx-auto">
            <span className="underline-shadow"> ulepszenie {improvement?.name.toLowerCase()}?</span>
          </strong>
        </span>
      }
      onUpperButtonClick={onEdit}
      upperAction={
        improvement &&
        improvement.can_update && (
          <IconWithText text="Edytuj ulepszenie" iconClassName="uil-edit-alt me-2 font-size-xxl" />
        )
      }
      bottomAction={
        <TextWithSpinner
          isLoading={isLoading}
          text="Usuń ulepszenie"
          loadingText="Usuwanie"
          spinnerColor="danger"
          onClick={handleDelete}
        />
      }
    />
  )
}
