import * as React from 'react'
import { useSteps } from '@hooks/use-steps'
import { LogoHolidayLink } from '@components/logo-holiday-link'
import { HeaderUserInfo } from '@modules/header/header-user-info'

export const Header = (): JSX.Element => {
  const { hasPrev, goPrev } = useSteps()

  return (
    <header className="d-flex align-items-center container-lg py-3 w-100">
      {hasPrev && (
        <button className="uil uil-angle-left-b d-xl-none font-size-icon me-3 btn-unstyled" onClick={goPrev} />
      )}
      <LogoHolidayLink className="mx-auto mx-xl-0 header__logo" />
      <div className="ms-auto align-items-center d-none d-xl-flex">
        <HeaderUserInfo />
      </div>
      {/*<div className="ms-auto align-items-center d-none d-xl-flex border-start">*/}
      {/*  <LanguageSelection />*/}
      {/*</div>*/}
      {/*<HamburgerMenu />*/}
    </header>
  )
}
