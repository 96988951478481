import { LoaderActionsTypes, LoaderActionTypesKeys } from '@store/action-types/loader-action-types'

export interface LoaderState {
  isVisible: boolean
}

const initialState: LoaderState = {
  isVisible: true,
}

const loaderState = (state: LoaderState = initialState, action: LoaderActionsTypes): LoaderState => {
  switch (action.type) {
    case LoaderActionTypesKeys.SET_LOADER_VISIBLE:
      return { ...state, isVisible: true }
    case LoaderActionTypesKeys.SET_LOADER_HIDDEN:
      return { ...state, isVisible: false }
    default:
      return state
  }
}

export default loaderState
