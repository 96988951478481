import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useApiRequest } from '@hooks/use-form-request'
import { removePromocode } from '@requests/reservation/reservation-requests'
import { useAppDispatch } from '@store/index'

interface Props {
  className?: string
}

export const SubscriptionWithBenefitCodeLabel = ({ className }: Props): JSX.Element => {
  const { handleAction } = useApiRequest()
  const dispatch = useAppDispatch()

  const handlePromocodeRemove = async () => {
    await handleAction(() => dispatch(removePromocode()))
  }

  return (
    <div className={className}>
      <div className="d-inline-block">
        <div className="subscription-with-benefits-owner__code-label">
          <IconWithText
            text={
              <span className="fw-semi-bold font-size-sm">
                Odblokowane <strong>Lokale Specjalne</strong>
                <button className="btn-unstyled" onClick={handlePromocodeRemove}>
                  <i className="uil-trash-alt ms-2" />
                </button>
              </span>
            }
            iconClassName="uil-tag-alt me-2 lh-1"
            wrapperClassName="text-white me-auto"
            textClassName="font-size-sm lh-1"
          />
        </div>
      </div>
    </div>
  )
}
