import * as clsx from 'clsx'
import * as React from 'react'
import { Resort } from '@models/app-data'
import { CustomTooltip } from '@components/custom-tooltip'
import { IconWithText } from '@components/icon-with-text'
import { useSelector } from 'react-redux'
import { selectResorts } from '@store/selectors/app-data-selectors'

interface Props {
  resort: Resort
}

export const ApartmentLocalizationFacilities = ({ resort }: Props): JSX.Element => {
  const resorts = useSelector(selectResorts)

  const resortWithFeatures = feature => resorts.filter(el => el.features.includes(feature))
  const isAvailable = feature => !!resort?.features.includes(feature)

  const features = React.useMemo(
    () => [...new Set(resorts.reduce((cum, acc) => [...cum, ...acc.features], []))],
    resorts,
  )

  return (
    <div className="d-flex flex-wrap mt-2">
      {features.map(feature => (
        <div key={feature} className="d-flex align-items-center text-disabled me-4 font-size-md fw-bold cursor-default">
          <CustomTooltip
            id={`${resort.name}-${feature}`}
            content={showResortWithFacility(resortWithFeatures(feature))}
            showOnHover={!isAvailable(feature)}
          >
            <IconWithText
              text={feature}
              iconClassName={clsx('uil-times font-size-xxl me-1', { 'text-primary uil-check': isAvailable(feature) })}
              textClassName={clsx({ 'text-primary': isAvailable(feature) })}
            />
          </CustomTooltip>
        </div>
      ))}
    </div>
  )
}

const showResortWithFacility = (resortWithFeature: Resort[]) => (
  <div className="apartment-localization__facilities-tooltip font-size-md">
    <span className="d-block mb-2">Udogodnienie dostępne w ośrodkach:</span>
    {resortWithFeature.map(resort => (
      <strong className="d-block text-start" key={resort.id}>
        <i className="uil-check font-size-sm me-2" />
        <span className="font-size-sm">{resort.name}</span>
      </strong>
    ))}
  </div>
)
